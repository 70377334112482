import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import styled from "styled-components";
import LoaderWrapper from "../../../../components/Loaders/LoaderWrapper";
import OutstandingModules from "../../../../components/OutstandingModules";
import PrivateHeader from "../../../../components/PrivateHeader";
import ErrorBlockWithPermissions from "../../../../components/UI/ErrorBlockWithPermissions";
import { ENGAGEMENT_TYPES } from "../../../../storage/constants";
import { GlobalState } from "../../../../store/GlobalState";
import useTeamMemberBreakdownInfo from "../../../../swr/hooks/RetailReports/singleTeamMemberV2/useTeamMemberBreakdownInfo";
import useTeamMemberGeneralInfo from "../../../../swr/hooks/RetailReports/singleTeamMemberV2/useTeamMemberGeneralInfo";
import EngagementTypeSelect from "../../reports/reuseableComponents/EngagementTypeSelect";
import Badges from "./Badges";
import RightSideRestricted from "./RightSideRestricted";
import TeamMemberRoles from "./TeamMemberRoles";
import UserHero from "./UserHero";
import { Helmet } from "react-helmet-async";

const MaxWidthWrapper = styled.div`
  margin: auto;
  max-width: 1600px;
  background-color: transparent;
  padding-bottom: 100px;
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 18px;
  flex-direction: row;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const InnerLeft = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 16px;
  flex-basis: 280px;

  @media (max-width: 900px) {
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 100%;
  }
`;

const TeamMemberDetail = ({ match }) => {
  const { id } = match.params;
  const { state } = useContext(GlobalState);
  const { job, config } = state;

  const isManager = job === "M";
  const { read_only: readOnly } = config.user;

  const {
    data: generalData,
    isLoading,
    isError,
    mutate,
  } = useTeamMemberGeneralInfo(id);

  const {
    data: breakdownData,
    isLoading: breakdownLoading,
    isError: breakdownError,
    // mutate: breakdownMutate,
  } = useTeamMemberBreakdownInfo(id);

  const [engagementType, setEngagementType] = useState(ENGAGEMENT_TYPES.ALL);

  if (isLoading) {
    return (
      <MaxWidthWrapper>
        <Helmet title="Spiffy | Team Member Detail" />
        <PrivateHeader header="Team Member" secondary={true} />
        <div
          style={{
            width: "100%",
            minHeight: 460,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoaderWrapper text="Team Member Details" />
        </div>
      </MaxWidthWrapper>
    );
  }

  if (isError) {
    return (
      <MaxWidthWrapper>
        <Helmet title="Spiffy | Team Member Detail" />

        <PrivateHeader header="Team Member" secondary={true} />
        <ErrorBlockWithPermissions errorObj={isError} />
      </MaxWidthWrapper>
    );
  }

  const fullName = `${generalData.user.first_name} ${generalData.user.last_name}`;

  return (
    <MaxWidthWrapper>
      <Helmet title="Spiffy | Team Member Detail" />

      <PrivateHeader
        header="Team Member"
        secondary={isLoading ? null : fullName}
        small
      >
        <EngagementTypeSelect
          engagementType={engagementType}
          setEngagementType={setEngagementType}
        />
      </PrivateHeader>
      <Container>
        <InnerLeft>
          <UserHero
            data={generalData}
            mutate={mutate}
            isManager={isManager}
            readOnly={readOnly}
          />

          <TeamMemberRoles
            id={id}
            readOnly={readOnly}
            isManager={isManager}
            details={generalData}
            mutate={mutate}
          />
          <OutstandingModules
            name={fullName}
            id={generalData.team_member.id}
            engagementType={engagementType}
          />
          <Badges badges={breakdownData?.badges} />
        </InnerLeft>
        <RightSideRestricted
          engagementType={engagementType}
          id={id}
          fullName={fullName}
          breakdownData={breakdownData}
          breakdownLoading={breakdownLoading}
          breakdownError={breakdownError}
        />
      </Container>
    </MaxWidthWrapper>
  );
};

export default TeamMemberDetail;

TeamMemberDetail.propTypes = {
  match: PropTypes.object.isRequired,
};
