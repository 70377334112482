import React from "react";
import IndividualModule from "./IndividualModule";

const DeleteList = ({ modules, handleItemClick, config, modIds }) => {
  const deleteList = modules.filter((mod) => mod.state === "4").reverse();

  return (
    <>
      {deleteList.map((mod) => (
        <IndividualModule
          key={mod.id}
          handleItemClick={handleItemClick}
          mod={mod}
          config={config}
          checked={modIds.includes(mod.id)}
        />
      ))}
    </>
  );
};

export default DeleteList;
