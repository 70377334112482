import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { fadeInRight } from "../../styles";
import { ENGAGEMENT_TYPES } from "../../../../storage/constants";

const Increase = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px 8px;
  border-radius: 4px;

  span {
    font-weight: 600;
    font-size: 11px;
  }

  svg {
    height: 14px;
    width: 14px;
  }
`;

const IncreaseDivAssigned = styled(Increase)`
  background-color: rgba(0, 0, 250, 0.1);
  animation: 400ms ${fadeInRight} ease-out;

  svg {
    color: #587dea;
  }

  span {
    color: #587dea;
  }
`;

const IncreaseDivAll = styled(Increase)`
  background-color: #92ff922e;
  animation: 400ms ${fadeInRight} ease-out;

  svg {
    color: #2e8b57b3;
  }

  span {
    color: #2e8b57b3;
  }
`;

const IncreaseDivExtraCredit = styled(Increase)`
  background-color: #ffeeb0;
  animation: 400ms ${fadeInRight} ease-out;

  svg {
    color: #aa9700;
  }

  span {
    color: #aa9700;
  }
`;

const HighlightIncrease = ({ children, subType }) => {
  if (subType === ENGAGEMENT_TYPES.EXTRACREDIT) {
    return <IncreaseDivExtraCredit>{children}</IncreaseDivExtraCredit>;
  }
  if (subType === ENGAGEMENT_TYPES.ASSIGNED) {
    return <IncreaseDivAssigned>{children}</IncreaseDivAssigned>;
  }

  return <IncreaseDivAll>{children}</IncreaseDivAll>;
};

export default HighlightIncrease;

HighlightIncrease.propTypes = {
  children: PropTypes.node.isRequired,
  subType: PropTypes.string.isRequired,
};
