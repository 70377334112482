import React from "react";
import { Tooltip, LinearProgress } from "@mui/material";
import { BarChart2, Check, XCircle } from "react-feather";
import styled from "styled-components";
import useSingleModuleProgress from "../../../../swr/hooks/RetailReports/useSingleModuleProgress";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
  position: relative;
  padding: 4px;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
    background-color: #eeeeee;
  }
`;

const InnerContainer = styled.div`
  background-color: white;
  border-radius: 4px;
  min-width: 200px;
`;

const CustomLinearProgress = styled(LinearProgress)`
  background-color: white;
  background-color: ${(props) => props.theme.palette.grey[100]};
  border-radius: 8px;
  min-height: 10px;
  width: 220px;
  border: 1px solid #eeeeee;

  .MuiLinearProgress-barColorPrimary {
    background-color: ${(props) => {
      if (props.value > 80) return props.theme.palette.success.main;
      if (props.value > 60) return props.theme.palette.success.light;
      if (props.value > 40) return props.theme.palette.warning.light;
      if (props.value > 20) return props.theme.palette.warning.main;
      if (props.value > 0) return props.theme.palette.error.main;
      return props.theme.palette.primary.main;
    }};
  }
`;

// const useStyles = makeStyles(() => ({
//   tooltip: {
//     borderRadius: 4,
//     padding: 0,
//     width: 230,
//     minHeight: 220,
//     background: "white",
//     border: "1px solid slategray",
//     padding: 0,
//   },
// }));

const getPercentage = (total, completed) => {
  if (!total || !completed) return 0;

  if (total === 0 || completed === 0) return 0;

  return Math.floor((completed / total) * 100);
};

const Inner = ({ moduleId }) => {
  const { isLoading, data } = useSingleModuleProgress(moduleId);

  if (isLoading) {
    return (
      <InnerContainer>
        <div
          style={{
            width: "100%",
            height: "100%",
            minHeight: 140,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span style={{ fontSize: 12, color: "lightgray" }}>loading...</span>
        </div>
      </InnerContainer>
    );
  }

  const { assigned_users: assignedUsers } = data;

  const numberOfUsers = assignedUsers.length;
  const numberOfUsersCompleted = assignedUsers.filter(
    (user) => user.completed,
  ).length;

  if (numberOfUsers === 0) {
    return (
      <InnerContainer>
        <div
          style={{
            width: "100%",
            padding: 8,
            background: "white",
            borderRadius: 4,
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            flexDirection: "column",
          }}
        >
          <span style={{ color: "firebrick" }}>
            no team members in audience
          </span>
        </div>
      </InnerContainer>
    );
  }

  const percentage = getPercentage(numberOfUsers, numberOfUsersCompleted);

  return (
    <InnerContainer>
      <div
        style={{
          width: "100%",
          padding: 4,
          background: "white",
          borderRadius: 4,
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <span style={{ color: "black", fontSize: 12 }}>Module Progress</span>
          <span style={{ color: "black", fontSize: 12, fontWeight: "700" }}>
            {percentage}%
          </span>
        </div>
        <div style={{ height: 6 }} />
        <CustomLinearProgress variant="determinate" value={percentage} />
        <div style={{ height: 2 }} />

        <span style={{ color: "black" }}>
          {numberOfUsers} assigned,{" "}
          <b style={{ color: "black", fontWeight: "500" }}>
            {numberOfUsersCompleted} completed
          </b>
        </span>
      </div>
      <div style={{ width: "100%", height: 1, background: "lightgray" }} />
      <div style={{ padding: 4, background: "white" }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span
            style={{
              fontSize: 9,
              fontWeight: "600",
              color: "black",
              marginTop: 2,
            }}
          >
            Assigned team members
          </span>
        </div>
      </div>
      <div
        style={{
          padding: "0px 4px 4px 4px",
          width: "100%",
          minHeight: 30,
          maxHeight: 140,
          overflowY: "scroll",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          flexDirection: "column",
          paddingBottom: 4,
          marginBottom: 8,
        }}
      >
        {assignedUsers.map((user) => (
          <div
            key={user?.id}
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "black", fontWeight: "400" }}>
              {user.first_name} {user.last_name}
            </span>
            {user.completed ? (
              <Check
                height={10}
                width={10}
                style={{
                  padding: 1,
                  borderRadius: 6,
                  background: "mediumseagreen",
                  color: "white",
                }}
              />
            ) : (
              <XCircle height={10} width={10} color="firebrick" />
            )}
          </div>
        ))}
      </div>
    </InnerContainer>
  );
};

const AudienceTooltip = ({ value, moduleId, state }) => {
  if (!value && state === "Live")
    return (
      <span style={{ fontSize: 10, color: "firebrick", fontWeight: "500" }}>
        No Audience
      </span>
    );

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      {(state === "Live" || state === "Closed") && (
        <Tooltip
          title={<Inner moduleId={moduleId} />}
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: "white",
                border: "1px solid slategrey",
                maxHeight: "none",
                padding: "0px",
              },
            },
          }}
          arrow
          placement="top"
          interactive
          leaveDelay={240}
        >
          <Container>
            {" "}
            <BarChart2 height={12} width={12} color="darkgrey" />{" "}
          </Container>
        </Tooltip>
      )}
    </div>
  );
};

export default AudienceTooltip;
