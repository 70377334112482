import useSWR from "swr";
import API from "../../../axios/instances/API";
import { getClientTimezone } from "../../../storage/helpers";

const { timezoneQueryStr } = getClientTimezone();

const BASE_URL = `dashboard/analytics/retail-reports/key-company-statistics/${timezoneQueryStr}`;

const fetcher = async (url) => {
  const { data } = await API.get(url);
  return data;
};

const useKeyCompanyStats = () => {
  const { data, error, mutate } = useSWR(BASE_URL, fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalidateOnMount: true,
  });

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useKeyCompanyStats;
