import styled from "styled-components";
import { Paper, Typography } from "@mui/material";

export const Wrapper = styled(Paper)`
  padding: 30px 40px 40px 40px;
  width: calc(min(520px, 96vw));
  min-height: 140px;

  @media (max-width: 560px) {
    padding: 20px;

    h2 {
      font-size: 22px;
    }
  }
`;

export const TextLink = styled(Typography)`
  cursor: pointer;
  text-decoration: underline;
  display: inline-block;
  font-weight: 500;
`;
