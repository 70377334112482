import useSWR from "swr";
import API from "../../../axios/instances/API";

const fetcher = async (url) => {
  const { data } = await API.get(url);
  return data.team;
};

const usePendingTeamInvitations = () => {
  const { data, error, mutate } = useSWR("dashboard/teams/pending/", fetcher, {
    revalidateOnFocus: false,
  });

  return {
    pending: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default usePendingTeamInvitations;
