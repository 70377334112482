import styled from "styled-components";
import { ChevronDown } from "react-feather";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";

export const IntroText = styled(Typography)`
  color: ${(props) => props.theme.palette.grey[700]};
  padding: 0px 40px 0px 0px;
  font-size: 12px;
  max-width: 640px;
`;

export const BDown = styled(ChevronDown)`
  color: ${(props) => props.theme.palette.primary.main};
`;

export const CustomDetails = styled(AccordionDetails)`
  display: block;
`;

export const CustomAccordionSummary = styled(AccordionSummary)`
  min-height: 20px;
  box-shadow: none;

  .Mui-expanded {
    min-height: 28px;
  }

  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 46px;
  }
`;

export const CustomAccordion = styled(Accordion)`
  box-shadow: none;

  &:before {
    display: none;
  }
`;
