import React, { useState, useContext } from "react";
import { Helmet } from "react-helmet-async";
import { Modal, AlertTitle, Alert } from "@mui/material";
import PrivateHeader from "../../../components/PrivateHeader";
import useAudienceList from "../../../swr/hooks/Audience/useAudienceList";
import LoaderWrapper from "../../../components/Loaders/LoaderWrapper";
import SnackbarWrapper from "../../../components/SnackbarWrapper";
import AudienceTable from "./AudienceTable";
import CreateAudience from "./CreateAudience";
import { GlobalState } from "../../../store/GlobalState";
import OnLoadError from "../../../components/UI/OnLoadError";
import { Cast } from "react-feather";
import CreateButton from "../../../components/UI/CreateButton";

const Audience = () => {
  const { state } = useContext(GlobalState);
  const { config, isRetail } = state;

  const { user } = config;
  const { read_only: readOnly } = user;

  const [addAudienceOpen, setAddAudienceOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState("");
  const { audienceList, isLoading, mutate, isError } = useAudienceList();

  if (isLoading) {
    return (
      <>
        <PrivateHeader
          header={isRetail ? "Audience Templates" : "Custom Audiences"}
          Icon={Cast}
        />
        <LoaderWrapper text="Audiences" />
      </>
    );
  }

  if (isError) {
    return <OnLoadError />;
  }

  return (
    <>
      <Helmet title="Spiffy | Audiences" />
      <PrivateHeader
        header={isRetail ? "Audience Templates" : "Custom Audiences"}
        Icon={Cast}
      />
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: 16,
        }}
      >
        <CreateButton
          disabled={readOnly}
          handleClick={() => setAddAudienceOpen(true)}
          title={isRetail ? "Create Template" : "Create Audience"}
        />

        <Alert severity="info">
          <AlertTitle style={{ fontWeight: "600" }}>
            What are audience templates?
          </AlertTitle>
          {isRetail && (
            <>
              <p>
                Most of the communication on our platform is sent to all team
                members at all locations, or all team members with a certain
                role, but sometimes you need to get more granular. That’s what
                audience templates are for! After creating a custom audience
                template, you can quickly apply it to modules you build in the
                future to have content delivered to this specific group of team
                members.
              </p>
              <div style={{ height: 8 }} />
              <ul>
                <li>
                  {" "}
                  Audience templates are used to create pre-saved groups of team
                  members, so you quickly and easily communicate with them when
                  building modules in the future. For example, “all Managers in
                  the Northeast region.”
                </li>
                <li>
                  You can add and remove groups by role or location, or get
                  granular by ticking one box at a time For example, “Managers
                  only, only at the Spiffsville location.”
                </li>
              </ul>
            </>
          )}
          {!isRetail && (
            <ul>
              <li>
                Most content is deployed to the marketplace, which means it is
                accessible to our broader userbase.
              </li>
              <li>
                If you want to create a module for a specific company, or
                selection of companies, you can use audeince templates to target
                them.
              </li>
            </ul>
          )}
        </Alert>

        <AudienceTable
          isRetail={isRetail}
          audienceList={audienceList}
          mutate={mutate}
          setSnackbarText={setSnackbarText}
          readOnly={readOnly}
        />
      </div>

      <Modal
        open={addAudienceOpen}
        onClose={() => setAddAudienceOpen(false)}
        aria-labelledby="Create New Audience"
        aria-describedby="Modal to Create New Audience"
      >
        <div>
          <CreateAudience
            setSnackbarText={setSnackbarText}
            mutate={mutate}
            isRetail={isRetail}
            setAddAudienceOpen={setAddAudienceOpen}
          />
        </div>
      </Modal>

      <SnackbarWrapper
        snackbarText={snackbarText}
        setSnackbarText={setSnackbarText}
      />
    </>
  );
};

export default Audience;
