import useSWR from "swr";
import API from "../../../axios/instances/API";

const fetcher = async (url) => {
  const { data } = await API.get(url);
  return data.streams;
};

const useStreams = () => {
  const { data, error, mutate } = useSWR("dashboard/streams/", fetcher, {
    revalidateOnFocus: false,
  });

  return {
    streams: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useStreams;
