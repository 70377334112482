import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Paper, Typography, Divider } from "@mui/material";
import { addDays } from "date-fns";
import { PieChart, Calendar, Cast } from "react-feather";
import { ShowState } from "../../../styles";
import {
  getDaysOpen,
  addCommas,
  getSrcTwo,
} from "../../../../../storage/helpers";
import {
  FlexDivTwo,
  MainContentContainer,
  SmallText,
  OverSized,
  ThirdContainer,
} from "../../reportStyles";
import ShowSelectedRetailAudience from "./ShowSelectedRetailAudience";

const Spacer = styled.div`
  height: 16px;
  background: transparent;
`;

const Wrapper = styled(Paper)`
  width: 100%;
  min-height: 200px;
  padding: 20px;
  border-radius: 4px;
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -8px;
`;

const ModuleImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 38px;
    width: 38px;
    margin-right: 8px;
    border-radius: 4px;
    border: 1px solid #eeeeee;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    span {
      font-size: 11px;
      color: ${(props) => props.theme.palette.grey[600]};
      // font-style: italic;
      margin-top: -2px;
    }
  }
`;

const QuizDetails = ({ selectedQuiz }) => {
  const { quiz } = selectedQuiz;
  const diff = getDaysOpen(quiz.startDate, quiz.dueDate);
  const selectAudience = selectedQuiz.quiz.audience;
  const cleanStartDate = quiz.startDate
    ? addDays(new Date(quiz.startDate), 1)
    : null;
  const cleanEndDate = quiz.dueDate ? addDays(new Date(quiz.dueDate), 1) : null;

  return (
    <>
      <Wrapper>
        <FlexDiv>
          <ModuleImageWrapper>
            <img src={getSrcTwo(quiz.image, true)} alt="module" />
            <div>
              <Typography variant="h5">{quiz.name}</Typography>
              {/* <span>{limitChars(quiz.description)}</span> */}
            </div>
          </ModuleImageWrapper>
          <ShowState $state={quiz.state}>
            <span>{quiz.state}</span>
          </ShowState>
        </FlexDiv>
        <Divider sx={{ mt: 1, mb: 1 }} />
        <MainContentContainer>
          <ThirdContainer>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: 4,
                // border: '1px solid red',
                marginBottom: 8,
                marginTop: 8,
              }}
            >
              <Calendar height={12} width={12} color="darkgrey" />
              <span
                style={{ color: "darkgray", fontWeight: "600", fontSize: 11 }}
              >
                DATES
              </span>
            </div>

            <FlexDivTwo>
              <SmallText>Start Date</SmallText>
              <OverSized>
                {quiz.startDate ? cleanStartDate.toDateString() : "None"}
              </OverSized>
            </FlexDivTwo>
            <FlexDivTwo>
              <SmallText>End Date</SmallText>
              <OverSized>
                {quiz.dueDate ? cleanEndDate.toDateString() : "None"}
              </OverSized>
            </FlexDivTwo>
            <FlexDivTwo>
              <SmallText>Days Open</SmallText>
              <OverSized>{`${diff} Days`}</OverSized>
            </FlexDivTwo>
          </ThirdContainer>
          <ThirdContainer>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: 4,
                marginBottom: 8,
                marginTop: 8,
              }}
            >
              <PieChart height={12} width={12} color="darkgrey" />
              <span
                style={{ color: "darkgray", fontWeight: "600", fontSize: 11 }}
              >
                STATS
              </span>
            </div>
            <FlexDivTwo>
              <SmallText>Total Submissions</SmallText>
              <OverSized>{addCommas(selectedQuiz.attempts)}</OverSized>
            </FlexDivTwo>
            <FlexDivTwo>
              <SmallText>Completions</SmallText>
              <OverSized>{addCommas(selectedQuiz.passed)}</OverSized>
            </FlexDivTwo>
            <FlexDivTwo>
              <SmallText>Failed Attempts</SmallText>
              <OverSized>{addCommas(selectedQuiz.failed)}</OverSized>
            </FlexDivTwo>
            <FlexDivTwo>
              <SmallText>Pass Rate</SmallText>
              <OverSized>{Math.floor(selectedQuiz.passRate * 100)}%</OverSized>
            </FlexDivTwo>
          </ThirdContainer>
          <ThirdContainer>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: 4,
                marginBottom: 8,
                marginTop: 8,
              }}
            >
              <Cast height={12} width={12} color="darkgrey" />
              <span
                style={{ color: "darkgray", fontWeight: "600", fontSize: 11 }}
              >
                AUDIENCE
              </span>
            </div>
            <ShowSelectedRetailAudience id={selectedQuiz.quiz.id} />
          </ThirdContainer>
        </MainContentContainer>
      </Wrapper>
      <Spacer />
    </>
  );
};

export default QuizDetails;

QuizDetails.propTypes = {
  selectedQuiz: PropTypes.object.isRequired,
};
