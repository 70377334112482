import React from "react";
import { Checkbox } from "@mui/material";
import { Check } from "react-feather";
import styled from "styled-components";

const StyledCheckbox = styled(Checkbox)`
  background-color: transparent;
  color: slategrey;
  height: 28px;
  width: 28px;

  &.Mui-checked {
    color: #2c61ac;
    background-color: transparent;
  }
`;

const CheckedIcon = ({ overrideColor = "#337ab7" }) => (
  <div
    style={{
      height: 14,
      width: 14,
      minHeight: 14,
      minWidth: 14,
      borderRadius: 4,
      background: overrideColor,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <Check height={12} width={12} style={{ color: "white" }} />
  </div>
);

const UncheckedIcon = () => (
  <div
    style={{
      height: 14,
      width: 14,
      minWidth: 14,
      minHeight: 14,
      borderRadius: 4,
      background: "lightgray",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  />
);

const CheckboxBrandBasic = ({
  checked,
  handleChange,
  overrideColor = "#337ab7",
}) => (
  <StyledCheckbox
    size="small"
    checked={checked}
    checkedIcon={<CheckedIcon overrideColor={overrideColor} />}
    icon={<UncheckedIcon />}
    onChange={handleChange}
  />
);

export default CheckboxBrandBasic;
