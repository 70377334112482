import styled from "styled-components";

export const FlexDivTwo = styled.div`
  padding: 1px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eeeeee86;
`;

export const SmallText = styled.span`
  font-size: 12px;
`;

export const OverSized = styled.span`
  font-size: 14px;
  font-weight: 700 !important;
`;

export const MainContentContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const ThirdContainer = styled.div`
  flex: 0.3;
  min-width: 260px;
`;
