import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  Typography,
  CircularProgress,
  TextField,
  Alert,
  Button,
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import { Helmet } from "react-helmet-async";
import { Link, useHistory } from "react-router-dom";
import { Wrapper, TextLink } from "../auth/styles";
import { setToken, setRefresh } from "../../../storage/helpers";
import AuthLayout from "../../../layouts/Auth";
import API from "../../../axios/instances/API";

const Subtitle = styled(Typography)`
  margin-bottom: 24px;
`;

const HyperLink = styled.a`
  color: ${(props) => props.theme.palette.error.light};
  text-decoration: underline;
  font-weight: 600;
`;

const initialValues = {
  email: "",
  password: "",
  submit: false,
};

const validationSchema = Yup.object({
  email: Yup.string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: Yup.string("Enter your password").required("Password is required"),
});

const SIGNIN_ERRORS = {
  NO_ACCOUNT_FOUND: "no_account_found",
  INVALID_PASSWORD: "invalid_password",
  APP_USER: "app_user",
};

const getResponseMsg = (err) => {
  switch (err) {
    case SIGNIN_ERRORS.NO_ACCOUNT_FOUND:
      return "There is no account with that email address";
    case SIGNIN_ERRORS.INVALID_PASSWORD:
      return "The password provided for this email is incorrect";
    case SIGNIN_ERRORS.APP_USER:
      return (
        <span>
          This account is for the app. Please download the{" "}
          <HyperLink href="https://apps.apple.com/ca/app/spiffy/id1138634357">
            Spiffy App
          </HyperLink>
        </span>
      );
    default:
      return "Unable to log in, please try again";
  }
};

const SignIn = ({ location }) => {
  const [showAlert, setShowAlert] = useState(false);
  const [showInviteMsg, setShowInviteMsg] = useState(false);
  const history = useHistory();
  useEffect(() => {
    if (!location) return;
    if (location === undefined) return;
    if (!location.state) return;

    if (location.state.fromConfig) {
      setShowAlert(
        "Oops.. we ran into an error loading your user information. Please try to sign in again.",
      );
    }

    if (location.state.fromInvite) {
      setShowInviteMsg(true);
    }
  }, [location]);

  const handleFormSubmit = async (values, { setSubmitting }) => {
    setShowAlert(false);
    setSubmitting(true);
    try {
      const { data } = await API.post("/dashboard/auth/", {
        username: values.email,
        password: values.password,
      });
      setToken(data.token);
      setRefresh(data.refresh);
      if (
        history.location.state &&
        history.location?.state?.pathName === "/marketplace/"
      ) {
        const pushUrl = `/marketplace/${
          history.location.state.search ? history.location.state.search : ""
        }`;
        return history.push(pushUrl);
      }
      return history.push("/home/");
    } catch (error) {
      setSubmitting(false);
      if (
        error.response &&
        error?.response?.data &&
        error?.response?.data?.error
      ) {
        const msg = getResponseMsg(error.response.data.error);
        return setShowAlert(msg);
      }
      return setShowAlert("Something went wrong. Please Try Again.");
    }
  };

  return (
    <AuthLayout>
      <Helmet title="Spiffy | Login" />
      <Wrapper align-text="center">
        <Typography variant="h2" component="h2" align="center">
          Welcome to Spiffy!
        </Typography>
        <Subtitle variant="subtitle2" align="center" color="textSecondary">
          Please Log In To Continue
        </Subtitle>
        {!showInviteMsg && (
          <Alert sx={{ mb: 4 }} severity="info">
            If you don&apos;t have an account yet..{" "}
            <TextLink color="primary" component={Link} to="/register">
              Sign Up
            </TextLink>
          </Alert>
        )}
        {showAlert && (
          <>
            <Alert
              severity="error"
              onClose={() => setShowAlert(false)}
              sx={{ mb: 4 }}
            >
              {showAlert}
            </Alert>
          </>
        )}
        {showInviteMsg && (
          <>
            <Alert severity="success" onClose={() => setShowInviteMsg(false)}>
              <p style={{ fontWeight: " 600" }}>
                Invitation successfully accepted.
              </p>
              <p>Please use credentials to sign in below.</p>
              <div style={{ height: 4 }} />
              <p style={{ fontWeight: "500" }}>{location?.state?.email}</p>
            </Alert>
            <div style={{ height: 12 }} />
          </>
        )}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
            isSubmitting,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <TextField
                type="email"
                name="email"
                label="Email Address"
                value={values.email}
                size="small"
                fullWidth
                error={Boolean(touched.email && errors.email)}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.email && errors.email}
                autoComplete="new-password"
                sx={{ mb: 4 }}
              />
              <TextField
                type="password"
                name="password"
                label="Your Password"
                value={values.password}
                sx={{ mb: 2 }}
                size="small"
                fullWidth
                error={Boolean(touched.password && errors.password)}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.password && errors.password}
                autoComplete="new-password"
              />
              <Button
                sx={{ mt: 4 }}
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
                disabled={isSubmitting}
                startIcon={isSubmitting && <CircularProgress size={20} />}
              >
                {isSubmitting ? "..." : "Log In"}
              </Button>
              <Button
                component={Link}
                to="/recover-password"
                align="center"
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
              >
                Forgot Password
              </Button>
            </form>
          )}
        </Formik>
      </Wrapper>
    </AuthLayout>
  );
};

export default SignIn;

SignIn.propTypes = {
  location: PropTypes.object.isRequired,
};
