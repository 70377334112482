import React from "react";
import useAudienceToolStore from "../../../store/useAudienceToolStore";
import Row from "./Row";
import { AlertTriangle } from "react-feather";

const TableBody = () => {
  const locations = useAudienceToolStore((state) => state.locations);
  const hasAtLeast1RoleAsOption = useAudienceToolStore(
    (state) => state.hasAtLeast1RoleAsOption,
  );
  const hasAtLeast1LocationAsOption = useAudienceToolStore(
    (state) => state.hasAtLeast1LocationAsOption,
  );

  return (
    <tbody
      style={{
        maxWidth: "100%",
        position: "relative",
      }}
    >
      {!hasAtLeast1RoleAsOption || !hasAtLeast1LocationAsOption ? (
        <tr style={{ border: "1px solid black" }}>
          <td style={{ background: "#eeeeee" }} />
          <td
            style={{
              height: 40,
              padding: 10,
              width: "100%",
              background: "#eeeeee",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <AlertTriangle color="darkorange" height={16} width={16} />
            <div style={{ width: 6 }} />
            <span
              style={{
                color: "black",
                whiteSpace: "none",
                fontSize: 13,
                fontWeight: "500",
              }}
            >
              Company must have at least 1 role and 1 location to build an
              audience
            </span>
          </td>
        </tr>
      ) : (
        <>
          {locations.map((location) => (
            <Row id={location.id} key={Number(location.id)} />
          ))}
        </>
      )}
    </tbody>
  );
};

export default TableBody;
