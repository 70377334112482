import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  background: white;
  border: 1px solid #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 260px;
  border-radius: 4px;
  transform: translateX(-1px);
`;

const TableLoader = ({ text = "loading.." }) => {
  return (
    <Wrapper>
      <span
        style={{
          fontSize: 14,
          color: "lightgray",
          letterSpacing: 1,
          fontWeight: 300,
        }}
      >
        loading...
      </span>
    </Wrapper>
  );
};

export default TableLoader;
