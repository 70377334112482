import useSWR from "swr";
import API from "../../../axios/instances/API";

const fetcher = async (url) => {
  const { data } = await API.get(url);

  return data;
};

const useAudienceV2 = (id) => {
  const { data, error, mutate } = useSWR(
    `dashboard/quiz/audience/?quiz_id=${id}`,
    fetcher,
    {
      revalidateOnFocus: false,
    },
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useAudienceV2;
