import React from "react";
import { MenuItem, InputAdornment, TextField } from "@mui/material";
import { Map } from "react-feather";

const LocationSelect = ({
  locations,
  handleChange,
  handleBlur,
  values,
  errors,
  touched,

  disabled = false,
}) => {
  return (
    <TextField
      select
      size="small"
      name="location"
      fullWidth
      variant="outlined"
      label="Location"
      onChange={handleChange}
      onBlur={handleBlur}
      disabled={disabled}
      value={values.location}
      error={Boolean(touched.location && errors.location)}
      helperText={touched.location && errors.location}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Map style={{ height: 14, width: 14, color: "darkgrey" }} />
          </InputAdornment>
        ),
      }}
    >
      {!locations ||
        (location.length === 0 && (
          <MenuItem disabled>No Locations Available</MenuItem>
        ))}
      {locations?.map((location) => (
        <MenuItem key={location.id} value={location.id}>
          {location.name}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default LocationSelect;
