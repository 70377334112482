import React, { useState } from "react";
import { Plus } from "react-feather";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  Autocomplete,
  Typography,
  TextField,
  Alert,
  Button,
  CircularProgress,
  Paper,
  Divider,
} from "@mui/material";
import SnackbarWrapper from "../../../../components/SnackbarWrapper";
import useLocations from "../../../../swr/hooks/Locations/useLocations";
import API from "../../../../axios/instances/API";
import { FEEDBACK_MESSAGES } from "../../../../storage/constants";

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(5)};
  min-height: 220px;
  min-width: 300px;
  text-align: left;
  display: flex;
  flex-direction: column;
`;

const LoadingMore = styled(CircularProgress)`
  height: 100px;
  width: 100px;
  color: ${(props) => props.theme.palette.info.light};
  position: relative;
`;

const Centered = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
`;

const AddSupervisorLocation = ({
  managerId,
  mutate,
  currentlyManaging,
  readOnly,
}) => {
  const [comboValue, setComboValue] = useState("");
  const [snackbarText, setSnackbarText] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [resetKey, setResetKey] = useState(false);
  const { locations, isLoading } = useLocations(); //tested

  if (isLoading) {
    return (
      <Wrapper>
        <Centered>
          <LoadingMore size={30} />
        </Centered>
      </Wrapper>
    );
  }

  /// Just filters the locations this person is already managing,
  // so you can't add them to a location they already manage
  const currentlyManagingArray = currentlyManaging.map(
    (location) => location.name,
  );

  const autocompleteOptions = locations.locations
    .filter((location) => !currentlyManagingArray.includes(location.name))
    .map((location) => ({
      id: location.id,
      name: `${location.name} (${location.city_name})`,
    }));

  const handleAdd = async () => {
    if (comboValue.length === 0) return;
    setIsSubmitting(true);
    setErrorAlert(false);
    try {
      await API.post("dashboard/managers/locations/", {
        manager_id: Number(managerId),
        locations: comboValue.map((location) => location.id),
      });
      mutate();
      setIsSubmitting(false);
      setSnackbarText("Locations successfully added success");
      setComboValue([]);
      setResetKey((p) => !p);
    } catch (error) {
      setIsSubmitting(false);
      setErrorAlert(true);
    }
  };

  return (
    <Wrapper style={{ flex: 1 }}>
      <Typography sx={{ mb: 1 }} variant="h4">
        Add Locations
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <Typography sx={{ fontSize: 13, fontWeight: 500, color: "darkgrey" }}>
        Add locations for this supervisor to manage.
      </Typography>
      <div style={{ marginTop: "auto" }} />
      <Autocomplete
        key={resetKey}
        multiple
        size="small"
        options={autocompleteOptions}
        noOptionsText="No Locations Available"
        getOptionLabel={(option) => option.name}
        onChange={(_, newValue) => setComboValue(newValue)}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField {...params} label="Locations To Add" variant="outlined" />
        )}
      />
      <Button
        onClick={handleAdd}
        disabled={readOnly || isSubmitting}
        startIcon={
          isSubmitting ? (
            <CircularProgress size={14} sx={{ color: "black" }} />
          ) : (
            <Plus height={16} width={16} />
          )
        }
        sx={{ mt: 2 }}
        variant="contained"
        color="primary"
        fullWidth
      >
        {isSubmitting ? "Adding" : "Add"}
      </Button>
      {errorAlert && (
        <Alert
          severity="error"
          sx={{ mt: 4 }}
          onClose={() => setErrorAlert(false)}
        >
          {FEEDBACK_MESSAGES.GENERIC_ERROR}
        </Alert>
      )}
      <SnackbarWrapper
        snackbarText={snackbarText}
        setSnackbarText={setSnackbarText}
      />
    </Wrapper>
  );
};

export default AddSupervisorLocation;

AddSupervisorLocation.propTypes = {
  managerId: PropTypes.string.isRequired,
  mutate: PropTypes.func.isRequired,
  currentlyManaging: PropTypes.array.isRequired,
  readOnly: PropTypes.bool.isRequired,
};
