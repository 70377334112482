import React from "react";
import { StyledItem } from "../../../styles";
import { Navigation } from "react-feather";
import {
  QUIZSTATES,
  LIBRARY_MODAL_KEYS,
  USER_TYPES,
} from "../../../../../storage/constants";

const LaunchPendingModule = ({
  id,
  moduleState,
  setModalState,
  handleClose,
  userType,
  isUserAManagerWhoCreatedModule,
}) => {
  if (moduleState !== QUIZSTATES.PENDING) return null;

  if (
    ![
      USER_TYPES.RETAIL_ADMIN,
      USER_TYPES.VENDOR,
      USER_TYPES.RETAIL_MANAGER_CAN_DEPLOY,
    ].includes(userType)
  )
    return null;

  if (
    userType === USER_TYPES.RETAIL_MANAGER_CAN_DEPLOY &&
    !isUserAManagerWhoCreatedModule
  )
    return null;

  return (
    <StyledItem
      onClick={() => {
        setModalState({ key: LIBRARY_MODAL_KEYS.LAUNCH_PENDING, id });
        handleClose();
      }}
    >
      <Navigation />
      <span>Launch Now</span>
    </StyledItem>
  );
};

export default LaunchPendingModule;
