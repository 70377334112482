import React, { useState } from "react";
import useEmailSettings from "../../swr/hooks/Util/useEmailSettings";
import styled from "styled-components";
import ExtraInfo from "../ExtraInfo";
import { CircularProgress, Switch } from "@mui/material";
import API from "../../axios/instances/API";

const ProgressMarginLeft = styled(CircularProgress)`
  margin-left: 2px;
`;

const SwitchContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FlexDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1px 0px;

  p {
    font-size: 12px;
    color: black;
  }

  span {
    font-size: 12px;
    color: black;
    font-weight: 500;
  }
`;

const UserSwitches = ({ user }) => {
  const { isLoading, isError, mutate, data } = useEmailSettings(
    true,
    user.username,
  );

  const [marketingEmailsUpdating, setMarketingEmailsUpdating] = useState(false);
  const [accountAccessEmailsUpdating, setAccountAccessEmailsUpdating] =
    useState(false);
  const [reportingEmailsUpdating, setReportingEmailsUpdating] = useState(false);

  if (isLoading) return null;
  if (isError) return null;

  const handleAccountAccessEmailsChange = async (e) => {
    setAccountAccessEmailsUpdating(true);
    try {
      await API.patch("/common/user/email-preferences/", {
        username: user.username,
        account_access_emails: e.target.checked,
      });
      await mutate();
      setAccountAccessEmailsUpdating(false);
    } catch (error) {
      setAccountAccessEmailsUpdating(false);
    }
  };

  const handleMarketingEmailsChange = async (e) => {
    setMarketingEmailsUpdating(true);
    try {
      await API.patch("/common/user/email-preferences/", {
        username: user.username,
        marketing_emails: e.target.checked,
      });
      await mutate();
      setMarketingEmailsUpdating(false);
    } catch (error) {
      setMarketingEmailsUpdating(false);
    }
  };

  const handleReportingEmailsChange = async (e) => {
    setReportingEmailsUpdating(true);
    try {
      await API.patch("/common/user/email-preferences/", {
        username: user.username,
        reporting_emails: e.target.checked,
      });
      await mutate();
      setReportingEmailsUpdating(false);
    } catch (error) {
      setReportingEmailsUpdating(false);
    }
  };

  return (
    <>
      <p style={{ fontSize: 11, color: "slategrey", fontWeight: "600" }}>
        COMMUNICATION OPTIONS
      </p>
      <div style={{ height: 4 }} />
      <SwitchContainer>
        <FlexDiv>
          <p>Allow reporting emails?</p>
          {reportingEmailsUpdating ? (
            <ProgressMarginLeft size={12} />
          ) : (
            <ExtraInfo
              text="Currently you do not allow report emails from Spiffy"
              saved={data.reporting_emails}
              savedText="You allow report emails to be sent to you from Spiffy"
            />
          )}
        </FlexDiv>
        <Switch
          checked={data.reporting_emails}
          onChange={handleReportingEmailsChange}
          disabled={reportingEmailsUpdating}
          size="small"
        />
      </SwitchContainer>
      <SwitchContainer>
        <FlexDiv>
          <p>Allow marketing emails?</p>
          {marketingEmailsUpdating ? (
            <ProgressMarginLeft size={12} />
          ) : (
            <ExtraInfo
              text="Currently you do not allow marketing emails from Spiffy"
              saved={data.marketing_emails}
              savedText="You allow marketing emails to be sent to you from Spiffy"
            />
          )}
        </FlexDiv>
        <Switch
          checked={data.marketing_emails}
          onChange={handleMarketingEmailsChange}
          disabled={marketingEmailsUpdating}
          size="small"
        />
      </SwitchContainer>
      <SwitchContainer>
        <FlexDiv>
          <p>Allow Account Access emails?</p>
          {accountAccessEmailsUpdating ? (
            <ProgressMarginLeft size={12} />
          ) : (
            <ExtraInfo
              text="Currently you do not allow account access emails from Spiffy"
              saved={data.account_access_emails}
              savedText="You allow account access emails to be sent to you from Spiffy"
            />
          )}
        </FlexDiv>
        <Switch
          checked={data.account_access_emails}
          onChange={handleAccountAccessEmailsChange}
          disabled={accountAccessEmailsUpdating}
          size="small"
        />
      </SwitchContainer>
    </>
  );
};

export default UserSwitches;
