import {
  Avatar,
  CircularProgress,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Trash2 } from "react-feather";
import styled from "styled-components";
import API from "../../../../axios/instances/API";
import { Shuffle } from "react-feather";
import { ShowState } from "../../styles";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  restrictToVerticalAxis,
  restrictToParentElement,
} from "@dnd-kit/modifiers";
import { get400ErrorMsg } from "../../../../storage/helpers";

const BlueShuffle = styled(Shuffle)`
  color: ${(props) => props.theme.palette.primary.light};
  transform: translateY(4px);
  margin-left: 4px;
`;

const CustomIconButton = styled(IconButton)`
  margin-left: auto;
  background-color: #eee;

  svg {
    height: 14px;
    width: 14px;
    cursor: pointer;
  }
`;

const CustomWrapper = styled(Paper)`
  width: min(100%, 700px);
  min-height: 340px;
  margin: auto;
  margin-top: 16px;
  padding: 20px;
`;

const DragWrapper = styled.div`
  width: 100%;
  min-height: 340px;
  margin: auto;
`;

const CustomDragItem = styled.div`
  width: 100%;
  height: 44px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  margin-top: 8px;
  cursor: pointer;
  background-color: white;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.2);
`;

const CustomAvatar = styled(Avatar)`
  background-color: #eeeeee44;
  border: 1px solid #eeeeee;
  height: 28px;
  width: 28px;
  border-radius: 6px;
  margin-right: 16px;
  font-size: 16px;
  font-weight: 700;
  color: black;
`;

const Centered = styled.div`
  width: 100%;
  height: 100%;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid #eeeeee;
  border-radius: 8px;
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  // flex: 1;
  flex-shrink: 1;
  flex-grow: 0;

  span {
    text-transform: uppercase;
    font-size: 11px;
    color: ${(props) => props.theme.palette.grey[500]};
  }
`;

const SortableItem = ({
  id,
  item,
  index,
  handleDelete,
  deletingId,
  details,
}) => {
  const readOnly = false;

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style}>
      <CustomDragItem>
        <div
          {...listeners}
          {...attributes}
          style={{
            flex: 1,
            paddingLeft: 8,
            display: "flex",
            alignItems: "center",
          }}
        >
          <CustomAvatar>
            <span style={{ color: "black" }}>{index + 1}</span>
          </CustomAvatar>

          <FlexDiv>
            <Typography
              variant="subtitle2"
              sx={{
                whiteSpace: "nowrap",
                maxWidth: 340,
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {item.name}
            </Typography>
            {item.is_marketplace ? (
              <span>By: {item.vendor}</span>
            ) : (
              <span>By: {details.stream.company}</span>
            )}
          </FlexDiv>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexBasis: 100,
              marginLeft: "auto",
              marginRight: 8,
            }}
          >
            <ShowState $state={item.state} style={{ marginLeft: 8 }}>
              <span>{item.state}</span>
            </ShowState>
          </div>
        </div>
        <div
          style={{
            height: "100%",
            flexBasis: 50,
            background: "#eeeeee",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "0px 12px",
          }}
        >
          <Tooltip title="Remove" placement="right">
            {deletingId === item?.id ? (
              <CircularProgress size={12} sx={{ color: "black" }} />
            ) : (
              <CustomIconButton
                size="small"
                aria-label="delete"
                disabled={readOnly || deletingId === item.id}
                onClick={() => handleDelete(item.id)}
              >
                <Trash2 />
              </CustomIconButton>
            )}
          </Tooltip>
        </div>
      </CustomDragItem>
    </div>
  );
};

const RearrangeQuizzesList = ({
  details,
  mutate,
  setSnackbarText,
  readOnly,
}) => {
  const [listQuizzes, setListQuizzes] = useState([]);
  const [deletingId, setDeletingId] = useState(null);

  const { quizzes } = details;

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  useEffect(() => {
    setListQuizzes(quizzes);
  }, [quizzes?.length]);

  const handleReorder = async (items) => {
    if (readOnly)
      return setSnackbarText("You are not authorized to reorder stream error");
    try {
      await API.put("dashboard/streams/quizzes/", {
        id: details.stream.id,
        quiz_ids: items.map((quiz) => quiz.id),
      });
      mutate();
      return setSnackbarText("Successfully reordered stream success");
    } catch (error) {
      // mutate in error, so it resets the order in the App to align with database //
      mutate();
      const errorMsg = get400ErrorMsg(error, "Failed to reorder stream error");
      return setSnackbarText(errorMsg);
    }
  };

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      setListQuizzes((items) => {
        const oldIndex = items.map((i) => i.id).indexOf(active.id);
        const newIndex = items.map((i) => i.id).indexOf(over.id);
        const newItemList = arrayMove(items, oldIndex, newIndex);
        handleReorder(newItemList);
        return newItemList;
      });
    }
  }

  const handleDelete = async (deleteId) => {
    setDeletingId(deleteId);
    const filteredQuizIds = listQuizzes
      .filter((quiz) => quiz.id !== deleteId)
      .map((quiz) => quiz.id);
    try {
      await API.put("dashboard/streams/quizzes/", {
        id: details.stream.id,
        quiz_ids: filteredQuizIds,
      });
      await mutate();
      setDeletingId(null);

      return setSnackbarText("Removed module from stream warning");
    } catch (error) {
      setDeletingId(null);
      const errorMsg = get400ErrorMsg(
        error,
        "Failed to remove module from stream error",
      );
      return setSnackbarText(errorMsg);
    }
  };

  if (details.quizzes.length === 0) {
    return (
      <CustomWrapper>
        <Typography variant="h3">
          Reorder Modules
          <BlueShuffle />
        </Typography>
        <Typography variant="subtitle1" sx={{ mb: 6 }}>
          Drag and drop modules to change the order they appear in the stream
        </Typography>
        <DragWrapper>
          <Centered>
            <Typography variant="h3">No Modules in this Stream yet</Typography>
            <Typography variant="subtitle1" color="secondary">
              Add Modules from the Available Modules Dropdown
            </Typography>
          </Centered>
        </DragWrapper>
      </CustomWrapper>
    );
  }

  return (
    <CustomWrapper>
      <Typography variant="h3">
        Reorder Modules
        <BlueShuffle />
      </Typography>
      <Typography variant="subtitle1" s={{ mb: 4 }}>
        Drag and drop modules to change the order they appear in the Spiffy App
      </Typography>
      <div>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          modifiers={[restrictToVerticalAxis, restrictToParentElement]}
        >
          <SortableContext
            items={listQuizzes}
            strategy={verticalListSortingStrategy}
          >
            {listQuizzes.map((item, i) => (
              <SortableItem
                index={i}
                item={item}
                key={item.id}
                id={item.id}
                handleDelete={handleDelete}
                deletingId={deletingId}
                details={details}
              />
            ))}
          </SortableContext>
        </DndContext>
      </div>
    </CustomWrapper>
  );
};

export default RearrangeQuizzesList;

RearrangeQuizzesList.propTypes = {
  details: PropTypes.object.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
  mutate: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  isRetail: PropTypes.bool.isRequired,
};
