import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import { Paper } from "@mui/material";
import { ChevronLeft, ChevronRight } from "react-feather";
import { Button, Typography } from "@mui/material";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const CustomPage = styled(Page)`
  max-height: 604px;
`;

const ModalWrapper = styled(Paper)`
  padding: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  max-width: 98vw;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const PdfDocumentHolder = styled(Document)`
  border: 2px solid #eeeeee;
`;

const PdfOutline = styled.div`
  height: 600px;
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PdfLoadError = () => (
  <PdfOutline>
    <em>
      <strong>
        <p>Error Loading PDF</p>
      </strong>
    </em>
  </PdfOutline>
);

const PdfLoading = () => (
  <PdfOutline>
    <em>
      <strong>
        <p>Loading...</p>
      </strong>
    </em>
  </PdfOutline>
);

const PdfModal = ({ media, setPdfExpanded }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [numberPages, setNumberPages] = useState(null);
  return (
    <ModalWrapper>
      <PdfDocumentHolder
        file={media.content}
        onLoadSuccess={({ numPages }) => setNumberPages(numPages)}
        noData="No Data"
        error={<PdfLoadError />}
        loading={<PdfLoading />}
      >
        <CustomPage
          renderAnnotationLayer={false}
          pageNumber={pageNumber}
          height={600}
        />
      </PdfDocumentHolder>
      {numberPages && (
        <>
          <Typography variant="body1">
            Page <strong>{pageNumber}</strong> of {numberPages}
          </Typography>
          <div>
            <Button
              onClick={() => setPageNumber((n) => n - 1)}
              disabled={pageNumber === 1}
              color="secondary"
              variant="contained"
              sx={{ mt: 2, mr: 1 }}
            >
              <ChevronLeft />
            </Button>
            <Button
              onClick={() => setPageNumber((n) => n + 1)}
              disabled={pageNumber === numberPages}
              color="secondary"
              variant="contained"
              sx={{ ml: 1, mt: 2 }}
            >
              <ChevronRight />
            </Button>
          </div>
        </>
      )}
    </ModalWrapper>
  );
};

export default PdfModal;

PdfModal.propTypes = {
  media: PropTypes.object.isRequired,
  setPdfExpanded: PropTypes.func.isRequired,
};
