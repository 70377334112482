import React from "react";
import SelectAll from "./SelectAll";
import { QUESTION_TYPES } from "../../../../../storage/constants";
import TrueFalse from "./TrueFalse";
import MultipleChoice from "./MultipleChoice";

const QuestionComponents = {
  [QUESTION_TYPES.TRUE_FALSE]: TrueFalse,
  [QUESTION_TYPES.MULTIPLE_CHOICE]: MultipleChoice,
  [QUESTION_TYPES.SELECT_ALL]: SelectAll,
};

const Questions = ({ data, questionIndex }) => {
  const numQuestions = data?.questions?.questions?.length;

  if (numQuestions === 0) {
    return (
      <div
        style={{
          flex: 1,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          padding: 8,
          background: "#eeeeee32",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span style={{ color: "darkgrey" }}>No questions added yet</span>
      </div>
    );
  }

  const selectedQuestion = data?.questions?.questions?.[questionIndex];
  const QuestionComponent = QuestionComponents[selectedQuestion.type];

  return (
    <div
      style={{
        flex: 1,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        padding: 8,
        background: "#eeeeee32",
      }}
    >
      <div
        style={{
          height: 30,
          width: "100%",

          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: 2,
        }}
      >
        <span style={{ fontSize: 15, fontWeight: "500" }}>Question</span>
        <span style={{ fontSize: 15, color: "black", fontWeight: "500" }}>
          {questionIndex + 1}
        </span>
        <span style={{ fontSize: 15, color: "darkgrey" }}>/</span>
        <span style={{ fontSize: 15, color: "darkgrey" }}>{numQuestions}</span>
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
          paddingTop: 8,
        }}
      >
        <div
          style={{
            width: "100%",
            minHeight: 200,
            background: "white",
            borderRadius: 4,
            boxShadow: "1px 1px 2px 2px #eeeeee",
            padding: 8,
          }}
        >
          <p
            style={{
              fontSize: 14,
              fontWeight: "500",
              lineHeight: 1.3,
              marginTop: 4,
            }}
          >
            {selectedQuestion?.question}
          </p>
          <QuestionComponent data={data} selectedQuestion={selectedQuestion} />
        </div>
      </div>
    </div>
  );
};

export default Questions;
