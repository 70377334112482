import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  IconButton,
  Tooltip,
  Drawer,
  Typography,
  Divider,
} from "@mui/material";
// import { HelpOutline } from "@mui/icons-material";
import { HelpCircle } from "react-feather";
import { useLocation } from "react-router-dom";
import QuizBuilder from "./QuizBuilder";
import { GlobalState } from "../../store/GlobalState";

const DrawerContentWrapper = styled.div`
  min-width: 400px;
  width: 46vw;
  max-width: 720px;
  padding: 40px;
  text-align: left;
  padding-top: 80px;
`;

const SplitHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    color: ${(props) => props.theme.palette.info.light};
    transform: translateY(-1px);
  }
`;

const CustomButton = styled(IconButton)`
  position: fixed;
  bottom: 20px;
  right: 10px;
  background-color: rgba(1, 1, 1, 0.04);
  border: 1px solid #eeeeee;
  z-index: 10;

  svg {
    color: ${(props) => props.theme.palette.grey[400]};
    transition: all 200ms ease-in;
  }

  &:hover {
    background-color: #eeeeee;
    svg {
      color: ${(props) => props.theme.palette.info.light};
    }
  }
`;

const AboutThisPage = ({ pageTitle, tipText }) => {
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const location = useLocation();
  const { state } = useContext(GlobalState);
  const { isRetail } = state;

  const getElement = (pathname) => {
    if (pathname.includes("/quiz-builder")) return QuizBuilder;

    return null;
  };

  const Element = getElement(location.pathname);

  return (
    <>
      <Tooltip title={tipText} placement="left" arrow>
        <CustomButton onClick={() => setIsInfoOpen((p) => !p)}>
          <HelpCircle height={20} width={20} />
        </CustomButton>
      </Tooltip>
      <Drawer
        anchor="right"
        open={isInfoOpen}
        onClose={() => setIsInfoOpen(false)}
      >
        <DrawerContentWrapper>
          <SplitHeader>
            <Typography variant="h3" sx={{ mb: 2 }}>
              {pageTitle}
            </Typography>
            <HelpCircle height={20} width={20} />
          </SplitHeader>
          <Divider sx={{ mb: 6 }} />
          <Element isRetail={isRetail} />
        </DrawerContentWrapper>
      </Drawer>
    </>
  );
};

export default AboutThisPage;

AboutThisPage.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  tipText: PropTypes.string.isRequired,
};
