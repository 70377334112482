import useSWR from "swr";
import API from "../../../axios/instances/API";

const fetcher = async (url) => {
  const { data } = await API.get(url);
  return data;
};

const useRoleDetails = (id) => {
  const { data, error, mutate } = useSWR(
    `dashboard/roles/details/?id=${id}`,
    fetcher,
  );

  return {
    details: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useRoleDetails;
