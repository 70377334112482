import typography from "./typography";
import overrides from "./overrides";
import breakpoints from "./breakpoints";
import { createTheme } from "@mui/material/styles";
import { grey } from "@mui/material/colors";

const customBlue = {
  50: "#e9f0fb",
  100: "#c8daf4",
  200: "#a3c1ed",
  300: "#7ea8e5",
  400: "#6395e0",
  500: "#22597f",
  600: "#233044",
  700: "#22597f",
  800: "#2f65cb",
  900: "#2052c2",
};

const options = {
  spacing: 4,
  breakpoints,
  components: overrides,
  typography: typography,
  palette: {
    mode: "light",
    primary: {
      main: "#337ab7",
      contrastText: "#FFF",
    },
    secondary: {
      main: "#233044",
      contrastText: "#FFF",
    },
    background: {
      default: "#F7F9FC",
      paper: "#FFF",
    },
  },
  header: {
    color: grey[500],
    background: "#FFF",
    search: {
      color: grey[800],
    },
    indicator: {
      background: customBlue[600],
    },
  },

  footer: {
    color: grey[500],
    background: "#FFF",
  },
  sidebar: {
    color: grey[200],
    background: "#233044",
    header: {
      color: grey[200],
      background: "#233044",
      brand: {
        color: customBlue[500],
      },
    },
    footer: {
      color: grey[200],
      background: "#1E2A38",
    },
    badge: {
      color: "#FFF",
      background: customBlue[500],
    },
  },
};

const theme = createTheme(options);

export default theme;
