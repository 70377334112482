import React from "react";
import { MenuItem, TextField } from "@mui/material";
import SubmissionTypeChip from "../../../../components/SubmissionTypeChip";
import { ENGAGEMENT_TYPES } from "../../../../storage/constants";
import styled from "styled-components";

const StyledMenuItem = styled(MenuItem)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 140px;
`;

const EngagementTypeSelect = ({
  engagementType,
  setEngagementType,
  isVendor = false,
}) => (
  <div
    style={{
      width: 200,
      marginRight: 12,
      minWidth: 160,
    }}
  >
    <TextField
      select
      label="Engagement Type"
      variant="outlined"
      size="small"
      fullWidth
      onChange={(e) => setEngagementType(e.target.value)}
      value={engagementType}
      style={{
        backgroundColor: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <StyledMenuItem value={ENGAGEMENT_TYPES.ALL}>
        <SubmissionTypeChip submissionType="All" isVendor={isVendor} />
      </StyledMenuItem>
      <StyledMenuItem value={ENGAGEMENT_TYPES.ASSIGNED}>
        <SubmissionTypeChip submissionType={ENGAGEMENT_TYPES.ASSIGNED} />
      </StyledMenuItem>
      <StyledMenuItem value={ENGAGEMENT_TYPES.EXTRACREDIT}>
        <SubmissionTypeChip
          submissionType={ENGAGEMENT_TYPES.EXTRACREDIT}
          isVendor={isVendor}
        />
      </StyledMenuItem>
    </TextField>
  </div>
);

export default EngagementTypeSelect;
