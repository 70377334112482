import React from "react";
import { makeDefaultTableOptions } from "../../../../../storage/helpers";
import { CustomDatatableV2 } from "../../../styles";
import { MessageSquare, ThumbsDown, ThumbsUp, User } from "react-feather";
import TableTitle from "../../../../../components/Table/TableTitle";
import styled from "styled-components";
import { useMediaQuery } from "@mui/material";
import DateTooltip from "../../../../../components/Table/DateTooltip";
import StyledLink from "src/components/UI/StyledLink";

const FlexDiv = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex;
  gap: 2px;
`;

const FeedbackTable = ({ feedback }) => {
  const isUnder600Px = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const options = makeDefaultTableOptions("feedback");
  if (isUnder600Px) {
    options.rowsPerPageOptions = [];
    options.jumpToPage = false;
  }

  const numFeedback = feedback.length;
  const numLiked = feedback.filter((f) => f.liked).length;
  const numDisliked = numFeedback - numLiked;

  const tableData = feedback.map((item) => ({
    id: item.user_id,
    name: `${item?.first_name} ${item?.last_initial}`,
    date: new Date(item.created).toISOString(),
    choice: item.liked,
    text: item.text || "",
  }));

  const columns = [
    {
      name: "id",
      label: " ",
      options: {
        filter: false,
        sort: false,
        display: false,
        print: false,
        download: false,
      },
    },
    {
      name: "name",
      label: "User Name",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) =>
          tableMeta.rowData[0] ? (
            <StyledLink
              CustomIcon={User}
              to={`/vendor-reports/user/${tableMeta.rowData[0]}`}
              value={value}
            />
          ) : (
            <span>{value}</span>
          ),
      },
    },
    {
      name: "date",
      label: "Date",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <DateTooltip displayYear date={value} shouldLocalize />
        ),
      },
    },
    {
      name: "choice",
      label: "Feedback Selected",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          <div
            style={{
              width: 100,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: 20,
                width: 20,
                padding: 3,
                borderRadius: 4,
                background: value ? "mediumseagreen" : "tomato",
              }}
            >
              {value ? (
                <ThumbsUp color="white" height={18} width={18} />
              ) : (
                <ThumbsDown color="white" height={18} width={18} />
              )}
            </div>
          </div>
        ),
      },
    },
    {
      name: "text",
      label: "Feedback Text",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          <div style={{ maxWidth: 540 }}>
            {value ? <span>{value}</span> : <div />}
          </div>
        ),
      },
    },
  ];

  return (
    <CustomDatatableV2
      options={options}
      data={tableData}
      columns={columns}
      components={{
        TableToolbar: () => (
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "0px 14px",
              height: 66,
              borderBottom: "2px solid lightgray",
            }}
          >
            <TableTitle Icon={MessageSquare} title="Feedback" />
            <div
              style={{
                // height: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",

                gap: 12,
                width: "fit-content",
              }}
            >
              <FlexDiv>
                <ThumbsUp
                  style={{
                    color: "white",
                    background: numLiked === 0 ? "lightgray" : "mediumseagreen",
                    height: 18,
                    width: 18,
                    borderRadius: 4,
                    padding: 3,
                  }}
                />
                <span style={{ fontWeight: "600", fontSize: 12 }}>
                  {numLiked} up
                </span>
              </FlexDiv>
              <FlexDiv>
                <ThumbsDown
                  style={{
                    color: "white",
                    background: numDisliked === 0 ? "lightgray" : "tomato",
                    height: 18,
                    width: 18,
                    borderRadius: 4,
                    padding: 3,
                  }}
                />
                <span style={{ fontWeight: "600", fontSize: 12 }}>
                  {numDisliked} down
                </span>
              </FlexDiv>
            </div>
          </div>
        ),
        // TableToolbarSelect: () => <span>tb select</span>,
      }}
    />
  );
};

export default FeedbackTable;
