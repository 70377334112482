import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { CircularProgress, Paper, Typography, Alert } from "@mui/material";
import { ArrowRight } from "react-feather";
import EvaluateSubmission from "../../pages/private/reports/reuseableComponents/SubmissionDetails/EvaluateSubmission";
import useSubmissionReport from "../../swr/hooks/useSubmissionReport";
import SubmissionResultIndicator from "../../components/Table/SubmissionResultIndicator";

const CenterLoader = styled.div`
  min-height: 320px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalWrapper = styled(Paper)`
  padding: 20px;
  min-width: min(600px, 96vw);
  max-width: 600px;
  min-height: 300px;
  border-radius: 6px;
  max-height: 90vh;
  overflow-y: scroll;
  text-align: left;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid black;
`;

const Half = styled.div`
  flex: 0.5;
  border-radius: 4px;
  padding: 4px;
  width: 100%;
  min-height: 40px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  margin-top: 18px;
  position: relative;
`;

const SeeSubmission = ({ submissionInfo }) => {
  const { data, isLoading, isError } = useSubmissionReport(submissionInfo.id);

  if (isLoading) {
    return (
      <ModalWrapper>
        <CenterLoader>
          <CircularProgress size={30} />
        </CenterLoader>
      </ModalWrapper>
    );
  }

  if (isError) {
    return (
      <ModalWrapper>
        <Alert severity="error" sx={{ mt: 2 }}>
          Encountered an issue loading this submission. Please try again.
        </Alert>
      </ModalWrapper>
    );
  }

  return (
    <ModalWrapper>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          marginBottom: -2,
          width: "100%",
          gap: 6,
        }}
      >
        <Typography variant="h5">Submission Result</Typography>
        <ArrowRight height={14} width={14} />

        <Typography style={{ color: "darkgrey", fontSize: 13 }}>
          #{submissionInfo?.id}
        </Typography>
        <div style={{ marginLeft: "auto" }} />
        <SubmissionResultIndicator
          value={data?.submission?.passed ? "Yes" : "No"}
        />
      </div>
      <div style={{ height: 12 }} />
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          flexDirection: "column",
          gap: 8,
        }}
      >
        <Half>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: 4,
            }}
          >
            <p
              style={{
                fontSize: 13,
                color: "black",
                fontWeight: "600",
              }}
            >
              {data?.user?.name}
            </p>
            <p style={{ fontWeight: "400" }}>
              {data?.submission?.passed ? "passed" : "failed"} module
            </p>

            <p
              style={{
                fontSize: 13,
                color: "black",
                fontWeight: "600",
              }}
            >
              {data?.quiz?.name}
            </p>
          </div>
          <span style={{ fontSize: 11, color: "darkgrey" }}>
            {new Date(data?.submission?.created).toDateString()}
          </span>
        </Half>
      </div>
      <div style={{ height: 22 }} />
      <EvaluateSubmission answers={data.answers} />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          marginTop: 14,
          gap: 4,
        }}
      >
        <div
          style={{
            width: 100,
            height: 50,
            borderRadius: 4,
            background: "white",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span
            style={{
              fontSize: 20,
              fontWeight: "800",
              color: "black",
            }}
          >
            {data?.submission?.score}/{data?.submission?.questions}
          </span>
          <span
            style={{
              fontSize: 10,
              fontWeight: "600",
              textTransform: "uppercase",
              color: "black",
            }}
          >
            Score achieved
          </span>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default SeeSubmission;

SeeSubmission.propTypes = {
  submissionInfo: PropTypes.object,
  setSubmissionInfo: PropTypes.func.isRequired,
};

SeeSubmission.defaultProps = {
  submissionInfo: null,
};
