import API from "../instances/API";
import { getToken } from "../../storage/helpers";

const authInterceptor = () =>
  API.interceptors.request.use((config) => {
    if (config.url === "/token-verify/") {
      return config;
    }
    const token = getToken();
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

export default authInterceptor;
