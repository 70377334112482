import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Link as LinkIcon } from "react-feather";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 280px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Slink = styled(Link)`
  text-decoration: none;
  color: black;
  font-weight: 400;
  &:hover {
    color: ${(props) => props.theme.palette.info.light};
    text-decoration: underline;
  }
`;
const StyledLink = ({ value, to, CustomIcon = null, small = false }) => (
  <Wrapper
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",

      maxWidth: 280,
      overflow: "hidden",
      textOverflow: "ellipsis",
    }}
  >
    {CustomIcon ? (
      <CustomIcon
        style={{
          height: 14,
          width: 14,
          minWidth: 14,
          color: "dodgerblue",
          padding: 3,
          backgroundColor: "#eeeeee",
          borderRadius: 4,
          marginRight: 4,
        }}
      />
    ) : (
      <LinkIcon
        style={{
          height: 14,
          width: 14,
          color: "dodgerblue",
          padding: 3,
          backgroundColor: "#eeeeee",
          borderRadius: 4,
          marginRight: 4,
        }}
      />
    )}
    <Slink
      to={to ? to : "/"}
      style={{
        fontSize: small ? 10 : 13,
        lineHeight: 1,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: 280,
      }}
    >
      {value}
    </Slink>
  </Wrapper>
);

export default StyledLink;
