import React, { useContext } from "react";
import { GlobalState } from "../../store/GlobalState";
import styled from "styled-components";

const Item = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1px 0px;
  border-bottom: 1px solid #eeeeee68;

  p {
    font-size: 12px;
    color: black;
  }

  span {
    font-size: 12px;
    color: black;
    font-weight: 500;
  }
`;

const CompanyPermissions = () => {
  const { state } = useContext(GlobalState);
  const { config, isRetail } = state;
  const { current_company: currentCompany } = config;
  const { permissions } = currentCompany;
  const { fields } = permissions;

  return (
    <div>
      <div style={{ height: 8 }} />
      <p style={{ fontSize: 11, color: "slategrey", fontWeight: "600" }}>
        COMPANY PERMISSIONS
      </p>
      <div style={{ height: 6 }} />
      {/* 
      <Item>
        <p>Permission Class:</p>
        <span>{permissions?.template_name}</span>
      </Item> */}
      <Item>
        <p>Custom Module Cap:</p>
        <span>
          {fields.custom_module_limit === 32767
            ? "Unlimited"
            : fields?.custom_module_limit}
        </span>
      </Item>
      {isRetail && (
        <>
          <Item>
            <p>Marketplace Module Cap:</p>
            <span>
              {fields.marketplace_module_limit === 32767
                ? "Unlimited"
                : fields?.custom_module_limit}
            </span>
          </Item>
          <Item>
            <p>Locations Cap:</p>

            <span>
              {fields.location_limit === 32767
                ? "Unlimited"
                : fields.location_limit}
            </span>
          </Item>

          <Item>
            <p>Users Cap:</p>
            <span>
              {fields.user_limit === 32767 ? "Unlimited" : fields.user_limit}
            </span>
          </Item>
          <Item>
            <p>Location Report:</p>
            <span>{fields.location_report ? "Yes" : "No"}</span>
          </Item>
          <Item>
            <p>Team Member Report:</p>
            <span>{fields.team_member_report ? "Yes" : "No"}</span>
          </Item>
        </>
      )}
      {!isRetail && (
        <>
          <Item>
            <p>Allow Followers:</p>
            <span>{fields.allow_followers ? "Yes" : "No"}</span>
          </Item>
          <Item>
            <p>Allow Notifications:</p>
            <span>{fields.allow_notifications ? "Yes" : "No"}</span>
          </Item>
          <Item>
            <p>Compay Report:</p>
            <span>{fields.company_report ? "Yes" : "No"}</span>
          </Item>
          <Item>
            <p>Engaged Users Report:</p>
            <span>{fields.engaged_users_report ? "Yes" : "No"}</span>
          </Item>
          <Item>
            <p>User Report:</p>
            <span>{fields.user_report ? "Yes" : "No"}</span>
          </Item>
          <Item>
            <p>Stream Report:</p>
            <span>{fields.stream_report ? "Yes" : "No"}</span>
          </Item>
        </>
      )}
      <>
        <Item>
          <p>Overview Report:</p>
          <span></span>
          {fields.overview_report ? "Yes" : "No"}
        </Item>

        <Item>
          <p>Module Report:</p>
          <span>{fields.module_report ? "Yes" : "No"}</span>
        </Item>
        <Item>
          <p>Feedback Report:</p>
          <span>{fields.feedback_report ? "Yes" : "No"}</span>
        </Item>
      </>
      {isRetail && (
        <Item>
          {" "}
          <p>All Time Submissions Report:</p>
          <span>{fields.all_time_submissions_report ? "Yes" : "No"}</span>
        </Item>
      )}
    </div>
  );
};

export default CompanyPermissions;
