import React from "react";
import { Modal } from "@mui/material";
import useAudienceToolStore from "../../../store/useAudienceToolStore";
import RoleSelectionModal from "../roleAndLocationSelectionModals/RoleSelectionModal";
import LocationSelectionModal from "../roleAndLocationSelectionModals/LocationSelectionModal";
import TemplatesModal from "../AudienceTool/TemplatesModal";
import ConfirmAllTeamMembersModal from "../roleAndLocationSelectionModals/ConfirmAllTeamMembersModal";
import ATT from "./ATT";
import SnackTest from "./AudienceSnack";

const TableModals = () => {
  const roles = useAudienceToolStore((state) => state.roles);
  const locations = useAudienceToolStore((state) => state.locations);
  const templates = useAudienceToolStore((state) => state.templates);

  const templateSelectorOpen = useAudienceToolStore(
    (state) => state.templateSelectorOpen,
  );
  const locationSelectorOpen = useAudienceToolStore(
    (state) => state.locationSelectorOpen,
  );
  const roleSelectorOpen = useAudienceToolStore(
    (state) => state.roleSelectorOpen,
  );
  const toggleRoleSelector = useAudienceToolStore(
    (state) => state.toggleRoleSelector,
  );
  const toggleLocationSelector = useAudienceToolStore(
    (state) => state.toggleLocationSelector,
  );
  const toggleTemplateSelector = useAudienceToolStore(
    (state) => state.toggleTemplateSelector,
  );
  const handleApplyLocationsFromModal = useAudienceToolStore(
    (state) => state.handleApplyLocationsFromModal,
  );
  const handleApplyRolesFromModal = useAudienceToolStore(
    (state) => state.handleApplyRolesFromModal,
  );
  const handleApplyTemplateFromModal = useAudienceToolStore(
    (state) => state.handleApplyTemplateFromModal,
  );
  const confirmAllTeamMembersOpen = useAudienceToolStore(
    (state) => state.confirmAllTeamMembersOpen,
  );
  const toggleConfirmAllTeamOpen = useAudienceToolStore(
    (state) => state.toggleConfirmAllTeamOpen,
  );
  const displayTableModal = useAudienceToolStore(
    (state) => state.displayTableModal,
  );
  const closeTableModal = useAudienceToolStore(
    (state) => state.closeTableModal,
  );

  return (
    <>
      <Modal open={roleSelectorOpen} onClose={toggleRoleSelector}>
        <div>
          <RoleSelectionModal
            handleApply={handleApplyRolesFromModal}
            roles={roles}
            handleClose={toggleRoleSelector}
          />
        </div>
      </Modal>
      <Modal open={locationSelectorOpen} onClose={toggleLocationSelector}>
        <div>
          <LocationSelectionModal
            handleApply={handleApplyLocationsFromModal}
            locations={locations}
            handleClose={toggleLocationSelector}
          />
        </div>
      </Modal>
      <Modal open={templateSelectorOpen} onClose={toggleTemplateSelector}>
        <div>
          <TemplatesModal
            handleApply={handleApplyTemplateFromModal}
            handleClose={toggleTemplateSelector}
            audienceList={templates}
          />
        </div>
      </Modal>
      <Modal
        open={confirmAllTeamMembersOpen}
        onClose={toggleConfirmAllTeamOpen}
      >
        <div>
          <ConfirmAllTeamMembersModal />
        </div>
      </Modal>
      <Modal open={displayTableModal} onClose={closeTableModal}>
        <div>
          <ATT />
          <SnackTest />
        </div>
      </Modal>
    </>
  );
};

export default TableModals;
