import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { AlertTitle } from "@mui/material";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Alert,
} from "@mui/material";
import { DeleteWrapper, DeleteButton } from "../../styles";
import API from "../../../../axios/instances/API";
import { FEEDBACK_MESSAGES } from "../../../../storage/constants";

const FlexDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
`;

const ConfirmDeleteManager = ({
  deleteInfo = null,
  setConfirmModalOpen,
  setSnackbarText,
  mutate,
  routeBacktoSuperviorPage = false,
}) => {
  const [isdeleting, setIsDeleting] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [actionRequired, setActionRequired] = useState(null);
  const [keepTm, setKeepTm] = useState(null);
  const [proceedMultiple, setProceedMultiple] = useState(null);
  const history = useHistory();

  const handleDelete = async (override = false) => {
    setIsDeleting(true);
    const sendData = {
      id: deleteInfo.id,
    };

    if (keepTm) {
      sendData.keep_tms = keepTm !== "Yes";
    }

    if (proceedMultiple || override) {
      sendData.proceed_despite_multiple_locations = true;
    }

    try {
      const res = await API.delete("dashboard/managers/details/", {
        data: sendData,
      });

      if (res.status === 202) {
        setIsDeleting(false);
        return setActionRequired(res.data.choice_required);
      }
      setIsDeleting(false);
      mutate();
      setSnackbarText("Manager deleted success");

      setConfirmModalOpen(false);
      if (routeBacktoSuperviorPage) {
        return history.push("/supervisors/");
      }
      return null;
    } catch (error) {
      setErrorAlert(true);
      return setIsDeleting(false);
    }
  };

  return (
    <DeleteWrapper style={{ border: "1px solid tomato", width: 460 }}>
      <>
        <Typography variant="h5" component="h5">
          Delete this Supervisor?
        </Typography>
        <Typography sx={{ mb: 3, mt: 1, fontWeight: 400, fontSize: 14 }}>
          {deleteInfo.name}
        </Typography>
        {actionRequired &&
          actionRequired === "proceed_despite_multiple_locations" && (
            <Alert
              severity="warning"
              sx={{ mb: 4 }}
              style={{ textAlign: "left" }}
            >
              <AlertTitle>
                {deleteInfo.name} is a Supervisor at multiple locations
              </AlertTitle>
              <span>
                Deleting will remove this manager from all locations. If you
                wish to only remove from 1 location, go to location details
                page. If you wish to remove manager from all locations, press
                "delete from all locations"
              </span>
            </Alert>
          )}
        {actionRequired && actionRequired === "keep_tms" && (
          <>
            <Alert severity="info" sx={{ mb: 2 }}>
              This person has a team member account. Would you like to remove
              them as a team member also?
            </Alert>
            <FlexDiv>
              <RadioGroup
                row
                value={keepTm}
                onChange={(e) => setKeepTm(e.target.value)}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FlexDiv>
          </>
        )}
        <DeleteButton
          fullWidth
          disabled={(actionRequired === "keep_tms" && !keepTm) || isdeleting}
          onClick={() => {
            if (actionRequired === "proceed_despite_multiple_locations") {
              setProceedMultiple("Yes");
              return handleDelete(true);
            }
            return handleDelete();
          }}
        >
          {isdeleting
            ? "Deleting"
            : actionRequired === "proceed_despite_multiple_locations"
              ? "Delete from all locations"
              : "Delete"}
        </DeleteButton>
        {errorAlert && (
          <Alert
            sx={{ mt: 4 }}
            severity="error"
            onClose={() => setErrorAlert(false)}
          >
            {FEEDBACK_MESSAGES.GENERIC_ERROR}
          </Alert>
        )}
      </>
    </DeleteWrapper>
  );
};

export default ConfirmDeleteManager;

ConfirmDeleteManager.propTypes = {
  deleteInfo: PropTypes.object,
  setConfirmModalOpen: PropTypes.func.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
  mutate: PropTypes.func.isRequired,
  routeBacktoSuperviorPage: PropTypes.bool,
};

ConfirmDeleteManager.defaultProps = {
  deleteInfo: null,
  routeBacktoSuperviorPage: false,
};
