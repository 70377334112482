import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import {
  CircularProgress,
  Paper,
  Alert,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import { Autocomplete } from "@mui/material";

import API from "../../../../axios/instances/API";
import useLocations from "../../../../swr/hooks/Locations/useLocations";
import { mutate as swrMutate } from "swr";

const Wrapper = styled(Paper)`
  padding: 20px;
  width: min(520px, 98vw);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  border-radius: 8px;
  border: 2px solid black;
`;

const CenterCircular = styled.div`
  width: 100%;
  height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ChangeMemberLocation = ({
  memberId,
  currentLocation,
  setSnackbarText,
  setChangeLocationOpen,
  memberName,
  mutate,
}) => {
  const { locations, isLoading, isError } = useLocations(); // tested
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [isChanging, setIsChanging] = useState(false);
  const [sendError, setSendError] = useState("");

  if (isLoading) {
    return (
      <Wrapper>
        <CenterCircular>
          <CircularProgress size={30} />
        </CenterCircular>
      </Wrapper>
    );
  }

  if (isError) {
    return (
      <Wrapper>
        <CenterCircular>
          <Alert severity="error">
            Error loading possible locations. Please try again.
          </Alert>
        </CenterCircular>
      </Wrapper>
    );
  }

  const autoCompleteOptions = locations.locations.map((location) => ({
    id: location.id,
    name: location.name,
  }));

  const handleChangeLocation = async () => {
    setIsChanging(true);
    try {
      await API.put("/dashboard/teams/change-team/", {
        team_member_id: memberId,
        location_id: selectedLocation.id,
      });
      await mutate();
      await swrMutate(
        `/dashboard/analytics/retail-reports/users-activity-breakdown/?team_member_id=${memberId}`,
      );
      setIsChanging(false);
      setSnackbarText(
        `Team member location changed to ${selectedLocation.name} success`,
      );
      setChangeLocationOpen(false);
    } catch (error) {
      setIsChanging(false);
      setSendError("Error changing location. Please try again.");
    }
  };

  return (
    <Wrapper>
      <Typography variant="h5">
        {`Edit Location for ${memberName}`} ?
      </Typography>
      <Typography sx={{ mb: 6 }} variant="body1">
        Current location: <strong>{currentLocation?.name}</strong>
      </Typography>
      <Autocomplete
        size="small"
        options={autoCompleteOptions}
        getOptionLabel={(option) => option.name}
        onChange={(_, newValue) => setSelectedLocation(newValue)}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionDisabled={(option) => option.id === currentLocation.id}
        renderInput={(params) => (
          <TextField
            sx={{ mb: 3, mt: 1 }}
            {...params}
            size="small"
            variant="outlined"
            label="Your Locations"
          />
        )}
      />
      <Button
        fullWidth
        variant="contained"
        color="primary"
        startIcon={isChanging ? <CircularProgress size={20} /> : null}
        disabled={isChanging}
        onClick={handleChangeLocation}
      >
        {isChanging ? "Changing" : "Change Location"}
      </Button>
      {sendError && (
        <Alert severity="error" sx={{ mt: 2 }} onClose={() => setSendError("")}>
          {sendError}
        </Alert>
      )}
    </Wrapper>
  );
};

export default ChangeMemberLocation;

ChangeMemberLocation.propTypes = {
  id: PropTypes.number,
  memberId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  currentLocation: PropTypes.object.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
  setChangeLocationOpen: PropTypes.func.isRequired,
  memberName: PropTypes.string.isRequired,
  mutate: PropTypes.func.isRequired,
};

ChangeMemberLocation.defaultProps = {
  id: undefined,
};
