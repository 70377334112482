import React from "react";
import { Briefcase } from "react-feather";
import CustomDataTableIcons from "../../../../../components/Table/CustomDataTableIcons";
import TableTitle from "../../../../../components/Table/TableTitle";
import { makeDefaultTableOptions } from "../../../../../storage/helpers";
import { CustomDatatableV2 } from "../../../styles";
import styled from "styled-components";

const options = makeDefaultTableOptions("stream-engaged-companies");

const KeyStatBlock = styled.div`
  flex: 1;
  height: 100%;
  background: white;
  border-radius: 8px;
  border: 1px solid gainsboro;
  padding: 12px 18px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  span {
    font-size: 30px;
    font-weight: 800;
    color: black;
    line-height: 1.2;
  }

  p {
    font-size: 13px;
    color: slategrey;
    font-weight: 500;
  }
`;

const KeyStatsContainer = styled.div`
  height: 80px;
  width: 100%;

  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;

const EngagedCompaniesTable = ({ companies }) => {
  const columns = [
    {
      name: "id",
      label: " ",
      options: {
        filter: false,
        sort: false,
        display: false,
        print: false,
        download: false,
      },
    },
    {
      name: "img",
      label: " ",
      options: {
        display: false,
      },
    },
    {
      name: "company",
      label: "Company",
      options: {
        display: true,
        customBodyRender: (value, tableMeta) => (
          <div
            style={{
              display: "flex",
              gap: 4,
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <img
              style={{
                height: 34,
                width: 34,
                border: "1px solid #eeeeee",
                borderRadius: "50%",
              }}
              alt="company-logo"
              src={tableMeta?.rowData[1]}
            />
            <span>{value}</span>
          </div>
        ),
      },
    },
    {
      name: "completedOne",
      label: "Users Started Stream",
    },
    {
      name: "completedAll",
      label: "Users Completed Stream",
    },
  ];

  const tableData = companies.map((c) => ({
    id: c?.id,
    img: c?.logo,
    company: c?.name,
    completedOne: c?.completed_one_module,
    completedAll: c?.completed_all_live_modules,
  }));

  const numCompaniesEngaging = companies?.length;
  const mostEngagedCompany = companies?.sort(
    (a, b) => b.completed_one_module - a.completed_one_module,
  )[0];

  const mostCompletedCompany = companies?.sort(
    (a, b) => b.completed_all_live_modules - a.completed_all_live_modules,
  )[0];

  return (
    <div
      style={{
        padding: 12,
        borderRadius: 6,
        background: "#eeeeee",
        display: "flex",
        flexDirection: "column",
        gap: 12,
        width: "100%",
      }}
    >
      <KeyStatsContainer>
        <KeyStatBlock>
          <span>{numCompaniesEngaging}</span>
          <p>
            {numCompaniesEngaging === 1 ? "Company has" : "Companies have"}{" "}
            engaged with the stream.
          </p>
        </KeyStatBlock>
        <KeyStatBlock>
          {!mostEngagedCompany ? (
            <p>--/--</p>
          ) : (
            <div
              style={{
                height: 40,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: 8,
              }}
            >
              <img
                style={{
                  height: 42,
                  width: 42,
                  borderRadius: "10%",
                  objectFit: "contain",
                  border: "1px solid #eeeeee",
                }}
                src={mostEngagedCompany?.logo}
                alt="company-logo"
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "center",
                }}
              >
                <span style={{ fontSize: 14, fontWeight: 600 }}>
                  {mostEngagedCompany?.name}
                </span>
                <p>
                  Most users to start stream. (
                  {mostEngagedCompany?.completed_one_module})
                </p>
              </div>
            </div>
          )}
        </KeyStatBlock>
        <KeyStatBlock>
          {!mostCompletedCompany ? (
            <p>--/--</p>
          ) : (
            <div
              style={{
                height: 40,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: 8,
              }}
            >
              <img
                style={{
                  height: 42,
                  width: 42,
                  borderRadius: "10%",
                  objectFit: "contain",
                  border: "1px solid #eeeeee",
                }}
                src={mostCompletedCompany?.logo}
                alt="company-logo"
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "center",
                }}
              >
                <span style={{ fontSize: 14, fontWeight: 600 }}>
                  {mostCompletedCompany?.name}
                </span>
                <p>
                  Most users to complete stream. (
                  {mostCompletedCompany?.completed_all_live_modules})
                </p>
              </div>
            </div>
          )}
        </KeyStatBlock>
      </KeyStatsContainer>

      <CustomDatatableV2
        title={
          <TableTitle Icon={Briefcase} isVendor title="Engaged Companies" />
        }
        options={options}
        columns={columns}
        data={tableData}
        components={{
          icons: CustomDataTableIcons,
        }}
      />
    </div>
  );
};

export default EngagedCompaniesTable;
