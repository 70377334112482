import React from "react";
import { XCircle } from "react-feather";
import { StyledItem } from "../../../styles";
import {
  QUIZSTATES,
  USER_TYPES,
  LIBRARY_MODAL_KEYS,
} from "../../../../../storage/constants";

const DeleteModule = ({
  id,
  userType,
  moduleState,
  setModalState,
  handleClose,
  moduleName,
}) => {
  if (moduleState !== QUIZSTATES.CLOSED) return null;

  if (
    [
      USER_TYPES.RETAIL_MANAGER_CANT_DEPLOY,
      USER_TYPES.RETAIL_MANAGER_CAN_DEPLOY,
    ].includes(userType)
  ) {
    return null;
  }

  return (
    <StyledItem
      onClick={() => {
        setModalState({ key: LIBRARY_MODAL_KEYS.DELETE, id, name: moduleName });
        handleClose();
      }}
    >
      {" "}
      <XCircle style={{ backgroundColor: "firebrick", color: "lightgray" }} />
      <span>Delete Module</span>
    </StyledItem>
  );
};

export default DeleteModule;
