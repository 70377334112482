import React from "react";
import styled from "styled-components";
import { IconButton, Tooltip } from "@mui/material";
import { CheckCircle, HelpCircle } from "react-feather";
import PropTypes from "prop-types";

const CheckSaved = styled(CheckCircle)`
  color: ${(props) => props.theme.palette.success.light};
  font-size: 16px;
  cursor: pointer;
`;

const ExtraInfo = ({
  text,
  saved = false,
  savedText = null,
  overrideBlack = false,
}) => (
  <>
    <Tooltip arrow title={saved ? savedText : text}>
      <IconButton size="small">
        {saved ? (
          <CheckSaved height={13} width={13} />
        ) : (
          <HelpCircle
            height={13}
            width={13}
            style={{ color: overrideBlack ? "black" : "#22597f" }}
          />
        )}
      </IconButton>
    </Tooltip>
  </>
);

export default ExtraInfo;

ExtraInfo.propTypes = {
  text: PropTypes.string.isRequired,
  saved: PropTypes.bool,
  savedText: PropTypes.string,
  overrideBlack: PropTypes.bool,
};

ExtraInfo.defaultProps = {
  saved: false,
  savedText: null,
  overrideBlack: false,
};
