import { AUDIENCETYPES, USER_TYPES } from "../../../storage/constants";

const getCustomAudienceMsg = (type) => {
  if (type === AUDIENCETYPES.SELECT_ALL) {
    return "All team members selected in matrix, saved as all team members success";
  }
  if (type === AUDIENCETYPES.LOCATIONS) {
    return "Only locations were selected in the audience matrix, so we've updated this audience type to 'By Location'. success";
  }
  if (type === AUDIENCETYPES.ROLES) {
    return "Only roles were selected in the audience matrix, so we've updated this audience type to 'By Roles'. success";
  }

  return null;
};

const checkIfSameNumberOfRoles = (arr) => {
  const testArr = [];

  arr.forEach((array) => {
    const test = array
      .map((v) => v.roleId)
      .sort((a, b) => a - b)
      .join("+");
    testArr.push(test);
  });

  let strToCheck = testArr[0];

  const myTest = testArr.every((str) => str === strToCheck);
  return myTest;
};

export const checkIfSavedCustomIsOtherAudienceType = (
  rows,
  numRoles,
  userType,
) => {
  if (
    [
      USER_TYPES.RETAIL_MANAGER_CANT_DEPLOY,
      USER_TYPES.RETAIL_MANAGER_CAN_DEPLOY,
    ].includes(userType)
  ) {
    return null;
  }

  const isAllTeamMembers = Object.values(rows)
    .flatMap((row) => row)
    .every((cell) => cell.checked);

  if (isAllTeamMembers) return getCustomAudienceMsg(AUDIENCETYPES.SELECT_ALL);

  const fullLocations = [...Object.values(rows)];
  const numCheckedArr = [];

  fullLocations.forEach((loc) => {
    numCheckedArr.push(loc.filter((loc) => loc.checked).length);
  });
  const isAllLocations = numCheckedArr.every((num) =>
    [0, numRoles].includes(num),
  );

  if (isAllLocations) {
    return getCustomAudienceMsg(AUDIENCETYPES.LOCATIONS);
  }

  // first need to filter so only checked are there
  const test = Object.values(rows);
  const arr = [];
  test.forEach((row) => arr.push(row.filter((cell) => cell.checked)));
  const isAllRoles = checkIfSameNumberOfRoles(arr);

  if (isAllRoles) return getCustomAudienceMsg(AUDIENCETYPES.ROLES);

  return null;
};

export function handleFormatAudience(audience) {
  if (audience?.mappingv2 === null) {
    return {
      audience: {},
      audience_modifiable_by_managers: false,
      audience_type: { raw: "O", formatted: "Other" },
    };
  }

  const newAudienceObj = {};

  audience.mapping[0]?.locations.forEach((l) => {
    newAudienceObj[l.id] = l.roles;
  });

  return {
    audience: newAudienceObj,
    audience_modifiable_by_managers: false,
    audience_type: { raw: "O", formatted: "Other" },
  };
}

export function formatCreatedTemplateForApi(rows) {
  const returnArr = [];
  Object.entries(rows).forEach(([key, values]) => {
    returnArr.push({
      id: Number(key),
      roles: values.filter((c) => c.checked).map((r) => r.roleId),
    });
  });
  return returnArr;
}

export function formatSelectedAudeinceForApi(rows, locations) {
  const keys = locations.map((l) => l.id);
  const formattedObj = {};
  keys.forEach(
    (key) =>
      (formattedObj[key] = rows[key]
        .filter((role) => role.checked)
        .map((role) => role.roleId)),
  );

  return formattedObj;
}

export function createTemplateMatrix(roles, locations, audience) {
  const matrix = {};

  locations.forEach((location) => {
    const targetLocation = audience[location.id]?.map((i) => i.id) || [];
    const body = roles.map((r) => ({
      roleId: r.id,
      checked: targetLocation.includes(r.id),
      locationId: location.id,
    }));

    matrix[location.id] = body;
  });

  return matrix;
}

export function areRowsAndCurrentAudienceEqual(rows, currentAudience) {
  try {
    const formattedRows = {};

    Object.keys(rows).forEach((key) => {
      const filtered = rows[key].filter((cell) => cell.checked);
      if (filtered.length > 0) {
        formattedRows[key] = filtered.map((cell) => ({ id: cell.roleId }));
      }
    });

    if (
      Object.keys(formattedRows).length !== Object.keys(currentAudience).length
    ) {
      // If they dont have the same # of locations... they are not the same
      return false;
    }

    if (
      Object.values(formattedRows).flat().length !==
      Object.values(currentAudience).flat().length
    ) {
      // If the # of checked in current audience !== # checked in rows ... they are not the same
      return false;
    }

    const checkArray = [];

    Object.keys(formattedRows).forEach((key) => {
      const roleIdsInRowsByKey = formattedRows[key].map((o) => o.id);
      const roleIdsInCurrentAudienceByKey = currentAudience[key].map(
        (o) => o.id,
      );

      checkArray.push(
        roleIdsInCurrentAudienceByKey.every((id) =>
          roleIdsInRowsByKey.includes(id),
        ),
      );
    });

    return checkArray.every((val) => val === true);
  } catch (error) {
    return false;
  }
}

export const getComboIds = (selectedAudience, audience) => {
  try {
    if (selectedAudience === AUDIENCETYPES.ROLES) {
      return Object.values(audience)?.[0]?.map((v) => Number(v.id));
    }

    if (selectedAudience === AUDIENCETYPES.LOCATIONS) {
      return Object.keys(audience).map((key) => Number(key));
    }

    return [];
  } catch (error) {
    return [];
  }
};

export const TABLE_STATES = {
  LOADING: "LOADING",
  ERROR: "ERROR",
  SAVING: "SAVING",
  LOCKED: "LOCKED",
  UNLOCKED: "EDITING",
};

export const initialState = {
  initialLoad: true,

  displayTableInline: false,
  displayTableModal: false,
  displayAudienceSelect: true,
  confirmAllTeamMembersOpen: false,

  snackText: "",
  exitModalOpen: false,
  isInitialized: false,
  roleSelectorOpen: false,
  locationSelectorOpen: false,
  templateSelectorOpen: false,
  error: null,
  templateId: null,
  templateName: null,
  isTemplate: false,
  tableState: TABLE_STATES.LOCKED,
  currentAudienceType: AUDIENCETYPES.NONE,
  canManagersModify: false,
  currentAudience: null,
  rows: [],
  roles: [],
  locations: [],
  templates: [],
  allowReset: false,
  moduleId: null,
  isDisplayOnly: false,
  hasAtLeast1RoleAsOption: false,
  hasAtLeast1LocationAsOption: false,
  userType: USER_TYPES.RETAIL_ADMIN,
  editTemplateNameModalOpen: false,
};
