import React from "react";
import PermissionsError from "./PermissionsError";
import OnLoadError from "./OnLoadError";

const ErrorBlockWithPermissions = ({ errorObj }) => {
  if (!errorObj) {
    return <OnLoadError />;
  }

  if (errorObj?.response?.data?.error?.title === "Permission Denied") {
    return <PermissionsError />;
  }

  return <OnLoadError />;
};

export default ErrorBlockWithPermissions;
