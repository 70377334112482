import React, { useContext, useState } from "react";
import { Button, CircularProgress, Paper } from "@mui/material";
import { Trash2 } from "react-feather";
import styled from "styled-components";
import API from "../../../../axios/instances/API";
import { GlobalState } from "../../../../store/GlobalState";
import CloseList from "./CloseList";
import DeleteList from "./DeleteList";
import SearchBar from "./SearchBar";

const ModalWrapper = styled(Paper)`
  padding: 20px;
  width: calc(min(500px, 96vw));
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow-y: scroll;
  border: 2px solid black;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-height: 700px;
  min-height: 480px;
`;

const Tab = styled.div`
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => (props.$isActive ? "black" : "#eeeeee55")};
  border: 1px solid slategrey;
  padding: 0px 6px;
  border-radius: 4px;
  cursor: pointer;

  span {
    font-size: 11px;
    font-weight: ${(props) => (props.$isActive ? "600" : "500")};
    color: ${(props) => (props.$isActive ? "white" : "black")};
  }
`;

const BulkDeleteModal = ({
  modules,
  mutate,
  setSnackbarText,
  handleCloseModal,
}) => {
  const [index, setIndex] = useState(0);
  const [modIds, setModIds] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [searchString, setSearchString] = useState("");
  const { state } = useContext(GlobalState);
  const { config } = state || {};

  const handleBulkClose = async () => {
    try {
      setIsUpdating(true);
      await API.delete("/dashboard/quiz/bulk_close/", {
        data: {
          quiz_ids: modIds,
        },
      });
      await mutate();
      setIsUpdating(false);

      setSnackbarText(
        `
        Successfully Closed ${modIds.length} ${
          modIds.length === 1 ? "Module" : "Modules"
        }. success`,
      );
      handleCloseModal();
    } catch (error) {
      setIsUpdating(false);
      setSnackbarText("Error closing modules please try again. error");
    }
  };

  const handleBulkDelete = async () => {
    try {
      setIsUpdating(true);
      await API.delete("/dashboard/quiz/bulk-delete/", {
        data: {
          quiz_ids: modIds,
        },
      });
      await mutate();
      setIsUpdating(false);
      setSnackbarText(
        `Successfully Deleted ${modIds.length} ${
          modIds.length === 1 ? "Module" : "Modules"
        }. success`,
      );
      handleCloseModal();
    } catch (error) {
      setIsUpdating(false);
      setSnackbarText("Error deleting modules please try again. error");
    }
  };

  const handleItemClick = (id) => {
    setModIds((p) => {
      if (p.includes(id)) {
        return p.filter((num) => num !== id);
      } else {
        return [...p, id];
      }
    });
  };

  const searchActive = searchString.length > 2;

  const displayModules = !searchActive
    ? modules
    : modules.filter((mod) =>
        mod.name.toLowerCase().includes(searchString.toLowerCase()),
      );

  return (
    <ModalWrapper>
      <span style={{ fontSize: 16, fontWeight: "600" }}>Bulk Actions</span>
      <span style={{ fontSize: 12, color: "darkgrey" }}>
        Use this feature to close/delete multiple modules at once.
      </span>
      <div style={{ height: 10 }} />
      <span
        style={{
          fontSize: 10,
          textTransform: "uppercase",
          color: "slategrey",
          fontWeight: "500",
        }}
      >
        Select Action
      </span>
      <div style={{ height: 4 }} />
      <div
        style={{
          width: "100%",
          height: 30,

          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: 6,
        }}
      >
        <Tab
          $isActive={index === 0}
          onClick={() => {
            setModIds([]);
            setSearchString("");
            setIndex(0);
          }}
        >
          <span>Bulk Close</span>
        </Tab>
        <Tab
          $isActive={index === 1}
          onClick={() => {
            setModIds([]);
            setSearchString("");

            setIndex(1);
          }}
        >
          <span>Bulk Delete</span>
        </Tab>
      </div>
      <div style={{ height: 8 }} />

      <SearchBar
        searchString={searchString}
        setSearchString={setSearchString}
      />
      <div style={{ height: 8 }} />

      <span
        style={{
          fontSize: 12,
          // textTransform: "uppercase",
          color: "slategrey",
          fontWeight: "500",
        }}
      >
        Modules available to {index === 0 ? "close" : "delete"}
      </span>
      <div
        style={{
          width: "100%",
          height: 400,
          marginTop: 3,
          borderRadius: 6,
          border: "1px solid lightgray",
          // flex: 1,
          overflowY: "scroll",
          display: "flex",
          padding: "4px",
          flexDirection: "column",
        }}
      >
        {index === 0 && (
          <CloseList
            modules={displayModules}
            handleItemClick={handleItemClick}
            config={config}
            modIds={modIds}
          />
        )}

        {index === 1 && (
          <DeleteList
            modules={displayModules}
            handleItemClick={handleItemClick}
            config={config}
            modIds={modIds}
          />
        )}
      </div>
      <Button
        color="error"
        size="small"
        disabled={modIds.length === 0 || isUpdating}
        startIcon={
          isUpdating ? (
            <CircularProgress size={14} sx={{ color: "white" }} />
          ) : (
            <Trash2 height={14} width={14} color="white" />
          )
        }
        style={{
          marginLeft: "auto",
          marginTop: 8,
          background:
            modIds.length === 0 || isUpdating ? "darkgrey" : "firebrick",
          color: "white",
          maxHeight: 38,
        }}
        onClick={index === 0 ? handleBulkClose : handleBulkDelete}
      >
        <span style={{ fontSize: 11, fontWeight: "600" }}>
          {index === 0
            ? `Close ${modIds.length > 0 ? modIds.length : ""} ${
                modIds.length === 1 ? "Module" : "Modules"
              }`
            : `Delete ${modIds.length > 0 ? modIds.length : ""}  ${
                modIds.length === 1 ? "Module" : "Modules"
              }`}
        </span>
      </Button>
    </ModalWrapper>
  );
};

export default BulkDeleteModal;
