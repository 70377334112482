import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  CircularProgress,
  Autocomplete,
  MenuItem,
  Paper,
  Alert,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import API from "../../../../axios/instances/API";
import AccessTable from "../../../../components/AdminAccess/AccessTable";
import {
  cleanAccessStateForApi,
  getStatefulAccess,
} from "../../../../components/AdminAccess/utils";
import { ACCESS_LEVELS, JOB_LEVELS } from "../../../../storage/constants";
import useLocations from "../../../../swr/hooks/Locations/useLocations";

const ADMIN_LEVELS = {
  SUPERVISOR: "supervisor",
  ADMIN: "adminAccess",
};

const Wrapper = styled(Paper)`
  padding: 20px;
  width: min(520px, 98vw);
  position: absolute;
  left: 50%;
  display: flex;
  flex-direction: column;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  border-radius: 8px;
  border: 2px solid black;
`;

const getPromoteErrorMsg = (error) => {
  return (
    error?.response?.data?.error?.details || "Failed to promote team member"
  );
};

const Promote = ({ promoteInfo, setPromoteModalOpen, setSnackbarText }) => {
  const [isPromoting, setIsPromoting] = useState(false);
  const [errorAlert, setErrorAlert] = useState(null);
  const [adminLevel, setAdminLevel] = useState(ADMIN_LEVELS.SUPERVISOR);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [accessState, setAccessState] = useState(null);

  const { locations, isLoading } = useLocations();

  const autocompleteOptions = isLoading
    ? null
    : locations.locations.map((location) => ({
        name: location.name,
        id: location.id,
      }));

  const handleSelectChange = (e) => {
    setAdminLevel(e.target.value);
  };

  const handlePromote = async () => {
    setErrorAlert(false);
    setIsPromoting(true);
    try {
      let sendObj;
      if (adminLevel === ADMIN_LEVELS.SUPERVISOR) {
        sendObj = {
          id: promoteInfo.id,
          job: JOB_LEVELS.SUPERVISOR,
          location_id: selectedLocation.id,
        };
      } else {
        sendObj = {
          id: promoteInfo.id,
          job: JOB_LEVELS.ADMIN,
          access_data: cleanAccessStateForApi(accessState),
        };
      }

      await API.put("dashboard/teams/promote/", sendObj);

      setSnackbarText(
        `Successfully promoted ${promoteInfo.fullName} to ${
          adminLevel === ADMIN_LEVELS.SUPERVISOR ? "Supervisor" : "Admin"
        } success`,
      );
      setIsPromoting(false);
      setPromoteModalOpen(false);
    } catch (error) {
      const msg = getPromoteErrorMsg(error);
      setErrorAlert(msg);
      setIsPromoting(false);
    }
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await API.get(
          `/dashboard/teams/promote/?id=${promoteInfo?.id}`,
        );

        const cleanAccessState = getStatefulAccess(data?.access);
        setAccessState(cleanAccessState);
      } catch (error) {
        setErrorAlert("Error getting data about this team member");
      }
    };

    getData();
  }, []);

  const handleChange = (e, companyId) => {
    const { value } = e.target;
    const updatedArr = accessState?.map((obj) => {
      if (obj?.company_id !== companyId) {
        return {
          ...obj,
        };
      } else {
        return {
          ...obj,
          admins_access: value,
        };
      }
    });

    setAccessState(updatedArr);
  };

  const noAccessGiven = accessState?.every(
    (obj) => obj?.admins_access === ACCESS_LEVELS.NONE,
  );

  return (
    <Wrapper>
      <Typography variant="h5">
        {`Promote ${promoteInfo.fullName}`} ?
      </Typography>
      <Typography sx={{ mb: 6 }} variant="body1">
        You can give <strong>{promoteInfo.fullName}</strong> Admin or Supervisor
        privileges
      </Typography>
      <TextField
        select
        fullWidth
        size="small"
        variant="outlined"
        value={adminLevel}
        onChange={handleSelectChange}
        name="level"
        sx={{ mb: 2 }}
        style={{ textAlign: "left" }}
        label="Select Promotion Level"
      >
        <MenuItem value={ADMIN_LEVELS.SUPERVISOR}>
          Promote To Supervisor
        </MenuItem>
        <MenuItem value={ADMIN_LEVELS.ADMIN}>Promote To Admin</MenuItem>
      </TextField>
      {adminLevel === ADMIN_LEVELS.SUPERVISOR && (
        <Autocomplete
          size="small"
          options={autocompleteOptions}
          getOptionLabel={(option) => option.name}
          onChange={(_, newValue) => setSelectedLocation(newValue)}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              sx={{ mb: 3, mt: 1 }}
              {...params}
              size="small"
              variant="outlined"
              label="Location To Supervise"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isLoading ? (
                      <CircularProgress color="primary" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      )}
      {adminLevel === ADMIN_LEVELS.ADMIN && (
        <AccessTable accessState={accessState} handleChange={handleChange} />
      )}
      <Button
        sx={{ mt: 2 }}
        style={{ marginLeft: "auto" }}
        variant="contained"
        color="primary"
        disabled={
          (adminLevel === ADMIN_LEVELS.SUPERVISOR && !selectedLocation) ||
          isPromoting
        }
        startIcon={isPromoting ? <CircularProgress size={16} /> : null}
        onClick={handlePromote}
      >
        {isPromoting ? "Promoting" : "Promote"}
      </Button>
      {errorAlert && (
        <Alert
          style={{ textAlign: "left" }}
          sx={{ mt: 2 }}
          severity="error"
          onClose={() => setErrorAlert(null)}
        >
          <p style={{ fontWeight: 500 }}>Error.</p>
          <span>{errorAlert}</span>
        </Alert>
      )}
    </Wrapper>
  );
};

export default Promote;

Promote.propTypes = {
  promoteInfo: PropTypes.object.isRequired,
  setPromoteModalOpen: PropTypes.func.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
};
