import useSWR from "swr";
import API from "../../../../axios/instances/API";

const BASE_ENDPOINT = "/dashboard/badges/details/?achievement_code=";

const fetcher = async (url) => {
  const { data } = await API.get(url);
  return data;
};

const useBadgeDetail = (code) => {
  const { data, error, mutate } = useSWR(`${BASE_ENDPOINT}${code}`, fetcher, {
    revalidateOnFocus: false,
  });
  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useBadgeDetail;
