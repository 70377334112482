import {
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Avatar,
  Chip,
  Paper,
  Tabs,
} from "@mui/material";
import { Award } from "react-feather";
import styled from "styled-components";

export const QuizTabAppBar = styled(AppBar)`
  width: fit-content;
  height: 30px;
  min-height: 30px;
  max-height: 30px;
  background-color: transparent;
  margin-top: 8px;
`;

export const QuizTabs = styled(Tabs)`
  height: 30px;
  max-height: 30px;
  min-height: 30px;

  .MuiTab-root {
    height: 30px;
    min-width: 100px;
    border-radius: 4px;
    border: 2px solid lightgray;
    color: darkgrey;
    margin: 0px 2px;
    background: #eeeeee44;
  }

  .MuiTab-root.Mui-selected {
    border: 2px solid #337ab7;
    background-color: #eeeeee22;
    color: black;
  }

  .MuiButtonBase-root {
    padding: 2px;
    min-height: 24px;
    min-width: 100px;
  }

  .MuiTab-wrapper {
    padding: 0px;
  }

  .MuiTab-wrapper.Mui-selected {
    color: black;
  }
`;

export const LoaderCentered = styled.div`
  width: 100%;
  height: 100%;
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Separate = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 20px;
  width: 100%;
  margin-top: 10px;
`;

export const SpacingDiv = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  margin-bottom: 4px;
  border-bottom: 1px solid #eeeeee;
  padding: 1px;
`;

export const QuestionAvatar = styled(Avatar)`
  height: 18px;
  width: 18px;
  font-size: 11px;
  font-weight: 600;
  margin-right: 12px;
  background-color: ${(props) =>
    props.$isActive ? "#337ab7" : props.theme.palette.grey[400]};
  // props.activecolor === "Yes" ? "#337ab7" : props.theme.palette.grey[400]};
`;

// Quiz Answer Components //

export const NoContentWrapper = styled.div`
  border: 2px solid #eeeeee;
  border-radius: 2px;
  min-height: 304px;
  min-width: 260px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
