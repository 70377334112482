import React from "react";
import useAudienceToolStore from "../../../store/useAudienceToolStore";
import SaveButton from "../AudienceTool/Buttons/SaveButton";
import styled from "styled-components";
import PercentageCheckedIndicator from "./PercentageCheckedIndicator";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 6px;
`;

const FlexDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TableBottomActions = () => {
  const isDisplayOnly = useAudienceToolStore((state) => state.isDisplayOnly);

  return (
    <Wrapper>
      {isDisplayOnly ? (
        <FlexDiv>
          <PercentageCheckedIndicator />
          <div />
        </FlexDiv>
      ) : (
        <FlexDiv>
          <PercentageCheckedIndicator />
          <SaveButton />
        </FlexDiv>
      )}
    </Wrapper>
  );
};

export default TableBottomActions;
