import React from "react";
import ImportButton from "./Buttons/ImportButton";
import UndoButton from "./Buttons/UndoButton";
import SelectAllButton from "./Buttons/SelectAllButton";
import ResetButton from "./Buttons/ResetButton";
import useAudienceToolStore from "../../../store/useAudienceToolStore";

const TableAudienceButtonsV2 = () => {
  const isTemplate = useAudienceToolStore((state) => state.isTemplate);
  const isDisplayOnly = useAudienceToolStore((state) => state.isDisplayOnly);
  const initialLoad = useAudienceToolStore((state) => state.initialLoad);

  if (isDisplayOnly) return null;

  if (initialLoad)
    return (
      <div
        style={{
          height: 40,
          width: "100%",
          background: "#eeeeee44",
          marginBottom: 8,
          borderRadius: 8,
        }}
      />
    );

  if (isTemplate) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: 4,
          width: "100%",
          marginBottom: 8,
        }}
      >
        <SelectAllButton />
        <ResetButton />
        <div style={{ width: 8, marginLeft: "auto" }} />

        <UndoButton />
      </div>
    );
  }

  return (
    <>
      <div
        style={{
          marginTop: 12,
          flexBasis: 48,
          marginBottom: 8,
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          flexWrap: "wrap",
          gap: 4,
          paddingLeft: 2,
          paddingRight: 4,
          borderRadius: 4,
        }}
      >
        <SelectAllButton />
        <ResetButton />
        <ImportButton />
        <div style={{ marginLeft: "auto" }} />
        <UndoButton />
      </div>
    </>
  );
};

export default TableAudienceButtonsV2;
