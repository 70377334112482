import React from "react";
import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import SeeSubmission from "./SeeSubmission";

const SeeSubmissionModal = ({ submissionInfo, setSubmissionInfo }) => (
  <Modal
    open={Boolean(submissionInfo)}
    onClose={() => setSubmissionInfo(null)}
    aria-labelledby="View single submission"
    aria-describedby="Modal to view a single submission"
  >
    <div>
      <SeeSubmission
        submissionInfo={submissionInfo}
        setSubmissionInfo={setSubmissionInfo}
      />
    </div>
  </Modal>
);

export default SeeSubmissionModal;

SeeSubmissionModal.propTypes = {
  submissionInfo: PropTypes.object,
  setSubmissionInfo: PropTypes.func.isRequired,
};

SeeSubmissionModal.defaultProps = {
  submissionInfo: null,
};
