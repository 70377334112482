import React from "react";
import useAudienceToolStore from "../../../../store/useAudienceToolStore";
import { USER_TYPES } from "../../../../storage/constants";
import { TABLE_STATES } from "../helpers";
import TemplateBox from "./TemplateBox";
import SupervisorBox from "./SupervisorBox";
import AdminBox from "./AdminBox";

const SavedAudienceBox = () => {
  const tableState = useAudienceToolStore((state) => state.tableState);

  const userIsManager = useAudienceToolStore(
    (state) => state.userType === USER_TYPES.RETAIL_MANAGER_CAN_DEPLOY,
  );
  const isTemplate = useAudienceToolStore((state) => state.isTemplate);

  const displayAudienceSelect = useAudienceToolStore(
    (state) => state.displayAudienceSelect,
  );

  if (
    isTemplate &&
    [TABLE_STATES.LOCKED, TABLE_STATES.SAVING].includes(tableState)
  ) {
    return <TemplateBox />;
  }

  if (displayAudienceSelect) {
    return <AdminBox />;
  }

  if (userIsManager && tableState === TABLE_STATES.LOCKED) {
    return <SupervisorBox />;
  }

  return null;
};

export default SavedAudienceBox;
