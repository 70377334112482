import React from "react";
import {
  Alert,
  Button,
  CircularProgress,
  Paper,
  Typography,
} from "@mui/material";
import { addDays } from "date-fns";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { PlusCircle } from "react-feather";
import styled from "styled-components";
import API from "../../../axios/instances/API";
import { AUDIENCETYPES } from "../../../storage/constants";
import useAudienceOptions from "../../../swr/hooks/Audience/useAudienceOptions";

import ACTIONS from "../../../pages/private/marketplace/state/actions";
import { apiFormatDate, getSrcTwo } from "../../../storage/helpers";
import CurrentAudience from "../currentAudience";
import RoleAndLocationSelectionModals from "../roleAndLocationSelectionModals";
import AudienceButtons from "./AudienceButtons";
import DateFields from "./DateFields";

const ModalWrapper = styled(Paper)`
  padding: 20px;
  width: calc(min(560px, 96vw));
  max-height: 90vh;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow-y: scroll;
  border: 2px solid black;
`;

const Subtitle = styled(Typography)`
  font-size: 13px;
  font-weight: 600;
`;

const formatSelectedAudeinceForApi = (
  selectedAudience,
  comboValue,
  options,
) => {
  const locationIds = options.locations.map((l) => l.id);
  const roleIds = options.roles.map((r) => r.id);
  const retObj = {};

  if (selectedAudience === AUDIENCETYPES.SELECT_ALL) {
    locationIds.forEach((id) => (retObj[id] = roleIds));

    return retObj;
  }

  if (selectedAudience === AUDIENCETYPES.ROLES) {
    const selectedRoleIds = comboValue;

    locationIds.forEach((id) => (retObj[id] = selectedRoleIds));

    return retObj;
  }

  if (selectedAudience === AUDIENCETYPES.LOCATIONS) {
    const selectedLocationIds = comboValue;
    const roleIds = options.roles.map((r) => r.id);

    locationIds.forEach(
      (id) => (retObj[id] = selectedLocationIds.includes(id) ? roleIds : []),
    );

    return retObj;
  }
};

const AddAudienceAndDatesToLaunchFromMarketplace = ({
  moduleId = null,
  setSnackbarText,
  setAdded,
  setLaunched,
  setAddModalInfo,
  dispatch,
}) => {
  const { options, isLoading: optionsLoading, isError } = useAudienceOptions();
  const [moduleInfo, setModuleInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [addingToLibrary, setAddingToLibrary] = useState(false);
  const [isLaunching, setIsLaunching] = useState(false);
  const [selectedAudience, setSelectedAudience] = useState(AUDIENCETYPES.NONE);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [errorAlert, setErrorAlert] = useState(null);
  const [locationModalOpen, setLocationModalOpen] = useState(false);
  const [roleModalOpen, setRoleModalOpen] = useState(false);
  const [comboValue, setComboValue] = useState([]);

  useEffect(() => {
    const getModuleInfo = async () => {
      try {
        const { data } = await API.get(
          `/dashboard/marketplace/quizzes/quiz/?id=${moduleId}`,
        );
        setModuleInfo(data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    getModuleInfo();
  }, []);

  useEffect(() => {
    if (Boolean(endDate) && startDate >= endDate) {
      const newEndDate = addDays(startDate, 1);

      return setEndDate(newEndDate);
    }
    return null;
  }, [startDate, endDate]);

  if (isLoading || optionsLoading) {
    return (
      <ModalWrapper style={{ minHeight: 400 }}>
        <div
          style={{
            width: "100%",
            height: "100%",
            minHeight: 370,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span style={{ fontSize: 12, color: "lightgray" }}>loading...</span>
        </div>
      </ModalWrapper>
    );
  }

  if (isError) {
    return (
      <Alert severity="error">Error Loading Options, please try again.</Alert>
    );
  }

  const handleAddToLibError = (error) => {
    if (error?.response?.data?.error?.details) {
      return {
        title: error.response?.data?.error?.title,
        details: error.response?.data?.error?.details,
      };
    }

    return {
      title: "Error",
      details:
        "Unable to add this module to your team library. There might be an issue with this module.",
    };
  };

  const handleLaunchError = (error) => {
    if (error?.response?.data?.error?.details) {
      return {
        title: error.response?.data?.error?.title,
        details: error.response?.data?.error?.details,
      };
    }

    return {
      title: "Error",
      details:
        "Unable to launch this module. There might be an issue with this module.",
    };
  };

  const { locations, roles } = options;
  const noLocations = locations.length === 0;

  const handleLaunchFromMarketplace = async () => {
    setIsLaunching(true);

    try {
      const audienceBody = formatSelectedAudeinceForApi(
        selectedAudience,
        comboValue,
        options,
      );

      const sendObj = {
        id: moduleInfo.id,
        start_date: apiFormatDate(startDate),
        due_date: endDate ? apiFormatDate(endDate) : null,
        audience: audienceBody,
      };

      await API.post("/dashboard/marketplace/quizzes/add-and-deploy/", sendObj);

      setLaunched(true);
      setSnackbarText("Module launched success");
      dispatch({
        type: ACTIONS.HANDLE_MODULE_EXISTS_CHANGE,
        payload: moduleId,
      });
      setAddModalInfo(null);
    } catch (error) {
      const errorObj = handleLaunchError(error);
      setErrorAlert(errorObj);
      setIsLaunching(false);
    }
  };

  const handleAddToLibrary = async () => {
    try {
      setAddingToLibrary(true);
      await API.post("/dashboard/marketplace/quizzes/add/", {
        id: moduleInfo.id,
      });
      setAdded(true);
      setSnackbarText("Module added to library success");
      dispatch({
        type: ACTIONS.HANDLE_MODULE_EXISTS_CHANGE,
        payload: moduleId,
      });
      setAddModalInfo(null);
      setAddingToLibrary(false);
    } catch (error) {
      const errorObj = handleAddToLibError(error);
      setAddingToLibrary(false);
      setErrorAlert(errorObj);
    }
  };

  const handleSetLocationsFromModal = (selectedLocations) => {
    setComboValue(selectedLocations);
    return setSelectedAudience(AUDIENCETYPES.LOCATIONS);
  };

  const handleSetRolesFromModal = (selectedRoles) => {
    setComboValue(selectedRoles);
    return setSelectedAudience(AUDIENCETYPES.ROLES);
  };

  const launchDisabled = !(
    Boolean(startDate) &&
    selectedAudience !== AUDIENCETYPES.NONE &&
    !isLaunching
  );

  return (
    <ModalWrapper>
      <div
        style={{
          height: 48,
          width: "100%",
          marginBottom: 24,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          borderRadius: 2,
        }}
      >
        <img
          style={{
            height: 46,
            width: 46,
            background: "white",
            borderRadius: 8,
            objectFit: "contain",
            border: "1px solid #eeeeee",
          }}
          alt="module"
          src={getSrcTwo(moduleInfo.image)}
        />
        <div style={{ marginLeft: 6 }}>
          <Typography
            style={{ fontSize: 16, fontWeight: "600", marginBottom: -2 }}
          >
            {moduleInfo?.name}
          </Typography>

          <Typography variant="body1">By: {moduleInfo.company}</Typography>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Subtitle>Select Start and End Date</Subtitle>
      </div>
      <DateFields
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
      />
      <Subtitle style={{ marginBottom: 8 }}>Select Audience</Subtitle>
      <AudienceButtons
        setSelectedAudience={setSelectedAudience}
        setComboValue={setComboValue}
        setLocationModalOpen={setLocationModalOpen}
        setRoleModalOpen={setRoleModalOpen}
        selectedAudience={selectedAudience}
      />
      <div style={{ height: 12 }} />
      <CurrentAudience
        selectedAudience={selectedAudience}
        comboValue={comboValue}
        options={options}
        moduleId={moduleId}
      />
      <div
        style={{
          width: "100%",
          display: "flex",
          marginTop: 16,
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Button
          variant="text"
          startIcon={
            addingToLibrary ? (
              <CircularProgress size={10} sx={{ color: "black" }} />
            ) : (
              <PlusCircle height={14} width={14} />
            )
          }
          color="inherit"
          disabled={noLocations}
          onClick={handleAddToLibrary}
        >
          Add to library
        </Button>

        <Button
          disabled={launchDisabled}
          variant="contained"
          color="primary"
          style={{
            backgroundColor: launchDisabled ? "darkgrey" : "#337AB7",
            color: "#eeeeee",
            marginLeft: "auto",
          }}
          onClick={handleLaunchFromMarketplace}
          startIcon={
            isLaunching ? (
              <CircularProgress size={18} sx={{ color: "white" }} />
            ) : null
          }
        >
          {isLaunching ? "Launching Module" : "Launch Module 🚀"}
        </Button>
      </div>

      {errorAlert && (
        <Alert
          severity="error"
          sx={{ mt: 3 }}
          onClose={() => setErrorAlert(null)}
        >
          <p style={{ fontWeight: "600", fontSize: 12 }}>{errorAlert?.title}</p>
          <p style={{ fontSize: 12 }}>{errorAlert?.details}</p>
        </Alert>
      )}
      <RoleAndLocationSelectionModals
        locations={locations}
        roles={roles}
        handleApplyRoles={handleSetRolesFromModal}
        handleApplyLocations={handleSetLocationsFromModal}
        handleRolesClose={() => setRoleModalOpen(false)}
        handleLocationsClose={() => setLocationModalOpen(false)}
        locationModalOpen={locationModalOpen}
        roleModalOpen={roleModalOpen}
        setRoleModalOpen={setRoleModalOpen}
        setLocationModalOpen={setLocationModalOpen}
      />
    </ModalWrapper>
  );
};

export default AddAudienceAndDatesToLaunchFromMarketplace;

AddAudienceAndDatesToLaunchFromMarketplace.propTypes = {
  setAddModalInfo: PropTypes.func.isRequired,
  setAdded: PropTypes.func.isRequired,
};
