import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Button,
  TextField,
} from "@mui/material";
import API from "../../../axios/instances/API";

const InviteDialog = ({
  dialogInfo,
  handleClose,
  locationOptions,
  setSnackbarText,
}) => {
  const [newLocation, setNewLocation] = useState(null);

  if (!dialogInfo) return null;

  const {
    status,
    invitee_data: inviteeData,
    users_data: usersData,
  } = dialogInfo;

  const handleLocationChange = async () => {
    try {
      await API.post("/dashboard/teams/switch_locations_in_bulk/", [
        {
          type: "switch",
          current_tm_id: usersData.current_tm_id,
          new_location_id: newLocation,
        },
      ]);
      setSnackbarText("Team member location changed success");
      return handleClose(true);
    } catch (error) {
      setSnackbarText("Team member location could not be changed error");
      return handleClose(false);
    }
  };

  return (
    <Dialog
      open={Boolean(dialogInfo)}
      onClose={() => handleClose(false)}
      fullWidth
    >
      <DialogTitle>Invite not sent</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {status === "failed"
            ? `There is already a team member at your company with ${
                inviteeData.phone_number ? "phone number" : "email"
              } ${
                inviteeData.phone_number
                  ? inviteeData.phone_number
                  : inviteeData.email
              }. You cannot invite this team member again`
            : `There is already a team member at your company with ${
                inviteeData.phone_number ? "phone number" : "email"
              } ${
                inviteeData.phone_number
                  ? inviteeData.phone_number
                  : inviteeData.email
              }. Their currect location is ${usersData.current_location_name}`}
        </DialogContentText>
        {status === "action_required" && (
          <>
            <p style={{ marginTop: 12 }}>
              You can select a new location for this team member if you like
            </p>
            <TextField
              select
              fullWidth
              variant="outlined"
              size="small"
              sx={{ mt: 3 }}
              label="Locations"
              value={newLocation}
              onChange={(e) => setNewLocation(e.target.value)}
            >
              {locationOptions.map((loc) => (
                <MenuItem
                  key={loc?.id}
                  value={loc?.id}
                  disabled={loc.id === usersData.current_location_id}
                >
                  {loc.name}
                </MenuItem>
              ))}
            </TextField>
          </>
        )}
      </DialogContent>
      <DialogActions style={{ marginTop: 12, marginBottom: 6 }}>
        {status === "action_required" && (
          <Button
            variant="contained"
            color="primary"
            disabled={!newLocation}
            onClick={handleLocationChange}
          >
            Change location
          </Button>
        )}
        <Button onClick={() => handleClose(false)} variant="outlined">
          Back to invite
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InviteDialog;

InviteDialog.propTypes = {
  dialogInfo: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  locationOptions: PropTypes.array.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
};
