import React from "react";
import { User } from "react-feather";
import { InputAdornment, TextField } from "@mui/material";

const LastNameField = ({
  touched,
  errors,
  values,
  handleChange,
  handleBlur,
}) => (
  <TextField
    type="text"
    name="lastName"
    variant="outlined"
    label="Last Name"
    size="small"
    fullWidth
    value={values.lastName}
    onChange={handleChange}
    onBlur={handleBlur}
    error={Boolean(touched.lastName && errors.lastName)}
    helperText={touched.lastName && errors.lastName}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <User style={{ height: 14, width: 14, color: "darkgrey" }} />
        </InputAdornment>
      ),
      autoComplete: "new-password",
      form: {
        autoComplete: "off",
      },
    }}
  />
);

export default LastNameField;
