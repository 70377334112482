import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import {
  InfoWrapper,
  InfoPaper,
  DescriptorText,
  OverSizedText,
} from "../../home/styles";
import { addCommas } from "../../../../storage/helpers";
import { Alert } from "@mui/material";
import LocationProgress from "./LocationProgress";
import SubmissionStats from "./SubmissionStats";
import TeamMemberTable from "./TeamMemberTable";
import SubmissionTable from "./SubmissionTable";
import ManagersDataTable from "./ManagersDataTable";
import ExtraInfo from "../../../../components/ExtraInfo";
import { BarChart2, Percent, PieChart, Award } from "react-feather";
import { ENGAGEMENT_TYPES } from "../../../../storage/constants";
import SubmissionTypeChip from "../../../../components/SubmissionTypeChip";

const Spacer = styled.div`
  height: 16px;
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 6px;
`;

const getSubsByEngagementType = (submissionsCount, engagementType) => {
  if (engagementType === ENGAGEMENT_TYPES.ALL) {
    return (
      submissionsCount.assigned_submissions +
      submissionsCount.extra_credit_submissions
    );
  }
  if (engagementType === ENGAGEMENT_TYPES.ASSIGNED) {
    return submissionsCount.assigned_submissions;
  }

  return submissionsCount.extra_credit_submissions;
};

const getCompsByEngagementType = (submissionsCount, engagementType) => {
  if (engagementType === ENGAGEMENT_TYPES.ALL) {
    return (
      submissionsCount.assigned_completions +
      submissionsCount.extra_credit_completions
    );
  }
  if (engagementType === ENGAGEMENT_TYPES.ASSIGNED) {
    return submissionsCount.assigned_completions;
  }

  return submissionsCount.extra_credit_completions;
};

const getPassRateByEngagementType = (submissionsCount, engagementType) => {
  let totalComps = 0;
  let totalSubs = 0;

  if (engagementType === ENGAGEMENT_TYPES.ALL) {
    totalComps =
      submissionsCount.assigned_completions +
      submissionsCount.extra_credit_completions;
    totalSubs =
      submissionsCount.assigned_submissions +
      submissionsCount.extra_credit_submissions;
  }
  if (engagementType === ENGAGEMENT_TYPES.ASSIGNED) {
    totalComps = submissionsCount.assigned_completions;
    totalSubs = submissionsCount.assigned_submissions;
  }

  if (engagementType === ENGAGEMENT_TYPES.EXTRACREDIT) {
    totalComps = submissionsCount.extra_credit_completions;
    totalSubs = submissionsCount.extra_credit_submissions;
  }

  if (totalSubs === 0) return "0%";

  const total = Math.floor((totalComps / totalSubs) * 100);

  return `${total}%`;
};

const LocationReport = ({
  locationId,
  setSnackbarText,
  mutate,
  data,
  engagementType,
}) => {
  const { submissions_count } = data;

  const { submissions } = data;
  return (
    <>
      <InfoWrapper>
        <InfoPaper>
          <FlexDiv>
            <BarChart2 />
            <SubmissionTypeChip submissionType={engagementType} small />
          </FlexDiv>
          <div style={{ height: 8 }} />

          <DescriptorText>Total Submissions</DescriptorText>
          <OverSizedText>
            {addCommas(
              getSubsByEngagementType(submissions_count, engagementType),
            )}
          </OverSizedText>
        </InfoPaper>
        <InfoPaper>
          <FlexDiv>
            <PieChart />
            <SubmissionTypeChip submissionType={engagementType} small />
          </FlexDiv>
          <div style={{ height: 8 }} />

          <DescriptorText>Total Completions</DescriptorText>
          <OverSizedText>
            {addCommas(
              getCompsByEngagementType(submissions_count, engagementType),
            )}
          </OverSizedText>
        </InfoPaper>
        <InfoPaper>
          <FlexDiv>
            <Percent />
            <SubmissionTypeChip submissionType={engagementType} small />
          </FlexDiv>
          <div style={{ height: 8 }} />

          <DescriptorText>Pass Rate</DescriptorText>
          <OverSizedText>
            {getPassRateByEngagementType(submissions_count, engagementType)}
          </OverSizedText>
        </InfoPaper>
        <InfoPaper style={{ background: "#eeeeee" }}>
          <Award
            color="slategrey"
            height={22}
            width={22}
            style={{ marginBottom: 1 }}
          />

          <div style={{ height: 8 }} />
          <DescriptorText>
            Average Spiffy Score
            <ExtraInfo text="A user’s Spiffy score is determined by considering their overall knowledge acquired, consistency in learning, and pass rate. The Average Spiffy score refers to the average value of the Spiffy scores of all team members within a specific location." />
          </DescriptorText>
          <OverSizedText>
            {Math.floor(data.location_spiffy_score)}
          </OverSizedText>
        </InfoPaper>
      </InfoWrapper>
      <LocationProgress locationId={locationId} />
      <Spacer />
      <TeamMemberTable
        teamMembers={data.team_members}
        locationName={data.location.name}
        locationId={locationId}
        teamMemberClickable
        data={data}
        engagementType={engagementType}
      />
      <Spacer />
      <ManagersDataTable
        readOnly={false}
        isAdmin
        data={data}
        mutate={mutate}
        setSnackbarText={setSnackbarText}
      />
      <Spacer />
      {submissions.length > 10 ? (
        <>
          <SubmissionTable
            location={data.location.name}
            submissions={submissions}
            engagementType={engagementType}
          />
          <div style={{ height: 16 }} />
          <SubmissionStats
            engagementType={engagementType}
            submissions={submissions}
            teamMembers={data.team_members.length}
          />
        </>
      ) : (
        <Alert severity="info" sx={{ mt: 4 }}>
          Additional analytics will be available once your team reaches 10
          submissions. Invite more team members or deploy some modules to boost
          engagement!
        </Alert>
      )}
    </>
  );
};

export default LocationReport;

LocationReport.propTypes = {
  locationId: PropTypes.string.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
  mutate: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};
