import React from "react";
import { CornerUpLeft } from "react-feather";
import useAudienceToolStore from "../../../../store/useAudienceToolStore";
import { TABLE_STATES } from "../helpers";
import { areRowsAndCurrentAudienceEqual } from "../helpers";
import AttActionButtonWithText from "./AttActionButtonWithText";

const UndoButton = () => {
  const rows = useAudienceToolStore((state) => state.rows);
  const currentAudience = useAudienceToolStore(
    (state) => state.currentAudience,
  );
  const handleDiscardChanges = useAudienceToolStore(
    (state) => state.handleDiscardChanges,
  );
  const shouldBeDisabled = useAudienceToolStore(
    (state) => state.tableState !== TABLE_STATES.UNLOCKED,
  );

  const changesMade = !areRowsAndCurrentAudienceEqual(rows, currentAudience);

  return (
    <AttActionButtonWithText
      title="Undo changes"
      disabled={!changesMade || shouldBeDisabled}
      handleClick={handleDiscardChanges}
      Icon={CornerUpLeft}
    />
  );
};

export default UndoButton;
