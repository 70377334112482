import React from "react";
import styled from "styled-components";
import LibraryShowModule from "../components/LibraryShowModule";
import { ShowState } from "../../styles";
import CheckboxBrandBasic from "../../../../components/UI/CheckboxBrandBasic";
import { STATE_MAP } from "../../../../storage/constants";

const IndividualModuleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 8px;
  background-color: #eeeeee35;
  border-width: 1px;
  margin-bottom: 2px;
  padding-top: 6px;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #eeeeee70;
  }
`;

const IndividualModule = ({ mod, config, handleItemClick, checked }) => {
  return (
    <IndividualModuleWrapper onClick={() => handleItemClick(mod.id)}>
      <LibraryShowModule
        srcString={mod.image}
        value={mod.name}
        marketplaceParentInfo={
          mod.fromMarketplace
            ? {
                companyName: mod.marketplaceParentCompanyName,
              }
            : null
        }
        config={config}
      />

      <div style={{ marginLeft: "auto" }} />
      <ShowState $state={STATE_MAP[mod.state]} small="Yes">
        <span>{STATE_MAP[mod.state]}</span>
      </ShowState>
      <div style={{ width: 6 }} />
      <CheckboxBrandBasic checked={checked} overrideColor="firebrick" />
    </IndividualModuleWrapper>
  );
};

export default IndividualModule;
