import React from "react";
import SnackbarWrapper from "../../SnackbarWrapper";
import useAudienceToolStore from "../../../store/useAudienceToolStore";

const AudienceSnack = () => {
  const snackText = useAudienceToolStore((state) => state.snackText);
  const setSnackText = useAudienceToolStore((state) => state.setSnackText);
  return (
    <SnackbarWrapper snackbarText={snackText} setSnackbarText={setSnackText} />
  );
};

export default AudienceSnack;
