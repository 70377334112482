import React, { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  CircularProgress,
  Alert,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import AuthLayout from "../../../layouts/Auth";

import { Wrapper } from "./styles";
import API from "../../../axios/instances/API";

const initialValues = {
  email: "",
  submit: false,
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required"),
});

const RecoverPassword = () => {
  const [alertText, setAlertText] = useState(false);
  const [successText, setSuccessText] = useState(false);

  const handleFormSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    setAlertText(false);
    setSuccessText(false);
    try {
      await API.post("dashboard/auth/forgot-password/", {
        email: values.email.trim(),
      });
      setSuccessText("Recovery link sent. Check your email");
      setSubmitting(false);
      resetForm();
    } catch (error) {
      setSubmitting(false);
      setAlertText("Hmm.. that email is not in our records");
      resetForm();
    }
  };

  return (
    <AuthLayout>
      <Helmet title="Spiffy | Recover Password" />
      <Wrapper>
        <Typography variant="h3" component="h3" align="center">
          Recover Password
        </Typography>
        <Typography
          component="h5"
          variant="subtitle2"
          align="center"
          color="textSecondary"
        >
          Enter your email to receive recovery link
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              {errors.submit && (
                <Alert sx={{ mt: 2, mb: 2 }} severity="error">
                  {errors.submit}
                </Alert>
              )}
              <TextField
                type="email"
                name="email"
                size="small"
                label="Email Address"
                value={values.email}
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                onBlur={handleBlur}
                onChange={handleChange}
                sx={{ mt: 4 }}
              />

              <Button
                type="submit"
                fullWidth
                sx={{ mt: 2 }}
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                startIcon={isSubmitting && <CircularProgress size={20} />}
              >
                {isSubmitting ? "Sending" : "Recover Password"}
              </Button>

              {successText && (
                <Alert
                  sx={{ mt: 2 }}
                  type="success"
                  onClose={() => setSuccessText(false)}
                >
                  {successText}
                </Alert>
              )}
              {alertText && (
                <Alert
                  severity="error"
                  onClose={() => setAlertText(false)}
                  sx={{ mt: 2 }}
                >
                  {alertText}
                </Alert>
              )}
            </form>
          )}
        </Formik>
      </Wrapper>
    </AuthLayout>
  );
};

export default RecoverPassword;
