import React, { useState } from "react";
import PropTypes from "prop-types";
import { Edit } from "react-feather";
import styled from "styled-components";
import {
  IconButton,
  Popover,
  Tooltip,
  Paper,
  MenuItem,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";

import API from "../../../axios/instances/API";
import { get400ErrorMsg } from "../../../storage/helpers";

const CustomIcon = styled(IconButton)`
  position: relative;
  background-color: ${(props) =>
    props.$isActive ? props.theme.palette.grey[200] : "transparent"};
  margin-left: 4px;
  padding: 8px;

  svg {
    height: 12px;
    width: 12px;
    color: ${(props) =>
      props.$isActive
        ? props.theme.palette.primary.light
        : props.theme.palette.grey[500]};
  }
`;

const Wrapper = styled(Paper)`
  padding: 10px;
  min-width: 60px;
  min-height: 40px;
  max-width: 200px;
  display: flex;
  flex-direction: column;
`;

const ReorderStream = ({
  value,
  numStreams,
  id,
  currentStreamOrder,
  readOnly,
  setSnackbarText,
  mutate,
}) => {
  const indexOptions = [...Array(numStreams).keys()];
  const [anchorEl, setAnchorEl] = useState(null);
  const [newIndex, setNewIndex] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const open = Boolean(anchorEl);

  const handleSave = async () => {
    setIsSubmitting(true);
    try {
      const filtered = currentStreamOrder.filter((num) => num !== id);
      filtered.splice(Number(newIndex), 0, Number(id));
      await API.put("/dashboard/streams/", {
        order: filtered,
      });
      await mutate();
      setIsSubmitting(false);
      setSnackbarText("Order of streams updated success");
      return setAnchorEl(null);
    } catch (error) {
      setIsSubmitting(false);
      const errorMsg = get400ErrorMsg(
        error,
        "Failed to update order of streams error",
      );
      return setSnackbarText(errorMsg);
    }
  };

  return (
    <>
      <span>{value + 1}</span>
      <Tooltip title="Change Position">
        <CustomIcon
          onClick={(e) => {
            setAnchorEl(e.target);
          }}
          disabled={readOnly}
          $isActive={open}
        >
          <Edit />
        </CustomIcon>
      </Tooltip>
      <Popover
        open={open}
        onClose={() => {
          setNewIndex("");
          setAnchorEl(null);
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Wrapper>
          <TextField
            select
            value={newIndex}
            onChange={(e) => setNewIndex(e.target.value)}
            size="small"
            variant="outlined"
            disabled={isSubmitting}
          >
            {indexOptions.map((option) => (
              <MenuItem key={option} value={option} disabled={option === value}>
                {option + 1}
              </MenuItem>
            ))}
          </TextField>
          <Button
            disabled={!newIndex && newIndex !== 0}
            sx={{ mt: 1 }}
            onClick={handleSave}
            startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
          >
            {isSubmitting ? "" : "Save"}
          </Button>
        </Wrapper>
      </Popover>
    </>
  );
};

export default ReorderStream;

ReorderStream.propTypes = {
  id: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  numStreams: PropTypes.number.isRequired,
  currentStreamOrder: PropTypes.array.isRequired,
  readOnly: PropTypes.bool.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
  mutate: PropTypes.func.isRequired,
};
