import React from "react";
import { Plus } from "react-feather";
import { Button, Tooltip } from "@mui/material";
import styled from "styled-components";

const StyledButtonPrimary = styled(Button)`
  height: 38px;
  width: fit-content;
  min-height: 38px;
  max-height: 38px;
  min-width: 38px;
  display: flex;
  border-radius: 4px;
  align-items: center;
  justify-content: flex-start;

  padding: 0px 8px;
  transition: background-color 200ms ease-in;

  svg {
    color: white;
  }

  &:hover {
    svg {
      color: #eeeeee;
    }
  }

  &:disabled {
    background-color: #eeeeee;
  }
`;

const CreateButton = ({ disabled = false, title = "add", handleClick }) => {
  return (
    <Tooltip title={title} arrow>
      <span>
        <StyledButtonPrimary
          onClick={handleClick}
          disabled={disabled}
          variant="contained"
          color="primary"
          sx={{
            whiteSpace: "nowrap",
            textTransform: "none",
          }}
        >
          <Plus height={17} width={17} />
          <div style={{ width: 6 }} />
          <span>{title}</span>
        </StyledButtonPrimary>
      </span>
    </Tooltip>
  );
};

export default CreateButton;
