import React from "react";
import useAudienceToolStore from "../../../../store/useAudienceToolStore";
import { TABLE_STATES } from "../helpers";
import AttActionButtonWithText from "./AttActionButtonWithText";
import { Save } from "react-feather";
import { USER_TYPES } from "../../../../storage/constants";

const SaveButton = () => {
  const handleSaveFromModal = useAudienceToolStore(
    (state) => state.handleSaveFromModal,
  );
  const shouldSaveBeDisabled = useAudienceToolStore((state) =>
    [
      TABLE_STATES.LOCKED,
      TABLE_STATES.LOADING,
      TABLE_STATES.ERROR,
      TABLE_STATES.SAVING,
    ].includes(state.tableState),
  );

  const isTemplate = useAudienceToolStore((state) => state.isTemplate);
  const handleSaveAsTemplate = useAudienceToolStore(
    (state) => state.handleSaveAsTemplate,
  );
  const isDeployableManager = useAudienceToolStore(
    (state) => state.userType === USER_TYPES.RETAIL_MANAGER_CAN_DEPLOY,
  );

  const selectedAudienceIsEmpty = useAudienceToolStore((state) =>
    [...Object.values(state.rows)].flat().every((obj) => !obj.checked),
  );

  return (
    <AttActionButtonWithText
      primary
      Icon={Save}
      title={isTemplate ? "Save template" : "Save Audience"}
      handleClick={
        isTemplate ? () => handleSaveAsTemplate() : handleSaveFromModal
      }
      disabled={
        shouldSaveBeDisabled ||
        (!isDeployableManager && selectedAudienceIsEmpty)
      }
    />
  );
};

export default SaveButton;
