import useSWR from "swr";
import API from "../../../axios/instances/API";

const fetcher = async (url) => {
  const { data } = await API.get(url);
  return data.categories;
};

const useRoleCategories = () => {
  const { data, error } = useSWR("dashboard/roles/categories/", fetcher, {
    revalidateOnFocus: false,
  });

  return {
    roleCategories: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export default useRoleCategories;
