import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { CustomDatatableV2 } from "../../styles";
import SubmissionChipExtraCreditTooltip from "src/components/UI/SubmissionChipExtraCreditTooltip";
import SubmissionChipAssignedTooltip from "src/components/UI/SubmissionChipAssignedTooltip";
import { makeDefaultTableOptions } from "../../../../storage/helpers";
import CustomHeadLabel from "./CustomHeadLabel";
import CustomFooter from "./CustomFooter";
import { ORDER_BY, ENGAGEMENT_TYPES } from "../../../../storage/constants";
import SubmissionTypeChip from "../../../../components/SubmissionTypeChip";
import API from "../../../../axios/instances/API";
import SubmissionResultIndicator from "../../../../components/Table/SubmissionResultIndicator";
import TableTitle from "../../../../components/Table/TableTitle";
import { Download, X } from "react-feather";
import DateTooltip from "../../../../components/Table/DateTooltip";
import DisplayScore from "../../../../components/UI/DisplayScore";
import TopFilters from "./TopFilters";
import FunLoader from "../../../../components/Loaders/FunLoader";
import VendorShowTeams from "../../../../components/UI/VendorShowTeams";

const SmallResetBtn = styled.div`
  height: 15px;
  width: 15px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: black;
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.7);
  }

  svg {
    color: white;
    height: 12px;
    width: 12px;
  }
`;

const FilterChip = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: white;
  padding: 2px 6px;
  border: 1px solid gainsboro;
  border-radius: 5px;
  height: 24px;
  width: 100%;
  margin-bottom: 8px;

  @media (max-width: 800px) {
    display: none;
  }
`;

const BASE_URL = "/dashboard/analytics/vendor-reports/submission-list/?";

const getTableData = (submissions, users) => {
  if (!submissions) return [];

  return submissions.map((submission) => {
    const user = users.find((u) => u.user_id === submission.user_id);

    return {
      date: submission.created,
      module: submission.quiz_name,
      name: submission.users_name,
      state: submission?.users_state || "",
      submission_type: submission.is_follower_submission
        ? "Follower"
        : ENGAGEMENT_TYPES.ASSIGNED,
      team: user.active_team_members,
      score: `${submission.score}/${submission.questions}`,
      passed: submission.passed ? "Yes" : "No",
    };
  });
};

const ServerSideSubmissionTable = ({ companies, users }) => {
  const [engagementType, setEngagementType] = useState(ENGAGEMENT_TYPES.ALL);
  const [pageSize, setPageSize] = useState(100);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [orderBy, setOrderBy] = useState(ORDER_BY.CREATED);
  const [orderDirection, setOrderDirection] = useState(null);
  const [submissions, setSubmissions] = useState(null);
  const [queryData, setQueryData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [companyId, setCompanyId] = useState(0);
  3;
  const getCompanyNameById = (id) => {
    return companies.find((c) => c.company_id === id)?.name;
  };

  const buildSearchParamsAsString = (paginated = true) => {
    const obj = {
      enable_pagination: paginated ? 1 : 0,
      page_size: pageSize,
      page: pageNumber,
      order_by: orderBy,
    };

    if (searchText.length > 2) {
      obj.search_text = searchText;
    }

    if (orderDirection) {
      obj.order_direction = orderDirection;
    }

    if (companyId) {
      obj.company_id = companyId;
    }

    if (engagementType !== ENGAGEMENT_TYPES.ALL) {
      obj.submission_source =
        engagementType === ENGAGEMENT_TYPES.ASSIGNED ? "assigned" : "follower";
    }

    const searchParams = new URLSearchParams(obj);

    return searchParams.toString();
  };

  const getData = async () => {
    try {
      setIsLoading(true);
      const urlWithParams = `${BASE_URL}${buildSearchParamsAsString()}`;
      const { data } = await API.get(urlWithParams);

      setSubmissions(data.submissions);
      setQueryData(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const tableData = useMemo(
    () => getTableData(submissions, users),
    [submissions, users],
  );

  useEffect(() => {
    getData();
  }, [
    orderBy,
    pageSize,
    pageNumber,
    orderDirection,
    searchText,
    companyId,
    engagementType,
  ]);

  if (!queryData) {
    return (
      <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
        <div
          style={{
            width: "100%",
            height: 60,
            borderRadius: 8,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "white",
          }}
        />
        <div style={{ height: 12 }} />
        <div
          style={{
            width: "100%",
            height: 400,
            borderRadius: 8,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "white",
          }}
        >
          <span style={{ color: "darkgrey" }}>Loading Submissions...</span>
        </div>
      </div>
    );
  }

  const columns = [
    {
      name: "date",
      label: "Submission Date",
      options: {
        sort: false,
        customBodyRender: (value) => (
          <DateTooltip shouldLocalize date={value} displayYear />
        ),
        customHeadLabelRender: () => (
          <CustomHeadLabel
            title="Date"
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            orderDirection={orderDirection}
            setOrderDirection={setOrderDirection}
            orderKey={ORDER_BY.CREATED}
            isOrderedByColumn={orderBy === ORDER_BY.CREATED}
          />
        ),
      },
    },
    {
      name: "name",
      label: "User Name",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => (
          <span style={{ color: "black" }}>{val}</span>
        ),
      },
    },
    {
      name: "state",
      label: "User State",
      options: {
        filter: false,
        sort: false,
        customHeadLabelRender: () => (
          <CustomHeadLabel
            title="User State"
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            orderDirection={orderDirection}
            setOrderDirection={setOrderDirection}
            orderKey={ORDER_BY.USERS_STATE}
            isOrderedByColumn={orderBy === ORDER_BY.USERS_STATE}
          />
        ),
        customBodyRender: (val) =>
          val ? (
            <span style={{ color: "black" }}>{val}</span>
          ) : (
            <span style={{ color: "darkgrey" }}>---/---</span>
          ),
      },
    },
    {
      name: "module",
      label: "Module Name",
      options: {
        filter: true,
        sort: false,

        customBodyRender: (val) => (
          <span style={{ color: "black" }}>{val}</span>
        ),
      },
    },
    {
      name: "team",
      label: "Team",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => <VendorShowTeams teamsArray={value} />,
      },
    },
    {
      name: "submission_type",
      label: "Type",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          if (value === ENGAGEMENT_TYPES.ASSIGNED) {
            return <SubmissionChipAssignedTooltip />;
          }
          if (value === "Follower") {
            return <SubmissionChipExtraCreditTooltip isVendor />;
          }
          return <span>{value}</span>;
        },
      },
    },
    {
      name: "score",
      label: "Score",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => <DisplayScore value={value} />,
        customHeadLabelRender: () => (
          <CustomHeadLabel
            title="Score"
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            orderDirection={orderDirection}
            setOrderDirection={setOrderDirection}
            orderKey={ORDER_BY.SCORE}
            isOrderedByColumn={orderBy === ORDER_BY.SCORE}
          />
        ),
      },
    },
    {
      name: "passed",
      label: "Result",
      options: {
        display: true,
        sort: false,
        download: false,
        print: false,
        customBodyRender: (value) => (
          <SubmissionResultIndicator value={value} />
        ),
      },
    },
  ];

  const options = makeDefaultTableOptions("all-submissions");

  options.customFooter = () => (
    <CustomFooter
      isLoading={isLoading}
      queryData={queryData}
      setPageNumber={setPageNumber}
      pageNumber={pageNumber}
      pageSize={pageSize}
      setPageSize={setPageSize}
    />
  );

  options.rowsPerPage = pageSize;
  options.sort = false;
  options.search = false;
  options.print = false;
  options.download = false;

  return (
    <div
      style={{
        width: "100%",
        marginTop: 0,
        minHeight: 200,
        padding: 12,
        background: "#eeeeee",
        borderRadius: 6,
      }}
    >
      <TopFilters
        engagmentType={engagementType}
        setEngagementType={setEngagementType}
        searchText={searchText}
        setSearchText={setSearchText}
        companyId={companyId}
        setCompanyId={setCompanyId}
        companies={companies}
        isLoading={isLoading}
        setPage={setPageNumber}
        tableData={tableData}
        buildSearchParamsAsString={buildSearchParamsAsString}
        users={users}
      />
      {searchText && (
        <FilterChip>
          <span style={{ fontSize: 12, color: "slategrey", marginRight: 4 }}>
            Including Keyword:
          </span>
          <span style={{ fontSize: 12, fontWeight: 600, marginRight: 4 }}>
            {searchText}
          </span>

          <div style={{ marginLeft: "auto" }} />
          <SmallResetBtn
            onClick={() => {
              setSearchText("");
            }}
          >
            <X />
          </SmallResetBtn>
        </FilterChip>
      )}
      <div style={{ width: 4 }} />
      {companyId === 0 ? null : (
        <FilterChip>
          <span style={{ fontSize: 12, color: "slategrey", marginRight: 4 }}>
            Filtered for Company:
          </span>
          <span style={{ fontSize: 12, fontWeight: 600, marginRight: 4 }}>
            {getCompanyNameById(companyId)}
          </span>
          <div style={{ marginLeft: "auto" }} />

          <SmallResetBtn onClick={() => setCompanyId(0)}>
            <X />
          </SmallResetBtn>
        </FilterChip>
      )}
      <div style={{ position: "relative" }}>
        {isLoading && (
          <div
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              background: "#ffffffed",
              zIndex: 1000,
              padding: 20,
              borderRadius: 6,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div>
              <FunLoader />
              <div style={{ height: 20 }} />

              <span style={{ color: "darkgrey" }}>Loading....</span>
            </div>
          </div>
        )}
        <CustomDatatableV2
          title={
            <div
              style={{
                height: 46,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <TableTitle title="Submissions" Icon={Download} />
              <div style={{ width: 8 }} />
              <SubmissionTypeChip
                submissionType={engagementType}
                small
                isVendor
              />
            </div>
          }
          options={options}
          columns={columns}
          data={tableData}
        />
      </div>
    </div>
  );
};

export default ServerSideSubmissionTable;
