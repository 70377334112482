import React from "react";
import styled from "styled-components";
import useAllCountries from "../../../swr/hooks/Register/useAllCountries";
import useStatesByCountry from "../../../swr/hooks/Register/useStatesByCountry";
import { MenuItem, InputAdornment } from "@mui/material";
import { MapPin } from "react-feather";
import { TextField } from "@mui/material";

const Wrapper = styled.div`
  width: 100%;
  display: ${(props) => (props.$columns ? "flex" : "block")};
  gap: 4px;
  align-items: center;
  justify-content: center;
`;

const CountryAndStateFields = ({
  handleChange,
  values,
  columns = false,
  handleBlur,
  setFieldValue,
}) => {
  const { data: countries, isLoading: countriesLoading } = useAllCountries();
  const { data: states, isLoading: statesLoading } = useStatesByCountry(
    values.country,
  );

  return (
    <Wrapper $columns={columns}>
      <TextField
        select
        onChange={(e) => {
          setFieldValue("state", "");
          return handleChange(e);
        }}
        onBlur={handleBlur}
        label="Country of workplace"
        fullWidth
        name="country"
        variant="outlined"
        disabled={countriesLoading}
        size="small"
        color="primary"
        value={values.country}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <MapPin style={{ height: 14, width: 14, color: "darkgrey" }} />
            </InputAdornment>
          ),
        }}
      >
        {countries?.map((country) => (
          <MenuItem key={country.code} value={country.code}>
            {country.name}
          </MenuItem>
        ))}
      </TextField>
      <div style={{ height: 12 }} />
      <TextField
        select
        label="State/Province of workplace"
        fullWidth
        name="state"
        onBlur={handleBlur}
        disabled={statesLoading}
        variant="outlined"
        size="small"
        onChange={handleChange}
        color="primary"
        value={countriesLoading || statesLoading ? "" : values.state}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <MapPin style={{ height: 14, width: 14, color: "darkgrey" }} />
            </InputAdornment>
          ),
        }}
      >
        {(!states || states.length === 0) && (
          <MenuItem disabled>
            {statesLoading ? "loading.." : "No States Available"}
          </MenuItem>
        )}
        {states?.map((state) => (
          <MenuItem value={state.id} key={state.id}>
            {state.name}
          </MenuItem>
        ))}
      </TextField>
    </Wrapper>
  );
};

export default CountryAndStateFields;
