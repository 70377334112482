import React, { useState } from "react";
import { Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import styled from "styled-components";
import LoaderWrapper from "../../../../../components/Loaders/LoaderWrapper";
import PrivateHeader from "../../../../../components/PrivateHeader";
import ErrorBlockWithPermissions from "../../../../../components/UI/ErrorBlockWithPermissions";
import useStreamReportDetails from "../../../../../swr/hooks/VendorReports/useStreamReportDetails";
import EngagedCompaniesTable from "./EngagedCompaniesTable";
import EngagedUsersTable from "./EngagedUsersTable";
import ModuleCard from "./ModuleCard";
import SubmissionsTable from "./SubmissionsTable";
import AudienceNotEqualWarning from "./SubmissionsTable/AudienceNotEqualWarning";

const TabBarWrapper = styled.div`
  width: 480px;
  height: 40px;
  padding: 4px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  border-radius: 4px;
  padding-left: 4px;
`;

const TabBarItem = styled.div`
  border-radius: 4px;
  flex: 0.33;
  border: ${(props) =>
    props?.$isActive ? "2px solid #337ab7" : "2px solid lightgray"};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: ${(props) => (props?.$isActive ? "aliceblue" : "white")};

  span {
    white-space: nowrap;
    font-weight: ${(props) => (props?.$isActive ? 600 : 500)};
  }
`;

const StreamWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  padding: 4px;
  overflow-x: scroll;
`;

const Title = styled(Typography)`
  font-size: 14px;
  font-weight: 600;
  margin-left: 8px;
  margin-bottom: 8px;
`;

const PanelContainer = styled.div`
  padding-bottom: 60px;
  min-height: 600px;
`;

const SingleStreamReport = ({ match }) => {
  const { id } = match?.params;
  const { data, isLoading, isError } = useStreamReportDetails(id);
  const [activeTab, setActiveTab] = useState(0);

  if (isLoading) {
    return (
      <>
        <Helmet title="Spiffy | Stream Report" />
        <PrivateHeader header="Stream Report" secondary={true} />
        <LoaderWrapper text="Stream Report" />
      </>
    );
  }

  if (isError) {
    return (
      <>
        <Helmet title="Spiffy | Stream Report" />
        <PrivateHeader header="Stream Report" secondary={true} />;
        <ErrorBlockWithPermissions errorObj={isError} />
      </>
    );
  }

  const companyArr = data?.engaged_companies?.map((c) => ({
    id: c.id,
    name: c.name,
  }));

  const numLiveModules = data?.stream?.quizzes?.filter(
    (q) => q?.state_display === "Live",
  )?.length;

  return (
    <>
      <Helmet title="Spiffy | Stream Report" />
      <PrivateHeader header="Stream Report" secondary={data?.stream?.name} />
      {!data?.audience_is_equal && <AudienceNotEqualWarning />}
      <Title>Modules In Stream</Title>
      <div style={{ height: 4, minHeight: 4 }} />
      <StreamWrapper>
        {data?.stream?.quizzes?.map((mod, i) => (
          <ModuleCard key={mod?.id} mod={mod} i={i} />
        ))}
      </StreamWrapper>
      <div style={{ height: 12 }} />
      <Title>Stream Engagement</Title>

      <TabBarWrapper>
        <TabBarItem $isActive={activeTab == 0} onClick={() => setActiveTab(0)}>
          <span>Engaged Users</span>
        </TabBarItem>
        <TabBarItem $isActive={activeTab == 1} onClick={() => setActiveTab(1)}>
          <span>Engaged Companies</span>
        </TabBarItem>
        <TabBarItem $isActive={activeTab == 2} onClick={() => setActiveTab(2)}>
          <span>All Submissions</span>
        </TabBarItem>
      </TabBarWrapper>
      <div style={{ height: 10 }} />
      <PanelContainer>
        {activeTab === 0 && (
          <EngagedUsersTable
            numLiveModules={numLiveModules}
            users={data?.engaged_users}
          />
        )}
        {activeTab === 1 && (
          <EngagedCompaniesTable companies={data?.engaged_companies} />
        )}
        {activeTab === 2 && (
          <SubmissionsTable streamId={id} companyArr={companyArr} />
        )}
      </PanelContainer>
      <div style={{ height: 20 }} />
    </>
  );
};

export default SingleStreamReport;
