import React from "react";
import { ChevronUp, ChevronDown } from "react-feather";
import { Button } from "@mui/material";

import { ORDER_DIRECTIONS } from "../../../../../../storage/constants";

const CustomHeadLabel = ({
  title,
  setOrderBy,
  orderKey,
  orderBy,
  isOrderedByColumn,
  setOrderDirection,
  orderDirection,
}) => {
  const handleOrderToggle = () => {
    if (orderBy !== orderKey) {
      setOrderBy(orderKey);
      return setOrderDirection(ORDER_DIRECTIONS.ASCENDING);
    }

    if (orderDirection === null) {
      setOrderBy(orderKey);
      return setOrderDirection(ORDER_DIRECTIONS.ASCENDING);
    }

    if (orderDirection === ORDER_DIRECTIONS.ASCENDING) {
      setOrderBy(orderKey);

      return setOrderDirection(ORDER_DIRECTIONS.DESCENDING);
    }

    if (orderDirection === ORDER_DIRECTIONS.DESCENDING) {
      setOrderBy(orderKey);
      setOrderDirection(ORDER_DIRECTIONS.ASCENDING);
    }

    return null;
  };

  // valid dom nesting log warning happens because this is nested in a <p></p>

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      <Button
        onClick={handleOrderToggle}
        endIcon={
          !isOrderedByColumn ? null : orderDirection ===
            ORDER_DIRECTIONS.ASCENDING ? (
            <ChevronDown style={{ height: 16, width: 16, color: "darkgrey" }} />
          ) : (
            <ChevronUp style={{ height: 16, width: 16, color: "lightgray" }} />
          )
        }
      >
        <span style={{ color: "black" }}>{title}</span>
      </Button>
    </div>
  );
};

export default CustomHeadLabel;
