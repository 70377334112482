import React from "react";
import { Divider, Paper, Tooltip, Typography } from "@mui/material";
import useBadgeDetail from "../../../../swr/hooks/RetailReports/singleTeamMemberV2/useBadgeDetail";
import styled from "styled-components";
import { Award, Check } from "react-feather";

const Wrapper = styled(Paper)`
  padding: 20px;
  border-radius: 6px;
  min-width: 280px;
  width: 100%;
`;

const FakeBadge = styled.div`
  height: 100px;
  width: 100px;
  border-radius: 16px;
  background: white;
  padding: 12px;
  cursor: pointer;
  box-shadow: 0px 0px 4px 3px #eeeeee60;
  border: 1px solid #eeeeee;
  transition: 200ms ease-in;
  margin-bottom: 22px;
  filter: grayscale(${(props) => (props.$isAchieved ? 0 : 1)});

  &:hover {
    cursor: pointer;
    box-shadow: 0px 0px 5px 1px lightgray;
  }
`;

const BadgeImage = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
`;

const BadgeDetailWrapper = styled.div`
  min-height: 80px;
  width: 240px;
  background: white;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 6px;
`;

const Node = styled.div`
  height: 13px;
  width: 13px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TitleUi = ({ badge, maxLevel, obtainedLevel }) => {
  const { isLoading, isError, data } = useBadgeDetail(badge.code);

  if (isLoading) {
    return (
      <BadgeDetailWrapper>
        <div style={{ height: 100 + maxLevel * 40 }} />
      </BadgeDetailWrapper>
    );
  }

  if (isError) {
    return (
      <BadgeDetailWrapper>
        <span style={{ color: "firebrick" }}>Error getting data.</span>
      </BadgeDetailWrapper>
    );
  }

  const { achievement, levels } = data;

  const levelsArray = levels.map((l) => ({
    level: l.level,
    achieved: obtainedLevel >= l.level,
  }));

  const heightOfLevelsContainer = levels.length * 40;

  return (
    <BadgeDetailWrapper>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 3,
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
          <img
            style={{
              height: 18,
              width: 18,
              borderRadius: 4,
              objectFit: "contain",
              background: "#eeeeee",
              filter: `grayscale(${obtainedLevel === null ? 1 : 0})`,
            }}
            alt="badge"
            src={levels?.[0]?.image}
          />
          <span style={{ color: "black", fontWeight: 600, fontSize: 13 }}>
            {achievement?.name}
          </span>
        </div>
        <Award color="black" height={12} width={12} />
      </div>
      <span style={{ color: "darkgrey", fontSize: 10 }}>
        {achievement?.descriptions?.english}
      </span>
      <span
        style={{
          fontSize: 12,
          color: "slategrey",
          fontWeight: 600,
          marginBottom: -2,
        }}
      >
        Levels
      </span>

      <div
        style={{
          width: "100%",
          height: heightOfLevelsContainer,
          display: "flex",
        }}
      >
        <div
          style={{
            flexBasis: 22,
            display: "flex",
            width: 4,
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              height: "96%",
              width: 4,
              background:
                levelsArray?.length === 1 ? "transparent" : "lightgray",
              borderRadius: 6,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "10px 0px",
            }}
          >
            {levelsArray.map((l) => (
              <Node
                key={l.level}
                style={{
                  background: l.achieved ? "darkgreen" : "slategrey",
                  boxShadow: l.achieved
                    ? "0px 0px 2px 3px mediumseagreen"
                    : "0px 0px 2px 3px gainsboro",
                }}
              >
                {l.achieved ? (
                  <Check height={10} width={10} color="white" />
                ) : null}
              </Node>
            ))}
          </div>
        </div>
        <div
          style={{
            flexGrow: 1,

            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          {levels.map((l) => (
            <div
              key={l.level}
              style={{
                width: "100%",
                borderBottom: "1px solid #eeeeee",
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
                padding: "0px 6px",
              }}
            >
              <span
                style={{ color: "slategrey", fontWeight: "500", fontSize: 11 }}
              >
                {" "}
                Level: {l.level}
              </span>
              <span style={{ color: "darkgrey", fontSize: 10 }}>
                {l?.descriptions?.short_description?.english}
              </span>
            </div>
          ))}
        </div>
      </div>
      <div style={{ height: 1 }} />
      <span style={{ fontSize: 11, color: "slategrey", fontWeight: 600 }}>
        {obtainedLevel || 0} / {maxLevel} levels completed
      </span>
    </BadgeDetailWrapper>
  );
};

const Badges = ({ badges }) => {
  return (
    <Wrapper>
      <Typography
        sx={{ mt: -1, mb: -1 }}
        style={{ fontSize: 15, fontWeight: "500" }}
      >
        Badges Earned
      </Typography>
      <Divider sx={{ mb: 3, mt: 3 }} />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          gap: 20,
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        {badges?.map((badge) => (
          <Tooltip
            key={badge.code}
            placement="right"
            arrow
            title={
              <TitleUi
                badge={badge}
                maxLevel={badge?.max_level}
                obtainedLevel={badge?.max_obtained_level}
              />
            }
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: "white",
                  border: "1px solid lightgray",
                  boxShadow: "0px 0px 3px 2px #eeeeee",
                  maxHeight: "none",
                  padding: "12px",
                  borderRadius: "12px",
                },
              },
            }}
          >
            <FakeBadge $isAchieved={badge?.max_obtained_level !== null}>
              <BadgeImage src={badge.image} />
              <div
                style={{
                  height: 14,
                  width: 14,
                  borderRadius: 4,
                  background: "darkgreen",
                  position: "absolute",
                  top: 14,
                  right: 14,
                  zIndex: 10,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow: "0px 0px 3px 2px mediumseagreen",
                }}
              >
                <span style={{ color: "white", fontWeight: 600, fontSize: 9 }}>
                  {badge?.max_obtained_level || 0}
                </span>
              </div>
              <div
                style={{
                  position: "absolute",
                  left: "50%",
                  right: "50%",
                  transform: "translate(-50%)",
                  textAlign: "center",
                  maxWidth: 110,

                  minWidth: 110,
                  bottom: -26,

                  height: 20,
                }}
              >
                <p
                  style={{
                    fontSize: 11,
                    whiteSpace: "nowrap",
                    fontWeight: "500",
                    lineHeight: 1.2,
                  }}
                >
                  {badge?.name}
                </p>
              </div>
            </FakeBadge>
          </Tooltip>
        ))}
      </div>
    </Wrapper>
  );
};

export default Badges;
