import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import LoaderWrapper from "../../../../components/Loaders/LoaderWrapper";
import PrivateHeader from "../../../../components/PrivateHeader";
import EditStreamName from "./EditStreamName";
import SnackbarWrapper from "../../../../components/SnackbarWrapper";
import useStreamDetails from "../../../../swr/hooks/Streams/useStreamDetails";
import AddQuizToStream from "./AddQuizToStream";
import RearrangeQuizzesList from "./RearrangeQuizzesList";
import { GlobalState } from "../../../../store/GlobalState";
import OnLoadError from "../../../../components/UI/OnLoadError";
import styled from "styled-components";

const ResponsiveContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  align-items: flex-start;
  justify-content: stretch;

  @media (max-width: 760px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

const StreamDetail = ({ match }) => {
  const { id } = match.params;
  const [snackbarText, setSnackbarText] = useState("");

  const { details, isLoading, mutate, isError } = useStreamDetails(id);
  const { state } = useContext(GlobalState);

  const { isRetail } = state;
  const { read_only: readOnly } = state?.config?.user;

  if (isLoading) {
    return (
      <>
        <PrivateHeader header="Stream" secondary={true} />
        <LoaderWrapper text="Stream Details" />
      </>
    );
  }

  if (isError) {
    return <OnLoadError />;
  }

  return (
    <>
      <Helmet title="Spiffy | Stream Details" />
      <PrivateHeader header="Stream" secondary={details?.stream?.name} />
      <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
        <ResponsiveContainer>
          <AddQuizToStream
            details={details}
            mutate={mutate}
            setSnackbarText={setSnackbarText}
            readOnly={readOnly}
            isRetail={isRetail}
          />

          <EditStreamName
            name={details.stream.name}
            mutate={mutate}
            setSnackbarText={setSnackbarText}
            id={id}
            readOnly={readOnly}
          />
        </ResponsiveContainer>
        <RearrangeQuizzesList
          details={details}
          mutate={mutate}
          setSnackbarText={setSnackbarText}
          readOnly={readOnly}
          isRetail={isRetail}
        />
      </div>

      <SnackbarWrapper
        snackbarText={snackbarText}
        setSnackbarText={setSnackbarText}
      />
    </>
  );
};

export default StreamDetail;

StreamDetail.propTypes = {
  match: PropTypes.object.isRequired,
};
