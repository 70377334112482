import React, { useState, useContext } from "react";
import { Menu } from "@mui/material";
import { ChevronDown } from "react-feather";
import Clone from "./actions/Clone";
import ViewProgress from "./actions/ViewProgress";
import AddAudAndLaunch from "./actions/AddAudAndLaunch";
import EditEndDate from "./actions/EditEndDate";
import EditAudience from "./actions/EditAudience";
import EditLiveModule from "./actions/EditLiveModule";
import Reopen from "./actions/Reopen";
import SendReminder from "./actions/SendReminder";
import PreviewModule from "./actions/PreviewModule";
import ViewActivityLog from "./actions/ViewActivityLog";
import CloseModule from "./actions/CloseModule";
import DeleteModule from "./actions/DeleteModule";
import EditModule from "./actions/EditModule";
import OrganizationCopy from "./actions/OrganizationCopy";
import { QUIZSTATES, USER_TYPES } from "../../../../storage/constants";
import { GlobalState } from "../../../../store/GlobalState";
import ActionButtonForTables from "../../../../components/Table/ActionButtonForTables";
import LaunchPendingModule from "./actions/LaunchPendingModule";
import EditStartDate from "./actions/EditStartDate";

const ActionsDropdown = ({
  id,
  mutate,
  setSnackbarText,
  moduleState,
  userType,
  isRetailerModuleFromMarketplace,
  setModalState,
  moduleName,
  createdId,
}) => {
  const { state } = useContext(GlobalState);

  const { config } = state;

  const currentCompanyId = config?.current_company?.id;

  const [anchor, setAnchor] = useState(null);

  const handleClick = (e) => {
    setAnchor(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchor(null);
  };

  const isUserAManagerWhoCreatedModule =
    userType === USER_TYPES.RETAIL_MANAGER_CAN_DEPLOY &&
    createdId === config?.user?.id;

  return (
    <>
      <div style={{ position: "relative" }}>
        <ActionButtonForTables
          title="actions"
          Icon={ChevronDown}
          disabled={
            userType === USER_TYPES.READ_ONLY ||
            (userType === USER_TYPES.RETAIL_MANAGER_CANT_DEPLOY &&
              moduleState === QUIZSTATES.IN_DEVELOPMENT) ||
            (userType === USER_TYPES.RETAIL_MANAGER_CAN_DEPLOY &&
              !isUserAManagerWhoCreatedModule &&
              moduleState === QUIZSTATES.IN_DEVELOPMENT)
          }
          handleClick={handleClick}
        />
      </div>
      <Menu
        style={{ minWidth: 120 }}
        anchorEl={anchor}
        keepMounted
        open={Boolean(anchor)}
        onClose={handleCloseMenu}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <LaunchPendingModule
          moduleState={moduleState}
          setModalState={setModalState}
          id={id}
          handleClose={handleCloseMenu}
          userType={userType}
          isUserAManagerWhoCreatedModule={isUserAManagerWhoCreatedModule}
        />
        <EditModule
          isUserAManagerWhoCreatedModule={isUserAManagerWhoCreatedModule}
          moduleState={moduleState}
          userType={userType}
          isRetailerModuleFromMarketplace={isRetailerModuleFromMarketplace}
          id={id}
          setModalState={setModalState}
          handleClose={handleCloseMenu}
        />
        <ViewProgress id={id} userType={userType} moduleState={moduleState} />

        <EditLiveModule
          isUserAManagerWhoCreatedModule={isUserAManagerWhoCreatedModule}
          id={id}
          moduleState={moduleState}
          userType={userType}
          isRetailerModuleFromMarketplace={isRetailerModuleFromMarketplace}
          moduleName={moduleName}
        />

        <AddAudAndLaunch
          id={id}
          setModalState={setModalState}
          handleClose={handleCloseMenu}
          moduleState={moduleState}
          userType={userType}
          isRetailerModuleFromMarketplace={isRetailerModuleFromMarketplace}
          isUserAManagerWhoCreatedModule={isUserAManagerWhoCreatedModule}
          moduleName={moduleName}
        />

        <EditAudience
          handleClose={handleCloseMenu}
          isUserAManagerWhoCreatedModule={isUserAManagerWhoCreatedModule}
          id={id}
          setModalState={setModalState}
          moduleState={moduleState}
          userType={userType}
          isRetailerModuleFromMarketplace={isRetailerModuleFromMarketplace}
          moduleName={moduleName}
        />
        <EditStartDate
          id={id}
          moduleState={moduleState}
          userType={userType}
          isRetailerModuleFromMarketplace={isRetailerModuleFromMarketplace}
          setModalState={setModalState}
          handleClose={handleCloseMenu}
        />
        <EditEndDate
          id={id}
          handleClose={handleCloseMenu}
          setModalState={setModalState}
          moduleState={moduleState}
          userType={userType}
          isRetailerModuleFromMarketplace={isRetailerModuleFromMarketplace}
        />
        <Clone
          id={id}
          mutate={mutate}
          setSnackbarText={setSnackbarText}
          moduleState={moduleState}
          userType={userType}
          handleClose={handleCloseMenu}
          isRetailerModuleFromMarketplace={isRetailerModuleFromMarketplace}
          currentCompanyId={currentCompanyId}
        />
        <Reopen
          id={id}
          mutate={mutate}
          setSnackbarText={setSnackbarText}
          moduleState={moduleState}
          userType={userType}
          isRetailerModuleFromMarketplace={isRetailerModuleFromMarketplace}
          handleClose={handleCloseMenu}
        />
        <OrganizationCopy
          id={id}
          userType={userType}
          moduleState={moduleState}
          setModalState={setModalState}
          handleClose={handleCloseMenu}
          isRetailerModuleFromMarketplace={isRetailerModuleFromMarketplace}
        />
        <SendReminder
          id={id}
          moduleName={moduleName}
          setSnackbarText={setSnackbarText}
          moduleState={moduleState}
          userType={userType}
          handleClose={handleCloseMenu}
        />
        <PreviewModule
          id={id}
          setSnackbarText={setSnackbarText}
          moduleState={moduleState}
          userType={userType}
          setModalState={setModalState}
          handleClose={handleCloseMenu}
        />
        <ViewActivityLog
          id={id}
          moduleName={moduleName}
          setSnackbarText={setSnackbarText}
          moduleState={moduleState}
          userType={userType}
          setModalState={setModalState}
          handleClose={handleCloseMenu}
        />
        <CloseModule
          id={id}
          setSnackbarText={setSnackbarText}
          moduleState={moduleState}
          setModalState={setModalState}
          userType={userType}
          handleClose={handleCloseMenu}
          moduleName={moduleName}
        />
        <DeleteModule
          id={id}
          handleClose={handleCloseMenu}
          setSnackbarText={setSnackbarText}
          moduleState={moduleState}
          userType={userType}
          setModalState={setModalState}
          moduleName={moduleName}
        />
      </Menu>
    </>
  );
};

export default ActionsDropdown;
