import React from "react";
import { TrendingUp } from "react-feather";
import styled from "styled-components";
import ACTIONS from "./state/actions";

const StreamChip = styled.div`
  height: 20px;
  width: fit-content;
  background: white;
  border: ${(props) =>
    props.$isFilteredForStream ? "2px solid #337ab7" : "1px solid #eeeeee"};
  border-radius: 4px;
  padding: 0px 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  span {
    color: black;
  }

  &:hover {
    border: 1px solid lightgray;
    background: #eeeeee60;

    span {
      color: black;
    }
  }
`;

const StreamContainer = styled.div`
  width: 100%;
  height: 26px;
  display: flex;
  flexdirection: row;
  padding-right: 6px;
  alignitems: flex-start;
  justifycontent: flex-start;
  overflow-x: scroll;
  gap: 4px;
  padding: 2px;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

const DisplayStreams = ({
  streams,
  company,
  dispatch,
  activeStreamId,
  goToTop,
}) => {
  if (streams?.length === 0) {
    return (
      <>
        <span style={{ fontSize: 10, fontWeight: "500", color: "slategrey" }}>
          Streams:
        </span>
        <div style={{ height: 26 }}>
          <span style={{ color: "lightgray", fontSize: 11 }}>---/---</span>
        </div>
      </>
    );
  }

  return (
    <>
      <span style={{ fontSize: 10, fontWeight: "500", color: "slategrey" }}>
        Streams:
      </span>

      <StreamContainer>
        {streams.map((stream) => (
          <StreamChip
            key={stream.id}
            $isFilteredForStream={stream?.id === activeStreamId}
            onClick={() => {
              dispatch({
                type: ACTIONS.ADD_STREAM_ID,
                payload: { stream, company },
              });
              goToTop();
            }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
              <TrendingUp height={12} width={12} color="#337ab7" />
              <span
                style={{
                  fontSize: 10,
                  fontWeight: "500",
                  whiteSpace: "nowrap",
                }}
              >
                {stream?.name}
              </span>
            </div>
          </StreamChip>
        ))}
      </StreamContainer>
      <div style={{ height: 4 }} />
    </>
  );
};

export default DisplayStreams;
