import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, ClickAwayListener } from "@mui/material";
import { Download, DownloadCloud } from "react-feather";
import { CSVLink } from "react-csv";
import API from "../../../../axios/instances/API";
import { ENGAGEMENT_TYPES } from "../../../../storage/constants";

const StyledButton = styled(Button)`
  height: 38px;
  width: 38px;
  max-height: 38px;
  max-width: 38px;
  min-height: 38px;
  min-width: 38px;
  border-radius: 8px;
  border: ${(props) =>
    props.$isOpen ? "2px solid #337ab7" : "1px solid lightgray"};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  transition: none;

  svg {
    height: 16px;
    width: 16px;
  }
`;

const Container = styled.div`
  background: #eeeeee;
  width: 135px;
  border-radius: 4px;
  border: 1px solid lightgray;
  display: flex;
  padding: 4px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  bottom: -74px;
  right: 0;
  z-index: 100000000;
  background: white;
  gap: 4px;
`;

const CustomCsvLink = styled(CSVLink)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: white;
  height: 28px;
  border: 1px solid #eeeeee;
  width: 100%;
  padding: 1px 4px;
  border-radius: 4px;
  text-decoration: none;

  span {
    text-decoration: none;
    color: ${(props) => (props?.$disabled ? "darkgrey" : "black")};

    font-size: 11px;
    font-weight: 600;
    text-align: center;
  }

  svg {
    color: ${(props) => (props?.$disabled ? "darkgrey" : "black")};
    margin-left: 2px;
    margin-right: 2px;
  }
  &:hover {
    background: #eeeeee55;
  }
`;

const BASE_URL = "/dashboard/analytics/vendor-reports/submission-list/?";

const formatDataForDownload = (submissions, users) => {
  if (!submissions) return [];

  return submissions.map((submission) => {
    const user = users.find((u) => u.user_id === submission.user_id);

    return {
      date: submission.created,
      module: submission.quiz_name,
      name: submission.users_name,
      state: submission?.users_state || "",
      submission_type: submission.is_follower_submission
        ? "Follower"
        : ENGAGEMENT_TYPES.ASSIGNED,
      team:
        !user?.active_team_members || user?.active_team_members.length === 0
          ? ""
          : user.active_team_members
              ?.map((c) => `${c.company} - ${c.location}`)
              .join(", "),
      score: `${submission.score}/${submission.questions}`,
      passed: submission.passed ? "Yes" : "No",
    };
  });
};

const CustomDownload = ({ tableData, buildSearchParamsAsString, users }) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [downloadAllData, setDownloadAllData] = useState([]);

  const getUnPagedData = async () => {
    try {
      setIsLoading(true);
      const { data } = await API.get(
        `${BASE_URL}${buildSearchParamsAsString(false)}`,
      );

      const cleaned = formatDataForDownload(data.submissions, users);
      setDownloadAllData(cleaned);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!open) return setDownloadAllData([]);

    getUnPagedData();
  }, [open]);

  return (
    <div style={{ position: "relative" }}>
      <StyledButton $isOpen={open} onClick={() => setOpen(true)}>
        <Download color="black" />
      </StyledButton>
      {open && (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <Container>
            <CustomCsvLink
              data={tableData.map((row) => ({
                ...row,
                team: row.team
                  ? row.team
                      .map((c) => `${c.company} - ${c.location}`)
                      .join(", ")
                  : "",
              }))}
              filename="all-time-submissions-single-page"
            >
              <DownloadCloud height={14} width={14} />
              <div style={{ width: 4 }} />
              <span>Download Page</span>
            </CustomCsvLink>
            <CustomCsvLink
              filename="all-time-submissions"
              disabled={isLoading}
              $disabled={isLoading}
              data={isLoading ? [] : downloadAllData}
            >
              <DownloadCloud height={14} width={14} />
              <div style={{ width: 4 }} />
              <span>Download All</span>
            </CustomCsvLink>
          </Container>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default CustomDownload;
