import React from "react";
import styled from "styled-components";

const OptionWrapper = styled.div`
  min-height: 100px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 4px;
`;

const Option = styled.div`
  border-radius: 6px;
  height: 34px;
  width: 100%;
  padding: 3px 8px;
  background: #eeeeee64;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  span {
    font-size: 12px;
  }
`;

const Wrapper = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;

const TrueFalse = () => {
  return (
    <Wrapper>
      <span style={{ fontSize: 9, fontWeight: "300", paddingLeft: 2 }}>
        True/False
      </span>
      <span style={{ fontSize: 9, color: "darkgrey", paddingLeft: 2 }}>
        Select most appropriate answer
      </span>
      <div style={{ height: 12 }} />
      <OptionWrapper>
        <Option>
          <span>True</span>
        </Option>
        <Option>
          <span>False</span>
        </Option>
      </OptionWrapper>
    </Wrapper>
  );
};

export default TrueFalse;
