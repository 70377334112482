import React, { useState } from "react";
import AuthLayout from "../../layouts/Auth";
import {
  IconButton,
  Paper,
  Switch,
  TextField,
  Tooltip,
  Typography,
  Button,
} from "@mui/material";

import styled from "styled-components";
import ExtraInfo from "../../components/ExtraInfo";
import API from "../../axios/instances/API";
import { Formik } from "formik";
import * as Yup from "yup";
import { X } from "react-feather";
import SnackbarWrapper from "../../components/SnackbarWrapper";

const Wrapper = styled(Paper)`
  padding: 20px;
  min-width: 440px;
  min-height: 10px;
`;

const PreferencesWrapper = styled.div`
  height: 100%;
  min-height: 160px;
  margin-top: 6px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const HoldPreference = styled.div`
  width: 100%;
  padding: 4px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
  justify-content: space-between;

  span {
    font-size: 12px;
  }
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const validationSchema = Yup.object({
  email: Yup.string().email().required(),
});

const EmailPreferences = ({ match }) => {
  const startingEmail = match?.params?.email || "";

  const initialValues = {
    email: startingEmail,
  };

  const [showingPreferences, setShowingPreferences] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [preferenceEmail, setPreferenceEmail] = useState(null);
  const [preferencesData, setPreferencesData] = useState(null);
  const [snackbarText, setSnackbarText] = useState("");

  const handleGetPreferences = async (values) => {
    try {
      const { email } = values;
      const encodedEmail = encodeURIComponent(email);

      setIsLoading(true);
      const { data } = await API.get(
        `common/user/email-preferences/?username=${encodedEmail}`,
      );
      setPreferencesData(data);
      setPreferenceEmail(email);
      setIsLoading(false);
      setShowingPreferences(true);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.detail === "Not found."
      ) {
        return setSnackbarText(
          "Email is not active on spiffy. Try a different email. error",
        );
      }
      return setSnackbarText(
        "Error loading email preferences, try again. error",
      );
    }
  };

  const handleUpdatePreference = async (e, key) => {
    try {
      const { checked } = e.target;
      await API.patch(`/common/user/email-preferences/`, {
        username: preferenceEmail,
        [key]: checked,
      });
      setSnackbarText("Updated success");
      await refreshPreferences();
    } catch (error) {
      setSnackbarText("Failed to update, please try again error");
    }
  };

  const refreshPreferences = async () => {
    try {
      const encodedEmail = encodeURIComponent(preferenceEmail);
      const { data } = await API.get(
        `common/user/email-preferences/?username=${encodedEmail}`,
      );
      setPreferencesData(data);
    } catch (error) {
      return null;
    }
  };

  return (
    <AuthLayout>
      <Wrapper>
        <Typography variant="h4">Email Preferences</Typography>
        <Typography
          sx={{ mb: 2 }}
          style={{
            fontSize: 12,
            color: "black",
            fontWeight: "300",
            marginLeft: 1,
          }}
        >
          {showingPreferences
            ? "Edit Preferences"
            : "Enter your email to update your preferences"}
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleGetPreferences}
          validateOnMount
        >
          {({
            values,
            handleChange,
            handleSubmit,
            handleBlur,
            isValid,
            isSubmitting,
            resetForm,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <FlexDiv>
                {!showingPreferences ? (
                  <>
                    <TextField
                      fullWidth
                      size="small"
                      type="email"
                      name="email"
                      value={values.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter email..."
                      variant="outlined"
                    />
                    <Button
                      variant="contained"
                      sx={{ ml: 2 }}
                      color="primary"
                      type="submit"
                      disabled={
                        isSubmitting || !isValid || values.email.length === 0
                      }
                    >
                      Submit
                    </Button>
                  </>
                ) : (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "2px",
                    }}
                  >
                    <span
                      style={{ color: "darkgrey", textDecoration: "underline" }}
                    >
                      {preferenceEmail}
                    </span>
                    <Tooltip title="Stop Editing" placement="left" arrow>
                      <IconButton
                        size="small"
                        onClick={() => {
                          setShowingPreferences(false);
                          setPreferenceEmail(null);
                          resetForm();
                        }}
                      >
                        <X height={18} width={18} color="firebrick" />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
              </FlexDiv>
            </form>
          )}
        </Formik>
        {showingPreferences && (
          <PreferencesWrapper>
            {isLoading ? (
              <div
                style={{
                  minHeight: 160,
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span style={{ fontSize: 10, color: "lightgray" }}>
                  Loading....
                </span>
              </div>
            ) : (
              <>
                <HoldPreference>
                  <FlexDiv>
                    <span>Allow Marketing Emails</span>
                    <ExtraInfo
                      text="Do you want to allow marketing emails from Spiffy?"
                      saved={preferencesData.marketing_emails}
                      savedText="You allow marketing emails"
                    />
                  </FlexDiv>
                  <Switch
                    checked={preferencesData.marketing_emails}
                    onClick={(e) =>
                      handleUpdatePreference(e, "marketing_emails")
                    }
                  />
                </HoldPreference>
                <HoldPreference>
                  <FlexDiv>
                    <span>Allow Reporting Emails</span>
                    <ExtraInfo
                      text="Do you want to allow reporting emails from Spiffy?"
                      saved={preferencesData.reporting_emails}
                      savedText="You allow reporting emails"
                    />
                  </FlexDiv>
                  <Switch
                    checked={preferencesData.reporting_emails}
                    onClick={(e) =>
                      handleUpdatePreference(e, "reporting_emails")
                    }
                  />
                </HoldPreference>
                <HoldPreference>
                  <FlexDiv>
                    <span>Allow Account Access Emails</span>
                    <ExtraInfo
                      text="Do you want to allow account access emails from Spiffy?"
                      saved={preferencesData.account_access_emails}
                      savedText="You allow account access emails"
                    />
                  </FlexDiv>
                  <Switch
                    checked={preferencesData.account_access_emails}
                    onClick={(e) =>
                      handleUpdatePreference(e, "account_access_emails")
                    }
                  />
                </HoldPreference>
              </>
            )}
          </PreferencesWrapper>
        )}
      </Wrapper>
      <SnackbarWrapper
        snackbarText={snackbarText}
        setSnackbarText={setSnackbarText}
      />
    </AuthLayout>
  );
};

export default EmailPreferences;
