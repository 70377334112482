import * as Yup from "yup";
import { format } from "date-fns";

export const getInviteDataFailedErrorString = (error) => {
  const errors = error?.response?.data?.error;

  if (!errors) {
    return {
      title: "Error with invite",
      body:
        error?.response?.data?.detail ||
        "This invitation could not be loaded. Please try again later.",
      context: null,
    };
  }

  const context = errors?.context?.errors?.join(" ");

  return {
    title: errors?.title,
    body: errors?.details,
    context: context || null,
  };
};

export const INVITATION_TYPES = {
  ADMIN_EXISTING_USER: "ADMIN_EXISTING_USER",
  ADMIN_NEW_USER: "ADMIN_NEW_USER",

  MANAGER_NEW_USER: "MANAGER_NEW_USER",
  MANAGER_EXISTING_USER: "MANAGER_EXISTING_USER",

  MANAGER_NEW_USER_CREATE_TEAM_MEMBER: "MANAGER_NEW_USER_CREATE_TEAM_MEMBER",
  MANAGER_EXISTING_USER_CREATE_TEAM_MEMBER:
    "MANAGER_EXISTING_USER_CREATE_TEAM_MEMBER",
};

export const MANAGER_INVITATION_TYPES = [
  INVITATION_TYPES.MANAGER_EXISTING_USER,
  INVITATION_TYPES.MANAGER_NEW_USER,
];

export const CREATE_TEAM_MEMBER_INVITATION_TYPES = [
  INVITATION_TYPES.MANAGER_NEW_USER_CREATE_TEAM_MEMBER,
  INVITATION_TYPES.MANAGER_EXISTING_USER_CREATE_TEAM_MEMBER,
];

export const getInviteType = (data) => {
  try {
    const hasUser = data.user_exists; // user and team member are different things, users can have multiple team members
    const isAdmin = data.job === "C";
    const isManager = data.job === "B" || data.job === "M";
    const hasAvailLocations = Object.keys(data).includes("available_locations");
    const hasAvailRoles = Object.keys(data).includes("available_roles");

    const needsToCreateTeamMember =
      isManager && (hasAvailLocations || hasAvailRoles);

    if (isAdmin && !hasUser) return INVITATION_TYPES.ADMIN_NEW_USER;
    if (isAdmin && hasUser) return INVITATION_TYPES.ADMIN_EXISTING_USER; // auto submit

    if (isManager && needsToCreateTeamMember && !hasUser)
      return INVITATION_TYPES.MANAGER_NEW_USER_CREATE_TEAM_MEMBER;
    if (isManager && needsToCreateTeamMember && hasUser)
      return INVITATION_TYPES.MANAGER_EXISTING_USER_CREATE_TEAM_MEMBER;

    if (isManager && !hasUser) return INVITATION_TYPES.MANAGER_NEW_USER;
    if (isManager && hasUser) return INVITATION_TYPES.MANAGER_EXISTING_USER; // auto submit
  } catch (error) {}
};

export const getInitialValues = (inviteType, data) => {
  // ADMIN EXISTING & MANAGER EXISTING DO NOT NEED ANY FIELDS

  if (inviteType === INVITATION_TYPES.ADMIN_NEW_USER) {
    return {
      email: data.email || "",
      firstName: data.first_name || "",
      lastName: data.last_name || "",
      password: "",
      confirmPassword: "",
      country: "",
      state: "",
      dob: null,
    };
  }

  if (inviteType === INVITATION_TYPES.MANAGER_NEW_USER) {
    return {
      email: data.email || "",
      firstName: data.first_name || "",
      lastName: data.last_name || "",
      password: "",
      confirmPassword: "",
      country: "",
      state: "",
      dob: null,
    };
  }

  if (inviteType === INVITATION_TYPES.MANAGER_NEW_USER_CREATE_TEAM_MEMBER) {
    return {
      email: data.email || "",
      firstName: data.first_name || "",
      lastName: data.last_name || "",
      password: "",
      confirmPassword: "",
      country: "",
      state: "",
      dob: null,

      roles: [],
      location: data.locations[0].id || "", // use default if possible
    };
  }

  if (
    inviteType === INVITATION_TYPES.MANAGER_EXISTING_USER_CREATE_TEAM_MEMBER
  ) {
    return {
      roles: [],
      location: data.locations[0].id || "", //use default if possible
    };
  }
};

export const getValidationSchema = (inviteType) => {
  // MANAGER & ADMIN EXISING DONT NEED schemas
  if (inviteType === INVITATION_TYPES.MANAGER_NEW_USER) {
    return Yup.object({
      email: Yup.string("Enter your email")
        .email("Enter a valid email")
        .required("Email is required"),
      firstName: Yup.string()
        .required("First name is required")
        .min(2, "Minimum 2 characters"),
      lastName: Yup.string()
        .required("Last name is required")
        .min(2, "Minimum 2 characters"),
      password: Yup.string()
        .required("Password is required")
        .min(8, "Password should be at least 8 characters"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords do not match")
        .required("Confirm password is required")
        .min(8, "Password should be at least 8 characters"),
      dob: Yup.date().required("Date of birth is required"),
      country: Yup.string().required(),
      state: Yup.number().required(),
    });
  }

  if (inviteType === INVITATION_TYPES.MANAGER_NEW_USER_CREATE_TEAM_MEMBER) {
    return Yup.object({
      email: Yup.string("Enter your email")
        .email("Enter a valid email")
        .required("Email is required"),
      firstName: Yup.string()
        .required("First name is required")
        .min(2, "Minimum 2 characters"),
      lastName: Yup.string()
        .required("Last name is required")
        .min(2, "Minimum 2 characters"),
      password: Yup.string()
        .required("Password is required")
        .min(8, "Password should be at least 8 characters"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords do not match")
        .required("Confirm password is required")
        .min(8, "Password should be at least 8 characters"),
      dob: Yup.date().required("Date of birth is required"),
      location: Yup.number().required("Location is a required field"),
      roles: Yup.array().required("Role is a required field"),
      country: Yup.string().required(),
      state: Yup.number().required(),
    });
  }

  if (
    inviteType === INVITATION_TYPES.MANAGER_EXISTING_USER_CREATE_TEAM_MEMBER
  ) {
    return Yup.object({
      location: Yup.number().required("Location is a required field"),
      roles: Yup.array().required("Role is a required field"),
    });
  }

  if (inviteType === INVITATION_TYPES.ADMIN_NEW_USER) {
    return Yup.object({
      email: Yup.string("Enter your email")
        .email("Enter a valid email")
        .required("Email is required"),
      firstName: Yup.string()
        .required("First name is required")
        .min(2, "Minimum 2 characters"),
      lastName: Yup.string()
        .required("Last name is required")
        .min(2, "Minimum 2 characters"),
      password: Yup.string()
        .required("Password is required")
        .min(8, "Password should be at least 8 characters"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords do not match")
        .required("Confirm password is required")
        .min(8, "Password should be at least 8 characters"),
      dob: Yup.date().required("Date of birth is required"),
      country: Yup.string().required(),
      state: Yup.number().required(),
    });
  }

  return {};
};

export const shouldFormBeDisabled = (
  values,
  isValid,
  isSubmitting,
  needsRoles,
) => {
  // disabled unless all required have values & no errors

  if (!values) return true;

  if (isSubmitting) return true;

  if (!isValid) return true;

  const emptyValues = Object?.values(values)?.filter((val) => !Boolean(val));
  if (emptyValues.length > 0 || (needsRoles && values?.roles?.length === 0))
    return true;

  return false;
};
const formatDobForApi = (date) => format(new Date(date), "yyyy-MM-dd");

export const getSubmitBody = (token, values, inviteType) => {
  if (inviteType === INVITATION_TYPES.MANAGER_EXISTING_USER) {
    return {
      token,
      team_member_location_id: values.location,
      team_member_role_ids: values.roles,
    };
  }

  if (inviteType === INVITATION_TYPES.MANAGER_NEW_USER) {
    return {
      token,
      password: values.password,
      first_name: values.firstName,
      last_name: values.lastName,
      country: values.country,
      state: values.state,
      birth_date: formatDobForApi(values.dob),
    };
  }

  if (inviteType === INVITATION_TYPES.MANAGER_NEW_USER_CREATE_TEAM_MEMBER) {
    return {
      token,
      password: values.password,
      first_name: values.firstName,
      last_name: values.lastName,
      country: values.country,
      state: values.state,
      birth_date: formatDobForApi(values.dob),
      team_member_location_id: values.location,
      team_member_role_ids: values.roles,
    };
  }

  if (
    inviteType === INVITATION_TYPES.MANAGER_EXISTING_USER_CREATE_TEAM_MEMBER
  ) {
    return {
      token,
      team_member_location_id: values.location,
      team_member_role_ids: values.roles,
    };
  }

  if (inviteType === INVITATION_TYPES.ADMIN_NEW_USER) {
    return {
      token,
      password: values.password,
      first_name: values.firstName,
      last_name: values.lastName,
      country: values.country,
      state: values.state,
      birth_date: formatDobForApi(values.dob),
    };
  }

  return {
    token,
  };
};
