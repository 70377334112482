import useSWR from "swr";
import API from "../../../axios/instances/API";

const fetcher = async (url) => {
  const { data } = await API.get(url);
  return data;
};

const useLocations = () => {
  const { data, error, mutate } = useSWR("/dashboard/locations/", fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  return {
    locations: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useLocations;
