export const ORDER_OPTIONS = {
  NEW: "NEW",
  POPULARITY: "POPULARITY",
};

export const SEARCH_OPTIONS = {
  VENDOR: "VENDOR",
  QUIZ_NAME: "QUIZ_NAME",
};

export const COUNTRIES = {
  US: "us",
  CANADA: "ca",
};

const ACTIONS = {
  INITIAL_LOAD: "INITIAL_LOAD",
  ERROR: "ERROR",
  GET_NEXT_PAGE: "GET_NEXT_PAGE",
  HANDLE_NEXT_PAGE: "HANDLE_NEXT_PAGE",
  // new
  HANDLE_OWNED_MODULES_TOGGLE: "HANDLE_OWNED_MODULES_TOGGLE",
  ORDER_BY: "ORDER_BY",
  SEARCH_BY_NAME: "SEARCH_BY_NAME",
  SEARCH_BY_VENDOR: "SEARCH_BY_VENDOR",
  RESET_MARKETPLACE: "RESET_QUIZZES",
  UPDATE_URL: "UPDATE_URL",
  END_INTIAL_LOAD: "END_INITIAL_LOAD",
  MARKETPLACE_TYPE: "MARKETPLACE_TYPE",
  ADD_AVAIL_COUNTRIES: "ADD_AVAIL_COUNTRIES",
  UPDATE_TO_SINGLE_COUNTRY: "UPDATE_TO_SINGLE_COUNTRY",
  UPDATE_TO_ALL_COUNTRIES: "UPDATE_TO_ALL_COUNTRIES",

  TOGGLE_DISPLAY_OWNED_MODS: "TOGGLE_DISPLAY_OWNED_MODS",
  // -- //
  HANDLE_MODULE_EXISTS_CHANGE: "HANDLE_MODULE_EXISTS_CHANGE",
  CLEAR_COMPANY_FILTER: "CLEAR_COMPANY_FILTER",
  CLEAR_SEARCH: "CLEAR_SEARCH",

  //
  ADD_STREAM_ID: "ADD_STREAM_ID",
  REMOVE_STREAM: "REMOVE_STREAM",
};

export default ACTIONS;
