import { FormControl, LinearProgress, MenuItem, Select } from "@mui/material";
import React from "react";
import styled from "styled-components";
import EngagementSelect from "../../../../components/Table/Paginated/EngagementSelect";
import CustomSearch from "../../reports/retailReports/allTimeSubmissionsReport/CustomSearch";
import CustomDownload from "./CustomDownload";

const BREAKPOINT = 800;

const Wrapper = styled.div`
  width: 100%;
  min-height: 66px;
  background: white;
  border-radius: 6px;
  margin-bottom: 8px;
  border: 1px solid lightgray;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 8px;
  gap: 4px;
  position: relative;

  @media (max-width: ${BREAKPOINT}px) {
    flex-direction: column;
    gap: 6px;
    padding: 14px;
  }
`;

const CustomFormControl = styled(FormControl)`
  width: 200px;

  @media (max-width: ${BREAKPOINT}px) {
    width: 100%;
  }
`;

const LoadingLinearProgress = styled(LinearProgress)`
  height: 1px;
  width: 99%;
  position: absolute;
  bottom: -1px;
  left: 4px;

  .MuiLinearProgress-barColorPrimary {
    background-color: slategrey;
  }
`;

const TopFilters = ({
  engagmentType,
  setEngagementType,
  setPage,
  setSearchText,
  companyId,
  setCompanyId,
  companies,
  isLoading,
  tableData,
  buildSearchParamsAsString,
  users,
}) => {
  return (
    <Wrapper>
      <EngagementSelect
        engagementType={engagmentType}
        setEngagementType={setEngagementType}
        setPage={setPage}
      />
      <CustomFormControl>
        <Select
          variant="outlined"
          size="small"
          placeholder="All Companies"
          value={companyId}
          margin="dense"
          onChange={(e) => setCompanyId(e.target.value)}
          style={{
            height: 40,
          }}
        >
          <MenuItem value={0}>All Companies</MenuItem>
          {companies.map((company) => (
            <MenuItem key={company.company_id} value={company.company_id}>
              {company?.name}
            </MenuItem>
          ))}
        </Select>
      </CustomFormControl>
      <div style={{ marginLeft: "auto" }} />
      <CustomSearch
        handleSearch={(text) => setSearchText(text)}
        handleClear={() => setSearchText("")}
      />
      <div
        style={{
          height: 32,
          width: 1,
          background: "lightgray",
          margin: "0px 8px",
        }}
      />
      <CustomDownload
        tableData={tableData}
        buildSearchParamsAsString={buildSearchParamsAsString}
        users={users}
      />

      {isLoading && (
        <LoadingLinearProgress variant="indeterminate" color="primary" />
      )}
    </Wrapper>
  );
};

export default TopFilters;
