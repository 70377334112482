import React from "react";
import { Tooltip } from "@mui/material";
import { Tag } from "react-feather";
import styled from "styled-components";
import useTeamMembersRoles from "../../../../swr/hooks/Roles/useTeamMembersRoles";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
  padding: 6px;
  border-radius: 8px;

  &:hover {
    cursor: pointer;
    background-color: #eeeeee;
  }
`;

const InnerContainer = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 4px;
  min-height: 40px;
  display: flex;
  padding: 4px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;

  span {
    color: black;
  }
`;

const Inner = ({ id, name }) => {
  const { data, isLoading, isError } = useTeamMembersRoles(id);

  if (isLoading) {
    return (
      <InnerContainer>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: 40,
          }}
        >
          <span style={{ color: "darkgrey" }}>loading...</span>
        </div>
      </InnerContainer>
    );
  }

  if (isError) {
    return (
      <InnerContainer>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: 40,
          }}
        >
          <span style={{ color: "firebrick" }}>error loading...</span>
        </div>
      </InnerContainer>
    );
  }

  return (
    <InnerContainer>
      <span style={{ fontSize: 11, fontWeight: "500" }}>{name}</span>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          flexDirection: "column",
          marginTop: 6,
        }}
      >
        {data?.map((str) => (
          <div
            key={str}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: 4,
            }}
          >
            <Tag height={10} width={10} color="darkgrey" />
            <span key={str} style={{ fontSize: 11, fontWeight: "400" }}>
              {str}
            </span>
          </div>
        ))}
      </div>
    </InnerContainer>
  );
};

const RolesTooltip = ({ id, name }) => (
  <Tooltip
    title={<Inner id={id} name={name} />}
    arrow
    placement="top"
    componentsProps={{
      tooltip: {
        sx: {
          backgroundColor: "white",
          border: "1px solid slategrey",
          maxHeight: "none",
          padding: "0px",
        },
      },
    }}
  >
    <Container>
      <Tag height={14} width={14} color="darkgrey" />
    </Container>
  </Tooltip>
);

export default RolesTooltip;
