import React, { useState } from "react";
import { Send } from "react-feather";
import API from "../../../../../axios/instances/API";
import { StyledItem } from "../../../styles";
import { USER_TYPES, QUIZSTATES } from "../../../../../storage/constants";

const SendReminder = ({
  id,
  setSnackbarText,
  userType,
  moduleState,
  handleClose,
  moduleName,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  if (moduleState !== QUIZSTATES.LIVE) return null;

  if (userType !== USER_TYPES.RETAIL_ADMIN) return null;

  const handleReminder = async () => {
    setIsLoading(true);
    try {
      await API.post("/dashboard/quiz/reminder/", {
        id,
      });
      setSnackbarText(
        `Successfully sent reminder to complete module "${moduleName}" success`,
      );
      setIsLoading(false);
      handleClose();
    } catch (error) {
      setIsLoading(false);
      setSnackbarText("Failed to send reminder error");
    }
  };

  return (
    <StyledItem onClick={handleReminder} disabled={isLoading}>
      <Send />
      <span style={{ color: isLoading ? "darkgrey" : "black" }}>
        {" "}
        Send Completion Reminder
      </span>
    </StyledItem>
  );
};

export default SendReminder;
