import { Paper, Link } from "@mui/material";
import React from "react";
import { Lock, Link as LinkIcon } from "react-feather";
import styled from "styled-components";

const Wrapper = styled(Paper)`
  width: 100%;
  display: flex;
  min-height: 340px;
  padding: 20px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const PermissionsError = () => {
  return (
    <Wrapper>
      <Lock height={28} width={28} color="firebrick" />
      <div style={{ height: 4 }} />
      <span style={{ fontWeight: "600" }}>This is a premium feature.</span>
      <div style={{ height: 4 }} />

      <Link
        href="https://meetings.hubspot.com/satch"
        rel="noopener"
        target="_blank"
        underline="hover"
        style={{ color: "slategrey", fontSize: 13, fontWeight: "500" }}
      >
        <LinkIcon
          height={12}
          width={12}
          style={{
            color: "slategrey",
            marginRight: 4,
            transform: "translateY(1px)",
          }}
        />
        <span>Connect with us to get access.</span>
      </Link>
    </Wrapper>
  );
};

export default PermissionsError;
