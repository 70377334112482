import React from "react";
import styled from "styled-components";
import BasicsTooltip from "../../../library/components/BasicsTooltip";

const Wrapper = styled.div`
  position: relative;
  width: 240px;
  height: 280px;
  border-radius: 14px;
  border: 1px solid lightgray;
  box-shadow: 0px 0px 4px 2px #eeeeee;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const StateChip = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  background: white;
  width: fit-content;
  border: 1px solid lightgray;
  border-radius: 8px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;

  span {
    font-size: 10px;
    font-weight: 600;
    color: ${(props) => (props?.$isLive ? "seagreen" : "slategrey")};
    text-transform: uppercase;
  }
`;

const ModuleImg = styled.img`
  width: 100%;
  height: 240px;
  border-top-right-radius: inherit;
  border-top-left-radius: inherit;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  object-fit: cover;
  background: white;
  filter: opacity(${(props) => (props?.$isLive ? "100%" : "35%")});
`;

const ModuleCard = ({ mod, i }) => {
  return (
    <Wrapper>
      <StateChip $isLive={mod?.state_display === "Live"}>
        <span>{mod?.state_display}</span>
      </StateChip>
      <ModuleImg
        $isLive={mod?.state_display === "Live"}
        alt="module"
        src={mod?.image}
      />
      <div
        style={{
          borderTop: "1px solid lightgray",
          background: "#eeeeee60",
          height: 40,
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          padding: 4,
          background: "white",
          borderBottomRightRadius: "inherit",
          borderBottomLeftRadius: "inherit",
        }}
      >
        <span
          style={{
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            maxWidth: 200,
            width: 200,

            fontWeight: 500,
            fontSize: 12,
            paddingLeft: 2,
            color: mod?.state_display === "Live" ? "black" : "darkgrey",
          }}
        >
          {i + 1}) {mod?.name}
        </span>
        <div
          style={{
            flexBasis: 36,

            minHeight: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <BasicsTooltip data={{ id: mod.id }} />
        </div>
      </div>
    </Wrapper>
  );
};

export default ModuleCard;
