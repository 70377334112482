import useSWR from "swr";
import API from "../../../axios/instances/API";

const fetcher = async (url) => {
  const { data } = await API.get(url);
  return data;
};

const useAdminDetail = (id, onlyReferralData = 0) => {
  const { data, error, mutate, isValidating } = useSWR(
    `/dashboard/admin/details/?id=${id}&only_referral_data=${onlyReferralData}`,
    fetcher,
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
    isValidating,
  };
};

export default useAdminDetail;
