import React from "react";
import { Calendar } from "react-feather";

const Last14Chip = () => (
  <div
    style={{
      background: "#eeeeee",
      padding: "4px 6px",
      borderRadius: 4,
      width: "fit-content",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: 4,
    }}
  >
    <Calendar height={9} width={9} color="slategrey" />
    <span
      style={{
        color: "slategrey",
        fontWeight: "700",
        textTransform: "uppercase",
        fontSize: 9,
        lineHeight: 1,
      }}
    >
      Last 14 days
    </span>
  </div>
);

export default Last14Chip;
