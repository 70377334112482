import {
  AppBar,
  Button,
  LinearProgress,
  Paper,
  Tabs,
  Typography,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import { darken } from "polished";
import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { ENGAGEMENT_TYPES, QUIZSTATES } from "../../../storage/constants";

export const CmPointerContainer = styled.div`
  height: 14px;
  width: 46px;
  background: #eeeeee;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-weight: 600;
    font-size: 8px;
    color: #337ab7;
  }

  &:hover {
    cursor: pointer;
    background: lightgray;
  }
`;

export const MpPointerContainer = styled.div`
  height: 14px;
  width: 18px;
  background: #907ac63b;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-weight: 600;
    font-size: 8px;
    color: #813eee;
  }

  &:hover {
    cursor: pointer;
    background: lightgray;
  }
`;

export const fadeInRight = keyframes`
  0% {
    opacity: 0;
    transform: translateX(20px) scale(.85);
  }
  100% {
    opacity: 1;
    transform: translateX(0px) scale(1);
  }
`;

export const CustomDatatable = styled(MUIDataTable)`
  .MuiToolbar-regular {
    @media print {
      display: block !important;
    }
  }

  .MuiSvgIcon-fontSizeSmall {
    font-size: 16px;
    margin-left: -8px;
    color: ${(props) => props.theme.palette.grey[500]};
  }
  text-align: left;
`;

export const CustomDatatableV2 = styled(MUIDataTable)`
  .MuiToolbar-regular {
    @media print {
      display: block !important;
    }
  }

  .MuiSvgIcon-fontSizeSmall {
    font-size: 14px;
    border-radius: 4px;
    padding: 0px;

    margin-left: -8px;
    color: ${(props) => props.theme.palette.grey[500]};
  }

  .MuiTableCell-footer {
    border-radius: 6px;
  }

  .MuiTableFooter-root {
    background: white;
    border-radius: 6px;

    .MuiTableCell-root {
      border: none;
    }
  }

  .MuiIconButton-root {
    padding: 0px;
    border-radius: 0px;
    cursor: pointer;
    margin-left: 4px;

    &:hover {
      background: transparent;
    }
  }

  text-align: left;
`;

export const CustomTabbedDatatable = styled(CustomDatatableV2)`
  border-top-left-radius: 0px;
`;

export const ShowState = styled.div`
  border-radius: 4px;
  height: 20px;
  padding: 1px 4px 1px 10px;
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  background: #eeeeee84;

  &:before {
    content: " ";
    position: relative;
    left: -6px;
    height: 11px;
    width: 11px;
    border-radius: 3px;
    background-color: ${(props) => {
      if (props.$state === QUIZSTATES.LIVE) return "mediumseagreen";
      if (props.$state === QUIZSTATES.CLOSED) return "tomato";
      if (props.$state === QUIZSTATES.IN_DEVELOPMENT) return "#71a5bb";
      if (props.$state === QUIZSTATES.PENDING) return "orange";
      if (props.$state === QUIZSTATES.CONVERSION) return "slategrey";
      if (props.$state === QUIZSTATES.IN_REVIEW) return "rgb(51, 122, 183)";
      return "white";
    }};
  }

  span {
    font-weight: 500;
    font-size: ${(props) => (props.small === "Yes" ? 10 : 12)}px;
    white-space: nowrap;
    color: black;
  }
`;

export const MarketplaceChip = styled.div`
  padding: 0px 8px;
  border-radius: 4px;
  background-color: #eeeeee;
  text-align: center;
  display: flex;
  align-items: center;
  height: 16px;
  justify-content: center;
  margin-left: 2px;
  width: fit-content;
  &:hover {
    cursor: pointer;
  }

  span {
    color: slategrey;
    font-size: 12px;
    font-weight: 500;
  }
`;

export const CustomChip = styled.div`
  padding: 0px 8px;
  border-radius: 4px;
  background-color: #004aff2b;
  text-align: center;
  margin-left: 2px;
  width: fit-content;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }

  span {
    color: #7246a8e0;
    font-size: 12px;
    font-weight: 500;
  }
`;

export const SubmissionChipAssigned = styled.div`
  background-color: rgba(0, 0, 250, 0.1);
  height: 18px;
  padding: 4px 6px;
  border-radius: 4px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }

  span {
    color: #587dea;
    font-weight: 800;
    font-size: 10px;
  }
`;

export const CenterWithColor = styled.div`
  height: ${(props) => (props.$small ? 22 : 34)}px;
  width: ${(props) => (props.$small ? 28 : 46)}px;
  min-width: fit-content;
  display: flex;
  padding: 1px 2px;
  align-items: center;
  border-radius: 3px;
  justify-content: center;
  background-color: ${(props) => {
    if (props.$engagementType === ENGAGEMENT_TYPES.ASSIGNED) {
      return "rgba(0, 0, 250, .05)";
    }
    if (props.$engagementType === ENGAGEMENT_TYPES.EXTRACREDIT) {
      return "#ffeeb066";
    }
    return "white";
  }};

  border: ${(props) => {
    if (props.$engagementType === ENGAGEMENT_TYPES.ASSIGNED) {
      return "1px solid #587dea";
    }
    if (props.$engagementType === ENGAGEMENT_TYPES.EXTRACREDIT) {
      return "1px solid #aa9700";
    }

    return "none";
  }};

  span {
    color: ${(props) => {
      if (props.$engagementType === ENGAGEMENT_TYPES.ASSIGNED) {
        return "#587dea";
      }

      if (props.$engagementType === ENGAGEMENT_TYPES.EXTRACREDIT) {
        return "#aa9700";
      }

      return "black";
    }};
    font-weight: ${(props) =>
      props.$engagementType !== ENGAGEMENT_TYPES.ALL ? "500" : "400"};
  }
`;

export const SubmissionChipExtraCredit = styled.div`
  background-color: #ffeeb091;
  height: 18px;
  padding: 4px 6px;
  border-radius: 4px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }

  span {
    color: #aa9700;
    font-weight: 800;
    font-size: 10px;
  }
`;

export const ReportLink = styled(Link)`
  text-decoration: none;
  color: black;

  &:hover {
    color: ${(props) => props.theme.palette.info.light};
    text-decoration: underline;
  }
`;

export const DeleteButton = styled(Button)`
  background-color: ${(props) => props.theme.palette.error.main};
  color: ${(props) => props.theme.palette.error.contrastText};
  border-radius: 6px;

  &:hover {
    background-color: ${(props) =>
      darken(0.12, props.theme.palette.error.main)};
  }

  &:disabled {
    background-color: ${(props) => darken(0.15, props.theme.palette.grey[300])};
    color: black;
  }
`;

// Reusable Icons

export const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(5)};
  min-height: 220px;
  min-width: 300px;
  text-align: left;
`;

export const DeleteWrapper = styled(Paper)`
  padding: 20px;
  width: min(520px, 98vw);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  border-radius: 8px;
`;

// Other

export const Question = styled(Typography)`
  color: black;
  font-size: ${(props) => props.theme.typography.subtitle1.fontSize};
  font-weight: ${(props) => props.theme.typography.h4.fontWeight};
`;

export const Answer = styled(Typography)`
  color: ${(props) => props.theme.palette.grey[800]};
  font-size: ${(props) => props.theme.typography.subtitle1.fontSize};
  font-weight: ${(props) => props.theme.typography.subtitle1.fontWeight};
  margin-left: auto;
`;

// Tab Components

export const TableTabBar = styled(AppBar)`
  border-radius: 4px;
  background-color: transparent;
  display: flex;
  top: 3px;
  right: 1px;
  z-index: 10;
  width: fit-content;
`;

export const TableTabs = styled(Tabs)`
  .MuiTab-root {
    border-top: 1px solid rgba(112, 128, 144, 0.2);
    border-left: 1px solid rgba(112, 128, 144, 0.2);
    border-right: 1px solid rgba(112, 128, 144, 0.2);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: #eee;
    opacity: 1;
    margin-right: 1px;
    color: black;
    font-weight: 400;
    min-width: 200px;
    font-size: 12px;

    @media (max-width: 900px) {
      min-width: 0px;
      font-size: 11px;
    }
  }
  .MuiTab-root.Mui-selected {
    background-color: #fff;
    color: black;
  }

  .MuiTab-wrapper {
    color: black;
    font-size: 14px;
    min-width: 240px;

    @media (max-width: 1450px) {
      min-width: 180px;
      font-size: 12px;
    }

    @media (max-width: 900px) {
      min-width: 0px;
      font-size: 10px;
      white-space: wrap;
      padding: 0;
    }

    @media (max-width: 560px) {
      font-size: 8px;
    }
  }
`;

export const SubtitleSmall = styled(Typography)`
  font-size: 11px;
  color: ${(props) => props.theme.palette.grey[500]};
`;

export const CustomLinearProgressMain = styled(LinearProgress)`
  background-color: #EEEEEE;
  border-radius: 8px;
  min-height: 14px;
  height: 15px;
  width: 100%;
  margin-right: 8px;
  border: 1px solid #eeeeee;

  .MuiLinearProgress-barColorPrimary {
    background-color: #337AB7;
  }
}
`;

export const WrapperLessPaddingTop = styled(Paper)`
  padding: 10px 20px 20px 20px;
  margin-top: 16px;
  border-radius: 6px;
`;

export const StyledItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 16px;
  height: 38px;
  width: 100%;
  background-color: white;

  &:hover {
    background-color: #eeeeee;
    cursor: pointer;

    svg {
      background-color: lightgray;
      padding: 3.5px;
      transform: scale(1.01);
      transition: all 200ms ease-in;
    }
  }

  svg {
    width: 20px;
    height: 20px;
    margin-right: 12px;
    color: black;
    background-color: #eeeeee;
    padding: 4px;
    border-radius: 4px;
  }

  span {
    font-size: 12px;
    font-weight: 400;
  }
`;
