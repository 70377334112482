import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Stepper, Step, StepLabel } from "@mui/material";

const SignStepper = styled(Stepper)`
  width: min(620px, 100vw);
  background: transparent;
  margin-bottom: 20px;

  @media (max-width: 400px) {
    display: none;
  }
`;

const StyledStep = styled(Step)`
  .MuiStepIcon-root.MuiStepIcon-completed {
    color: mediumseagreen;
  }
`;

const SignStepLabel = styled(StepLabel)`
  cursor: pointer;
`;

const SignupStepper = ({ signupState }) => {
  const { index } = signupState;

  if (index === 4) return null;

  const steps = [
    "Select Path",
    "Personal Details",
    "Find Your Company",
    "Confirm & Register",
  ];

  return (
    <SignStepper activeStep={index} alternativeLabel>
      {steps.map((label) => (
        <StyledStep key={label}>
          <SignStepLabel>{label}</SignStepLabel>
        </StyledStep>
      ))}
    </SignStepper>
  );
};

export default SignupStepper;

SignupStepper.propTypes = {
  signupState: PropTypes.object.isRequired,
};
