import React, { useState } from "react";
import { InputAdornment } from "@mui/material";
import { Calendar } from "react-feather";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker"; //TEST
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const DobField = ({ setFieldValue, values }) => {
  const [open, setOpen] = useState(false);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MobileDatePicker
        sx={{ width: "100%" }}
        label="Date of birth"
        open={open}
        format="MMM dd yyyy"
        color="primary"
        onClose={() => setOpen(false)}
        openTo="year"
        disableFuture
        value={values.dob}
        closeOnSelect
        onAccept={(date) => {
          setFieldValue("dob", date);
        }}
        slots={{
          inputAdornment: () => null,
        }}
        slotProps={{
          yearButton: {
            sx: {
              color: "black",
            },
          },
          textField: {
            placeholder: " ",
            size: "small",
            onClick: () => setOpen(true),
            InputProps: {
              startAdornment: (
                <InputAdornment position="start">
                  <Calendar
                    style={{ height: 14, width: 14, color: "darkgrey" }}
                  />
                </InputAdornment>
              ),
            },
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default DobField;
