"use client";
import React from "react";
import { Tooltip } from "@mui/material";
import { Calendar } from "react-feather";
import { formatDateWithTime, getClientTimezone } from "../../storage/helpers";
import { format } from "date-fns";
import styled from "styled-components";
import { withErrorBoundary } from "react-error-boundary";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
  padding: 4px;
  border-radius: 4px;
  max-height: 22px;

  &:hover {
    cursor: pointer;
    background-color: #eeeeee;
  }
`;

const DATE_FORMAT_SHORT = "MMM do";
const DATE_FORMAT_SHORT_YEAR_INC = "MMM do uu";

const DateTooltip = withErrorBoundary(
  ({ date, displayYear = false, shouldLocalize = false }) => {
    const { timezone } = getClientTimezone();

    const dateObj = new Date(date);

    const localizedTimeStr = dateObj.toLocaleString("en-US", {
      timeZone: timezone,
    });

    const localized = new Date(localizedTimeStr);

    const formattedLong = formatDateWithTime(
      shouldLocalize ? localized : dateObj,
    );

    const formattedShort = format(
      shouldLocalize ? localized : dateObj,
      !displayYear ? DATE_FORMAT_SHORT : DATE_FORMAT_SHORT_YEAR_INC,
    );

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: 2,
        }}
      >
        <Tooltip
          arrow
          placement="top"
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: "white",
                border: "1px solid lightgray",

                height: 24,
                padding: "4px 8px",
              },
            },
          }}
          title={
            <span
              style={{
                fontWeight: "400",
                color: "black",
                fontSize: 11,
                color: "black",
                whiteSpace: "nowrap",
              }}
            >
              {formattedLong}
            </span>
          }
        >
          <Container>
            <Calendar height={12} width={12} color="darkgrey" />
          </Container>
        </Tooltip>
        <span style={{ whiteSpace: "nowrap" }}>{formattedShort}</span>
      </div>
    );
  },
  {
    fallback: (
      <span style={{ color: "darkgrey", fontSize: 11 }}>
        error parsing date
      </span>
    ),
  },
);

export default DateTooltip;
