import React, { useState } from "react";
import { Paper, Button, Typography } from "@mui/material";
import styled from "styled-components";
import { DeleteButton } from "../../pages/private/styles";

const Wrapper = styled(Paper)`
  padding: 20px;
  width: 400px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  border-radius: 8px;
  border: 2px solid
    ${(props) =>
      props.$modaltype === "positive" ? "mediumseagreen" : "tomato"};
`;

const ConfirmModalSmall = ({
  title,
  name,
  handleConfirm,
  confirmAccept = false,
}) => {
  const [updating, setUpdating] = useState(false);

  const handleFinish = async () => {
    try {
      setUpdating(true);
      await handleConfirm();
    } catch (error) {
      setUpdating(false);
    }
  };

  return (
    <Wrapper $modaltype={confirmAccept ? "positive" : "negative"}>
      <Typography variant="h5" component="h5">
        {title}
      </Typography>
      <Typography
        sx={{ mb: 3, mt: 1 }}
        style={{ fontWeight: "400", fontSize: 14 }}
      >
        {name}
      </Typography>
      {confirmAccept ? (
        <Button
          fullWidth
          variant="contained"
          style={{
            backgroundColor: updating ? "lightgray" : "mediumseagreen",
            color: "white",
          }}
          onClick={handleFinish}
        >
          {updating ? "..." : "Confirm"}
        </Button>
      ) : (
        <DeleteButton
          fullWidth
          variant="contained"
          onClick={handleFinish}
          disabled={updating}
          style={{
            backgroundColor: updating ? "lightgray" : "#f44336",
          }}
        >
          {updating ? "..." : "Confirm"}
        </DeleteButton>
      )}
    </Wrapper>
  );
};

export default ConfirmModalSmall;
