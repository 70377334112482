import { Button, Paper, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import { TABLE_STATES } from "../../../../../components/audience/AudienceTool/helpers";
import useAudienceToolStore from "../../../../../store/useAudienceToolStore";

const Wrapper = styled(Paper)`
  padding: 20px;
  width: 420px;
  text-align: left;
  display: flex;
  flex-direction: column;
  max-width: 520px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid black;
`;

const EditAudienceNameModal = ({ initialName }) => {
  const [text, setText] = useState(initialName);
  const handleUpdateTemplateName = useAudienceToolStore(
    (state) => state.handleUpdateTemplateName,
  );
  const isSaving = useAudienceToolStore(
    (state) => state.tableState === TABLE_STATES.SAVING,
  );

  return (
    <Wrapper>
      <Typography variant="h4" style={{ fontSize: 16, fontWeight: "600" }}>
        Edit Template Name
      </Typography>
      <div style={{ height: 16 }} />
      <TextField
        value={text}
        onChange={(e) => setText(e.currentTarget.value)}
        size="small"
        fullWidth
        label="Template Name"
        variant="outlined"
      />
      <div style={{ height: 8 }} />
      <Button
        variant="contained"
        color="primary"
        disabled={text.length < 2 || initialName === text || isSaving}
        onClick={() => handleUpdateTemplateName(text)}
        style={{ marginLeft: "auto" }}
      >
        {isSaving ? "Saving" : "Save"}
      </Button>
    </Wrapper>
  );
};

export default EditAudienceNameModal;
