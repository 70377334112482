import React, { useState } from "react";
import { Modal, Divider, Paper } from "@mui/material";
import { Phone, Mail, User, Trash } from "react-feather";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Question, Answer } from "../../styles";
import { Button, Typography } from "@mui/material";
import ConfirmDeleteManager from "./ConfirmDeleteManager";
import SnackbarWrapper from "../../../../components/SnackbarWrapper";

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(5)};
  min-height: 220px;
  min-width: 300px;
  text-align: left;
  display: flex;
  flex-direction: column;
`;

const IconHolder = styled.div`
  margin-right: 8px;

  svg {
    height: 12px;
    width: 12px;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const About = ({ manager, mutate, readOnly }) => {
  const [snackbarText, setSnackbarText] = useState("");
  const [deleteInfo, setDeleteInfo] = useState(null);

  return (
    <>
      <Wrapper style={{ flex: 1 }}>
        <Typography sx={{ mb: 1 }} variant="h4">
          About
        </Typography>
        <Divider sx={{ mb: 4 }} />
        <InfoContainer>
          <IconHolder>
            <User color="#337ab7" />
          </IconHolder>
          <Question>Name:</Question>
          <Answer>{`${manager.firstName} ${manager.lastName}`}</Answer>
        </InfoContainer>
        <InfoContainer>
          <IconHolder>
            <Mail color="#337ab7" />
          </IconHolder>
          <Question>Email:</Question>
          <Answer>{manager.email}</Answer>
        </InfoContainer>
        <InfoContainer>
          <IconHolder>
            <Phone color="#337ab7" />
          </IconHolder>
          <Question>Phone Number:</Question>
          <Answer>{manager.phoneNumber || "---/---"}</Answer>
        </InfoContainer>
        <div style={{ marginTop: "auto" }} />
        <Button
          startIcon={<Trash height={16} width={16} />}
          fullWidth
          variant="contained"
          color="inherit"
          disabled={readOnly}
          onClick={() =>
            setDeleteInfo({
              id: manager.id,
              name: `${manager.firstName} ${manager.lastName}`,
            })
          }
        >
          Delete Supervisor
        </Button>
      </Wrapper>
      <Modal
        open={Boolean(deleteInfo)}
        onClose={() => setDeleteInfo(null)}
        aria-labelledby="Delete a Supervisor"
        aria-describedby="Delete a Supervisor"
      >
        <div>
          <ConfirmDeleteManager
            deleteInfo={deleteInfo}
            setConfirmModalOpen={() => setDeleteInfo(null)}
            setSnackbarText={setSnackbarText}
            mutate={mutate}
            setDeleteInfo={setDeleteInfo}
            routeBacktoSuperviorPage
          />
        </div>
      </Modal>
      <SnackbarWrapper
        snackbarText={snackbarText}
        setSnackbarText={setSnackbarText}
      />
    </>
  );
};

export default About;

About.propTypes = {
  manager: PropTypes.object.isRequired,
  mutate: PropTypes.func.isRequired,
};
