import useSWR from "swr";
import API from "../../../axios/instances/API";

const fetcher = async (url) => {
  const { data } = await API.get(url);
  return data;
};

const useTeamInvitations = () => {
  const { data, error, mutate } = useSWR(
    "dashboard/teams/invitations/",
    fetcher,
    {
      revalidateOnFocus: false,
    },
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useTeamInvitations;
