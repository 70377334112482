import React, { useState } from "react";
import { Switch } from "@mui/material";
import API from "../../../../axios/instances/API";
import DeployPermissionsTooltip from "../../../../components/UI/DeployPermissionsTooltip";

const ToggleManagerDeploy = ({
  mutate,
  canDeploy,
  readOnly,
  setSnackbarText,
  id,
  isManager,
}) => {
  const [superDeployUpdating, setSuperDeployUpdating] = useState(false);

  const handleToggleManagerDeploy = async (e) => {
    setSuperDeployUpdating(true);
    try {
      const sendObj = {
        id: Number(id),
        managers_can_deploy_content: e.target.checked,
      };

      await API.put("dashboard/locations/details/", sendObj);
      await mutate();
      setSuperDeployUpdating(false);
      setSnackbarText("Updated supervisor deploy permissions success");
    } catch (error) {
      setSuperDeployUpdating(false);
      setSnackbarText("Failed to update supervisor deploy permissions error");
    }
  };

  return (
    <div
      style={{
        borderRadius: 6,
        background: "white",
        minHeight: 50,
        width: "fit-content",
        padding: "0px 12px",
        border: "1px solid lightgray",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: 22,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
          gap: 4,
        }}
      >
        <span style={{ fontSize: 12, fontWeight: "500" }}>
          Give supervisors at this location deploy permissions?
        </span>
        <DeployPermissionsTooltip />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <span style={{ fontSize: 11, color: "darkgrey", marginRight: 6 }}>
          {superDeployUpdating ? "..." : canDeploy ? "(Yes)" : "(No)"}
        </span>

        <Switch
          size="small"
          color="secondary"
          disabled={readOnly || isManager}
          checked={canDeploy}
          onChange={handleToggleManagerDeploy}
        />
      </div>
    </div>
  );
};

export default ToggleManagerDeploy;
