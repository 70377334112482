import { Button } from "@mui/material";
import React from "react";
import { AlertTriangle } from "react-feather";
import useAudienceToolStore from "../../../store/useAudienceToolStore";

const ErrorScreen = () => {
  const error = useAudienceToolStore((state) => state.error);
  const handleClearError = useAudienceToolStore(
    (state) => state.handleClearError,
  );

  if (!error) return null;

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        left: 0,
        top: 0,
        zIndex: 20,
        background: "#eeeeee96",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          minHeight: 100,
          width: 320,
          background: "white",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          zIndex: 10000,
          borderRadius: 12,
          padding: 12,
          border: "2px solid black",
        }}
      >
        <AlertTriangle style={{ color: "firebrick" }} />
        <span style={{ fontSize: 16, fontWeight: "600" }}>ERROR</span>
        <div style={{ height: 8 }} />
        <span style={{ fontSize: 13, fontWeight: "500", textAlign: "center" }}>
          {error?.title}
        </span>
        <span
          style={{
            fontSize: 12,
            fontWeight: "400",
            textAlign: "center",
            color: "darkgray",
          }}
        >
          {error?.body}
        </span>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 16,
            gap: 8,
          }}
        >
          <Button variant="outlined" fullWidth onClick={handleClearError}>
            Close
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ErrorScreen;
