import React from "react";
import PropTypes from "prop-types";
import { CircularProgress, Typography } from "@mui/material";
import styled from "styled-components";
import useSingleLocationProgress from "../../../../swr/hooks/RetailReports/useSingleLocationProgress";
import { CustomLinearProgressMain, WrapperLessPaddingTop } from "../../styles";

const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;

  span {
    color: black;
    font-weight: 800;
    font-size: 18px;
  }
`;

const AttemptsSpan = styled.span`
  margin-top: 4px;
  font-size: 12px;
  color: ${(props) => props.theme.palette.grey[500]};
  font-weight: 600;
  display: block;
`;

const Centered = styled.div`
  display: flex;
  min-height: 10px;
  align-items: flex-start;
`;

const LocationProgress = ({ locationId }) => {
  const {
    data: progress,
    isLoading,
    isError,
  } = useSingleLocationProgress(locationId); // updated

  if (isLoading) {
    return (
      <WrapperLessPaddingTop>
        <Centered>
          <CircularProgress size={30} />
        </Centered>
      </WrapperLessPaddingTop>
    );
  }

  if (isError) {
    return (
      <WrapperLessPaddingTop>
        <Centered>
          <Typography variant="subtitle2">Error loading progress..</Typography>
        </Centered>
      </WrapperLessPaddingTop>
    );
  }

  const progressArray = Object.values(progress);

  const totalAssigned = progressArray.reduce((acc, curr) => {
    return acc + curr.assigned;
  }, 0);
  const totalCompletedOfAssigned = progressArray.reduce((acc, curr) => {
    return acc + curr.completed_out_of_assigned;
  }, 0);

  const totalOutstanding = totalAssigned - totalCompletedOfAssigned;

  const value = !totalCompletedOfAssigned
    ? 0
    : Math.ceil((totalCompletedOfAssigned / totalAssigned) * 100);

  return (
    <WrapperLessPaddingTop>
      <TopWrapper>
        <Typography style={{ fontSize: 16, fontWeight: "600" }}>
          Location Progress
        </Typography>
        <span>{value}%</span>
      </TopWrapper>
      <div style={{ height: 6 }} />
      <CustomLinearProgressMain variant="determinate" value={value} />
      <AttemptsSpan>
        {`${totalCompletedOfAssigned} modules completed, ${totalOutstanding} modules outstanding`}
      </AttemptsSpan>
    </WrapperLessPaddingTop>
  );
};

export default LocationProgress;

LocationProgress.propTypes = {
  locationId: PropTypes.string.isRequired,
};
