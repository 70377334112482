import React from "react";
import { Dialog, Button } from "@mui/material";
import { AlertTriangle } from "react-feather";

const KeepTmsDialog = ({ open, handleClose, handleUpdate, info }) => {
  return (
    <Dialog open={open} onClose={() => {}}>
      <div
        style={{
          width: 340,
          border: "2px solid black",
          minHeight: 80,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          padding: 12,
          gap: 4,
        }}
      >
        <AlertTriangle height={18} width={18} color="darkorange" />
        <span style={{ fontSize: 12, fontWeight: 500 }}>
          This admin also has an active team member at this company. Would you
          also like to remove that team member?
        </span>
        <div style={{ marginLeft: "auto", display: "flex" }}>
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              handleUpdate(info, true);
              handleClose();
            }}
          >
            No
          </Button>
          <div style={{ width: 4 }} />
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              handleUpdate(info, false);
              handleClose();
            }}
          >
            Yes
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default KeepTmsDialog;
