import React from "react";
import styled from "styled-components";
import { List } from "react-feather";
import { Tooltip } from "@mui/material";
import { maxWidth } from "@mui/system";

const Container = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background: #eeeeee;
  }
`;

const customPopperStyles = {
  backgroundColor: "white",
  border: "1px solid slategrey",
  maxHeight: "none",
  padding: "8px",
  maxWidth: 200,
};

const ExplanationTooltip = ({ val }) => {
  if (val === "A") {
    return (
      <Tooltip
        componentsProps={{
          tooltip: {
            sx: customPopperStyles,
          },
        }}
        arrow
        title={
          <div>
            <p style={{ fontWeight: "500", color: "black", fontSize: 11 }}>
              Team Member
            </p>

            <span style={{ color: "darkgrey", fontSize: 10 }}>
              Team members get access to the app and are assigned content.
            </span>
          </div>
        }
      >
        <Container>
          <List style={{ color: "darkgrey", height: 12, width: 12 }} />
        </Container>
      </Tooltip>
    );
  }

  if (val === "C") {
    return (
      <Tooltip
        componentsProps={{
          tooltip: {
            sx: customPopperStyles,
          },
        }}
        arrow
        title={
          <div>
            <p style={{ fontWeight: "500", color: "black", fontSize: 11 }}>
              Admin
            </p>

            <span style={{ color: "darkgrey", fontSize: 10 }}>
              Admins get total access to the dashboard. There are multiple
              levels of admins.
            </span>
          </div>
        }
      >
        <Container>
          <List style={{ color: "darkgrey", height: 12, width: 12 }} />
        </Container>
      </Tooltip>
    );
  }

  if (val === "M") {
    return (
      <Tooltip
        componentsProps={{
          tooltip: {
            sx: customPopperStyles,
          },
        }}
        arrow
        title={
          <div>
            <p style={{ fontWeight: "500", color: "black", fontSize: 11 }}>
              Supervisor
            </p>
            <span style={{ color: "darkgrey", fontSize: 10 }}>
              Supervisors get access to a limited version of the dashboard that
              allows them to manage specific locations.
            </span>
          </div>
        }
      >
        <Container>
          <List style={{ color: "darkgrey", height: 12, width: 12 }} />
        </Container>
      </Tooltip>
    );
  }

  return null;
};

export default ExplanationTooltip;
