import React from "react";
import useAudienceToolStore from "../../../../store/useAudienceToolStore";
import { FilePlus } from "react-feather";
import { AUDIENCETYPES, USER_TYPES } from "../../../../storage/constants";
import AttActionButtonWithText from "./AttActionButtonWithText";

const ImportButton = () => {
  const hasAtLeast1RoleAsOption = useAudienceToolStore(
    (state) => state.hasAtLeast1RoleAsOption,
  );
  const hasAtLeast1LocationAsOption = useAudienceToolStore(
    (state) => state.hasAtLeast1LocationAsOption,
  );

  const toggleTemplateSelector = useAudienceToolStore(
    (state) => state.toggleTemplateSelector,
  );

  const userIsManager = useAudienceToolStore((state) =>
    [
      USER_TYPES.RETAIL_MANAGER_CAN_DEPLOY,
      USER_TYPES.RETAIL_MANAGER_CANT_DEPLOY,
    ].includes(state.userType),
  );

  const checked = useAudienceToolStore(
    (state) => state.currentAudienceType === AUDIENCETYPES.OTHER,
  );

  if (userIsManager) return null;

  return (
    <AttActionButtonWithText
      title="Import Template"
      Icon={FilePlus}
      checked={checked}
      disabled={!hasAtLeast1RoleAsOption || !hasAtLeast1LocationAsOption}
      handleClick={() => toggleTemplateSelector()}
    />
  );
};

export default ImportButton;
