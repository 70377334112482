import React from "react";
import { USER_TYPES, QUIZSTATES } from "../../../../../storage/constants";
import { StyledItem } from "../../../styles";
import { Cast } from "react-feather";
import { LIBRARY_MODAL_KEYS } from "../../../../../storage/constants";

const AddAudAndLaunch = ({
  isRetailerModuleFromMarketplace,
  userType,
  moduleState,
  setModalState,
  handleClose,
  id,
  isUserAManagerWhoCreatedModule,
  moduleName,
}) => {
  if (moduleState !== QUIZSTATES.IN_DEVELOPMENT) {
    return null;
  }

  if (!isRetailerModuleFromMarketplace) return null;

  if (
    ![USER_TYPES.RETAIL_MANAGER_CAN_DEPLOY, USER_TYPES.RETAIL_ADMIN].includes(
      userType,
    )
  ) {
    return null;
  }

  if (
    userType === USER_TYPES.RETAIL_MANAGER_CAN_DEPLOY &&
    !isUserAManagerWhoCreatedModule
  )
    return null;

  return (
    <StyledItem
      onClick={() => {
        setModalState({
          key: LIBRARY_MODAL_KEYS.ADD_AUDIENCE_AND_LAUNCH,
          id,
          moduleName,
        });
        handleClose();
      }}
    >
      <Cast />
      <span>Add Audience & Launch</span>
    </StyledItem>
  );
};

export default AddAudAndLaunch;
