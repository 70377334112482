import React, { useState } from "react";
import { AlertCircle, ChevronDown, ChevronUp } from "react-feather";
import { Collapse, IconButton } from "@mui/material";
import styled from "styled-components";

// <- CHORE TODO ->
// Standardize these types of chips a bit,
// employer/supplier and these should all be
//  similar in look

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 8px;
  border: 1px solid lightgray;
  background: #eeeeee70;
  border-radius: 4px;
  margin-top: 12px;
`;

const CompanyItem = styled.div`
  padding: 4px 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px dashed lightgray;
  border-radius: 6px;
  margin-bottom: 4px;
`;

const ChipWrapper = styled.div`
  padding: ${(props) => (props.$small ? "0px 4px" : "2px 6px")};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => (props.$small ? "16px" : "auto")};
`;

export const WriteChip = ({ small = false }) => (
  <ChipWrapper
    style={{
      background: "#336ab719",
    }}
    $small={small}
  >
    <span style={{ color: "#337ab7", fontSize: small ? 9 : 11 }}>
      Write Access
    </span>
  </ChipWrapper>
);

export const ReadChip = ({ small = false }) => (
  <ChipWrapper
    style={{
      background: "#CF9FFF50",
    }}
    $small={small}
  >
    <span style={{ color: "purple", fontSize: small ? 9 : 11 }}>Read Only</span>
  </ChipWrapper>
);

const DisplayInvitedTo = ({ invitedCompanies }) => {
  const [collapsed, setCollapsed] = useState(true);

  if (!invitedCompanies) return null;

  return (
    <Wrapper>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: 6,
          padding: "0px 4px",
          width: "100%",
          height: 30,
          marginBottom: 4,
        }}
      >
        <AlertCircle height={14} width={14} color="black" />
        <span style={{ fontWeight: 400, fontSize: 13, color: "black" }}>
          Your invitation includes admin access to{" "}
          <b style={{ color: "#337ab7" }}>
            {invitedCompanies?.length}{" "}
            {invitedCompanies?.length > 1 ? "teams" : "team"}.
          </b>
        </span>
        <IconButton
          style={{ marginLeft: "auto" }}
          size="small"
          onClick={() => setCollapsed((p) => !p)}
        >
          {!collapsed ? (
            <ChevronUp height={15} width={15} color="black" />
          ) : (
            <ChevronDown height={15} width={15} color="black" />
          )}
        </IconButton>
      </div>
      <Collapse in={!collapsed}>
        {invitedCompanies?.map((c, i) => (
          <CompanyItem key={c?.company_id}>
            <span
              style={{
                fontWeight: 500,
                fontSize: 12,
                color: "black",
                flexGrow: 1,
                overflowY: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                maxWidth: 270,
              }}
            >
              {i + 1}. {c?.company_name}
            </span>
            {c?.read_only ? <ReadChip /> : <WriteChip />}
          </CompanyItem>
        ))}
      </Collapse>
    </Wrapper>
  );
};

export default DisplayInvitedTo;
