import useSWR from "swr";
import API from "../../../axios/instances/API";

const fetcher = async (url) => {
  const { data } = await API.get(url);
  return data;
};

const useAudience = (id) => {
  const { data, error, mutate, isValidating } = useSWR(
    !id ? null : `dashboard/audiences/details/?id=${id}`,
    fetcher,
    {
      revalidateOnFocus: false,
    },
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
    isValidating,
  };
};

export default useAudience;
