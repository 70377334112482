import React from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";
import "../../css/trialLoader.css";

const Root = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 90%;
  vertical-align: middle;
  margin: auto;
`;

// Come Back to this on Safari.. weird breaking

const fadeInOut = keyframes`
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.6;
  }
  100%{
    opacity: 0.2;
  }
`;

const LoadingText = styled.span`
  color: #233044;
  transform: translateY(-40px);
  font-style: italic !important;
  font-size: 12px !important;
  letter-spacing: 2px !important;
  opacity: 0;
  animation: 1.8s ${fadeInOut} ease-out infinite;
`;

const LoaderAnimation = () => <div className="loader" />;

const LoaderWrapper = ({ text = null }) => (
  <Root>
    <LoaderAnimation />
    {text && <LoadingText>Loading {text}</LoadingText>}
  </Root>
);

export default LoaderWrapper;

LoaderWrapper.propTypes = {
  text: PropTypes.string,
};

LoaderWrapper.defaultProps = {
  text: null,
};
