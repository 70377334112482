import { Button, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import { Box } from "react-feather";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import SpiffyLogo from "../../assets/images/newspiffy.avif";
import API from "../../axios/instances/API";
import { hasLogoImage } from "../../storage/helpers";
import ACTIONS from "../../store/actions";
import CheckboxBrandBasic from "../UI/CheckboxBrandBasic";
import { useSWRConfig } from "swr";

const ModalWrapper = styled(Paper)`
  padding: 16px;
  width: 380px;
  text-align: left;
  min-height: 180px;
  max-width: 400px;
  max-height: 600px;
  position: absolute;
  left: 50%;
  top: 50%;
  border: 2px solid black;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 2px;
  padding: 2px;
  background: white;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 200ms ease-in;
  background: ${(props) => (props.$isSelected ? "#eeeeee" : "white")};
  &:hover {
    background: ${(props) => (props.$isSelected ? "#eeeeee" : "#eeeeee66")};
  }
`;

const ScrollContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  overflow-y: scroll;
  gap: 4px;
  padding-bottom: 8px;
`;

const getCompaniesGrouped = (availableCompanies) => {
  const orgIds = [...new Set(availableCompanies.map((c) => c.organization_id))];

  const retArr = [];

  orgIds.forEach((orgId) => {
    const companies = availableCompanies.filter(
      (c) => c.organization_id === orgId,
    );

    const targetOrg = availableCompanies.find(
      (c) => c.organization_id === orgId,
    );

    retArr.push({
      org: {
        id: orgId,
        logo: targetOrg.organization_logo,
        name: targetOrg?.organization_name,
      },
      companies,
    });
  });

  return retArr;
};

const SelectCompanyModal = ({
  availableCompanies,
  currentCompanyId,
  handleClose,
  dispatch,
}) => {
  const [selected, setSelected] = useState(currentCompanyId || null);
  const history = useHistory();

  const { mutate } = useSWRConfig();

  const clearCache = () => mutate(() => true, undefined, { revalidate: false });

  const handleCompanyChange = async (company) => {
    try {
      await API.put("dashboard/account/switch-company/", {
        id: selected,
      });

      // Need to get the new organization details..
      // and get rid of all Cached SWR Data (new data for new company)
      // Doesn't actually log you out.. but gets the config info again
      clearCache();
      dispatch({ type: ACTIONS.REFRESH_CONFIG });
      handleClose();
      history.push("/home/");
    } catch (error) {
      // Need To Find Good Way to handle this error
    }
  };

  const orgsGrouped = getCompaniesGrouped(availableCompanies);

  return (
    <ModalWrapper>
      <Typography style={{ fontSize: 16, fontWeight: 600 }}>
        Select Company
      </Typography>
      <Typography style={{ fontSize: 11, color: "darkgrey" }}>
        All companies available to you are listed below.
      </Typography>
      <div style={{ height: 12 }} />
      <ScrollContainer>
        {orgsGrouped?.map((org) => (
          <div key={org?.org?.id} style={{ width: "100%", marginBottom: 12 }}>
            <div
              style={{
                width: "100%",
                padding: 4,
                borderBottom: "1px solid #eeeeee",
                marginBottom: 8,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: 4,
              }}
            >
              {Boolean(org?.org?.name) && (
                <Box height={14} width={14} color="slategrey" />
              )}
              <span
                style={{
                  color: "slategrey",
                  textTransform: "uppercase",
                  fontWeight: 600,
                  fontSize: 12,
                }}
              >
                {org?.org?.name || "No"} Organization
              </span>
            </div>

            {org.companies.map((company) => (
              <Item
                onClick={() => setSelected(company.company_id)}
                key={company?.company_id}
                $isSelected={selected === company.company_id}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  width: "100%",
                  gap: 4,
                  marginBottom: 4,
                }}
              >
                <img
                  style={{
                    height: 32,
                    width: 32,
                    borderRadius: 14,

                    border: "1px solid lightgray",
                    objectFit: "contain",
                    backgroundColor: "white",
                    marginRight: 4,
                  }}
                  src={
                    hasLogoImage(company.company_logo)
                      ? company.company_logo
                      : SpiffyLogo
                  }
                  alt="company"
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    width: "100%",

                    flexDirection: "column",
                  }}
                >
                  <span style={{ fontSize: 12, fontWeight: 500 }}>
                    {company?.company_name}
                  </span>
                  <span
                    style={{ fontSize: 10, color: "darkgrey", marginTop: -2 }}
                  >
                    {company?.read_only ? "Read Only Access" : "Write Access"}
                  </span>
                </div>
                <div style={{ marginLeft: "auto" }}>
                  <CheckboxBrandBasic
                    checked={selected === company.company_id}
                    handleChange={() => {}}
                  />
                </div>
              </Item>
            ))}
          </div>
        ))}
      </ScrollContainer>
      <div style={{ height: 12 }} />
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="contained"
          size="small"
          color="primary"
          style={{ color: "white", marginLeft: "auto" }}
          onClick={handleCompanyChange}
        >
          Apply
        </Button>
      </div>
    </ModalWrapper>
  );
};

export default SelectCompanyModal;
