import useSWR from "swr";
import API from "../../../axios/instances/API";

const fetcher = async (url) => {
  const { data } = await API.get(url);
  return data.managers;
};

const useManagers = () => {
  const { data, error, mutate } = useSWR("/dashboard/managers/", fetcher, {
    revalidateOnFocus: false,
  });

  return {
    managers: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useManagers;
