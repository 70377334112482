import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  MenuItem,
  CircularProgress,
  Paper,
  Typography,
  TextField,
  Button,
} from "@mui/material";

import useRoleCategories from "../../../../swr/hooks/Roles/useRoleCategories";
import API from "../../../../axios/instances/API";

const EditWrapper = styled(Paper)`
  min-width: 420px;
  flex: 0.5;
  flex-grow: 1;
  padding: 20px;
  min-height: 200px;
`;

const CenterProgress = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EditRole = ({ setSnackbarText, mutate, details, readOnly }) => {
  const { isLoading, roleCategories } = useRoleCategories();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [category, setCategory] = useState("");
  const [name, setName] = useState(details.name);

  const [frName, setFrName] = useState(details.frName);

  useEffect(() => {
    if (isLoading) return;
    if (!roleCategories) return setCategory("None");
    if (details.category === null) return setCategory("None");
    const [currentCategory] = roleCategories.filter(
      (cat) => cat.id === details.category.id,
    );
    if (!currentCategory) return setCategory("None");
    setCategory(currentCategory.name);
  }, [isLoading]);

  if (isLoading) {
    return (
      <EditWrapper>
        <Typography variant="h3">Edit Role</Typography>
        <Typography variant="subtitle1" sx={{ mb: 12 }}>
          Change the inputs below to Edit Role.
        </Typography>
        <CenterProgress>
          <CircularProgress size={30} />
        </CenterProgress>
      </EditWrapper>
    );
  }

  const handleDisable = () => {
    if (!category || category === "None") return true;
    if (readOnly) return true;
    if (
      name !== details.name ||
      frName !== details.frName ||
      details.category === null ||
      category !== details.category.name
    ) {
      return false;
    }
    return true;
  };

  const isDisabled = handleDisable();
  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      if (category === "None") {
        setIsSubmitting(false);
        return setSnackbarText("All roles must have a category error");
      }
      if (name.length < 2) {
        setIsSubmitting(false);
        return setSnackbarText("Name must be minimum 2 characters error");
      }
      const [newCategory] = roleCategories.filter(
        (cat) => cat.name === category,
      );
      const sendObj = {
        name,

        fr_name: frName ? frName : name,
        id: details.id,
        category_id: newCategory.id,
      };
      await API.put("dashboard/roles/details/", sendObj);
      await mutate();
      setIsSubmitting(false);
      setSnackbarText(`Role "${name}" updated success`);
    } catch (error) {
      setIsSubmitting(false);
      setSnackbarText("Failed to update role error");
    }
  };

  return (
    <EditWrapper>
      <Typography variant="h4">Edit Role</Typography>
      <Typography variant="subtitle1" sx={{ mb: 10 }}>
        Change the inputs below to Edit Role.
      </Typography>
      <div style={{ width: "100%", display: "flex", gap: 12 }}>
        <TextField
          variant="outlined"
          fullWidth
          value={name}
          onChange={(e) => setName(e.currentTarget.value)}
          label="Role Name"
          size="small"
          style={{ flex: 1 }}
        />

        {isLoading ? (
          <CircularProgress size={20} />
        ) : (
          <TextField
            select
            value={category}
            onChange={(e) => {
              setCategory(e.target.value);
            }}
            label="Category"
            elevation={0}
            variant="outlined"
            size="small"
            fullWidth
            style={{ flexBasis: 140 }}
          >
            {roleCategories &&
              roleCategories.map((cat) => (
                <MenuItem key={cat.id} value={cat.name}>
                  {cat.name}
                </MenuItem>
              ))}
          </TextField>
        )}
      </div>

      <Button
        sx={{ mt: 4 }}
        onClick={handleSubmit}
        disabled={isDisabled || isSubmitting}
        variant="contained"
        color="primary"
        startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
        fullWidth
      >
        {isSubmitting ? "Saving" : "Save"}
      </Button>
    </EditWrapper>
  );
};

export default EditRole;

EditRole.propTypes = {
  mutate: PropTypes.func.isRequired,
  details: PropTypes.object.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
};
