import React, { useState } from "react";
import { Paper, Modal, Typography, Divider } from "@mui/material";
import { Edit2 } from "react-feather";
import PropTypes from "prop-types";

import SnackbarWrapper from "../../../../components/SnackbarWrapper";
import styled from "styled-components";
import { Check } from "react-feather";
import EditRolesModal from "./EditRolesModal";
import ActionButtonForTables from "../../../../components/Table/ActionButtonForTables";

const CheckedIcon = () => (
  <div
    style={{
      height: 14,
      width: 14,
      minHeight: 14,
      minWidth: 14,
      borderRadius: 4,
      background: "lightgray",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <Check height={10} width={10} style={{ color: "white" }} />
  </div>
);

const Wrapper = styled(Paper)`
  padding: 20px;
  padding-top: 16px;
  min-height: 220px;
  text-align: left;
  border-radius: 6px;

  min-width: 280px;
  width: 100%;
`;

const TeamMemberRoles = ({ id, readOnly, details, mutate }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [snackbarText, setSnackbarText] = useState("");

  return (
    <Wrapper>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 2,
        }}
      >
        <Typography sx={{ mb: 1 }} style={{ fontSize: 15, fontWeight: "500" }}>
          Roles
        </Typography>

        <ActionButtonForTables
          Icon={Edit2}
          handleClick={() => setIsEditing(true)}
          disabled={readOnly}
          title="edit"
        />
      </div>
      <Divider sx={{ mb: 3 }} />

      {details?.team_member?.roles.map((r) => (
        <div
          key={r.id}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",

            padding: "2px 0px",
            paddingLeft: 0,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: 2,
            }}
          >
            <span style={{ fontWeight: "400", fontSize: 12 }}>{r.name}</span>
          </div>
          <CheckedIcon />
        </div>
      ))}

      <SnackbarWrapper
        snackbarText={snackbarText}
        setSnackbarText={setSnackbarText}
      />
      <Modal open={isEditing} onClose={() => setIsEditing(false)}>
        <div>
          <EditRolesModal
            usersCurrentRoles={details?.team_member?.roles.map((r) => r.id)}
            mutate={mutate}
            userId={id}
            setSnackbarText={setSnackbarText}
            handleClose={() => setIsEditing(false)}
          />
        </div>
      </Modal>
    </Wrapper>
  );
};

export default TeamMemberRoles;

TeamMemberRoles.propTypes = {
  id: PropTypes.string.isRequired,
  readOnly: PropTypes.bool.isRequired,
  isManager: PropTypes.bool.isRequired,
  details: PropTypes.object.isRequired,
  mutate: PropTypes.func.isRequired,
};
