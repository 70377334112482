import { min, eachDayOfInterval, startOfDay, subDays } from "date-fns";

export const TIMEPERIODS = {
  TODAY: "Today",
  WEEK: "Last Week",
  TWOWEEK: "Last 14 Days",
};

const SUBCATEGORIES = {
  ASSIGNED: "ASSIGNED",
  FOLLOWER: "FOLLOWER",
  ALL: "ALL",
};

const TODAY = new Date();

export const getTimePeriod = (timestring) => {
  if (timestring === TIMEPERIODS.TODAY) return 1;
  if (timestring === TIMEPERIODS.WEEK) return 7;
  if (timestring === TIMEPERIODS.TWOWEEK) return 14;
};

const getSubsByChartType = (submissions, chartSubmissionType) => {
  if (chartSubmissionType === SUBCATEGORIES.ALL) return submissions;

  if (chartSubmissionType === SUBCATEGORIES.ASSIGNED) {
    return submissions.filter((sub) => !sub.fromFollowers);
  }

  if (chartSubmissionType === SUBCATEGORIES.FOLLOWER) {
    return submissions.filter((sub) => sub.fromFollowers);
  }

  return null;
};

export const getSubmissionsByDayStats = (submissions, chartSubmissionType) => {
  const allDates = submissions.map((sub) => new Date(sub.created));
  // Need TO Add 14 days ago//
  const fourteenDays = subDays(TODAY, 14);
  allDates.push(fourteenDays);
  const minDate = min(allDates);
  const finalDate = new Date(); // Should be today but doublecheck
  const days = eachDayOfInterval({
    start: minDate,
    end: finalDate,
  });
  const dayObj = {};

  days.forEach(
    (day) =>
      (dayObj[day] = {
        subs: 0,
        pass: 0,
        fail: 0,
        questions: 0,
        score: 0,
      }),
  );

  const subsByChartType = getSubsByChartType(submissions, chartSubmissionType);

  subsByChartType.forEach((submission) => {
    const day = startOfDay(new Date(submission.created));
    dayObj[day].subs += 1;
    dayObj[day].questions += submission.questions;
    dayObj[day].score += submission.score;
    if (submission.passed) {
      dayObj[day].pass += 1;
    } else {
      dayObj[day].fail += 1;
    }
  });
  return dayObj;
};

export const getMetrics = (submissions, timePeriod) => {
  if (submissions.length === 0) {
    return {
      questions: 0,
      correct: 0,
      passed: 0,
      failed: 0,
      averageScore: 0,
      passPercentage: 0,
    };
  }

  const numDays = getTimePeriod(timePeriod);
  const timeAgo = numDays === 1 ? startOfDay(TODAY) : subDays(TODAY, numDays);
  const submissionsLastTimePeriod = submissions.filter(
    (submission) => new Date(submission.created) > timeAgo,
  );

  let questions = 0;
  let correct = 0;
  let passed = 0;
  let failed = 0;

  submissionsLastTimePeriod.forEach((sub) => {
    questions += sub.questions;
    correct += sub.score;
    passed += sub.passed;
    failed += !sub.passed;
  });

  const averageScore =
    correct > 0 ? Math.floor((correct / questions) * 100) : 0;
  const passPercentage =
    passed > 0 ? Math.floor((passed / (passed + failed)) * 100) : 0;
  return {
    questions,
    correct,
    passed,
    failed,
    averageScore,
    passPercentage,
  };
};

export const getMostRecentSub = (submissions, users = null) => {
  try {
    if (!submissions || submissions.length === 0) return null;

    let mostRecent;
    submissions.forEach((sub) => {
      if (!mostRecent) {
        mostRecent = sub;
      }
      const newDate = new Date(sub.created);
      if (newDate > new Date(mostRecent.created)) {
        mostRecent = sub;
      }
    });

    if (users) {
      const selectedUser = users[mostRecent.user_id];
      mostRecent.users_first_name = selectedUser.first_name;
      mostRecent.users_last_name = selectedUser.last_initial;
    }

    return mostRecent;
  } catch (error) {}
};
