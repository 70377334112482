import React from "react";
import { Typography } from "@mui/material";
import styled from "styled-components";
import { Wrapper } from "./styles";
import { XCircle } from "react-feather";

const UnselectedWrapper = styled(Wrapper)`
  border: 1px solid black;
`;

const CurrentUnselected = () => (
  <UnselectedWrapper>
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: 6,
      }}
    >
      <XCircle color="firebrick" height={13} width={13} />
      <Typography variant="subtitle2" style={{ color: "black" }}>
        No audience selected
      </Typography>
    </div>
    <span
      style={{
        fontSize: 12,
        fontWeight: "400",
        color: "darkgrey",
        marginLeft: 18,
      }}
    >
      Please select an audience
    </span>
  </UnselectedWrapper>
);

export default CurrentUnselected;
