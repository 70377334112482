import useSWR from "swr";
import API from "../../../axios/instances/API";

const fetcher = async (url) => {
  const { data } = await API.get(url);
  return data.company;
};

const useCompanyDetails = () => {
  const { data, error, mutate } = useSWR(
    "dashboard/company/details/",
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalideIfStale: false,
    },
  );

  return {
    company: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useCompanyDetails;
