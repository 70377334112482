import React, { useContext, useState } from "react";
import {
  Button,
  MenuItem,
  TextField,
  Modal,
  useMediaQuery,
} from "@mui/material";
import PropTypes from "prop-types";
import { List } from "react-feather";
import { LazyLoadImage } from "react-lazy-load-image-component";
import styled from "styled-components";
import NoImage from "../../../assets/images/empty-small.jpg";
import CustomDataTableIcons from "../../../components/Table/CustomDataTableIcons";
import CustomSearchBarForTables from "../../../components/Table/CustomSearchBarForTables";
import TableTitle from "../../../components/Table/TableTitle";
import { QUIZSTATES, STATE_MAP } from "../../../storage/constants";
import {
  fixIncomingDate,
  getSrcTwo,
  getUserType,
} from "../../../storage/helpers";
import { GlobalState } from "../../../store/GlobalState";
import { CustomTabbedDatatable, ShowState } from "../styles";
import ActionsDropdown from "./actionsDropdown";
import BulkDeleteModal from "./BulkDeleteModal";
import AudienceTooltip from "./components/AudienceTooltip";
import BasicsTooltip from "./components/BasicsTooltip";
import LibraryShowModule from "./components/LibraryShowModule";
import LibraryModals from "./LibraryModals";
import ProFeatureChip from "../../../components/UI/ProFeatureChip";
import DateTooltip from "../../../components/Table/DateTooltip";

const CustomSelect = styled(TextField)`
  .MuiSelect-outlined.MuiSelect-outlined {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
`;

const FlexDivImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  img {
    border: 1px solid #eeeeee;
    margin-right: 8px;
    height: 44px;
    width: 44px;
    object-fit: contain;
    border-radius: 4px;
  }
`;

const CenterMenuItem = styled(MenuItem)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 16px;
`;

const AbsoluteFilterDiv = styled.div`
  position: absolute;
  top: -44px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 360px;
  z-index: 2002;

  @media (max-width: 800px) {
    display: none;
  }
`;

const RelativeContainer = styled.div`
  position: relative;
  z-index: 1;
  top: 2px;
`;

const CONTENT_TYPES = {
  ALL: "All",
  CUSTOM: "Custom",
  MARKETPLACE: "Marketplace",
};

const LibraryTable = ({ modules, setSnackbarText, mutate }) => {
  const { state } = useContext(GlobalState);
  const { config, job, isRetail } = state;
  const [stateFilter, setStateFilter] = useState(6);
  const [typeFilter, setTypeFilter] = useState(CONTENT_TYPES.ALL);
  const [modalState, setModalState] = useState(null);
  const [bulkDeleteOpen, setBulkDeleteOpen] = useState(false);
  const isUnder600Px = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const isManager = job === "M";
  const userType = getUserType(config);

  const hasModuleReportPermissions = !isRetail
    ? false
    : config?.current_company?.permissions?.fields?.module_report;

  const initialColumns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
        download: false,
        print: false,
      },
    },
    {
      name: "name",
      label: "Module",
      options: {
        filter: false,
        sort: true,
        display: true,
        customBodyRender: (value, tableMeta) => {
          if (isRetail) {
            return (
              <LibraryShowModule
                value={value}
                srcString={tableMeta.rowData[11]}
                marketplaceParentInfo={tableMeta.rowData[6].marketplaceInfo}
                config={config}
              />
            );
          }

          // If vendor, all mods are custom so no need for indicator
          return (
            <FlexDivImage>
              <LazyLoadImage
                src={getSrcTwo(tableMeta.rowData[11])}
                effect="opacity"
                onError={(event) => (event.target.src = NoImage)}
              />
              <span
                style={{
                  maxWidth: "40ch",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {value}
              </span>
            </FlexDivImage>
          );
        },
      },
    },
    {
      name: "isMarketplace",
      label: "Origin",
      options: {
        filter: isRetail,
        sort: isRetail,
        display: false,
        download: isRetail,
        filterList: [],
      },
    },
    {
      name: "created",
      label: "Created",
      options: {
        filter: false,
        sort: true,
        display: true,
        customBodyRender: (val) => <DateTooltip date={val} shouldLocalize />,
      },
    },
    {
      name: "moduleType",
      label: "Module Type",
      options: {
        display: false,
        download: false,
        filter: false,
        sort: false,
        print: false,
      },
    },
    {
      name: "start",
      label: "Start Date",
      options: {
        filter: false,
        sort: true,
        display: false,
        customBodyRender: (value) => (
          <span style={{ fontSize: 12 }}>
            {!value ? "None" : fixIncomingDate(value).toDateString()}
          </span>
        ),
      },
    },
    {
      name: "moduleInfo",
      label: "Module Info",
      options: {
        filter: false,
        sort: false,
        display: true,
        customBodyRender: (value) => <BasicsTooltip data={value} />,
      },
    },
    {
      name: "downloads",
      label: "# Downloads",
      options: {
        filter: false,
        sort: true,
        display: !isRetail,
        download: !isRetail,
      },
    },
    {
      name: "audience",
      label: "Audience",
      options: {
        filter: false,
        sort: false,
        display: isRetail && !isManager,
        print: false,
        download: false,
        customBodyRender: (value, tableMeta) =>
          hasModuleReportPermissions ? (
            <AudienceTooltip
              value={value}
              moduleId={tableMeta.rowData[0]}
              state={tableMeta.rowData[9]}
            />
          ) : (
            <ProFeatureChip />
          ),
      },
    },

    {
      name: "state",
      label: "State",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <ShowState $state={value} small="Yes">
            <span>{value === "In Development" ? "Development" : value}</span>
          </ShowState>
        ),
      },
    },
    {
      name: "actions",
      label: " ",
      options: {
        filter: false,
        sort: false,
        print: false,
        download: false,
        customBodyRender: (_, tableMeta) => (
          <ActionsDropdown
            moduleName={tableMeta.rowData[1]}
            id={tableMeta.rowData[0]}
            moduleState={tableMeta.rowData[9]}
            mutate={mutate}
            setSnackbarText={setSnackbarText}
            userType={userType}
            isRetailerModuleFromMarketplace={
              isRetail && tableMeta.rowData[2] === "Marketplace"
            }
            setModalState={setModalState}
            createdId={tableMeta.rowData[6]?.createdBy?.id || null}
          />
        ),
      },
    },
    {
      name: "image",
      label: "image",
      options: {
        sort: false,
        filter: false,
        download: false,
        display: false,
        print: false,
      },
    },
    {
      name: "marketplaceParentCompanyName",
      label: " ",
      options: {
        sort: false,
        filter: true,
        download: false,
        display: true,
        customBodyRender: () => <div />,
        print: false,
      },
    },
  ];

  const [columns, setColumns] = useState(initialColumns);

  const options = {
    filter: false,
    viewColumns: false,
    elevation: 1,
    selectableRows: "none",
    rowsPerPage: 20,
    print: true,
    sortOrder: {
      name: "created",
      direction: "desc",
    },
    textLabels: {
      body: {
        noMatch:
          "There are no modules to display. Click the Create Module button to get started!",
      },
    },
    isRowSelectable: (dataIndex) => {
      if (modules[dataIndex].state * 1 === 3) return true;
      return false;
    },
    jumpToPage: true,
    rowsPerPageOptions: [5, 10, 20, 50, 100],
    responsive: "standard",
    customToolbar: () => (
      <Button
        size="small"
        variant="outlined"
        style={{
          border: "1px solid #eeeeee",
          height: 28,
          order: -1,
        }}
        onClick={() => setBulkDeleteOpen(true)}
      >
        <span style={{ fontSize: 11, whiteSpace: "nowrap", color: "black" }}>
          Bulk Actions
        </span>
      </Button>
    ),
    downloadOptions: {
      filename: "modules.csv",
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
  };

  options.customSearchRender = (searchText, onSearch, onHide) => (
    <CustomSearchBarForTables
      searchText={searchText}
      onSearch={onSearch}
      onHide={onHide}
    />
  );

  if (isUnder600Px) {
    options.rowsPerPageOptions = [];
    options.jumpToPage = false;
  }

  const onFilterState = ({ target: { value } }) => {
    setStateFilter(value);
    const filteredCols = [...columns];
    const stateValue = STATE_MAP[value];
    if (stateValue === STATE_MAP[6]) {
      filteredCols[9].options.filterList = [];
      return setColumns(filteredCols);
    }
    filteredCols[9].options.filterList = [stateValue];
    return setColumns(filteredCols);
  };

  const onFilterType = ({ target: { value } }) => {
    setTypeFilter(value);
    const filteredCols = [...columns];
    if (value === CONTENT_TYPES.ALL) {
      filteredCols[2].options.filterList = [];
      return setColumns(filteredCols);
    }

    filteredCols[2].options.filterList = [value];
    return setColumns(filteredCols);
  };

  const tableData = modules.map((mod) => ({
    id: mod.id,
    name: mod.name,
    created: mod.created,
    state: STATE_MAP[mod.state],
    isMarketplace: mod.fromMarketplace ? "Marketplace" : "Custom",
    start: mod.startDate
      ? new Date(mod.startDate).toISOString().split("T")[0]
      : null,
    downloads: mod.marketplaceScore || 0,
    moduleInfo: {
      createdBy: mod.userId
        ? {
            access: mod.userJob,
            firstName: mod.userFirstName,
            id: mod.userId,
            lastName: mod.userLastName,
          }
        : null,
      deployedBy: mod.deployedById
        ? {
            access: mod.deployedByJob,
            firstName: mod.deployedByFirstName,
            id: mod.deployedById,
            lastName: mod.deployedByLastName,
          }
        : null,
      marketplaceInfo: mod.marketplaceParentId
        ? {
            id: mod.marketplaceParentId,
            companyLogo: mod.marketplaceParentCompanyLogo,
            companyName: mod.marketplaceParentCompanyName,
            parentName: mod.marketplaceParentName,
          }
        : null,
      state: STATE_MAP[mod.state],
      id: mod.id,
      image: mod.image,
      isRetail,
    },
    audience: mod.has_audience,
    image: mod.image,
    marketplaceParentCompanyName: mod.marketplaceParentId
      ? mod.marketplaceParentCompanyName
      : "",
  }));

  return (
    <RelativeContainer>
      <AbsoluteFilterDiv>
        {isRetail && (
          <CustomSelect
            select
            label="Module Type"
            variant="outlined"
            size="small"
            style={{
              flexBasis: "48%",
              backgroundColor: "white",
            }}
            value={typeFilter}
            onChange={onFilterType}
          >
            <CenterMenuItem value={CONTENT_TYPES.ALL}>
              <span>All</span>
            </CenterMenuItem>
            <CenterMenuItem value={CONTENT_TYPES.CUSTOM}>
              <span>Custom</span>
            </CenterMenuItem>
            <CenterMenuItem value={CONTENT_TYPES.MARKETPLACE}>
              <span>Marketplace</span>
            </CenterMenuItem>
          </CustomSelect>
        )}
        <TextField
          select
          label="State"
          variant="outlined"
          size="small"
          style={{
            flexBasis: "48%",
            marginLeft: "auto",
            backgroundColor: "white",
            zIndex: 2000,
          }}
          value={stateFilter}
          onChange={onFilterState}
        >
          <CenterMenuItem value={6}>All</CenterMenuItem>
          <CenterMenuItem value={3}>
            <ShowState $state={QUIZSTATES.LIVE}>
              <span>Live</span>
            </ShowState>
          </CenterMenuItem>
          <CenterMenuItem value={0}>
            <ShowState $state={QUIZSTATES.IN_DEVELOPMENT}>
              <span>In Development</span>
            </ShowState>
          </CenterMenuItem>
          <CenterMenuItem value={1}>
            <ShowState $state={QUIZSTATES.PENDING}>
              <span>Pending Release</span>
            </ShowState>
          </CenterMenuItem>
          <CenterMenuItem value={2}>
            <ShowState $state={QUIZSTATES.CONVERSION}>
              <span>Content Conversion</span>
            </ShowState>
          </CenterMenuItem>
          {!isRetail && (
            <CenterMenuItem value={5}>
              <ShowState $state={QUIZSTATES.IN_REVIEW}>
                <span>Spiffy Review</span>
              </ShowState>
            </CenterMenuItem>
          )}
          <CenterMenuItem value={4}>
            <ShowState $state={QUIZSTATES.CLOSED}>
              <span>Closed</span>
            </ShowState>
          </CenterMenuItem>
        </TextField>
      </AbsoluteFilterDiv>
      <CustomTabbedDatatable
        title={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <TableTitle title="All Modules" Icon={List} />
          </div>
        }
        data={tableData}
        columns={columns}
        options={options}
        components={{
          // this gets rid of the filter chip
          TableFilterList: () => <span />,
          icons: CustomDataTableIcons,
        }}
      />
      {modalState && (
        <LibraryModals
          modalState={modalState}
          setSnackbarText={setSnackbarText}
          setModalState={setModalState}
          mutate={mutate}
        />
      )}
      <Modal open={bulkDeleteOpen} onClose={() => setBulkDeleteOpen(false)}>
        <div>
          <BulkDeleteModal
            modules={modules}
            mutate={mutate}
            setSnackbarText={setSnackbarText}
            handleCloseModal={() => setBulkDeleteOpen(false)}
          />
        </div>
      </Modal>
    </RelativeContainer>
  );
};

export default LibraryTable;

LibraryTable.propTypes = {
  modules: PropTypes.array.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
  mutate: PropTypes.func.isRequired,
};
