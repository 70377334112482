import React, { useState } from "react";
import styled from "styled-components";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { X } from "react-feather";
import { IconButton, InputAdornment } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers"; // TEST
import { addDays } from "date-fns";
const DateHolder = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 20px;
  gap: 16px;
`;

const SelectedIndicator = styled.div`
  height: 13px;
  width: 13px;
  min-width: 13px;
  min-height: 13px;
  background: ${(props) => (props.$isSelected ? "#337AB7" : "lightgray")};
  margin-right: 8px;
  border-radius: 4px;
`;

const DateFields = ({ startDate, endDate, setStartDate, setEndDate }) => {
  const [startOpen, setStartOpen] = useState(false);
  const [endOpen, setEndOpen] = useState(false);

  const minEndDate = addDays(startDate, 1);
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateHolder>
        <DatePicker
          value={startDate}
          sx={{ width: "100%" }}
          label="Start Date"
          color="primary"
          format="MMM dd yyyy"
          disableHighlightToday
          disablePast
          focused
          open={startOpen}
          onClose={() => setStartOpen(false)}
          slots={{
            inputAdornment: () => null,
          }}
          onAccept={(date) => {
            setStartDate(date);
          }}
          slotProps={{
            textField: {
              size: "small",
              onClick: () => setStartOpen(true),
              InputProps: {
                startAdornment: (
                  <InputAdornment position="start">
                    <SelectedIndicator $isSelected={Boolean(startDate)} />
                  </InputAdornment>
                ),
                endAdornment: startDate ? (
                  <InputAdornment position="end">
                    <IconButton
                      sx={{ zIndex: 100 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setStartDate(null);
                      }}
                    >
                      <X height={12} width={12} />
                    </IconButton>
                  </InputAdornment>
                ) : null,
              },
            },
          }}
        />

        <DatePicker
          value={endDate}
          sx={{ width: "100%" }}
          open={endOpen}
          closeOnSelect
          disablePast
          disableHighlightToday
          format="MMM dd yyyy"
          minDate={minEndDate}
          color="primary"
          onAccept={(date) => {
            setEndDate(date);
          }}
          onClose={() => setEndOpen(false)}
          label="End Date"
          slots={{
            inputAdornment: () => null,
          }}
          slotProps={{
            textField: {
              size: "small",
              onClick: () => setEndOpen(true),
              InputProps: {
                startAdornment: (
                  <InputAdornment position="start">
                    <SelectedIndicator $isSelected={Boolean(endDate)} />
                  </InputAdornment>
                ),
                endAdornment: endDate ? (
                  <InputAdornment position="end">
                    <IconButton
                      sx={{ zIndex: 100 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setEndDate(null);
                      }}
                    >
                      <X height={12} width={12} />
                    </IconButton>
                  </InputAdornment>
                ) : null,
              },
            },
          }}
        />

        {/* <DatePicker
        disableToolbar
        disablePast
        label="Start Date"
        emptyLabel="Start Date"
        fullWidth
        autoOk
        // helperText="*required"
        inputVariant="outlined"
        size="small"
        variant="inline"
        format="yyyy-MM-dd"
        value={startDate}
        onChange={(date) => setStartDate(date)}
        InputProps={{
          startAdornment: (
            <SelectedIndicator $isSelected={Boolean(startDate)} />
          ),
        }}
      /> */}

        {/* <DatePicker
        disablePast
        disableToolbar
        // helperText="*optional"
        label="End Date"
        emptyLabel="End Date (optional)"
        autoOk
        minDate={minEndDate}
        input
        inputVariant="outlined"
        size="small"
        fullWidth
        variant="inline"
        format="yyyy-MM-dd "
        value={endDate}
        onChange={(date) => setEndDate(date)}
        InputProps={{
          startAdornment: (
            <SelectedIndicator $isSelected={Boolean(endDate)} />
          ),
          endAdornment: endDate ? (
            <X
              style={{
                color: "darkgrey",
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.stopPropagation();
                setEndDate(null);
              }}
            />
          ) : (
            <></>
          ),
        }}
      /> */}
      </DateHolder>
    </LocalizationProvider>
  );
};

export default DateFields;
