import React from "react";
import useAudienceToolStore from "../../../../store/useAudienceToolStore";
import AttActionButton from "./AttActionButton";
import { MinusCircle } from "react-feather";
import { TABLE_STATES } from "../helpers";

const ResetButton = () => {
  const handleResetTable = useAudienceToolStore(
    (state) => state.handleResetTable,
  );
  const isLocked = useAudienceToolStore((state) =>
    [TABLE_STATES.LOCKED, TABLE_STATES.SAVING].includes(state.tableState),
  );
  const hasAtLeast1LocationAsOption = useAudienceToolStore(
    (state) => state.locations.length > 0,
  );
  const hasAtLeast1RoleAsOption = useAudienceToolStore(
    (state) => state.roles.length > 0,
  );
  const initialLoad = useAudienceToolStore((state) => state.initialLoad);

  return (
    <AttActionButton
      Icon={MinusCircle}
      handleClick={handleResetTable}
      title="Unselect all"
      disabled={
        isLocked ||
        initialLoad ||
        !hasAtLeast1RoleAsOption ||
        !hasAtLeast1LocationAsOption
      }
    />
  );
};

export default ResetButton;
