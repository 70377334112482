import React, { useContext, useState } from "react";
import { Modal } from "@mui/material";
import { AlertTitle, Alert } from "@mui/material";
import { TrendingUp } from "react-feather";
import { Helmet } from "react-helmet-async";
import LoaderWrapper from "../../../components/Loaders/LoaderWrapper";
import PrivateHeader from "../../../components/PrivateHeader";
import SnackbarWrapper from "../../../components/SnackbarWrapper";
import CreateButton from "../../../components/UI/CreateButton";
import ErrorBlockWithPermissions from "../../../components/UI/ErrorBlockWithPermissions";
import { GlobalState } from "../../../store/GlobalState";
import useStreams from "../../../swr/hooks/Streams/useStreams";
import CreateStream from "./CreateStream";
import StreamsTable from "./StreamsTable";

const Streams = () => {
  const [snackbarText, setSnackbarText] = useState("");
  const [addStreamOpen, setAddStreamOpen] = useState(false);
  const { state, isRetail } = useContext(GlobalState);
  const { read_only: readOnly } = state.config.user;

  const { streams, isLoading, mutate, isError } = useStreams();

  if (isLoading) {
    return (
      <>
        <PrivateHeader header="Streams" Icon={TrendingUp} />
        <LoaderWrapper text="Streams" />;
      </>
    );
  }

  if (isError) {
    <ErrorBlockWithPermissions errorObj={isError} />;
  }

  return (
    <>
      <Helmet title="Spiffy | Streams" />
      <PrivateHeader header="Streams" Icon={TrendingUp} />
      <div
        style={{
          width: "100%",

          display: "flex",
          flexDirection: "column",
          gap: 16,
        }}
      >
        <div style={{ width: "fit-content" }}>
          <CreateButton
            title="Create Stream"
            handleClick={() => setAddStreamOpen(true)}
            disabled={readOnly}
          />
        </div>
        <Alert severity="info">
          <AlertTitle style={{ fontWeight: "600" }}>
            What are streams?
          </AlertTitle>
          {isRetail ? (
            <ul>
              <li>
                Streams help you to organize modules deployed to your team
                members.
              </li>
              <li>
                Streams will be placed vertically in the app feed depending on
                selected feed position.
              </li>
              <li>
                You can use the feed position action button to organize the
                priority level of each stream.
              </li>
            </ul>
          ) : (
            <ul>
              <li>
                Streams help you organize modules deployed to all users. You can
                create custom streams to categorize similar modules together.
                For example, you can categorize all limited-time products in one
                stream and all brand-based modules in another.
              </li>
              <li>Streams are placed vertically in the app feed.</li>
              <li>
                You can use the Feed Position action button to organize the
                priority level of each stream.
              </li>
            </ul>
          )}
        </Alert>

        <StreamsTable
          isRetail={isRetail}
          streams={streams}
          setSnackbarText={setSnackbarText}
          mutate={mutate}
          readOnly={readOnly}
        />
      </div>

      <Modal
        open={addStreamOpen}
        onClose={() => setAddStreamOpen(false)}
        aria-labelledby="Create New Stream"
        aria-describedby="Modal to Create a New Stream"
      >
        <div>
          <CreateStream
            setSnackbarText={setSnackbarText}
            setAddStreamOpen={setAddStreamOpen}
            mutate={mutate}
          />
        </div>
      </Modal>
      <SnackbarWrapper
        snackbarText={snackbarText}
        setSnackbarText={setSnackbarText}
      />
    </>
  );
};

export default Streams;
