import React from "react";
import { Typography } from "@mui/material";
import { Users } from "react-feather";
import { Wrapper } from "./styles";
const CurrentSelectAll = () => (
  <Wrapper>
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      <Users
        style={{
          height: 14,
          width: 14,
          color: "black",
          marginRight: 6,
        }}
      />
      <Typography variant="subtitle2" style={{ color: "black" }}>
        All Team Members
      </Typography>
    </div>
    <span
      style={{
        fontSize: 12,
        fontWeight: "400",
        color: "darkgrey",
        paddingLeft: 20,
      }}
    >
      This module will be deployed to all team members, at all locations.
    </span>
  </Wrapper>
);

export default CurrentSelectAll;
