import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ShowState } from "../pages/private/styles";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 44px;
  margin-bottom: 1px;
`;

const Field = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  flex-direction: column;
`;

const CompanyName = styled.span`
  margin-left: 2px;
  font-size: 11px;
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.grey[500]};
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;

const CustomField = ({ option, companyName, ...props }) => {
  const { fromMarketplace } = option;
  return (
    <Wrapper {...props}>
      <Field>
        <FlexDiv>
          <span
            style={{
              fontWeight: "500",
              whiteSpace: "nowrap",
              maxWidth: 280,
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontSize: 12,
            }}
          >
            {option.name}
          </span>
          <CompanyName>
            {fromMarketplace ? `By: ${option.vendor}` : `By: ${companyName}`}
          </CompanyName>
        </FlexDiv>
      </Field>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ShowState $state={option.state} style={{ marginLeft: 4 }}>
          <span>{option.state}</span>
        </ShowState>
      </div>
    </Wrapper>
  );
};

export default CustomField;

CustomField.propTypes = {
  option: PropTypes.object.isRequired,
  companyName: PropTypes.string.isRequired,
};
