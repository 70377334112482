import {
  formatDistanceToNow,
  subMonths,
  subYears,
  subDays,
  getWeek,
  eachWeekOfInterval,
  min,
  max,
  startOfYear,
  isThisYear,
} from "date-fns";

export const getTotals = (submissions) => {
  if (submissions.length === 0) {
    return {
      totalUniqueModules: 0,
      mostAttempts: 0,
      passedFirstTry: 0,
      firstTryPercentage: 0,
    };
  }

  const totals = {};
  submissions.forEach((submission) => {
    if (submission.quiz_id in totals) {
      totals[submission.quiz_id] += 1;
    } else {
      totals[submission.quiz_id] = 1;
    }
  });

  // Total Unique
  const totalUniqueModules = Object.values(totals).length;
  // See if we can do this without sort--later
  const mostAttempted = Object.entries(totals).sort(
    (prev, next) => next[1] - prev[1],
  )[0];

  // Most Attempted Module
  const answer = submissions.find(
    (submission) => submission.quiz_id == mostAttempted[0],
  );
  const mostAttempts = { name: answer.quiz_name, times: mostAttempted[1] };

  // # Passed First Try
  const passedOnce = Object.entries(totals)
    .filter((entry) => entry[1] === 1)
    .map((arr) => Number(arr[0]));
  const passedSubmissions = submissions.filter(
    (submission) => submission.passed,
  );
  const passedFirstTry = passedSubmissions.filter((submission) =>
    passedOnce.includes(Number(submission.quiz_id)),
  ).length;

  // First Try Percentage
  const firstTryPercentage =
    passedFirstTry < 1
      ? 0
      : Math.floor((passedFirstTry / totalUniqueModules) * 100);
  return {
    totalUniqueModules,
    mostAttempts,
    passedFirstTry,
    firstTryPercentage,
  };
};

export const getDaysSinceLastSubmission = (submissions) => {
  const allDates = submissions.map((sub) => new Date(sub.date || sub.created));
  const lastSubDate = max(allDates);
  return formatDistanceToNow(lastSubDate);
};

export const getDaysSinceLastSubmissionTwo = (lastSubmission) => {
  const lastSubDate = new Date(lastSubmission);
  return formatDistanceToNow(lastSubDate);
};

// Will have to come back to this at some point//
export const getAverageTimeToComplete = (timeStrings) => {
  try {
    const totalMilliseconds = timeStrings.reduce((acc, val) => {
      return (acc += val);
    }, 0);
    const avg = Math.floor(totalMilliseconds / 1000 / timeStrings.length);
    return avg || "n/a";
  } catch (error) {
    return "n/a";
  }
};

export const getAverageScore = (submissions) => {
  let questions = 0;
  let correct = 0;
  let totalSubs = 0;
  let passedSubs = 0;
  submissions.forEach((sub) => {
    totalSubs += 1;

    if (sub?.passed) {
      passedSubs += 1;
    }

    questions += sub.questions;
    correct += sub.score;
  });
  const averageScore =
    correct > 0 ? Math.floor((correct / questions) * 100) : 0;

  const passRate =
    totalSubs === 0 || !totalSubs
      ? 0
      : Math.floor((passedSubs / totalSubs) * 100);

  return {
    averageScore: { questions, correct, averageScore },
    passRate,
  };
};

const getTimeAgo = (timePeriod) => {
  const Today = new Date();
  if (timePeriod === "Month") {
    return subMonths(Today, 1);
  }

  if (timePeriod === "Quarter") {
    return subMonths(Today, 4);
  }

  if (timePeriod === "Week") {
    return subDays(Today, 7);
  }

  if (timePeriod === "Year") {
    return subYears(Today, 1);
  }

  return null;
};

export const getTimeStats = (submissions, timePeriod) => {
  const timeAgo = getTimeAgo(timePeriod);
  const submissionsLastTimePeriod = submissions.filter(
    (submission) => new Date(submission.created) > timeAgo,
  );

  const passedLastTimePeriod = submissionsLastTimePeriod.filter(
    (sub) => sub.passed,
  );
  const { totalUniqueModules, passedFirstTry, firstTryPercentage } = getTotals(
    submissionsLastTimePeriod,
  );
  const { averageScore } = getAverageScore(submissionsLastTimePeriod);

  return {
    totalUniqueModules,
    submissionsLastTimePeriod: submissionsLastTimePeriod.length,
    passedLastTimePeriod: passedLastTimePeriod.length,
    passedFirstTry,
    firstTryPercentage,
    averageScore,
  };
};

export const getSubmissionsByWeekStats = (
  submissions,
  createdInstead = false,
) => {
  const allSubDates = createdInstead
    ? submissions.map((sub) => new Date(sub.created))
    : submissions.map((sub) => new Date(sub.date));
  // used to be one year ago, now resets every year
  const oldestSubDate = min(allSubDates);
  const beginningOfYearDate = startOfYear(new Date());
  const finalDate =
    oldestSubDate > beginningOfYearDate ? oldestSubDate : beginningOfYearDate;
  const weeks = eachWeekOfInterval(
    {
      start: finalDate,
      end: new Date(),
    },
    { weekStartsOn: 1 },
  );
  const weekObj = {};
  weeks.forEach((week) => {
    weekObj[getWeek(week, { weekStartsOn: 1 })] = {
      date: week,
      count: 0,
      passed: 0,
    };
  });

  const filteredThisYear = createdInstead
    ? submissions.filter((sub) => isThisYear(new Date(sub.created)))
    : submissions.filter((sub) => isThisYear(new Date(sub.date)));

  filteredThisYear.forEach((submission) => {
    const weekIndex = createdInstead
      ? getWeek(new Date(submission.created), { weekStartsOn: 1 })
      : getWeek(new Date(submission.date), { weekStartsOn: 1 });
    if (!weekObj.hasOwnProperty(weekIndex)) return;

    weekObj[weekIndex].count += 1;
    if (submission.passed) {
      weekObj[weekIndex].passed += 1;
    }
  });
  return weekObj;
};
