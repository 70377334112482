import React from "react";
import SpiffyScoreSvg from "../../assets/svg/test.svg";

const DisplaySpiffyScore = ({ val, large = false }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: 4,
        padding: "1px 4px",
        borderRadius: 6,
        // background: "#eeeeee60",
        width: "fit-content",
      }}
    >
      {/* <img
        src={SpiffyScoreSvg}
        style={{ height: large ? 15 : 13, width: large ? 15 : 13 }}
        alt="spiffy-score-icon"
      /> */}
      <span
        style={{
          color: "black",
          fontWeight: 400,
          fontSize: 13,
        }}
      >
        {val}
      </span>
    </div>
  );
};

export default DisplaySpiffyScore;
