import { ENGAGEMENT_TYPES } from "../../../../storage/constants";

export const formatDateValue = (dateString) => {
  if (dateString === null) return null;

  return dateString.replace(/-/g, "/");
};

export const parseDateChange = (date) => {
  const newDateString = new Date(
    date.getTime() - date.getTimezoneOffset() * 60000,
  )
    .toISOString()
    .split("T")[0];
  return newDateString;
};

export const retOutstanding = (id, progress) => {
  if (!progress) return "loading";
  if (progress === "ERROR") return "Error Loading";
  const filtered = progress[id];
  if (filtered === undefined) return 0;
  const value = filtered.assigned - filtered.completed_out_of_assigned;
  return value;
};

export const retOutstandingV2 = (id, progress) => {
  if (!progress) return "loading";
  if (progress === "ERROR") return "Error Loading";
  const filtered = progress[id];
  if (filtered === undefined) return 0;
  const value = filtered.assigned - filtered.completed_out_of_assigned;
  return value;
};

export const retComplete = (id, progress) => {
  if (!progress) return "loading";
  if (progress === "ERROR") return "Error Loading";
  const filtered = progress[id];
  if (filtered === undefined) return "Not Available";
  return filtered.completed_out_of_assigned;
};

export const retProgress = (id, progress) => {
  if (!progress) return 0;
  if (progress === "ERROR") return 0;
  const filtered = progress[id];
  if (filtered === undefined) return 0;

  const value =
    filtered.completed_out_of_assigned === 0
      ? 0
      : Math.ceil(
          (filtered.completed_out_of_assigned / filtered.assigned) * 100,
        );
  return value;
};

export const retProgressV2 = (id, progress) => {
  if (!progress) return 0;
  if (progress === "ERROR") return 0;
  const filtered = progress[id];
  if (filtered === undefined) return 0;

  const value =
    filtered.completed_out_of_assigned === 0
      ? 0
      : Math.ceil(
          (filtered.completed_out_of_assigned / filtered.assigned) * 100,
        );
  return value;
};

export const retSubsByEngagementType = (obj, engagementType) => {
  try {
    if (engagementType === ENGAGEMENT_TYPES.ALL) {
      return obj.assigned_submissions + obj.follower_submissions;
    }
    if (engagementType === ENGAGEMENT_TYPES.ASSIGNED) {
      return obj.assigned_submissions;
    }
    return obj.follower_submissions;
  } catch (error) {
    return 0;
  }
};

export const retCompsByEngagementType = (obj, engagementType) => {
  try {
    if (engagementType === ENGAGEMENT_TYPES.ALL) {
      return obj.assigned_completions + obj.follower_completions;
    }
    if (engagementType === ENGAGEMENT_TYPES.ASSIGNED) {
      return obj.assigned_completions;
    }
    return obj.follower_completions;
  } catch (error) {
    return 0;
  }
};

export const retPassRateByEngagementType = (obj, engagementType) => {
  let passRate = 0;

  try {
    if (engagementType === ENGAGEMENT_TYPES.ALL) {
      passRate =
        obj.assigned_submissions + obj.follower_submissions > 0
          ? Math.floor(
              ((obj.assigned_completions + obj.follower_completions) /
                (obj.assigned_submissions + obj.follower_submissions)) *
                100,
            )
          : 0;
    } else if (engagementType === ENGAGEMENT_TYPES.ASSIGNED) {
      passRate =
        obj.assigned_submissions > 0
          ? Math.floor(
              (obj.assigned_completions / obj.assigned_submissions) * 100,
            )
          : 0;
    } else {
      passRate =
        obj.follower_submissions > 0
          ? Math.floor(
              (obj.follower_completions / obj.follower_submissions) * 100,
            )
          : 0;
    }

    return isNaN(passRate) ? 0 : passRate;
  } catch (error) {
    return 0;
  }
};
