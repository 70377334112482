import React from "react";
import { Tool } from "react-feather";
import styled, { keyframes } from "styled-components";

const spin = keyframes`
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const SpinIcon = styled(Tool)`
  transform: rotate(0);
  animation: 1.5s ${spin} ease-out infinite;
  height: 26px;
  width: 26px;
  color: darkgrey;
`;

const FunLoader = () => (
  <div
    style={{
      height: 56,
      width: 56,
      borderRadius: 8,
      background: "#eeeeee44",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      alignSelf: "center",
      marginTop: 12,
    }}
  >
    <SpinIcon color="lightgray" height={26} width={26} />
  </div>
);

export default FunLoader;
