import React from "react";
import PropTypes from "prop-types";
import HorizontalBar from "./HorizontalBar";
import { ENGAGEMENT_TYPES } from "../../../../storage/constants";

const getSubmissionStats = (subs, engagementType) => {
  const employees = {};

  const filteredSubs =
    engagementType === ENGAGEMENT_TYPES.ASSIGNED
      ? subs.filter((sub) => sub.assigned)
      : engagementType === ENGAGEMENT_TYPES.EXTRACREDIT
        ? subs.filter((sub) => !sub.assigned)
        : subs;

  filteredSubs.forEach((sub) => {
    const employeeName = `${sub.first_name} ${sub.last_name}`;
    if (!employees.hasOwnProperty(employeeName)) {
      employees[employeeName] = {
        submissions: 1,
        passed: sub.passed ? 1 : 0,
        failed: !sub.passed ? 1 : 0,
        score: sub.score,
        questions: sub.questions,
        name: employeeName,
      };
    } else {
      employees[employeeName].submissions += 1;
      employees[employeeName].score += sub.score;
      employees[employeeName].questions += sub.questions;
      if (sub.passed) {
        employees[employeeName].passed += 1;
      } else {
        employees[employeeName].failed += 1;
      }
    }
  });
  return employees;
};

const getBestWorst = (employeeObj) => {
  const employeeArr = Object.values(employeeObj).map((employee) => ({
    ...employee,
    passRate: Number(
      ((employee.passed / employee.submissions) * 100).toFixed(2),
    ),
    averageScore: Number(
      ((employee.score / employee.questions) * 100).toFixed(2),
    ),
  }));

  return {
    employeeArr,
  };
};

const SubmissionStats = ({ submissions, engagementType }) => {
  const employees = getSubmissionStats(submissions, engagementType);

  if (Object.keys(employees).length < 3) return null;

  const { employeeArr } = getBestWorst(employees);

  return (
    <HorizontalBar employeeArr={employeeArr} engagementType={engagementType} />
  );
};

export default SubmissionStats;

SubmissionStats.propTypes = {
  submissions: PropTypes.array.isRequired,
};
