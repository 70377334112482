import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { CircularProgress, Typography } from "@mui/material";
import { Filter } from "react-feather";

const NoResultsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 12px;
  padding-bottom: 4px;
  width: 100%;
  height: 220px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 12px;
  height: 50px;
  padding: 12px;
  // border-bottom: 1px solid #eeeeee;
`;

const ShowSearchResults = ({ state }) => {
  const { isLoading, quizzes, searchName, resultCount, stream } = state;

  if (stream) return null;

  if (isLoading) {
    return (
      <Wrapper>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: 110,
            justifyContent: "flex-start",
            background: "white",
            paddingLeft: 8,
            gap: 4,
            borderRadius: 4,
            height: 38,
            border: "1px solid lightgray",
          }}
        >
          <CircularProgress size={18} sx={{ color: "darkgrey" }} />
          <span style={{ fontWeight: "400", color: "darkgrey", marginLeft: 4 }}>
            loading...
          </span>
        </div>
      </Wrapper>
    );
  }

  const hasNoResults = quizzes.length === 0;
  const isSearchByCompany = Boolean(state?.vendorSearchInfo);
  const isSearchByText = searchName?.length > 2;

  if (hasNoResults) {
    return (
      <NoResultsWrapper>
        <div>
          <Typography
            style={{
              fontSize: 16,
              fontWeight: "500",
              color: "darkgrey",
              letterSpacing: 1,
            }}
            align="center"
          >
            -- No Results Found --
          </Typography>
        </div>
      </NoResultsWrapper>
    );
  }

  if (isSearchByCompany || isSearchByText) {
    return (
      <Wrapper>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: 110,
            justifyContent: "flex-start",
            background: "white",
            paddingLeft: 8,
            gap: 4,
            borderRadius: 4,
            height: 38,
            border: "1px solid slategrey",
          }}
        >
          <Filter height={15} width={15} color="black" />
          <span style={{ marginLeft: 4, fontWeight: "600", color: "black" }}>
            {resultCount || state?.quizzes?.length}{" "}
          </span>
          <span style={{ fontWeight: "600", color: "black" }}>results</span>
        </div>
      </Wrapper>
    );
  }

  return null;
};

export default ShowSearchResults;

ShowSearchResults.propTypes = {
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};
