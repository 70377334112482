import React from "react";
import useCompanyLimits from "../../../../swr/hooks/Library/useCompanyLimits";
import LimitsMsg from "../../../../components/LimitsMsg";

const LIMITS_TEXT = {
  RETAIL_LIMIT_REACHED:
    "Your company has reached the limit of LIVE custom modules. You cannot launch new modules until you close existing modules or upgrade your account.",
  RETAIL_LIMIT_NOT_REACHED:
    "Your company allows access to a limited number of custom modules. To add more, close existing custom modules or upgrade your plan.",
  VENDOR_LIMIT_REACHED:
    "Your company is at its limit for custom live modules. All new modules will need to be reviewed by the Spiffy Team before they can go live.",
  VENDOR_LIMIT_NOT_REACHED:
    "Your company allows access to a limited number of custom modules. To add more, close existing custom modules or upgrade your plan.",
};

const LimitsCheck = ({ isRetail = true }) => {
  const { data, isLoading, isError } = useCompanyLimits("custom_modules");

  if (isLoading || isError) return null;

  const limitReached =
    data?.custom_modules?.used >= data?.custom_modules?.limit;

  return (
    <LimitsMsg
      title="Custom Live Modules"
      current={data?.custom_modules?.used}
      limit={data?.custom_modules?.limit}
      explanationText={
        isRetail && limitReached
          ? LIMITS_TEXT.RETAIL_LIMIT_REACHED
          : isRetail && !limitReached
            ? LIMITS_TEXT.RETAIL_LIMIT_NOT_REACHED
            : !isRetail && limitReached
              ? LIMITS_TEXT.VENDOR_LIMIT_REACHED
              : LIMITS_TEXT.VENDOR_LIMIT_NOT_REACHED
      }
    />
  );
};

export default LimitsCheck;
