import { Button, Tooltip } from "@mui/material";
import React from "react";
import { TrendingUp } from "react-feather";
import styled from "styled-components";
import ACTIONS from "./state/actions";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  background: white;
  padding: 6px;
  border: 2px solid #337ab7;
  border-radius: 6px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const ShowStreamResults = ({ stream, streamCompany, dispatch }) => {
  return (
    <Wrapper>
      <div style={{ display: "flex", flexDirection: "row", gap: 6 }}>
        <span style={{ fontWeight: "500", color: "darkgrey", fontSize: 12 }}>
          Stream Name:{" "}
        </span>
        <span>
          <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
            <TrendingUp
              color="#337ab7"
              height={16}
              width={16}
              style={{
                border: "1px solid #eeeeee",
                borderRadius: 4,
                padding: 2,
              }}
            />
            <b style={{ fontWeight: "600", color: "black", fontSize: 13 }}>
              {stream?.name}
            </b>
          </div>
        </span>
      </div>
      <div style={{ width: 20 }} />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 6,
          alignItems: "center",
          justifyContent: "flex-start",
          // border: "1px solid orange",
        }}
      >
        <span
          style={{
            // width: 90,
            fontWeight: "500",
            color: "darkgrey",
            fontSize: 12,
          }}
        >
          Created By:
        </span>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 4,
            // border: "1px solid green",
          }}
        >
          {/* <TrendingUp color="#337ab7" height={12} width={12} /> */}
          <img
            style={{
              height: 17,
              width: 17,
              borderRadius: 4,
              objectFit: "contain",
              background: "white",
              border: "1px solid #eeeeee",
            }}
            src={streamCompany?.logo}
            alt="company"
          />
          <b style={{ fontWeight: "600", color: "black", fontSize: 13 }}>
            {streamCompany?.name}
          </b>
        </div>
      </div>
      {/* <Tooltip arrow title="streams explanation goes right here">
        <Button style={{ marginLeft: "auto" }} variant="text" size="small">
          <span style={{ fontSize: 11 }}>What are streams?</span>
        </Button>
      </Tooltip>
      <div style={{ width: 8 }} /> */}
      <div style={{ marginLeft: "auto" }} />
      <Tooltip title="Clear Filter">
        <Button
          size="small"
          variant="outlined"
          onClick={() => {
            dispatch({ type: ACTIONS.REMOVE_STREAM });
          }}
        >
          Clear
        </Button>
      </Tooltip>
    </Wrapper>
  );
};

export default ShowStreamResults;
