import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, ClickAwayListener } from "@mui/material";
import { Download, DownloadCloud } from "react-feather";
import { CSVLink } from "react-csv";
import API from "../../../../../../axios/instances/API";

const StyledButton = styled(Button)`
  height: 38px;
  width: 38px;
  max-height: 38px;
  max-width: 38px;
  min-height: 38px;
  min-width: 38px;
  border-radius: 8px;
  border: ${(props) =>
    props.$isOpen ? "2px solid #337ab7" : "1px solid lightgray"};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  transition: none;

  svg {
    height: 16px;
    width: 16px;
  }
`;

const Container = styled.div`
  background: #eeeeee;
  width: 135px;
  border-radius: 4px;
  border: 1px solid lightgray;
  display: flex;
  padding: 4px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  bottom: -74px;
  right: 0;
  z-index: 100000000;
  background: white;
  gap: 4px;
`;

const CustomCsvLink = styled(CSVLink)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: white;
  height: 28px;
  border: 1px solid #eeeeee;
  width: 100%;
  padding: 1px 4px;
  border-radius: 4px;
  text-decoration: none;

  span {
    text-decoration: none;
    color: black;
    font-size: 11px;
    font-weight: 600;
    text-align: center;
  }

  svg {
    color: black;
    margin-left: 2px;
    margin-right: 2px;
  }
  &:hover {
    background: #eeeeee55;
  }
`;

const CustomDownload = ({
  tableData,
  buildSearchParamsAsString,
  streamId,
  createTableDataFromSubmissions,
}) => {
  const [open, setOpen] = useState(false);
  const [unpaginatedData, setUnpaginatedData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getUnpaginatedData = async () => {
    setIsLoading(true);

    try {
      const paramsAsStr = buildSearchParamsAsString(true);
      const URL = `/dashboard/analytics/vendor-reports/streams/${streamId}/submissions/?${paramsAsStr}`;
      const { data } = await API.get(URL);
      const formattedForDownload = createTableDataFromSubmissions(
        data?.submissions,
      );
      setUnpaginatedData(formattedForDownload);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!open) {
      setUnpaginatedData([]);
    } else {
      getUnpaginatedData();
    }
  }, [open]);

  return (
    <div style={{ position: "relative" }}>
      <StyledButton $isOpen={open} onClick={() => setOpen(true)}>
        <Download color="black" />
      </StyledButton>
      {open && (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <Container>
            <CustomCsvLink
              data={tableData}
              filename="All-time-submissions-single-page"
            >
              <DownloadCloud height={14} width={14} />
              <div style={{ width: 4 }} />
              <span>Download Page</span>
            </CustomCsvLink>
            <CustomCsvLink
              filename="All-time-submissions"
              disabled={isLoading}
              data={unpaginatedData}
            >
              <DownloadCloud height={14} width={14} />
              <div style={{ width: 4 }} />
              <span>Download All</span>
            </CustomCsvLink>
          </Container>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default CustomDownload;
