import useSWR from "swr";
import API from "../../../axios/instances/API";

const fetcher = async (url) => {
  const { data } = await API.get(url);
  return data.states;
};

const useStatesByCountry = (countryCode) => {
  const { data, error, mutate } = useSWR(
    countryCode
      ? `dashboard/address/states/?country_code=${countryCode}`
      : null,
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalideIfStale: false,
    },
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useStatesByCountry;
