import React, { useState, useEffect } from "react";
import {
  Paper,
  MenuItem,
  CircularProgress,
  Typography,
  Alert,
  Button,
  TextField,
} from "@mui/material";
import styled from "styled-components";
import { Formik } from "formik";

import * as Yup from "yup";
import PropTypes from "prop-types";

import API from "../../../../axios/instances/API";

const Wrapper = styled(Paper)`
  padding: 30px;
  width: 560px;
  text-align: left;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid black;
`;

const WideAlert = styled(Alert)`
  width: 100%;
`;

const Spacer = styled.div`
  height: 16px;
  min-height: 16px;
`;

const validationSchema = Yup.object({
  locationName: Yup.string("Enter your First Name").required(
    "Fist name is required",
  ),
  line1: Yup.string("Enter your Last Name").required("Last name is required"),
  postal: Yup.string().max(7, "Postal Code/Zip should be 6 characters"),
});

const EditLocationDetails = ({
  data,
  readOnly,
  isAdmin,
  setSnackbarText,
  handleClose,
}) => {
  const [countryCodeValue, setCountryCodeValue] = useState("CA");
  const [stateValue, setStateValue] = useState(null);
  const [cityValue, setCityValue] = useState(null);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [locationRequestError, setLocationRequestError] = useState(false);
  const [resetStates, setResetStates] = useState(false);
  const [resetCities, setResetCities] = useState(false);

  useEffect(() => {
    const getStatesAndCities = async () => {
      try {
        const { data: countryData } = await API.get(
          "dashboard/address/countries/",
        );
        const { data: stateData } = await API.get(
          `dashboard/address/states/?country_code=${data.location.address.country.code}`,
        );
        const { data: cityData } = await API.get(
          `dashboard/address/cities/?state_id=${data.location.address.state.id}`,
        );
        // Set possible country/state/city value
        setCountries(countryData.countries);
        setStates(stateData.states);
        setCities(cityData.cities);
        // Set the current value
        setCountryCodeValue(data.location.address.country.code);
        setStateValue(data.location.address.state);
        setCityValue(data.location.address.city);
      } catch (error) {
        setLocationRequestError(true);
      }
    };

    return getStatesAndCities();
  }, []);

  const changeCountry = async (e) => {
    try {
      setResetStates((p) => !p);
      setResetCities((p) => !p);
      setCityValue("");
      setStateValue("");
      setCountryCodeValue(e.target.value);
      const { data: countryData } = await API.get(
        `dashboard/address/states/?country_code=${e.target.value}`,
      );
      setStates(countryData.states);
    } catch (err) {
      setLocationRequestError(true);
    }
  };

  const changeState = async (e) => {
    if (!e.target.value) {
      setCities([]);
      setCityValue("");
      setStateValue("");
      setResetStates((p) => !p);
      return setResetCities((p) => !p);
    }

    try {
      setStateValue(e.target.value);
      setResetCities((p) => !p);
      setCities([]);
      setCityValue("");
      const { data: stateData } = await API.get(
        `dashboard/address/cities/?state_id=${e.target.value}`,
      );
      return setCities(stateData.cities);
    } catch (err) {
      return setLocationRequestError(true);
    }
  };

  const changeCity = (e) => {
    if (!e.target.value) {
      setCityValue("");
      return setResetCities((p) => !p);
    }

    return setCityValue(e.target.value);
  };

  const initialValues = {
    locationName: data.location.name,
    line1: data.location.address.line1,
    line2: data.location.address.line2,
    postal:
      data.location.address.postcode === null
        ? ""
        : data.location.address.postcode,
  };

  const handleEditLocationSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const sendObj = {
      id: data.location.id,
      name: values.locationName,
      line1: values.line1,
      line2: values.line2,
      state_id: stateValue.id,
      city_id: cityValue.id,
      country_code: countryCodeValue,
      postcode: values.postal,
    };
    try {
      await API.put("dashboard/locations/details/", sendObj);
      setSubmitting(false);
      setSnackbarText("Location details updated success");
      return handleClose();
    } catch (err) {
      setSubmitting(false);
      return setSnackbarText("Location details failed to update error");
    }
  };

  return (
    <Wrapper>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Typography variant="h3">Edit Location Details</Typography>
      </div>
      <Typography variant="body1" sx={{ mb: 6 }}>
        Edit inputs and save to change details for this location
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleEditLocationSubmit}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          errors,
          touched,
          isSubmitting,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <div style={{ width: "100%", display: "flex", gap: 12 }}>
              <TextField
                type="text"
                name="locationName"
                label="Location Name"
                value={values.locationName}
                fullWidth
                error={Boolean(touched.locationName && errors.locationName)}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.locationName && errors.locationName}
                autoComplete="new-password"
                variant="outlined"
                size="small"
              />

              <TextField
                type="text"
                name="line1"
                label="Address Line 1"
                value={values.line1}
                fullWidth
                error={Boolean(touched.line1 && errors.line1)}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.line1 && errors.line1}
                autoComplete="new-password"
                variant="outlined"
                size="small"
              />
            </div>
            <Spacer />
            <div style={{ width: "100%", display: "flex", gap: 12 }}>
              <TextField
                type="text"
                name="line2"
                label="Address Line 2"
                value={values.line2}
                fullWidth
                error={Boolean(touched.line2 && errors.line2)}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.line2 && errors.line2}
                autoComplete="new-password"
                variant="outlined"
                size="small"
              />

              <TextField
                type="text"
                name="postal"
                label="Postal Code/ ZIP"
                value={values.postal}
                fullWidth
                error={Boolean(touched.postal && errors.postal)}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.postal && errors.postal}
                autoComplete="new-password"
                variant="outlined"
                size="small"
              />
            </div>
            <Spacer />
            <div
              style={{
                width: "100%",
                display: "flex",
                gap: 12,
                alignItems: "stretch",
                justifyContent: "stretch",
              }}
            >
              <TextField
                select
                label="Country"
                value={countryCodeValue}
                onChange={changeCountry}
                fullWidth
                size="small"
                variant="outlined"
              >
                {countries.length > 0 ? (
                  countries.map((country) => (
                    <MenuItem key={country.code} value={country.code}>
                      {country.code}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">None</MenuItem>
                )}
              </TextField>
              <TextField
                select
                label="State"
                value={stateValue}
                onChange={changeState}
                fullWidth
                size="small"
                variant="outlined"
              >
                {states.length > 0 ? (
                  states.map((state) => (
                    <MenuItem key={state.code} value={state.id}>
                      {state.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">None</MenuItem>
                )}
              </TextField>
              <TextField
                select
                label="City"
                value={cityValue}
                onChange={changeCity}
                fullWidth
                size="small"
                variant="outlined"
              >
                {cities.length > 0 ? (
                  cities.map((city) => (
                    <MenuItem key={city.id} value={city.id}>
                      {city.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">None</MenuItem>
                )}
              </TextField>
            </div>

            <Button
              sx={{ mt: 6 }}
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              disabled={
                isSubmitting ||
                readOnly ||
                !isAdmin ||
                !countryCodeValue ||
                !cityValue ||
                !stateValue
              }
              startIcon={isSubmitting && <CircularProgress size={20} />}
            >
              {isSubmitting ? "Submitting" : "Save"}
            </Button>
            {locationRequestError && (
              <WideAlert
                sx={{ mt: 2 }}
                severity="error"
                onClose={() => setLocationRequestError(false)}
                fullWidth
              >
                Error loading location data. Please refresh and try again.
              </WideAlert>
            )}
          </form>
        )}
      </Formik>
    </Wrapper>
  );
};

export default EditLocationDetails;

EditLocationDetails.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};
