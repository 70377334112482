import React from "react";
import { Paper } from "@mui/material";
import styled from "styled-components";
import AudienceTool from "./AudienceTool";
import MockHeader from "./AudienceTool/MockHeader";

const ModalWrapper = styled(Paper)`
  padding: 20px;
  width: calc(min(940px, 96vw));
  max-height: 90vh;
  position: absolute;
  left: 50%;
  height: fit-content;
  min-height: 460px;
  overflow-y: scroll;
  border: 2px solid black;
  top: 50%;
  transform: translate(-50%, -50%);
  padding-bottom: 60px;
`;

const EditAudienceModalV2 = ({ id, moduleName }) => {
  return (
    <ModalWrapper>
      <MockHeader title="Edit Module Audience" secondary={moduleName} />
      <AudienceTool moduleId={id} />
    </ModalWrapper>
  );
};

export default EditAudienceModalV2;
