import React from "react";
import { Edit } from "react-feather";
import { useHistory } from "react-router-dom";
import { StyledItem } from "../../../styles";
import { QUIZSTATES, USER_TYPES } from "../../../../../storage/constants";

const EditLiveModule = ({
  moduleState,
  userType,
  id,
  isRetailerModuleFromMarketplace,
  isUserAManagerWhoCreatedModule,
}) => {
  const history = useHistory();

  if (moduleState !== QUIZSTATES.LIVE) {
    return null;
  }

  if (isRetailerModuleFromMarketplace) return null;

  if (
    ![
      USER_TYPES.RETAIL_ADMIN,
      USER_TYPES.VENDOR,
      USER_TYPES.RETAIL_MANAGER_CAN_DEPLOY,
    ].includes(userType)
  )
    return null;

  if (
    userType === USER_TYPES.RETAIL_MANAGER_CAN_DEPLOY &&
    !isUserAManagerWhoCreatedModule
  ) {
    return null;
  }

  const handleModify = () => {
    history.push({
      pathname: `/modules/quiz-builder/${id}`,
      state: {
        modifyLive: true,
      },
    });
  };

  return (
    <StyledItem onClick={handleModify}>
      <Edit />
      <span>Edit Module</span>
    </StyledItem>
  );
};

export default EditLiveModule;
