import { useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { Download, User } from "react-feather";
import CustomDataTableIcons from "../../../../../components/Table/CustomDataTableIcons";
import SubmissionResultIndicator from "../../../../../components/Table/SubmissionResultIndicator";
import TableTitle from "../../../../../components/Table/TableTitle";
import { ENGAGEMENT_TYPES } from "../../../../../storage/constants";
import { makeDefaultTableOptions } from "../../../../../storage/helpers";
import useSingleCompanySubmissions from "../../../../../swr/hooks/VendorReports/useSingleCompanySubmissions";
import { CustomDatatableV2 } from "../../../styles";
import SubmissionChipExtraCreditTooltip from "src/components/UI/SubmissionChipExtraCreditTooltip";
import SubmissionChipAssignedTooltip from "src/components/UI/SubmissionChipAssignedTooltip";
import DateTooltip from "../../../../../components/Table/DateTooltip";
import DisplayScore from "../../../../../components/UI/DisplayScore";
import StyledLink from "src/components/UI/StyledLink";

const getTableData = (submissions, engagementType) => {
  const filteredSubmissions =
    engagementType === ENGAGEMENT_TYPES.ASSIGNED
      ? submissions.filter((sub) => !sub.is_follower_submission)
      : engagementType === ENGAGEMENT_TYPES.EXTRACREDIT
        ? submissions.filter((sub) => sub.is_follower_submission)
        : submissions;
  return filteredSubmissions.map((submission) => {
    return {
      id: submission.id,
      date: submission.created,
      userId: submission.user_id,
      // name: `${submission.first_name} ${submission.last_initial}`,
      name: submission.users_name,
      module: submission.quiz_name,
      type: submission.is_follower_submission
        ? ENGAGEMENT_TYPES.EXTRACREDIT
        : ENGAGEMENT_TYPES.ASSIGNED,
      score: `${submission.score}/${submission.questions}`,
      passed: submission.passed ? "Yes" : "No",
    };
  });
};

const SubmissionsTable = ({ companyId, companyName, engagementType }) => {
  const isUnder600Px = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const { isLoading, isError, data } = useSingleCompanySubmissions(companyId);

  if (isLoading) return <span>loading...</span>;

  if (isError) return null;

  const options = makeDefaultTableOptions(`${companyName}-submissions`);
  options.sortOrder = {
    name: "date",
    direction: "desc",
  };
  options.rowsPerPage = 20;

  if (isUnder600Px) {
    options.rowsPerPageOptions = [];
    options.jumpToPage = false;
  }

  const tableData = getTableData(data.submissions, engagementType);

  const columns = [
    {
      name: "id",
      label: " ",
      options: {
        filter: false,
        sort: false,
        display: false,
        print: false,
        download: false,
      },
    },
    {
      name: "userId",
      label: " ",
      options: {
        filter: false,
        sort: false,
        display: false,
        print: false,
        download: false,
      },
    },
    {
      name: "date",
      label: "Date",
      options: {
        filter: true,
        customBodyRender: (val) => (
          <DateTooltip displayYear shouldLocalize date={val} />
          // <span>{formatDateWithTime(new Date(val))}</span>
        ),
      },
    },
    {
      name: "name",
      label: "User",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => (
          <StyledLink
            value={value}
            to={`/vendor-reports/user/${tableMeta.rowData[1]}`}
            CustomIcon={User}
          />
        ),
      },
    },
    {
      name: "module",
      label: "Module",
    },
    {
      name: "type",
      label: "Submission Type",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (val) =>
          val === ENGAGEMENT_TYPES.ASSIGNED ? (
            <SubmissionChipAssignedTooltip />
          ) : (
            <SubmissionChipExtraCreditTooltip isVendor />
          ),
      },
    },
    {
      name: "score",
      label: "Score",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => <DisplayScore value={value} />,
      },
    },
    {
      name: "passed",
      label: "Result",
      options: {
        display: true,
        download: true,
        print: true,
        customBodyRender: (value) => (
          <SubmissionResultIndicator value={value} />
        ),
      },
    },
  ];

  return (
    <CustomDatatableV2
      title={
        <TableTitle
          title="Submissions"
          Icon={Download}
          isVendor
          submissionType={engagementType}
        />
      }
      options={options}
      columns={columns}
      data={tableData}
      components={{
        icons: CustomDataTableIcons,
      }}
    />
  );
};

export default SubmissionsTable;

SubmissionsTable.propTypes = {
  companyId: PropTypes.number.isRequired,
  companyName: PropTypes.string.isRequired,
  engagementType: PropTypes.string.isRequired,
};
