import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import {
  CircularProgress,
  Paper,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import styled from "styled-components";
import { GlobalState } from "../../store/GlobalState";
import API from "../../axios/instances/API";
import ACTIONS from "../../store/actions";
import { mutate } from "swr";

const Wrapper = styled(Paper)`
  padding: 20px;
  width: 500px;
  text-align: left;
  max-width: 520px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid black;
`;

const EditCompanyName = ({ handleClose, setSnackbarText }) => {
  const { state, dispatch } = useContext(GlobalState);
  const { config } = state;
  const { read_only: readOnly } = config?.user;
  const [name, setName] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);

  const getConfig = async () => {
    try {
      const { data } = await API.get("dashboard/account/config/");
      return data;
    } catch (error) {
      throw new Error("Failed to load the config object");
    }
  };

  const handleUpdate = async () => {
    setIsUpdating(true);
    try {
      await API.put("dashboard/company/details/", {
        name,
      });
      const data = await getConfig();
      dispatch({ type: ACTIONS.UPDATE_CONFIG, payload: data });
      mutate("dashboard/company/details/");
      setSnackbarText("Company name updated success");
      setIsUpdating(false);
      handleClose();
    } catch (error) {
      setSnackbarText(
        "There was an issue updating company name.. please try again. error",
      );
      setIsUpdating(false);
    }
  };

  return (
    <Wrapper>
      <Typography variant="h4" sx={{ mb: 4 }}>
        Edit Company Name
      </Typography>
      <TextField
        value={name}
        onChange={(e) => setName(e.currentTarget.value)}
        size="small"
        fullWidth
        sx={{ mb: 3 }}
        label="Enter new company name..."
        variant="outlined"
      />
      <Button
        variant="contained"
        color="primary"
        disabled={name.length < 2 || readOnly || isUpdating}
        fullWidth
        onClick={handleUpdate}
        startIcon={isUpdating ? <CircularProgress size={20} /> : null}
      >
        {isUpdating ? "Saving" : "Save"}
      </Button>
    </Wrapper>
  );
};

export default EditCompanyName;

EditCompanyName.propTypes = {
  handleClose: PropTypes.func.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
};
