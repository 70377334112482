import { useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { UserPlus } from "react-feather";
import CustomDataTableIcons from "../../../../components/Table/CustomDataTableIcons";
import DateTooltip from "../../../../components/Table/DateTooltip";
import TableTitle from "../../../../components/Table/TableTitle";
import { makeDefaultTableOptions } from "../../../../storage/helpers";
import { CustomDatatableV2, ReportLink } from "../../styles";

const columns = [
  {
    name: "id",
    label: "",
    options: {
      display: false,
    },
  },
  {
    name: "joined",
    label: "Date",
    options: {
      filter: true,
      sort: true,
      print: true,
      download: true,
      customBodyRender: (value) => <DateTooltip date={value} />,
    },
  },
  {
    name: "name",
    label: "Name",
    options: {
      filter: true,
      sort: true,
      print: true,
      download: true,
      customBodyRender: (value, tableMeta) => {
        const id = tableMeta?.rowData[0];

        if (id) {
          return (
            <ReportLink to={`/teammembers/detail/${id}`}>{value}</ReportLink>
          );
        }
        return <span>{value}</span>;
      },
    },
  },

  {
    name: "email",
    label: "Email",
    options: {
      filter: true,
      sort: true,
      print: true,
      download: true,
    },
  },
  {
    name: "location",
    label: "Location",
    options: {
      filter: true,
      sort: true,
      download: true,
      print: true,
    },
  },
];

const NewMembers = ({ members }) => {
  const isUnder600Px = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const options = makeDefaultTableOptions("new-team-members");
  options.sortOrder = {
    name: "joined",
    direction: "desc",
  };
  options.rowsPerPage = 10;

  if (isUnder600Px) {
    options.rowsPerPageOptions = [];
    options.jumpToPage = false;
  }

  const tableData = members.map((member) => ({
    id: member?.id,
    name: `${member?.first_name} ${member?.last_name}`,
    joined: new Date(member?.created).toISOString(),
    email: member?.email || "",
    location: member?.location_name,
  }));

  return (
    <CustomDatatableV2
      title={
        <div>
          <TableTitle Icon={UserPlus} title="New Team Members" />
        </div>
      }
      data={tableData}
      columns={columns}
      options={options}
      components={{
        icons: CustomDataTableIcons,
      }}
    />
  );
};

export default NewMembers;

NewMembers.propTypes = {
  members: PropTypes.array.isRequired,
  isRetailHomepage: PropTypes.bool.isRequired,
};
