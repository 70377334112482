import useSWR from "swr";
import API from "../../../axios/instances/API";
import { getClientTimezone } from "../../../storage/helpers";

const { timezoneQueryStr } = getClientTimezone();

const BASE_URL = `/dashboard/analytics/vendor-reports/engaged-users/${timezoneQueryStr}`;

const fetcher = async (url) => {
  const { data } = await API.get(url);
  return data;
};

const useEngagedUsers = () => {
  const { data, error, mutate } = useSWR(BASE_URL, fetcher, {
    revalidateOnFocus: false,
  });

  return {
    followers: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useEngagedUsers;
