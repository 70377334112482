import React from "react";
import styled from "styled-components";
import OptionItem from "./OptionItem";

const OptionWrapper = styled.div`
  min-height: 150px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 4px;
  // border: 1px solid red;
`;

const Wrapper = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;

const MultipleChoice = ({ selectedQuestion }) => {
  const { options } = selectedQuestion || {};

  return (
    <Wrapper>
      <span style={{ fontSize: 9, fontWeight: "300", paddingLeft: 2 }}>
        Multiple Choice
      </span>
      <span style={{ fontSize: 9, color: "darkgrey", paddingLeft: 2 }}>
        Select most appropriate answer
      </span>
      <div style={{ height: 12 }} />
      <OptionWrapper>
        {["A", "B", "C", "D"].map((letter) => (
          <OptionItem
            letter={letter}
            text={options[letter.toLowerCase()]}
            key={letter}
          />
        ))}
      </OptionWrapper>
    </Wrapper>
  );
};

export default MultipleChoice;
