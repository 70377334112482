import React, { useState, useContext, lazy, Suspense } from "react";
import { Helmet } from "react-helmet-async";
import { Modal, Button } from "@mui/material";
import { MapPin, Plus } from "react-feather";
import PrivateHeader from "../../../components/PrivateHeader";
import SnackbarWrapper from "../../../components/SnackbarWrapper";
import CreateNewLocation from "./CreateNewLocation";
import LocationsDataTable from "./LocationsDatatable";
import useLocations from "../../../swr/hooks/Locations/useLocations";
import LoaderWrapper from "../../../components/Loaders/LoaderWrapper";
import { GlobalState } from "../../../store/GlobalState";
import OnLoadError from "../../../components/UI/OnLoadError";
import DeleteLocationModal from "./DeleteLocationModal";
import CreateButton from "../../../components/UI/CreateButton";
import LimitsMsg from "../../../components/LimitsMsg";

const CreateLocationsViaCsv = lazy(() => import("./CreateLocationsViaCsv"));

const Locations = () => {
  const { isLoading, locations, mutate, isError } = useLocations();
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [deleteInfo, setDeleteInfo] = useState(null);
  const [snackbarText, setSnackbarText] = useState("");
  const [csvModalOpen, setCsvModalOpen] = useState(false);
  const { state } = useContext(GlobalState);

  const { config, job } = state;
  const { read_only: readOnly } = config.user;
  const isManager = job === "M";
  const { permissions } = config?.current_company;

  if (isLoading) {
    return (
      <>
        <PrivateHeader header="Locations" Icon={MapPin} />
        <LoaderWrapper text="Locations" />
      </>
    );
  }

  if (isError) {
    return <OnLoadError />;
  }

  return (
    <>
      <Helmet title="Spiffy | Locations" />
      <PrivateHeader header="Locations" Icon={MapPin}>
        <LimitsMsg
          title="Locations"
          limit={permissions?.fields?.location_limit}
          current={locations?.locations?.length}
          explanationText={`Your account allows up to ${permissions?.fields?.location_limit} locations. To add more, remove existing locations or upgrade your plan.`}
        />
      </PrivateHeader>
      <div
        style={{
          width: "fit-content",
          display: "flex",

          gap: 6,
          alignItems: "flex-start",
          justifyContent: "flex-start",
        }}
      >
        <CreateButton
          title="Create Location"
          handleClick={() => setCreateModalOpen(true)}
          disabled={readOnly || isManager}
        />
        <Button
          variant="outlined"
          color="inherit"
          disabled={readOnly || isManager}
          style={{ height: 38, borderRadius: 4 }}
          startIcon={<Plus height={14} width={14} />}
          onClick={() => setCsvModalOpen(true)}
        >
          Create via CSV
        </Button>
      </div>
      <div style={{ height: 18 }} />

      <LocationsDataTable
        setDeleteInfo={setDeleteInfo}
        readOnly={readOnly}
        isManager={isManager}
        setSnackbarText={setSnackbarText}
        teamData={{
          id: config?.current_company?.id,
          name: config?.current_company?.name,
        }}
      />

      <Modal
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        aria-labelledby="Create new location"
        aria-describedby="modal to create new location"
      >
        <div>
          <CreateNewLocation
            setCreateModalOpen={setCreateModalOpen}
            setSnackbarText={setSnackbarText}
          />
        </div>
      </Modal>
      <Modal
        open={Boolean(deleteInfo)}
        onClose={() => setDeleteInfo(null)}
        aria-labelledby="Delete a location"
        aria-describedby="modal to delete a location"
      >
        <div>
          <DeleteLocationModal
            deleteInfo={deleteInfo}
            setDeleteInfo={setDeleteInfo}
            setSnackbarText={setSnackbarText}
            mutate={mutate}
            args={{
              id: deleteInfo ? deleteInfo.id : null,
            }}
          />
        </div>
      </Modal>
      <Modal
        open={csvModalOpen}
        onClose={() => {
          mutate();
          setCsvModalOpen(false);
        }}
        aria-labelledby="Create locations via Csv"
        aria-describedby="Modal to create locations via Csv"
      >
        <div>
          <Suspense fallback={<span />}>
            <CreateLocationsViaCsv
              setCsvModalOpen={setCsvModalOpen}
              mutate={mutate}
            />
          </Suspense>
        </div>
      </Modal>
      <SnackbarWrapper
        snackbarText={snackbarText}
        setSnackbarText={setSnackbarText}
      />
    </>
  );
};

export default Locations;
