import { Collapse, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import { ArrowDownCircle, ArrowUpCircle } from "react-feather";
import styled from "styled-components";
import { CustomLinearProgressMain } from "../../../styles";
import ProgressByLocation from "./ProgressByLocation";

const Wrapper = styled(Paper)`
  padding: 20px;
  width: 100%;
  min-height: 80px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  border-radius: 6px;
`;

const AttemptsSpan = styled.span`
  font-size: 12px;
  color: ${(props) => props.theme.palette.grey[500]};
  font-weight: 600;
  display: block;
`;

const UnderlineButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  cursor: pointer;
  background: transparent;
  border-radius: 4px;
  padding: 2px 6px;

  &:hover {
    background: #eeeeee90;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -4px;
  margin-bottom: 4px;
  width: 100%;

  span {
    color: black;
    font-weight: 800;
    font-size: 18px;
  }
`;

const ProgressHero = ({ locationProgress, assignedUsers, moduleName }) => {
  const [collapsed, setCollapsed] = useState(true);

  const completions = assignedUsers.filter((u) => u.completed);

  const progressValue =
    !assignedUsers || assignedUsers.length === 0
      ? 0
      : Math.ceil((completions.length / assignedUsers.length) * 100);

  return (
    <Wrapper>
      <TopWrapper>
        <Typography style={{ fontSize: 16, fontWeight: "600" }}>
          Module Progress
        </Typography>
        <span>{progressValue}%</span>
      </TopWrapper>
      <CustomLinearProgressMain variant="determinate" value={progressValue} />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          marginTop: 4,

          marginBottom: -3,
        }}
      >
        <AttemptsSpan>
          {`${completions.length}/${assignedUsers.length} assigned team members have completed this module`}
        </AttemptsSpan>
        {locationProgress.length >= 1 && (
          <UnderlineButton onClick={() => setCollapsed((p) => !p)}>
            {collapsed ? (
              <ArrowDownCircle height={12} width={12} color="#337ab7" />
            ) : (
              <ArrowUpCircle height={12} width={12} color="#337ab7" />
            )}
            <span style={{ color: "#337ab7", fontWeight: "500", fontSize: 11 }}>
              {collapsed ? "View" : "Hide"} By Location
            </span>
          </UnderlineButton>
        )}
      </div>
      <Collapse in={!collapsed} unmountOnExit style={{ width: "100%" }}>
        <ProgressByLocation
          locationProgress={locationProgress}
          moduleName={moduleName}
        />
      </Collapse>
    </Wrapper>
  );
};

export default ProgressHero;
