import React from "react";
import styled from "styled-components";
import { CustomDatatableV2 } from "../../../styles";
import TableTitle from "../../../../../components/Table/TableTitle";
import { makeDefaultTableOptions } from "../../../../../storage/helpers";
import CustomDataTableIcons from "../../../../../components/Table/CustomDataTableIcons";
import { Users } from "react-feather";
import ProgBarMini from "../../../home/retail/ProgBarMini";
import VendorShowTeams from "../../../../../components/UI/VendorShowTeams";
import { Alert } from "@mui/material";
import StyledLink from "src/components/UI/StyledLink";

const KeyStatBlock = styled.div`
  flex: 1;
  height: 100%;
  background: white;
  border-radius: 8px;
  border: 1px solid gainsboro;
  padding: 12px 18px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  span {
    font-size: 30px;
    font-weight: 800;
    color: black;
    line-height: 1.2;
  }

  p {
    font-size: 13px;
    color: slategrey;
    font-weight: 500;
  }
`;

const KeyStatsContainer = styled.div`
  height: 80px;
  width: 100%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;

const options = makeDefaultTableOptions("engaged-users");

const EngagedUsersTable = ({ users, numLiveModules }) => {
  const columns = [
    {
      name: "id",
      label: " ",
      options: {
        display: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        customBodyRender: (value, tableMeta) => (
          <StyledLink
            value={value}
            to={`/vendor-reports/user/${tableMeta.rowData[0]}`}
          />
        ),
      },
    },
    {
      name: "teamMembers",
      label: "Team",
      options: {
        customBodyRender: (val) => (
          <VendorShowTeams shouldLinkToReport teamsArray={val} />
        ),
      },
    },
    {
      name: "completed",
      label: "Modules Completed",
    },
    {
      name: "progress",
      label: "Progress",
      options: {
        customBodyRender: (val) => (
          <div style={{ display: "flex", gap: 4, width: 190 }}>
            <ProgBarMini progress={val} />
            <span
              style={{
                whiteSpace: "nowrap",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 4,
                fontSize: 11,
                fontWeight: 600,
                color: "darkgrey",
              }}
            >
              {val}%
            </span>
          </div>
        ),
      },
    },
  ];

  const tableData = users?.map((user) => ({
    id: user?.user_id,
    name: `${user?.first_name} ${user?.last_initial}`,
    completed: user?.completed_quizzes_in_stream,
    teamMembers: user?.team_member_info,
    progress: user?.progress_on_live_quizzes_in_stream * 100,
  }));

  const totalUsers = users?.length;

  const usersCompletedAll = users?.filter(
    (u) => u?.progress_on_live_quizzes_in_stream === 1,
  )?.length;

  // const conversionRate =
  //   totalUsers === 0 ? 0 : (usersCompletedAll / totalUsers) * 100;

  return (
    <div
      style={{
        padding: 12,
        background: "#eeeeee",
        borderRadius: 6,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <KeyStatsContainer>
        <KeyStatBlock>
          <span>{totalUsers}</span>
          <p>
            {totalUsers === 1 ? "User has" : "Users have"} completed at least 1
            module.
          </p>
        </KeyStatBlock>
        <KeyStatBlock>
          <span>{usersCompletedAll}</span>
          <p>
            {usersCompletedAll === 1 ? "User has" : "Users have"} completed all
            the live modules.
          </p>
        </KeyStatBlock>
        <KeyStatBlock>
          <span>{numLiveModules}</span>
          <p>Total Live Modules</p>
        </KeyStatBlock>
      </KeyStatsContainer>
      <div style={{ height: 12 }} />
      <CustomDatatableV2
        title={<TableTitle Icon={Users} isVendor title="Engaged Users" />}
        options={options}
        columns={columns}
        data={tableData}
        components={{
          icons: CustomDataTableIcons,
        }}
      />
      <div style={{ height: 12 }} />
      <Alert
        severity="info"
        style={{
          border: "1px solid gainsboro",
          borderRadius: 6,
          background: "white",
        }}
      >
        <p>
          <strong>Progress </strong>
          is the percentage of total <strong>live</strong> modules in the stream
          completed by the user.
        </p>
      </Alert>
    </div>
  );
};

export default EngagedUsersTable;
