import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { CircularProgress, Typography, TextField, Button } from "@mui/material";
import { Wrapper } from "../../styles";
import API from "../../../../axios/instances/API";
import { FEEDBACK_MESSAGES } from "../../../../storage/constants";

const EditStreamName = ({ name, setSnackbarText, mutate, id, readOnly }) => {
  const [prevName, setPrevName] = useState(name);
  const [newName, setNewName] = useState(name);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setPrevName(name);
  }, [name]);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      await API.put("dashboard/streams/details/", {
        id,
        name: newName.trim(),
      });
      setIsSubmitting(false);
      mutate();
      setSnackbarText("Successfully updated stream name success");
    } catch (error) {
      setIsSubmitting(false);
      setSnackbarText(FEEDBACK_MESSAGES.GENERIC_ERROR.concat(" error"));
    }
  };

  return (
    <Wrapper style={{ flex: 1 }}>
      <Typography variant="h3">Edit Stream Name</Typography>
      <Typography variant="subtitle1" sx={{ mb: 10 }}>
        You can change your stream name here
      </Typography>
      <TextField
        value={newName}
        onChange={(e) => setNewName(e.target.value)}
        size="small"
        variant="outlined"
        fullWidth
        label="Stream Name"
        sx={{ mb: 4 }}
      />
      <Button
        color="primary"
        variant="contained"
        fullWidth
        disabled={newName.length < 3 || newName === prevName || readOnly}
        startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
        onClick={handleSubmit}
      >
        {isSubmitting ? "Saving Stream Name!" : "Save Stream Name"}
      </Button>
    </Wrapper>
  );
};

export default EditStreamName;

EditStreamName.propTypes = {
  setSnackbarText: PropTypes.func.isRequired,
  mutate: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  readOnly: PropTypes.bool.isRequired,
};
