import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "@mui/material";
import styled from "styled-components";
import { MapPin } from "react-feather";
import StyledLink from "./StyledLink";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
  padding: 6px;
  border-radius: 8px;

  &:hover {
    cursor: pointer;
    background-color: #eeeeee;
  }
`;

const LocationTooltip = ({ location }) => (
  <Tooltip
    title={<span style={{ color: "black" }}>{location}</span>}
    arrow
    placement="top"
    componentsProps={{
      tooltip: {
        sx: {
          backgroundColor: "white",
          border: "1px solid slategrey",
          maxHeight: "none",
        },
      },
    }}
  >
    <Container>
      <MapPin color="darkgrey" height={11} width={11} />
    </Container>
  </Tooltip>
);

const VendorShowTeams = ({ teamsArray, shouldLinkToReport = false }) => {
  if (!teamsArray) {
    return (
      <span style={{ color: "firebrick", fontSize: 12 }}>No Active Team</span>
    );
  }

  if (teamsArray.length === 1) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          width: "fit-content",
        }}
      >
        {shouldLinkToReport ? (
          <StyledLink
            to={`/vendor-reports/company/${teamsArray[0].company_id}`}
            value={teamsArray[0].company || teamsArray[0]?.company_name}
          />
        ) : (
          <span style={{ fontSize: 12, whiteSpace: "nowrap" }}>
            {teamsArray[0].company || teamsArray[0]?.company_name}
          </span>
        )}
        <LocationTooltip
          location={teamsArray[0].location || teamsArray[0]?.location_name}
        />
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: 1,
      }}
    >
      <span style={{ fontSize: 12, whiteSpace: "nowrap" }}>
        {teamsArray[0].company || teamsArray[0]?.company_name}
      </span>
      <LocationTooltip
        location={teamsArray[0]?.location || teamsArray[0]?.location_name}
      />

      <Tooltip
        arrow
        componentsProps={{
          // TODO -> move this into the overrides styles & remove from all these tooltip components
          tooltip: {
            sx: {
              backgroundColor: "white",
              border: "1px solid slategrey",
              maxHeight: "none",
              maxWidth: 800,
            },
          },
        }}
        title={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              overflow: "hidden",
            }}
          >
            {teamsArray.slice(1, teamsArray.length).map((v, i) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                key={i}
              >
                <span style={{ whiteSpace: "nowrap", color: "black" }}>
                  {v?.company || v?.company_name}
                </span>
                <span
                  style={{
                    whiteSpace: "nowrap",
                    marginLeft: 6,
                    color: "darkgrey",
                  }}
                >
                  ({v?.location || v?.location_name})
                </span>
              </div>
            ))}
          </div>
        }
      >
        <span
          style={{
            fontSize: 10,
            color: "black",
            textDecoration: "underline",
            fontWeight: 600,
            cursor: "pointer",
            whiteSpace: "nowrap",
          }}
        >
          +{teamsArray.length - 1} more
        </span>
      </Tooltip>
    </div>
  );
};

export default VendorShowTeams;

VendorShowTeams.propTypes = {
  teamsArray: PropTypes.array.isRequired,
};
