import React from "react";
import { MenuItem, Select, FormControl } from "@mui/material";

const CompanyFilter = ({
  selectedCompany,
  setSelectedCompany,
  companiesArray,
}) => {
  return (
    <FormControl style={{ width: 200 }}>
      <Select
        size="small"
        margin="dense"
        variant="outlined"
        color="secondary"
        placeholder="All Companies"
        value={selectedCompany}
        onChange={(e) => setSelectedCompany(e.target.value)}
        style={{ minWidth: 180, borderRadius: 6, height: 40 }}
      >
        <MenuItem value={0}>All Companies</MenuItem>

        {companiesArray?.map((c) => (
          <MenuItem size="small" key={c.id} value={c.id}>
            {c.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CompanyFilter;
