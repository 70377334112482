import { useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { Users } from "react-feather";
import CustomDataTableIcons from "../../../../components/Table/CustomDataTableIcons";
import TableTitle from "../../../../components/Table/TableTitle";
import { ENGAGEMENT_TYPES } from "../../../../storage/constants";
import { makeDefaultTableOptions } from "../../../../storage/helpers";
import useSingleLocationProgress from "../../../../swr/hooks/RetailReports/useSingleLocationProgress";
// import OutstandingModules from "../../reports/reuseableComponents/OutstandingModules";
import ProgressBar from "../../reports/reuseableComponents/ProgressBar";
import { retOutstanding, retProgress } from "../../reports/utils/utils";
import { CenterWithColor, CustomDatatableV2 } from "../../styles";
import DisplaySpiffyScore from "../../../../components/Table/DisplaySpiffyScore";
import StyledLink from "src/components/UI/StyledLink";

const TeamMemberTable = ({
  teamMembers,
  locationName,
  locationId,
  teamMemberClickable = false,
  data,
  engagementType,
}) => {
  const { data: progress } = useSingleLocationProgress(locationId); // updated

  const isUnder600Px = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const getTotalCompletionsV2 = (userId, allSubmissions, engagementType) => {
    const compsFromUser = allSubmissions.filter(
      (sub) => sub.user_id === userId && sub.passed,
    );

    const compsByEngagementType =
      engagementType === ENGAGEMENT_TYPES.EXTRACREDIT
        ? compsFromUser.filter((sub) => !sub.assigned)
        : engagementType === ENGAGEMENT_TYPES.ASSIGNED
          ? compsFromUser.filter((sub) => sub.assigned)
          : compsFromUser;

    return compsByEngagementType.length;
  };

  const getTotalSubsV2 = (userId, allSubmissions, engagementType) => {
    const subsFromUser = allSubmissions.filter((sub) => sub.user_id === userId);

    const subsByEngagementType =
      engagementType === ENGAGEMENT_TYPES.EXTRACREDIT
        ? subsFromUser.filter((sub) => !sub.assigned)
        : engagementType === ENGAGEMENT_TYPES.ASSIGNED
          ? subsFromUser.filter((sub) => sub.assigned)
          : subsFromUser;

    return subsByEngagementType.length;
  };

  const options = makeDefaultTableOptions(`location-report-${locationName}`);
  options.textLabels = {
    body: {
      noMatch:
        "There are no team members for this location’s team. You can invite new team members to join this location or add existing team members to this team through the Team Members Page.",
    },
  };
  options.rowsPerPage = 10;
  options.sortOrder = {
    name: "spiffyScore",
    direction: "desc",
  };

  if (isUnder600Px) {
    options.rowsPerPageOptions = [];
    options.jumpToPage = false;
  }

  const tableData = teamMembers.map((member) => {
    return {
      id: member.id,
      userId: member.user_id,
      name: `${member.first_name} ${member.last_name}`,
      completions: getTotalCompletionsV2(
        member.user_id,
        data?.submissions,
        engagementType,
      ),
      submissions: getTotalSubsV2(
        member.user_id,
        data?.submissions,
        engagementType,
      ),
      outstanding: retOutstanding(member.user_id, progress),
      progress: retProgress(member.user_id, progress),
      spiffyScore: member.spiffy_score,
    };
  });

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
        print: false,
      },
    },
    {
      name: "userId",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
        print: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
        display: true,
        customBodyRender: (value, tableMeta) => {
          if (teamMemberClickable) {
            return (
              <StyledLink
                to={`/teammembers/detail/${tableMeta.rowData[0]}`}
                value={value}
              />
            );
          }
          return <span>{value}</span>;
        },
      },
    },

    {
      name: "submissions",
      label: "Submissions",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => (
          <CenterWithColor $engagementType={engagementType}>
            <span>{value}</span>
          </CenterWithColor>
        ),
      },
    },
    {
      name: "completions",
      label: "Completions",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => (
          <CenterWithColor $engagementType={engagementType}>
            <span>{value}</span>
          </CenterWithColor>
        ),
      },
    },

    {
      name: "outstanding",
      label: "Outstanding",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          if (engagementType === ENGAGEMENT_TYPES.EXTRACREDIT) {
            return <span style={{ color: "darkgrey" }}>Not applicable</span>;
          }
          return <span>{value}</span>;
        },
      },
    },
    {
      name: "progress",
      label: "Progress",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (_, tableMeta) => {
          if (engagementType === ENGAGEMENT_TYPES.EXTRACREDIT) {
            return <span style={{ color: "darkgrey" }}>---/---</span>;
          }
          return <ProgressBar progress={progress} id={tableMeta.rowData[1]} />;
        },
      },
    },
    {
      name: "spiffyScore",
      label: "Spiffy Score",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => (
          <DisplaySpiffyScore val={value} large />
          // <div
          //   style={{
          //     display: "flex",
          //     alignItems: "center",
          //     justifyContent: "center",
          //     gap: 4,
          //   }}
          // >
          //   <span>{value}</span>
          // </div>
        ),
      },
    },
  ];

  return (
    <>
      <CustomDatatableV2
        title={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",

              gap: 6,
            }}
          >
            <TableTitle
              title={`Team Members at ${locationName}`}
              Icon={Users}
              submissionType={engagementType}
            />
          </div>
        }
        data={tableData}
        options={options}
        columns={columns}
        components={{
          icons: CustomDataTableIcons,
        }}
      />
    </>
  );
};

export default TeamMemberTable;

TeamMemberTable.propTypes = {
  teamMembers: PropTypes.array.isRequired,
  locationName: PropTypes.string.isRequired,
  locationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  teamMemberClickable: PropTypes.bool,
};

TeamMemberTable.defaultProps = {
  teamMemberClickable: false,
};
