import React from "react";
import { Tooltip } from "@mui/material";
import styled from "styled-components";

const SubmissionChipAssigned = styled.div`
  background-color: rgba(0, 0, 250, 0.1);
  height: 18px;
  padding: 4px 6px;
  border-radius: 4px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }

  span {
    color: #587dea;
    font-weight: 800;
    font-size: 10px;
  }
`;

const SubmissionChipAssignedTooltip = ({ title = "" }) => (
  <Tooltip title={title || "Assigned Submisson"} arrow placement="right">
    <SubmissionChipAssigned>
      <span>A</span>
    </SubmissionChipAssigned>
  </Tooltip>
);

export default SubmissionChipAssignedTooltip;
