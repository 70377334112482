import useSWR from "swr";
import API from "../../../axios/instances/API";

const fetcher = async (url) => {
  const { data } = await API.get(url);
  return data;
};
// '/dashboard/analytics/report/?report=vendor_users_breakdown'
const BASE_URL = "/dashboard/analytics/vendor-reports/users-breakdown/";

const useVendorUsersBreakdown = () => {
  const { data, error, mutate } = useSWR(BASE_URL, fetcher, {
    revalidateOnFocus: false,
  });

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useVendorUsersBreakdown;
