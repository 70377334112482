import React from "react";
import { Typography } from "@mui/material";

const MockHeader = ({ title, secondary }) => {
  return (
    <div
      style={{
        height: 40,
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: 6,
        padding: 4,
      }}
    >
      <Typography style={{ fontSize: 16, fontWeight: "700", color: "black" }}>
        {title}
      </Typography>
      <span style={{ fontSize: 16, color: "lightgray" }}>/</span>
      <Typography
        style={{ fontSize: 16, color: "slategrey", fontWeight: "600" }}
      >
        {secondary}
      </Typography>
    </div>
  );
};

export default MockHeader;
