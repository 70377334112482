import React from "react";
import { Tooltip } from "@mui/material";
import styled from "styled-components";
import { Info } from "react-feather";

const Container = styled.div`
  height: 18px;
  width: 18px;
  background: transparent;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #eeeeee;
    cursor: pointer;
  }
`;

const Inner = () => (
  <div
    style={{
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      flexDirection: "column",
      gap: 4,
    }}
  >
    <span style={{ color: "black", fontSize: 11, fontWeight: "600" }}>
      What are supervisor deploy permissions?
    </span>
    <span style={{ color: "darkgrey" }}>
      Supervisors with deploy permissions gain 3 main functionalities
    </span>
    <div style={{ display: "flex", flexDirection: "column" }}>
      <span style={{ color: "black", fontWeight: "400" }}>
        1) The ability to create custom modules.
      </span>
      <span style={{ color: "black", fontWeight: "400" }}>
        2) The ability to view and use the marketplace.
      </span>
      <span style={{ color: "black", fontWeight: "400" }}>
        3) The ability to change the audience of modules.
      </span>
    </div>
  </div>
);

const DeployPermissionsTooltip = () => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
    }}
  >
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: "white",
            border: "1px solid lightgray",
            maxHeight: "none",
            padding: "8px",
          },
        },
      }}
      arrow
      placement="top"
      title={<Inner />}
    >
      <Container>
        <Info height={12} width={12} color="slategrey" />
      </Container>
    </Tooltip>
  </div>
);

export default DeployPermissionsTooltip;
