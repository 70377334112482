import React from "react";
import useAudienceToolStore from "../../../store/useAudienceToolStore";

const getPercentageChecked = (numChecked, numCells) => {
  try {
    if (numChecked === 0) return 0;

    return Math.floor((numChecked / numCells) * 100);
  } catch (error) {
    return null;
  }
};

const PercentageCheckedIndicator = () => {
  const rows = useAudienceToolStore((state) => state.rows);

  const flattenedRows = [...Object.values(rows)].flat();

  const numCells = flattenedRows.length;
  const numChecked = flattenedRows.filter((cell) => cell.checked).length;
  const percentageChecked = getPercentageChecked(numChecked, numCells);

  if (percentageChecked === null) return null;

  return (
    <div>
      <span style={{ color: "darkgrey" }}>
        ({numChecked} / {numCells})
      </span>
      <span style={{ marginLeft: 6, marginRight: 6, color: "darkgrey" }}>
        |
      </span>
      <span style={{ color: "darkgrey" }}>
        {percentageChecked}% of cells checked
      </span>
    </div>
  );
};

export default PercentageCheckedIndicator;
