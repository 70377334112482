import React from "react";
import { CircularProgress, MenuItem, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";
import useAllCountries from "../../../swr/hooks/Register/useAllCountries";
import useAllVerticals from "../../../swr/hooks/Register/useAllVerticals";
import ExplanationTooltip from "./ExplanationTooltip";
import styled from "styled-components";

const Spacer = styled.div`
  height: 16px;
  min-height: 16px;
`;

const ExplainCompanies = () => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        flexDirection: "column",
      }}
    >
      <div style={{ height: 8 }} />
      <p style={{ color: "white", fontWeight: "600", fontSize: 11 }}>
        Employer Company:
      </p>
      <span style={{ color: "white", fontWeight: "400" }}>
        Businesses that train and manage employees.
      </span>
      <div style={{ height: 8 }} />
      <p style={{ color: "white", fontWeight: "600", fontSize: 11 }}>
        Supplier Company:
      </p>
      <span style={{ color: "white", fontWeight: "400" }}>
        Vendors or product suppliers that provide product knowledge and training
        to businesses selling or using their products.
      </span>
    </div>
  );
};

const validationSchema = Yup.object({
  companyName: Yup.string("Enter your Company Name").required("Required field"),
  companyVertical: Yup.object().required("Required field"),
  country: Yup.object().required("Required field"),
  type: Yup.string().required("Required field"),
});

const COMPANY_TYPES = {
  VENDOR: "VENDOR",
  RETAIL: "RETAIL",
};

const CreateCompanyForm = ({ signupState, dispatch, ACTIONS }) => {
  const { createCompanyInfo } = signupState;

  const { data: verticals, isLoading: verticalsLoading } = useAllVerticals();
  const { data: countries, isLoading: countriesLoading } = useAllCountries();

  const handleTheSubmit = async (values, { setSubmitting }) => {
    const sendObj = {
      companyVertical: values.companyVertical,
      companyName: values.companyName.trim(),
      country: values.country,
      type: values.type,
    };

    try {
      dispatch({ type: ACTIONS.CREATE_COMPANY, payload: sendObj });
    } catch (error) {
      setSubmitting(false);
    }
  };

  const shouldFormBeDisabled = (errors, values) => {
    if (Object.values(errors).length > 0) return true;

    const allRequiredFieldsHaveValues = Boolean(
      values.companyName &&
        values.companyVertical &&
        values.type &&
        values.country,
    );

    if (!allRequiredFieldsHaveValues) return true;

    return false;
  };

  const initialValues = {
    companyName: createCompanyInfo?.companyName || "",
    companyVertical: createCompanyInfo?.companyVertical || "",
    country: createCompanyInfo?.country || "",
    type: createCompanyInfo?.type || "",
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleTheSubmit}
        enableReinitialize
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          errors,
          touched,
          isSubmitting,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <TextField
              select
              small
              label="Company Type"
              variant="outlined"
              name="type"
              size="small"
              onChange={handleChange}
              value={values.type}
              onBlur={handleBlur}
              fullWidth
            >
              <MenuItem key={COMPANY_TYPES.RETAIL} value={COMPANY_TYPES.RETAIL}>
                Employer
              </MenuItem>
              <MenuItem key={COMPANY_TYPES.VENDOR} value={COMPANY_TYPES.VENDOR}>
                Supplier
              </MenuItem>
            </TextField>
            <ExplanationTooltip
              title="What is this?"
              BodyComponent={ExplainCompanies}
              text="There are two different types of companies."
            />
            <Spacer />
            <TextField
              type="text"
              name="companyName"
              label="Company Name"
              value={values.companyName}
              fullWidth
              error={Boolean(touched.companyName && errors.companyName)}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.companyName && errors.companyName}
              autoComplete="new-password"
              variant="outlined"
              size="small"
            />
            <Spacer />

            <TextField
              name="country"
              select
              label="Country"
              onChange={handleChange}
              disabled={countriesLoading}
              value={values.country}
              variant="outlined"
              size="small"
              fullWidth
            >
              {countriesLoading && (
                <MenuItem value={null} disabled>
                  loading...
                </MenuItem>
              )}
              {countries?.map((country) => (
                <MenuItem value={country} key={country.code}>
                  {country.name}
                </MenuItem>
              ))}
            </TextField>
            <Spacer />

            <TextField
              select
              type="text"
              name="companyVertical"
              label="Industry"
              value={values.companyVertical}
              fullWidth
              error={Boolean(touched.companyVertical && errors.companyVertical)}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.companyVertical && errors.companyVertical}
              autoComplete="new-password"
              variant="outlined"
              disabled={verticalsLoading}
              size="small"
            >
              {verticalsLoading ? (
                <MenuItem disabled>none</MenuItem>
              ) : (
                verticals?.map((vertical) => (
                  <MenuItem value={vertical} key={vertical.id}>
                    {vertical.name}
                  </MenuItem>
                ))
              )}
            </TextField>
            <ExplanationTooltip
              title="What is this?"
              text="Industry field is used to categorize the nature of your business; this field helps customize your dashboard and the marketplace to ensure relevant content is available for your team. Ex. If you’re a Cannabis store, you should select ‘Cannabis’ as your industry, or if you’re a Cafè, you should select ‘Quick Service Restaurant’ as your Industry."
            />
            <Spacer />

            <Button
              sx={{ mt: 2 }}
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              disabled={shouldFormBeDisabled(errors, values)}
              startIcon={isSubmitting && <CircularProgress size={14} />}
            >
              {isSubmitting ? "Submitting" : "Create Company"}
            </Button>
          </form>
        )}
      </Formik>
    </>
  );
};

export default CreateCompanyForm;

CreateCompanyForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  ACTIONS: PropTypes.object.isRequired,
  signupState: PropTypes.object.isRequired,
};
