import React, { useMemo } from "react";
import { Bar } from "react-chartjs-2";
import styled from "styled-components";
import { ENGAGEMENT_TYPES } from "../../../../../storage/constants";
import { format, addDays } from "date-fns";
import { getSubmissionsByWeekStats } from "../../../teamMembers/detail/utils";
import { Paper } from "@mui/material";

const Wrapper = styled(Paper)`
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  border-radius: 4px;
`;

const ChartWrapper = styled(Paper)`
  width: 100%;
  height: 300px;
  padding: 10px 10px 10px 0px;
`;

const thisYear = new Date().getFullYear();

const EngagementByWeekBarChart = ({ data, engagementType }) => {
  const { all_submissions: submissions } = data;

  if (submissions.length === 0) return null;

  const filteredSubmissions =
    engagementType === ENGAGEMENT_TYPES.ASSIGNED
      ? submissions.filter((sub) => sub.is_follower_submission)
      : engagementType === ENGAGEMENT_TYPES.EXTRACREDIT
        ? submissions.filter((sub) => !sub.is_follower_submission)
        : submissions;

  const weekData = useMemo(
    () => getSubmissionsByWeekStats(filteredSubmissions, true),
    [submissions, engagementType],
  );

  const WEEKLY_LABEL_FORMAT = "MMM do";
  const chartLabels = Object.values(weekData).map((val) => {
    return `${format(val.date, WEEKLY_LABEL_FORMAT)}-${format(
      addDays(val.date, 6),
      "do",
    )} `;
  });

  const chartCount = Object.values(weekData).map((val) => val.count);

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: false,
      tooltip: {
        callbacks: {
          afterBody: (context) =>
            `Submissions: ${chartCount[context[0].dataIndex]}`,
          label: () => "",
        },
      },
    },
    responsive: true,
    scales: {
      y: {
        stacked: false,
        beginAtZero: true,
        ticks: {
          stepSize: 5,
          display: false,
        },
        grid: {
          color: "rgba(0,0,0,0.05)",
        },
      },
      x: {
        stacked: false,
        ticks: {
          display: "false",
        },
        grid: {
          color: "rgba(0,0,0,0.05)",
        },
      },
    },
  };

  const chartData = (canvas) => {
    const ctx = canvas.getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, 160);
    gradient.addColorStop(
      0,
      engagementType === ENGAGEMENT_TYPES.ASSIGNED
        ? "rgba(0, 0, 250, .05)"
        : engagementType === ENGAGEMENT_TYPES.EXTRACREDIT
          ? "#ffeeb066"
          : "rgba(158,158,158,0.43)",
    );
    gradient.addColorStop(1, "rgba(158,158,158, .19)");

    return {
      labels: chartLabels,
      datasets: [
        {
          label: "Submisisons",
          id: "Submissions",
          backgroundColor:
            engagementType === ENGAGEMENT_TYPES.ASSIGNED
              ? "rgba(0, 0, 250, .05)"
              : engagementType === ENGAGEMENT_TYPES.EXTRACREDIT
                ? "#ffeeb066"
                : "#337ab7",
          borderColor: "rgba(158,158,158,.05)",
          pointBorderColor: "slategrey",
          borderRadius: 4,
          borderColor:
            engagementType === ENGAGEMENT_TYPES.ASSIGNED
              ? "#587dea"
              : engagementType === ENGAGEMENT_TYPES.EXTRACREDIT
                ? "#aa9700"
                : "black",
          borderWidth: 2,
          data: chartCount,
        },
      ],
    };
  };

  return (
    <Wrapper>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          flexDirection: "row",
          paddingTop: 4,
          gap: 8,
          paddingLeft: 26,
          // border: "1px solid orange",
        }}
      >
        <span style={{ fontSize: 16, fontWeight: 600, color: "black" }}>
          {data.quiz_info.name}
        </span>
        <span
          style={{
            fontWeight: 400,
            color: "darkgrey",
            fontSize: 13,
          }}
        >
          Weekly Submissions ({thisYear})
        </span>
      </div>
      <ChartWrapper>
        <Bar data={chartData} options={options} />
      </ChartWrapper>
    </Wrapper>
  );
};

export default EngagementByWeekBarChart;
