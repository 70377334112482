import React, { useState } from "react";
import styled from "styled-components";
import { Button, ClickAwayListener, TextField } from "@mui/material";
import { Search } from "react-feather";

const SearchButton = styled(Button)`
  height: 40px;
  width: 40px;
  border-radius: 8px;
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;
  border: ${(props) =>
    props.$isOpen ? "2px solid #337ab7" : "1px solid lightgray"};
  transition: none;
`;

const SearchContainer = styled.div`
  background: white;
  min-height: 100px;
  padding: 8px 10px;
  width: 260px;

  box-shadow: 1px 1px 5px 2px gainsboro;
  position: absolute;
  top: 44px;
  z-index: 1000000000000;
  border-radius: 8px;
  right: 0px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;

const CustomSearch = ({ handleSearch, handleClear }) => {
  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");

  return (
    <div style={{ position: "relative" }}>
      <SearchButton $isOpen={open} onClick={() => setOpen(true)}>
        <Search height={16} width={16} color="black" />
      </SearchButton>
      {!open ? null : (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <SearchContainer>
            <span style={{ fontWeight: "500" }}>Search by Keyword</span>
            <div style={{ height: 6 }} />
            <TextField
              value={text}
              variant="outlined"
              size="small"
              color="primary"
              onChange={(e) => setText(e.target.value)}
              autoFocus
              fullWidth
            />
            <div style={{ height: 4 }} />

            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: 4,
                justifyContent: "flex-end",
              }}
            >
              <Button
                size="small"
                color="secondary"
                onClick={() => {
                  setText("");
                  handleClear();
                }}
              >
                Clear
              </Button>
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={() => {
                  handleSearch(text);
                  setOpen(false);
                }}
              >
                Search
              </Button>
            </div>
          </SearchContainer>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default CustomSearch;
