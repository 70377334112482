import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { MapPin } from "react-feather";
import styled from "styled-components";
import { Chip, Popper } from "@mui/material";
import Inner from "./Inner";

const LocationChip = styled(Chip)`
  padding: 1px 6px;
  margin: 2px;
  border-radius: 4px;
  max-width: 220px;
  color: black;
  background: #eeeeee;
  color: ${(props) => props.theme.palette.grey[900]};
  border: 1px solid
    ${(props) => {
      if (props.$isActive) {
        return "black";
      }
      return props.theme.palette.grey[300];
    }};

  svg {
    color: black;
  }
`;

const ShowLocationChip = ({ name, id, showLinkToLocation = true }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  return (
    <>
      <LocationChip
        ref={anchorRef}
        $isActive={open}
        icon={<MapPin style={{ height: 10, width: 10 }} />}
        label={name}
        size="small"
        onClick={() => setOpen((p) => !p)}
      />
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-end"
        style={{ zIndex: "10000000001" }}
      >
        <Inner
          id={id}
          name={name}
          setOpen={setOpen}
          showLinkToLocation={showLinkToLocation}
        />
      </Popper>
    </>
  );
};

export default ShowLocationChip;

ShowLocationChip.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  name: PropTypes.string.isRequired,
  canDelete: PropTypes.bool,
  deleteFunc: PropTypes.func,
  isRemoving: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
};

ShowLocationChip.defaultProps = {
  canDelete: false,
  deleteFunc: null,
  isRemoving: false,
};
