import React, { useContext } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import {
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
} from "@mui/material";
import { Menu as MenuIcon } from "react-feather";
import ShowTranscode from "../ShowTranscode";
import AccountDropdownV2 from "./AccountDropdownV2";
import { GlobalState } from "../../store/GlobalState";
import CompanyDropdown from "./CompanyDropdown";
import SuperDisguise from "./SuperDisguise";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const ConditionalSpacing = styled.div`
  width: 8px;
  height: 34px
  background: transparent;
  margin-left: 12px;
`;

const AppBarComponent = ({ onDrawerToggle }) => {
  const { state, dispatch } = useContext(GlobalState);
  const { config } = state;
  const { transcoding } = state;

  return (
    <>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <div
            style={{
              width: "100%",

              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              height: "100%",
            }}
          >
            <IconButton
              style={{
                borderRadius: "50%",
                maxHeight: 40,
                maxWidth: 40,
                minHeight: 40,
                minWidth: 40,
                padding: 0,
              }}
              sx={{
                display: { lg: "none", md: "flex" },
              }}
              color="inherit"
              aria-label="Open drawer"
              onClick={onDrawerToggle}
              size="large"
            >
              <MenuIcon height={22} width={22} />
            </IconButton>
            {config?.user?.can_access_superuser_company_switch && (
              <SuperDisguise dispatch={dispatch} />
            )}
            <div style={{ marginLeft: "auto" }} />
            {transcoding && transcoding.length > 0 && (
              <ShowTranscode transcoding={transcoding} />
            )}

            <CompanyDropdown />

            <ConditionalSpacing />

            <AccountDropdownV2 />
            <div style={{ minWidth: 12 }} />
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default AppBarComponent;

AppBarComponent.propTypes = {
  onDrawerToggle: PropTypes.any.isRequired,
};
