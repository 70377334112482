import { useMediaQuery } from "@mui/material";
import React from "react";
import { Download } from "react-feather";
import CustomDataTableIcons from "../../../../../components/Table/CustomDataTableIcons";
import DateTooltip from "../../../../../components/Table/DateTooltip";
import SubmissionResultIndicator from "../../../../../components/Table/SubmissionResultIndicator";
import TableTitle from "../../../../../components/Table/TableTitle";
import DisplayScore from "../../../../../components/UI/DisplayScore";
import { ENGAGEMENT_TYPES } from "../../../../../storage/constants";
import { makeDefaultTableOptions } from "../../../../../storage/helpers";
import { CustomDatatableV2 } from "../../../styles";
import SubmissionChipExtraCreditTooltip from "src/components/UI/SubmissionChipExtraCreditTooltip";
import SubmissionChipAssignedTooltip from "src/components/UI/SubmissionChipAssignedTooltip";

const options = makeDefaultTableOptions("user-info");
options.sortOrder = {
  name: "date",
  direction: "desc",
};
options.rowsPerPage = 10;

const getTableData = (submissions, engagementType) => {
  if (engagementType === ENGAGEMENT_TYPES.ASSIGNED) {
    return submissions
      .filter((sub) => !sub.is_follower_submission)
      .map((submission) => ({
        id: submission.id,
        name: submission.name,
        date: submission.created,
        type: submission.is_follower_submission,
        score: `${submission.score}/${submission.questions}`,
        passed: submission.passed ? "Yes" : "No",
      }));
  }
  if (engagementType === ENGAGEMENT_TYPES.EXTRACREDIT) {
    return submissions
      .filter((sub) => sub.is_follower_submission)
      .map((submission) => ({
        id: submission.id,
        name: submission.name,
        date: submission.created,
        type: submission.is_follower_submission,
        score: `${submission.score}/${submission.questions}`,
        passed: submission.passed ? "Yes" : "No",
      }));
  }

  return submissions.map((submission) => ({
    id: submission.id,
    name: submission.name,
    date: submission.created,
    type: submission.is_follower_submission,
    score: `${submission.score}/${submission.questions}`,
    passed: submission.passed ? "Yes" : "No",
  }));
};

const SubmissionsTable = ({ submissions, engagementType }) => {
  const isUnder600Px = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const options = makeDefaultTableOptions("user-info");
  options.sortOrder = {
    name: "date",
    direction: "desc",
  };
  options.rowsPerPage = 10;

  if (isUnder600Px) {
    options.rowsPerPageOptions = [];
    options.jumpToPage = false;
  }

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "date",
      label: "Date",
      options: {
        filter: true,
        customBodyRender: (val) => (
          <DateTooltip date={val} displayYear shouldLocalize />
        ),
      },
    },
    {
      name: "name",
      label: "Module Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "type",
      label: "Submission Type",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          if (!val) {
            return <SubmissionChipAssignedTooltip />;
          }
          return <SubmissionChipExtraCreditTooltip isVendor />;
        },
      },
    },
    {
      name: "score",
      label: "Score",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (val) => <DisplayScore value={val} />,
      },
    },
    {
      name: "passed",
      label: "Passed",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => (
          <SubmissionResultIndicator value={value} />
        ),
      },
    },
  ];

  const tableData = getTableData(submissions, engagementType);

  return (
    <CustomDatatableV2
      title={
        <TableTitle
          title="Submissions from this user"
          Icon={Download}
          isVendor
          submissionType={engagementType}
        />
      }
      options={options}
      columns={columns}
      data={tableData}
      components={{
        icons: CustomDataTableIcons,
      }}
    />
  );
};

export default SubmissionsTable;
