import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { SWRConfig } from "swr";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import "./css/index.css";
import { StyledEngineProvider } from "@mui/material/styles";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import App from "./App";
import GlobalStateProvider from "./store/GlobalState";
import theme from "./theme";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  // We recommend adjusting this value in production
  tracesSampleRate: 0.5,
});

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <Helmet defaultTitle="Spiffy" />
      <GlobalStateProvider>
        <StyledEngineProvider injectFirst>
          <StyledThemeProvider theme={theme}>
            <MuiThemeProvider theme={theme}>
              <SWRConfig value={{ errorRetryCount: 2 }}>
                <Router>
                  <App />
                </Router>
              </SWRConfig>
            </MuiThemeProvider>
          </StyledThemeProvider>
        </StyledEngineProvider>
      </GlobalStateProvider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);
