import useSWR from "swr";
import API from "../../../axios/instances/API";

const BASE_URL = "dashboard/analytics/vendor-reports/streams/";

const fetcher = async (url) => {
  const { data } = await API.get(url);
  return data;
};

const useStreamReportDetails = (id) => {
  const { data, error, mutate } = useSWR(`${BASE_URL}${id}/`, fetcher, {
    revalidateOnFocus: false,
  });

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useStreamReportDetails;
