import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { makeDefaultTableOptions } from "../../../../storage/helpers";
import { CustomDatatableV2, ShowState } from "../../styles";
import TableTitle from "../../../../components/Table/TableTitle";
import { Book } from "react-feather";
import CustomDataTableIcons from "../../../../components/Table/CustomDataTableIcons";
import { useMediaQuery } from "@mui/material";

const Contain = styled.div`
  max-width: 60px;
`;

const columns = [
  {
    name: "name",
    label: "Module",
  },
  {
    name: "state",
    label: "State",
    options: {
      display: true,
      customBodyRender: () => (
        <Contain>
          <ShowState $state="Live">
            <span>Live</span>
          </ShowState>
        </Contain>
      ),
    },
  },
];

const RoleModulesTable = ({ assignedQuizzes }) => {
  const isUnder600Px = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const options = makeDefaultTableOptions("role-live-modules");
  options.jumpToPage = false;
  options.rowsPerPage = 50;

  if (isUnder600Px) {
    options.rowsPerPageOptions = [];
    options.jumpToPage = false;
  }

  return (
    <div style={{ flex: 1 }}>
      <CustomDatatableV2
        style={{ width: "100%", flex: 1 }}
        title={<TableTitle title="Modules assigned to role" Icon={Book} />}
        data={assignedQuizzes}
        columns={columns}
        options={options}
        components={{
          icons: CustomDataTableIcons,
        }}
      />
    </div>
  );
};

export default RoleModulesTable;

RoleModulesTable.propTypes = {
  assignedQuizzes: PropTypes.array.isRequired,
};
