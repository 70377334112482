import React from "react";
import { AUDIENCETYPES } from "../../../storage/constants";
import CurrentLocations from "./CurrentLocations";
import CurrentRoles from "./CurrentRoles";
import CurrentSelectAll from "./CurrentSelectAll";
import CurrentUnselected from "./CurrentUnselected";
import CurrentOther from "./CurrentOther";
import CurrentSupervisor from "./CurrentSupervisor";

const COMPONENTS = {
  [AUDIENCETYPES.SELECT_ALL]: CurrentSelectAll,
  [AUDIENCETYPES.ROLES]: CurrentRoles,
  [AUDIENCETYPES.LOCATIONS]: CurrentLocations,
  [AUDIENCETYPES.OTHER]: CurrentOther,
  [AUDIENCETYPES.NONE]: CurrentUnselected,
  [AUDIENCETYPES.MANAGER]: CurrentSupervisor,
};

const CurrentAudience = (props) => {
  const { selectedAudience } = props;

  const Selected = COMPONENTS[selectedAudience];

  return <Selected {...props} />;
};

export default CurrentAudience;
