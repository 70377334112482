import useSWR from "swr";
import API from "../../axios/instances/API";

const BASE_URL =
  "/dashboard/analytics/report/?report=submission_view&submission_id=";

const fetcher = async (url) => {
  const { data } = await API.get(url);
  return data.submission;
};

const useSubmissionReport = (id) => {
  const { data, error, mutate } = useSWR(`${BASE_URL}${id}`, fetcher, {
    revalidateOnFocus: false,
  });

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useSubmissionReport;
