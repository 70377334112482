import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  CircularProgress,
  Paper,
  Typography,
  TextField,
  Button,
  Alert,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { Plus } from "react-feather";
import { FEEDBACK_MESSAGES } from "../../../../storage/constants";
import API from "../../../../axios/instances/API";

const handleApiError = (error) => {
  const errorObj = error?.response?.data?.error;

  if (errorObj) {
    return {
      title: errorObj.title,
      body: errorObj.details,
    };
  }

  return {
    title: "ERROR",
    body: FEEDBACK_MESSAGES.GENERIC_ERROR,
  };
};

const ModalWrapper = styled(Paper)`
  padding: 20px;
  min-width: min(540px, 96vw);
  max-width: 600px;
  text-align: left;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 6px;
  border: 2px solid black;
`;

const CreateQuizModal = () => {
  const [quizName, setQuizName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const history = useHistory();

  const createQuiz = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorAlert(false);
    const sendObj = {};
    sendObj.name = quizName;

    try {
      const { data } = await API.post("dashboard/quiz/", sendObj);
      setIsSubmitting(false);
      history.push({
        pathname: `/modules/quiz-builder/${data.id}`,
        state: {
          newQuiz: true,
        },
      });
    } catch (error) {
      setIsSubmitting(false);
      const errorObj = handleApiError(error);
      return setErrorAlert(errorObj);
    }
  };

  const launchDisabled = quizName.length < 3 || isSubmitting;

  return (
    <ModalWrapper>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Typography variant="h3">Create Module</Typography>
      </div>
      <Typography
        variant="subtitle1"
        color="textSecondary"
        sx={{ mb: 6, ml: 0.5 }}
      >
        Input a name to create a new module.
      </Typography>
      <form id="createQuizForm" onSubmit={createQuiz}>
        <TextField
          variant="outlined"
          fullWidth
          autoFocus
          value={quizName}
          onChange={(e) => setQuizName(e.currentTarget.value)}
          label="Module Name"
          size="small"
          sx={{ mb: 4 }}
        />
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button
            type="submit"
            form="createQuizForm"
            variant="contained"
            style={{
              backgroundColor: launchDisabled ? "darkgrey" : "#337AB7",
              color: "#eeeeee",
              marginLeft: "auto",
            }}
            color="primary"
            disabled={launchDisabled}
            startIcon={
              isSubmitting ? (
                <CircularProgress size={16} sx={{ color: "white" }} />
              ) : (
                <Plus color="white" height={14} width={14} />
              )
            }
          >
            {isSubmitting ? "Creating Module" : "Create Module"}
          </Button>
        </div>
      </form>
      {errorAlert && (
        <Alert
          severity="error"
          sx={{ mt: 2 }}
          onClose={() => setErrorAlert(false)}
        >
          <p style={{ fontWeight: "600", fontSize: 13 }}>{errorAlert?.title}</p>
          <p style={{ fontWeight: "400", fontSize: 12 }}>{errorAlert?.body}</p>
        </Alert>
      )}
    </ModalWrapper>
  );
};

export default CreateQuizModal;

CreateQuizModal.propTypes = {
  managerLocations: PropTypes.array,
};

CreateQuizModal.defaultProps = {
  managerLocations: null,
};
