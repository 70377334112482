import React from "react";
import { ENGAGEMENT_TYPES } from "../../../storage/constants";
import { FormControl, Select, MenuItem } from "@mui/material";

const EngagementSelect = ({
  engagementType,
  setEngagementType,
  setPage,
  isRetail,
}) => (
  <FormControl style={{ width: 200 }}>
    <Select
      variant="outlined"
      size="small"
      margin="dense"
      color="secondary"
      value={engagementType}
      onChange={(e) => {
        setEngagementType(e.target.value);
        setPage(1);
      }}
      style={{
        borderRadius: 6,
        height: 40,
      }}
    >
      <MenuItem value={ENGAGEMENT_TYPES.ALL}>All Engagement</MenuItem>
      <MenuItem value={ENGAGEMENT_TYPES.ASSIGNED}>Assigned</MenuItem>
      <MenuItem value={ENGAGEMENT_TYPES.EXTRACREDIT}>
        {isRetail ? "Extra Credit" : "Follower"}
      </MenuItem>
    </Select>
  </FormControl>
);

export default EngagementSelect;
