import React from "react";
import { TextField } from "@mui/material";
const SearchBar = ({ searchString, setSearchString }) => {
  return (
    <TextField
      fullWidth
      size="small"
      variant="outlined"
      placeholder="Search..."
      value={searchString}
      onChange={(e) => setSearchString(e.target.value)}
    />
  );
};

export default SearchBar;
