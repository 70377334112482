import React, { useContext, useState } from "react";
import {
  MenuItem,
  Paper,
  Switch,
  InputAdornment,
  Typography,
  Button,
  TextField,
} from "@mui/material";
import styled from "styled-components";

import * as Yup from "yup";
import { Formik } from "formik";
import useLocations from "../../swr/hooks/Locations/useLocations";
import API from "../../axios/instances/API";
import { Lock } from "react-feather";
import { GlobalState } from "../../store/GlobalState";
import ACTIONS from "../../store/actions";
import { getConfig, getInvitesErrorMsgServer } from "../../storage/helpers";
import PreInfoDialog from "../../pages/private/supervisors/PreInfoDialog";
import LocationSelectMultiple from "../../pages/public/Inputs/LocationSelectMultiple";
import ErrorAlertInvite from "../UI/ErrorAlertInvite";
import SelectAdminAccessButton from "../../pages/private/admin/SelectAdminAccessButton";

const ACCESS_LEVELS = {
  ADMIN: "Admin",
  SUPERVISOR: "Supervisor",
};

const Wrapper = styled(Paper)`
  padding: 20px;
  width: 480px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 2px solid black;
`;

const Label = styled.p`
  font-size: 12px;
  color: darkgrey;
  text-align: left;
  margin-bottom: -3px;
`;

const validationSchema = Yup.object({
  accessLevel: Yup.string().required(),

  location: Yup.array().when("accessLevel", {
    is: (val) => val === ACCESS_LEVELS.SUPERVISOR,
    then: Yup.array().required("Must select location to manage"),
  }),

  createTeamMember: Yup.boolean(),
});

const isFormDisabled = (values, errors, isSubmitting) => {
  if (isSubmitting) return true;

  if (errors && Object.values(errors).length > 0) return true;

  if (values.accessLevel === ACCESS_LEVELS.SUPERVISOR) {
    if (values.location.length < 1) return true;

    return false;
  }

  return false;
};

const AcceptDarModal = ({
  name,
  id,
  accessLevel,
  initialLocation,
  setSnackbarText,
  handleClose,
  mutate,
}) => {
  const { locations, isLoading } = useLocations();
  const { state, dispatch } = useContext(GlobalState);
  const { isRetail } = state;

  const [errorMsg, setErrorMsg] = useState(null);
  const [dialogInfo, setDialogInfo] = useState(null);
  const [selectedArray, setSelectedArray] = useState([]);

  if (isLoading) return null;

  const initialValues = {
    accessLevel: accessLevel || "",
    location:
      accessLevel === ACCESS_LEVELS.SUPERVISOR
        ? locations.locations.filter((loc) =>
            initialLocation.includes(loc.id),
          ) || []
        : [],
    createTeamMember: false,
  };

  const getSendBody = (values) => {
    const { accessLevel } = values;

    if (accessLevel === ACCESS_LEVELS.SUPERVISOR) {
      return {
        id,
        job: "M",
        create_team_member: values.createTeamMember,
        locations: values.location.map((v) => v.id),
      };
    }

    return {
      id,
      job: "C",
      access_data: selectedArray?.map((obj) => ({
        company_id: obj?.company_id,
        read_only: obj?.readOnly,
      })),
      confirm: false,
    };
  };

  const handleFormSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const sendBody = getSendBody(values);
    try {
      await API.post("/dashboard/dashboard-access-request/details/", sendBody);
      await mutate();
      const config = await getConfig();
      setSnackbarText(`${name} accepted to your team success `);
      dispatch({ type: ACTIONS.UPDATE_CONFIG, payload: config });
      setSubmitting(false);
      handleClose();
    } catch (error) {
      setSubmitting(false);
      const errorObj = getInvitesErrorMsgServer(error);
      if (errorObj?.status === 409) {
        errorObj.body = sendBody;
        return setDialogInfo(errorObj);
      }
      setErrorMsg(errorObj);
    }
  };

  const handleCloseDialog = () => {
    setDialogInfo(null);
    handleClose();
  };

  const handleSubmitFromDialog = async (obj) => {
    const sendObj = { ...obj, confirm: true };
    await API.post("/dashboard/dashboard-access-request/details/", sendObj);
    await mutate();
    const config = await getConfig();
    setSnackbarText(
      `${obj.first_name} ${obj.last_name} accepted to your team success`,
    );
    dispatch({ type: ACTIONS.UPDATE_CONFIG, payload: config });
  };

  return (
    <Wrapper>
      <PreInfoDialog
        open={Boolean(dialogInfo)}
        info={dialogInfo}
        handleSubmitFromDialog={handleSubmitFromDialog}
        handleClose={handleCloseDialog}
      />
      <Typography variant="h5">Access Request | {name}</Typography>
      <Typography
        sx={{ mb: 3 }}
        style={{ fontWeight: "400", fontSize: 13, fontWeight: "300" }}
      >
        Double check info is correct and accept
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          errors,
          touched,
          isSubmitting,
          setFieldValue,
        }) => (
          <form noValidate style={{ width: "100%" }} onSubmit={handleSubmit}>
            <Label>Select Access Level</Label>
            <TextField
              select
              name="accessLevel"
              touched={touched.accessLevel}
              error={errors.accessLevel}
              value={values.accessLevel}
              onChange={handleChange}
              handleBlur={handleBlur}
              fullWidth
              sx={{ mt: 2 }}
              variant="outlined"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock
                      style={{ height: 14, width: 14, color: "darkgrey" }}
                    />
                  </InputAdornment>
                ),
              }}
            >
              <MenuItem value={ACCESS_LEVELS.ADMIN}>Admin</MenuItem>

              {isRetail && (
                <MenuItem value={ACCESS_LEVELS.SUPERVISOR}>Supervisor</MenuItem>
              )}
            </TextField>
            {values.accessLevel === ACCESS_LEVELS.SUPERVISOR && (
              <>
                <div style={{ height: 8 }} />
                <Label>Select Location to Supervise</Label>
                <div style={{ height: 8 }} />
                {!isLoading && (
                  <LocationSelectMultiple
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    values={values}
                    errors={errors}
                    touched={touched}
                    locations={locations?.locations || []}
                    setFieldValue={setFieldValue}
                  />
                )}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    width: "100%",
                    marginTop: 6,
                  }}
                >
                  <Label style={{ marginRight: 4 }}>
                    Create Team Member for {name}?
                  </Label>
                  <Switch
                    size="medium"
                    onChange={handleChange}
                    value={values.createTeamMember}
                    name="createTeamMember"
                  />
                </div>
              </>
            )}
            {values?.accessLevel === ACCESS_LEVELS.ADMIN && (
              <div style={{ width: "100%", paddingTop: 8 }}>
                <SelectAdminAccessButton
                  selectedArray={selectedArray}
                  setSelectedArray={setSelectedArray}
                />
              </div>
            )}
            <Button
              fullWidth
              type="submit"
              variant="contained"
              sx={{ mt: 4 }}
              color="primary"
              disabled={isFormDisabled(values, errors, isSubmitting)}
            >
              Accept Request
            </Button>
          </form>
        )}
      </Formik>
      {errorMsg && (
        <>
          <div style={{ height: 8 }} />
          <ErrorAlertInvite
            errorMsg={errorMsg}
            handleClose={() => setErrorMsg(null)}
          />
        </>
      )}
    </Wrapper>
  );
};

export default AcceptDarModal;
