import useSWR from "swr";
import API from "../../../axios/instances/API";

const fetcher = async (url) => {
  const { data } = await API.get(url);
  return data;
};

const useManagerDetail = (id) => {
  const { data, error, mutate } = useSWR(
    `dashboard/managers/details/?id=${id}`,
    fetcher,
    {
      revalidateOnFocus: false,
    },
  );

  return {
    details: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useManagerDetail;
