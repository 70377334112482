import React from "react";
import { Tooltip } from "@mui/material";
import styled from "styled-components";
import { X, Check } from "react-feather";

const Container = styled.div`
  cursor: pointer;
  position relative;
  padding: 4px;
  border-radius: 6px;
  max-width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  &:hover {
    background-color: #eeeeee;
  }
`;

const InnerNo = () => (
  <div
    style={{
      height: "100%",
      width: "100%",
      padding: 4,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      gap: 4,
    }}
  >
    <X height={10} width={10} color="firebrick" />

    <span style={{ fontWeight: "400", color: "black" }}>
      Supervisors at this location do not have deploy permissions.
    </span>
  </div>
);

const InnerYes = () => (
  <div
    style={{
      height: "100%",
      width: "100%",
      padding: 4,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      gap: 4,
    }}
  >
    <Check height={10} width={10} color="mediumseagreen" />

    <span style={{ fontWeight: "400", color: "black" }}>
      Supervisors at this location have deploy permissions.
    </span>
  </div>
);

const SuperDeloyTooltip = ({ value }) => {
  if (value === "Yes") {
    return (
      <Tooltip
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: "white",
              border: "1px solid slategrey",
              maxHeight: "none",
              padding: "0px",
              maxWidth: "none",
            },
          },
        }}
        title={<InnerYes />}
        arrow
        placement="top"
      >
        <Container>
          <Check height={10} width={10} color="mediumseagreen" />
          <span style={{ color: "darkgrey", fontSize: 10 }}>Deploy</span>
        </Container>
      </Tooltip>
    );
  }

  if (value === "No") {
    return (
      <Tooltip
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: "white",
              border: "1px solid slategrey",
              maxHeight: "none",
              padding: "0px",
              maxWidth: "none",
            },
          },
        }}
        title={<InnerNo />}
        arrow
        placement="top"
      >
        <Container>
          <X height={10} width={10} color="firebrick" />
          <span style={{ color: "darkgrey", fontSize: 10 }}>Deploy</span>
        </Container>
      </Tooltip>
    );
  }

  return null;
};

export default SuperDeloyTooltip;
