import useSWR from "swr";
import API from "../../../axios/instances/API";

const fetcher = async (url) => {
  const { data } = await API.get(url);
  return data;
};

const BASE_URL =
  "/dashboard/analytics/vendor-reports/engaged-company-breakdown/?retail_company_id=";

const useSingleCompanyBreakdown = (id) => {
  const { data, error, mutate } = useSWR(`${BASE_URL}${id}`, fetcher, {
    revalidateOnFocus: false,
  });

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useSingleCompanyBreakdown;
