import React from "react";
import { Tooltip } from "@mui/material";
import styled from "styled-components";

const SubmissionChipExtraCredit = styled.div`
  background-color: #ffeeb091;
  height: 18px;
  padding: 4px 6px;
  border-radius: 4px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }

  span {
    color: #aa9700;
    font-weight: 800;
    font-size: 10px;
  }
`;

const SubmissionChipExtraCreditTooltip = ({
  title = null,
  isVendor = false,
}) => (
  <Tooltip
    title={title || isVendor ? "Follower Submission" : "Extra Credit Submisson"}
    placement="right"
    arrow
  >
    <SubmissionChipExtraCredit>
      <span>{isVendor ? "F" : "EC"}</span>
    </SubmissionChipExtraCredit>
  </Tooltip>
);

export default SubmissionChipExtraCreditTooltip;
