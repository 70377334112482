// redeploy
import React, { useState, useContext } from "react";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import useRoleDetails from "../../../../swr/hooks/Roles/useRoleDetails";
import RoleDetailTable from "./RoleDetailTable";
import PrivateHeader from "../../../../components/PrivateHeader";
import LoaderWrapper from "../../../../components/Loaders/LoaderWrapper";
import SnackbarWrapper from "../../../../components/SnackbarWrapper";
import { GlobalState } from "../../../../store/GlobalState";
import OnLoadError from "../../../../components/UI/OnLoadError";
import AddTeamMembers from "./AddTeamMembers";
import EditRole from "./EditRole";
import RoleModulesTable from "./RoleModulesTable";
import styled from "styled-components/macro";

const FlexContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;

  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
  }
`;

const RoleDetail = ({ match }) => {
  const { id: roleId } = match.params;
  const [snackbarText, setSnackbarText] = useState("");
  const { details, isLoading, mutate, isError } = useRoleDetails(roleId);

  const { state } = useContext(GlobalState);
  const { read_only: readOnly } = state.config.user;

  if (isLoading) return <LoaderWrapper text="Role Details" />;

  if (isError) {
    return <OnLoadError />;
  }
  return (
    <>
      <Helmet title="Spiffy | Role Details" />
      <PrivateHeader header="Role" secondary={details.role.name} />
      <FlexContainer>
        <AddTeamMembers
          currentTeamMembers={details.role.teamMembers}
          readOnly={readOnly}
          setSnackbarText={setSnackbarText}
          roleId={roleId}
          mutate={mutate}
        />

        <EditRole
          setSnackbarText={setSnackbarText}
          mutate={mutate}
          details={details.role}
          readOnly={readOnly}
        />
      </FlexContainer>
      <div style={{ height: 16, minHeight: 16 }} />
      <FlexContainer>
        <RoleDetailTable
          roleId={roleId}
          readOnly={readOnly}
          teamMembers={details.role.teamMembers}
          mutate={mutate}
          name={details.role.name}
        />

        <RoleModulesTable
          assignedQuizzes={details.assigned_quizzes}
          timeRoleCreated={details?.role?.created}
        />
      </FlexContainer>

      <SnackbarWrapper
        snackbarText={snackbarText}
        setSnackbarText={setSnackbarText}
      />
    </>
  );
};

export default RoleDetail;

RoleDetail.propTypes = {
  match: PropTypes.object.isRequired,
};
