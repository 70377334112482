const overrides = {
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiButton: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiModal: {
    root: {
      outline: "none",
    },
  },
  MuiCard: {
    root: {
      borderRadius: "6px",
      boxShadow:
        "rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px",
    },
  },
  MuiButtonContainedSecondary: {
    backgroundColor: "#337ab7",
  },
  MuiCardHeader: {
    action: {
      marginTop: "-4px",
      marginRight: "-4px",
    },
  },
  MUIDataTable: {
    styleOverrides: {
      paper: {
        borderRadius: 6,

        borderBottom: "none",
      },
    },
  },
  MuiTableFooter: {
    styleOverrides: {
      root: {
        background: "#eeeeee",

        borderRadius: 12,
        overflow: "hidden",
      },
    },
  },

  MuiTableCell: {
    styleOverrides: {
      root: {
        padding: "6px 16px",
      },
    },
  },
  // Added Myself
  MUIDataTableHeadCell: {
    styleOverrides: {
      data: {
        fontWeight: 600,
        fontSize: "13px",
        marginRight: "0px",
      },
      root: {
        fontWeight: 600,
        fontSize: "13px",
        padding: "6px 16px",
      },
    },
  },
  MUIDataTableToolbarSelect: {
    styleOverrides: {
      root: {
        backgroundColor: "#EEEEEE34",
      },
      title: {
        display: "none",
      },
    },
  },
  MuiTableSortLabel: {
    styleOverrides: {
      root: {
        width: "14px",
      },
    },
  },
  // Added Myself
  MUIDataTableToolbar: {
    styleOverrides: {
      root: {
        borderBottom: "1px solid rgba(0,0,0,0.12)",
        "@media(max-width: 600px)": {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
      },
      actions: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end",
        gap: 4,
        flex: 0,
        "@media(max-width: 600px)": {
          display: "none",
        },
      },
      icon: {
        color: "black",
      },
    },
  },
  MuiCircularProgress: {
    root: {
      color: "white",
    },
  },
  MuiBackdrop: {
    root: {
      backgroundColor: "rgba(0, 0, 0, .75)",
    },
  },
  MuiTabs: {
    defaultProps: {
      indicator: {
        backgroundColor: "transparent",
      },
    },
  },
  MuiTab: {
    root: {
      "&$selected": {
        backgroundColor: "#FFF",
      },
    },
  },
  MuiChip: {
    root: {
      borderRadius: "6px",
    },
  },
};

export default overrides;
