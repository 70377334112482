import React, {
  useRef,
  useEffect,
  useReducer,
  useState,
  useContext,
} from "react";
import { Helmet } from "react-helmet-async";
import { Modal, Fab, Tooltip, Typography } from "@mui/material";
import styled from "styled-components";
import { ArrowUp } from "react-feather";
import reducer from "./state/reducer";
import LoaderWrapper from "../../../components/Loaders/LoaderWrapper";
import ACTIONS, { ORDER_OPTIONS } from "./state/actions";
import ModuleCardV2 from "./ModuleCardV2";
import ReviewModal from "./review";
import useInfiniteMarketplace from "./useInfiniteMarketplace";
import ShowSearchResults from "./ShowSearchResults";
import SnackbarWrapper from "../../../components/SnackbarWrapper";
import PrivateHeader from "../../../components/PrivateHeader";
import { GlobalState } from "../../../store/GlobalState";
import FilterToolbar from "./FilterToolbar";
import FunLoader from "../../../components/Loaders/FunLoader";
import DisplayError from "./DisplayError";
import MpLimits from "./MpLimits";
import ShowStreamResults from "./ShowStreamResults";

const CustomFloatingAction = styled(Fab)`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #eeeeee;
  border: 2px solid ${(props) => props.theme.palette.grey[400]};
  transition: 300ms ease-in;

  &:hover {
    background-color: white;

    svg {
      transition: 200ms ease-in;
      height: 32px;
      width: 32px;
    }
  }
  svg {
    color: ${(props) => props.theme.palette.grey[400]};
  }
`;

const MarketplaceWrapper = styled.div`
  min-height: 10px;
  width: 100%;
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(auto-fit, 290px);
  align-items: center;
  justify-content: center;
  position: relative;
`;

const ObserverDiv = styled.div`
  height: 10px;
  width: 10px;
  pointer-events: none;
  position: absolute;
  bottom: 800px;
  left: 50%;
  right: 50px;
`;

const LoadMoreWrapper = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const setInitalUrl = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);

  if (urlSearchParams.has("page")) {
    urlSearchParams.set("page", 1);
  }

  const cleanUrl = `${window.location.pathname}${urlSearchParams.toString()}`;

  return cleanUrl;
};

const initialState = {
  isLoading: false,
  orderBy: new URLSearchParams(window.location.search).has("order_by")
    ? ORDER_OPTIONS.NEW
    : ORDER_OPTIONS.POPULARITY,
  initialLoading: true,
  error: false,
  errorMsg: "",
  quizzes: [],
  numPages: null,
  hasNext: false,
  countryCodes: [],
  countryCodeString: "",
  page: 1,
  searchName: "",
  resultCount: null,
  vendorSearchInfo: null,
  vertical: null,
  statefulUrl: setInitalUrl(),
  displayOwnedModules: false,
  stream: null,
  streamCompany: null,
};

const MarketPlace = () => {
  const { state: globalState } = useContext(GlobalState);
  const { config } = globalState;

  const [state, dispatch] = useReducer(reducer, initialState);
  const [reviewModalInfo, setReviewModalInfo] = useState(null);
  const [snackbarText, setSnackbarText] = useState("");
  const topRef = useRef(null);

  useInfiniteMarketplace(state, dispatch);
  const {
    isLoading,
    initialLoading,
    error,
    quizzes,
    hasNext,
    searchName,
    vendorSearchInfo,
    vertical,
    errorMsg,
    stream,
    streamCompany,
  } = state;

  const observerRef = useRef(null);

  const handleObserver = (entities) => {
    const target = entities[0];
    if (target.isIntersecting && hasNext) {
      return dispatch({ type: ACTIONS.GET_NEXT_PAGE });
    }
  };

  useEffect(() => {
    if (isLoading) return;
    const observer = new IntersectionObserver(handleObserver);
    if (observerRef.current) {
      observer.observe(observerRef.current);
    }
  }, [isLoading, initialLoading]);

  if (initialLoading) {
    return (
      <>
        <Helmet title="Spiffy | Marketplace" />
        <PrivateHeader header="Marketplace" secondary={true} hideBackBtn />
        <LoaderWrapper text="Marketplace" />
      </>
    );
  }

  const goToTop = () =>
    topRef?.current?.scrollIntoView({ behavior: "smooth", block: "start" });

  const hasNoMoreModules =
    !isLoading && !hasNext && !vendorSearchInfo && !searchName;

  return (
    <div ref={topRef}>
      <Helmet title="Spiffy | Marketplace" />
      <PrivateHeader header="Marketplace" secondary={vertical} hideBackBtn>
        <MpLimits />
      </PrivateHeader>
      {!stream && <FilterToolbar state={state} dispatch={dispatch} />}
      {(searchName.length >= 1 || vendorSearchInfo) && (
        <ShowSearchResults state={state} dispatch={dispatch} />
      )}
      {stream && !isLoading && (
        <ShowStreamResults
          streamCompany={streamCompany}
          stream={stream}
          dispatch={dispatch}
        />
      )}
      <MarketplaceWrapper>
        <>
          {quizzes.map((quiz) => (
            <ModuleCardV2
              key={quiz.id}
              quiz={quiz}
              setReviewModalInfo={setReviewModalInfo}
              setSnackbarText={setSnackbarText}
              config={config}
              dispatch={dispatch}
              stream={stream}
              goToTop={goToTop}
            />
          ))}
        </>
        {!isLoading && <ObserverDiv ref={observerRef} />}
      </MarketplaceWrapper>
      {isLoading && !error && !searchName && !vendorSearchInfo && (
        <LoadMoreWrapper>
          <div
            style={{
              height: 240,
              width: 400,
              borderRadius: 8,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <FunLoader />
            <div style={{ height: 12 }} />
            <span style={{ color: "darkgrey" }}>Loading modules...</span>
          </div>
        </LoadMoreWrapper>
      )}
      {hasNoMoreModules && !error && (
        <Typography
          style={{
            fontSize: 14,
            fontWeight: "500",
            letterSpacing: 1,
            color: "lightgray",
          }}
          align="center"
          my={20}
        >
          ---- No more modules available -----
        </Typography>
      )}
      {error && <DisplayError errorMsg={errorMsg} />}
      <Tooltip title="Back To Top" aria-label="return to top">
        <CustomFloatingAction size="medium" onClick={goToTop}>
          <ArrowUp />
        </CustomFloatingAction>
      </Tooltip>
      <Modal
        open={Boolean(reviewModalInfo)}
        onClose={() => setReviewModalInfo(null)}
        aria-labelledby="Review MarketPlace Quiz"
        aria-describedby="Modal to Review MarketPlace Quiz"
      >
        <div>
          <ReviewModal
            setSnackbarText={setSnackbarText}
            reviewModalInfo={reviewModalInfo}
            setReviewModalInfo={setReviewModalInfo}
            handleClose={() => setReviewModalInfo(null)}
          />
        </div>
      </Modal>
      <SnackbarWrapper
        snackbarText={snackbarText}
        setSnackbarText={setSnackbarText}
      />
    </div>
  );
};

export default MarketPlace;
