import React from "react";
import { Alert } from "@mui/material";

const AudienceNotEqualWarning = () => {
  return (
    <>
      <Alert
        severity="warning"
        style={{ width: "100%", border: "1px solid darkorange" }}
      >
        <p style={{ fontWeight: 600, fontSize: 14 }}>
          The audience is not equal
        </p>
        <p style={{}}>
          Modules in this stream are deployed to different marketplaces. Some
          users will not be able to access all of the modules in this stream.
        </p>
      </Alert>
      <div style={{ height: 12 }} />
    </>
  );
};

export default AudienceNotEqualWarning;
