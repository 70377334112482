import API from "../instances/API";
import { clearTokens } from "../../storage/helpers";

const responseInterceptor = () =>
  API.interceptors.response.use(
    (response) =>
      new Promise((resolve) => {
        resolve(response);
      }),
    (error) => {
      if (!error.response) {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
      const { data, status, statusText } = error.response;
      if (
        status === 401 &&
        data.detail === "Authentication credentials were not provided." &&
        window.location.pathname !== "/"
      ) {
        clearTokens();
        // window.location = '/'
      } else if (status === 401 && data.code === "token_not_valid") {
        // Token is not valid, clear the token and push to Signin
        clearTokens();
        // window.location = '/';
      } else if (
        status === 401 &&
        statusText === "Unauthorized" &&
        window.location.pathname !== "/"
      ) {
        // This Person is an app user... boot to Signin Page. Should prob show message
        clearTokens();
        window.location = "/";
      } else {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }

      return Promise.reject(error);
    },
  );

export default responseInterceptor;
