import React from "react";
import { Lock } from "react-feather";
const ProFeatureChip = () => (
  <div
    style={{
      width: "fit-content",
      padding: "1px 4px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: 4,
      background: "#eeeeee84",
      border: "1px solid #eeeeee",
      borderRadius: 3,
    }}
  >
    <Lock height={11} width={11} color="slategrey" />
    <span style={{ fontSize: 11, color: "slategrey" }}>Pro+ Feature</span>
  </div>
);

export default ProFeatureChip;
