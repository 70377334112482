import React, { useState } from "react";
import { Collapse, IconButton, Paper } from "@mui/material";
import {
  ChevronDown,
  Eye,
  HelpCircle,
  Lock,
  Edit,
  ChevronUp,
} from "react-feather";
import styled from "styled-components";

const Wrapper = styled(Paper)`
  margin-top: 12px;
`;

const Head = styled.div`
  background: #eeeeee;
  height: 48px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: inherit;
  cursor: pointer;
`;

const Body = styled.div`
  width: 100%;
  min-height: 300px;
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

const PermissionBlock = styled.div`
  height: 100%;
  flex-grow: 1;
  min-height: 120px;
  flex-basis: 240px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  justify-content: flex-start;
  padding: 14px;
  text-align: center;
  background: #eeeeee;
  border-radius: 6px;

  p {
    font-weight: 500;
    font-size: 14px;
  }

  span {
    color: slategrey;
    font-size: 12px;
  }

  svg {
    height: 15px;
    width: 15px;
  }
`;

const Subtitle = styled.span`
  color: slategrey;
  font-weight: 600;

  // text-transform: uppercase;
`;

const AccessWarning = () => {
  const [open, setOpen] = useState(false);

  return (
    <Wrapper>
      <Head onClick={() => setOpen((p) => !p)}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 8,
            flexGrow: 1,
            // border: "1px solid red",
          }}
        >
          <HelpCircle color="#337ab7" height={16} width={16} />
          <span style={{ color: "black", fontWeight: 600, fontSize: 13 }}>
            How Do Admin Permissions Work?
          </span>
        </div>
        <IconButton size="small">
          {open ? (
            <ChevronUp height={20} width={20} color="black" />
          ) : (
            <ChevronDown height={20} width={20} color="black" />
          )}
        </IconButton>
      </Head>
      <Collapse in={open} style={{ width: "100%" }}>
        <Body>
          <Subtitle>Admin Permissions</Subtitle>
          <div style={{ height: 4 }} />

          <p>
            The admin access system has been redesigned to be more flexible.
            Admins are now granted access at the company level instead of the
            organizational level. Additionally, admins can customize access
            levels for each company, with write permissions for some and
            read-only access for others if required.
          </p>
          <div style={{ height: 12 }} />

          <Subtitle>Key Features</Subtitle>
          <div style={{ height: 4 }} />

          <ul style={{ paddingLeft: 20 }}>
            <li>
              Admin permissions are applied at the <b>company level</b>.
            </li>
            <li>
              Dashboard admins can have{" "}
              <b>admin permissions in multiple companies</b>.
            </li>
            <li>
              A dashboard admin can have write access in one company, and
              read-only access in another company.
            </li>
            <li>
              Admins with <b>write access</b> at a company, can{" "}
              <b>edit the permissions</b> of other admins within that company.
            </li>
          </ul>
          <div style={{ height: 12 }} />
          <Subtitle>New Permission Levels</Subtitle>
          <div style={{ height: 8 }} />

          <div
            style={{
              width: "100%",

              display: "flex",
              alignItems: "stretch",
              justifyContent: "stretch",
              gap: 12,
            }}
          >
            {" "}
            <PermissionBlock>
              <Edit />
              <p>Write Access</p>
              <span>
                The admin has access to the full functionality of the company
                dashboard. Can change other admins access.
              </span>
            </PermissionBlock>
            <PermissionBlock>
              <Eye />
              <p>Read Only Access</p>
              <span>
                The admin can view all dashboard pages for the company, but
                cannot change anything. Cannot change other admins access.
              </span>
            </PermissionBlock>
            <PermissionBlock>
              <Lock />
              <p>No Access</p>
              <span>
                Not an administrator at the company. Can not view or edit any
                dashboard pages for the company.
              </span>
            </PermissionBlock>
          </div>
        </Body>
      </Collapse>
    </Wrapper>
  );
};

export default AccessWarning;
