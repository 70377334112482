import React, { useEffect, useState } from "react";
import { Alert, Button } from "@mui/material";
import { List, X } from "react-feather";
import styled from "styled-components";
import API from "../../../../../../axios/instances/API";
import FunLoader from "../../../../../../components/Loaders/FunLoader";
import CustomDataTableIcons from "../../../../../../components/Table/CustomDataTableIcons";
import DateTooltip from "../../../../../../components/Table/DateTooltip";
import EngagementSelect from "../../../../../../components/Table/Paginated/EngagementSelect";
import SubmissionResultIndicator from "../../../../../../components/Table/SubmissionResultIndicator";
import TableTitle from "../../../../../../components/Table/TableTitle";
import DisplayScore from "../../../../../../components/UI/DisplayScore";
import {
  ENGAGEMENT_TYPES,
  ORDER_DIRECTIONS,
} from "../../../../../../storage/constants";
import { makeDefaultTableOptions } from "../../../../../../storage/helpers";
import SubmissionTypeChip from "../../../../../../components/SubmissionTypeChip";
import { CustomDatatableV2 } from "../../../../styles";
import SubmissionChipExtraCreditTooltip from "src/components/UI/SubmissionChipExtraCreditTooltip";
import CustomSearch from "../../../retailReports/allTimeSubmissionsReport/CustomSearch";
import CompanyFilter from "../CompanyFilter";
import CustomDownload from "./CustomDownload";
import CustomFooter from "./CustomFooter";
import CustomHeadLabel from "./CustomHeadLabel";
import SubmissionChipAssignedTooltip from "src/components/UI/SubmissionChipAssignedTooltip";

const options = makeDefaultTableOptions("Stream_Submissions");

export const ORDER_BY_OPTIONS = {
  ID: "id",
  USERS_NAME: "users_name",
  USERS_STATE: "users_state",
  QUIZ_NAME: "quiz_name",
  COMPUTED_SCORE: "computed_score",
  CREATED: "created",
};

const Wrapper = styled.div`
  padding: 12px;
  background: #eeeeee;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
`;

const SmallResetBtn = styled(Button)`
  max-height: 15px;
  min-height: 15px;
  min-width: 15px;
  max-width: 15px;
  height: 15px;
  width: 15px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: black;
  cursor: pointer;
  margin-left: auto;
  padding: 0px;

  &:hover {
    background: rgba(0, 0, 0, 0.7);
  }

  svg {
    color: white;
    height: 12px;
    width: 12px;
  }
`;

const rowsPerPage = 10;

const SubmissionsTable = ({ streamId, companyArr }) => {
  // general state
  const [queryData, setQueryData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  // query param state
  const [page, setPage] = useState(1);
  const [engagementType, setEngagementType] = useState(ENGAGEMENT_TYPES.ALL);
  const [searchText, setSearchText] = useState("");
  const [filterCompanyId, setFilterCompanyId] = useState(0);
  const [orderBy, setOrderBy] = useState(ORDER_BY_OPTIONS?.CREATED);
  const [orderDirection, setOrderDirection] = useState(
    ORDER_DIRECTIONS?.DESCENDING,
  );

  const [errorObj, setErrorObj] = useState(null);

  useEffect(() => {
    getData();
  }, [
    page,
    orderBy,
    engagementType,
    searchText,
    filterCompanyId,
    orderDirection,
  ]);

  const getData = async () => {
    const BASE_URL = `/dashboard/analytics/vendor-reports/streams/${streamId}/submissions/?`;
    const params = buildSearchParamsAsString();

    try {
      setIsLoading(true);
      const { data } = await API.get(`${BASE_URL}${params}`);
      setQueryData(data);
      setErrorObj(null);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setErrorObj(
        "There was an issue fetching the submissions data. Try refreshing the page.",
      );
    }
  };

  // apply options to table
  options.rowsPerPage = rowsPerPage;
  options.sort = false;
  options.search = false;
  options.download = false;
  options.print = false;

  options.customFooter = () => (
    <CustomFooter
      page={page}
      setPage={setPage}
      isLoading={isLoading}
      totalPages={queryData?.pages_count || 0}
      rowsPerPage={rowsPerPage}
      totalRows={queryData?.submissions_count}
    />
  );

  const buildSearchParamsAsString = (disablePagination = false) => {
    const obj = {
      enable_pagination: disablePagination ? 0 : 1,
      page_size: rowsPerPage,
      page: page,
      order_by: orderBy,
      order_direction: orderDirection,
    };

    if (engagementType !== ENGAGEMENT_TYPES.ALL) {
      obj.submission_source =
        engagementType === ENGAGEMENT_TYPES?.ASSIGNED ? "assigned" : "follower";
    }

    if (filterCompanyId) {
      obj.company_id = filterCompanyId;
    }

    if (searchText) {
      obj.search_text = searchText;
    }

    const searchParams = new URLSearchParams(obj);
    return searchParams.toString();
  };

  const columns = [
    {
      name: "created",
      label: "Date",
      options: {
        sort: false,
        customBodyRender: (value) => (
          <DateTooltip date={value} displayYear shouldLocalize />
        ),
        customHeadLabelRender: (props) => (
          <CustomHeadLabel
            title={props.label}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            orderDirection={orderDirection}
            setOrderDirection={setOrderDirection}
            orderKey={ORDER_BY_OPTIONS.CREATED}
            isOrderedByColumn={orderBy === ORDER_BY_OPTIONS.CREATED}
          />
        ),
      },
    },
    {
      name: "module",
      label: "Module",
      options: {
        sort: false,
        customHeadLabelRender: (props) => (
          <CustomHeadLabel
            title={props.label}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            orderDirection={orderDirection}
            setOrderDirection={setOrderDirection}
            orderKey={ORDER_BY_OPTIONS.QUIZ_NAME}
            isOrderedByColumn={orderBy === ORDER_BY_OPTIONS.QUIZ_NAME}
          />
        ),
      },
    },
    {
      name: "user",
      label: "User",
      options: {
        sort: false,
        customHeadLabelRender: (props) => (
          <CustomHeadLabel
            title={props.label}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            orderDirection={orderDirection}
            setOrderDirection={setOrderDirection}
            orderKey={ORDER_BY_OPTIONS.USERS_NAME}
            isOrderedByColumn={orderBy === ORDER_BY_OPTIONS.USERS_NAME}
          />
        ),
      },
    },
    {
      name: "state",
      label: "State/Province",
      options: {
        sort: false,
        customHeadLabelRender: (props) => (
          <CustomHeadLabel
            title={props.label}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            orderDirection={orderDirection}
            setOrderDirection={setOrderDirection}
            orderKey={ORDER_BY_OPTIONS.USERS_STATE}
            isOrderedByColumn={orderBy === ORDER_BY_OPTIONS.USERS_STATE}
          />
        ),
      },
    },
    {
      name: "type",
      label: "Type",
      options: {
        customBodyRender: (v) => {
          if (v === "Follower") {
            return <SubmissionChipExtraCreditTooltip isVendor />;
          }
          return <SubmissionChipAssignedTooltip />;
        },
      },
    },
    {
      name: "score",
      label: "Score",
      options: {
        sort: false,
        customBodyRender: (value) => <DisplayScore value={value} />,
        customHeadLabelRender: (props) => (
          <CustomHeadLabel
            title={props.label}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            orderDirection={orderDirection}
            setOrderDirection={setOrderDirection}
            orderKey={ORDER_BY_OPTIONS.COMPUTED_SCORE}
            isOrderedByColumn={orderBy === ORDER_BY_OPTIONS.COMPUTED_SCORE}
          />
        ),
      },
    },
    {
      name: "result",
      label: "Result",
      options: {
        customBodyRender: (value) => (
          <SubmissionResultIndicator value={value} />
        ),
      },
    },
  ];

  if (!queryData) {
    if (errorObj) {
      return (
        <Alert
          // onClose={() => setErrorObj(null)}
          severity="error"
          style={{
            width: "100%",
            border: "1px solid firebrick",
            marginBottom: 12,
          }}
        >
          <p style={{ color: "firebrick", fontSize: 14, fontWeight: 600 }}>
            Error
          </p>
          <p>{errorObj}</p>
        </Alert>
      );
    }

    return <Skeleton />;
  }

  const createTableDataFromSubmissions = (submissionsArray) => {
    return submissionsArray?.map((sub) => ({
      // id: sub?.id,
      created: new Date(sub?.created).toISOString(),
      module: sub?.quiz_name,
      user: sub?.users_name,
      state: sub?.users_state,
      type: sub?.is_follower_submission ? "Follower" : "Assigned",
      score: `${sub?.score}/${sub?.questions}`,
      result: sub?.passed ? "Yes" : "No",
    }));
  };

  const tableData = createTableDataFromSubmissions(queryData?.submissions);

  return (
    <Wrapper>
      {Boolean(errorObj) && (
        <Alert
          onClose={() => setErrorObj(null)}
          severity="error"
          style={{
            width: "100%",
            border: "1px solid firebrick",
            marginBottom: 12,
          }}
        >
          <p style={{ color: "firebrick", fontSize: 14, fontWeight: 600 }}>
            Error
          </p>
          <p>{errorObj}</p>
        </Alert>
      )}
      <div
        style={{
          height: 60,
          background: "white",
          width: "100%",
          display: "flex",
          alignItems: "center",
          borderRadius: 6,
          padding: "0px 10px",
        }}
      >
        <EngagementSelect
          engagementType={engagementType}
          setEngagementType={setEngagementType}
          setPage={setPage}
        />
        <div style={{ width: 8, minWidth: 8 }} />

        <CompanyFilter
          selectedCompany={filterCompanyId}
          setSelectedCompany={setFilterCompanyId}
          companiesArray={companyArr}
        />
        <div style={{ marginLeft: "auto" }} />
        <CustomSearch
          handleSearch={(text) => setSearchText(text)}
          handleClear={() => setSearchText(null)}
        />
        <div
          style={{
            height: 32,
            width: 1,
            background: "lightgray",
            margin: "0px 8px",
          }}
        />
        <CustomDownload
          tableData={tableData}
          buildSearchParamsAsString={buildSearchParamsAsString}
          streamId={streamId}
          createTableDataFromSubmissions={createTableDataFromSubmissions}
        />
      </div>
      <div
        style={{
          minHeight: 12,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "8px 0px",
          flexDirection: "column",
          gap: 4,
        }}
      >
        {Boolean(filterCompanyId) && (
          <FilterItem
            text="Filtered for Company"
            boldText={companyArr?.find((c) => c.id === filterCompanyId)?.name}
            handleRemove={() => setFilterCompanyId(0)}
          />
        )}
        {Boolean(searchText) && (
          <FilterItem
            text="Search for Keyword"
            boldText={searchText}
            handleRemove={() => setSearchText(null)}
          />
        )}
      </div>
      <div style={{ position: "relative" }}>
        {isLoading && (
          <div
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              background: "#ffffffed",
              zIndex: 1000,
              padding: 20,
              borderRadius: 6,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div>
              <FunLoader />
              <div style={{ height: 20 }} />

              <span style={{ color: "darkgrey" }}>Loading....</span>
            </div>
          </div>
        )}
        <CustomDatatableV2
          title={
            <div style={{ display: "flex", alignItems: "flex-start", gap: 6 }}>
              <TableTitle title="Submissions" isVendor Icon={List} />
              <SubmissionTypeChip
                isVendor
                submissionType={engagementType}
                small
              />
            </div>
          }
          options={options}
          columns={columns}
          data={tableData}
          components={{
            icons: CustomDataTableIcons,
          }}
        />
      </div>
    </Wrapper>
  );
};

export default SubmissionsTable;

const FilterItem = ({ handleRemove, text, boldText }) => {
  return (
    <div
      style={{
        height: 28,
        width: "100%",
        padding: "4px 8px",
        background: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        borderRadius: 6,
        border: "1px solid lightgray",
        gap: 5,
      }}
    >
      <span style={{ color: "slategrey" }}>{text}:</span>
      <spa style={{ color: "black", fontWeight: 500 }}>{boldText}</spa>

      <div style={{ marginLeft: "auto" }} />
      <SmallResetBtn onClick={handleRemove}>
        <X height={13} width={13} color="white" />
      </SmallResetBtn>
    </div>
  );
};

const Skeleton = () => {
  return (
    <div
      style={{
        width: "100%",
        padding: 12,
        background: "#eeeeee",
        borderRadius: 6,
      }}
    >
      <div
        style={{
          width: "100%",
          height: 60,
          background: "white",
          borderRadius: 8,
        }}
      />
      <div style={{ minHeight: 12 }} />
      <div
        style={{
          width: "100%",
          height: 400,
          background: "white",
          borderRadius: 8,
        }}
      ></div>
    </div>
  );
};
