import PropTypes from "prop-types";
import React, { lazy, Suspense, useState } from "react";
import { Helmet } from "react-helmet-async";
import LoaderWrapper from "../../../../components/Loaders/LoaderWrapper";
import SnackbarWrapper from "../../../../components/SnackbarWrapper";
import OnLoadError from "../../../../components/UI/OnLoadError";
import useAudience from "../../../../swr/hooks/Audience/useAudience";

const RetailAudience = lazy(() => import("./retail/index.js"));
const VendorAudience = lazy(() => import("./vendor/index.js"));

const AudienceDetail = ({ match }) => {
  const { id } = match.params;
  const [snackbarText, setSnackbarText] = useState("");
  const { data, isLoading, mutate, isError } = useAudience(id);

  if (isLoading) {
    return <LoaderWrapper text="Audience Details" />;
  }

  if (isError) {
    return <OnLoadError />;
  }

  if (data.audience.mappingType === "vendor") {
    return (
      <>
        <Helmet title="Spiffy | Custom Audience" />
        <Suspense fallback={<span />}>
          <VendorAudience
            data={data}
            setSnackbarText={setSnackbarText}
            id={id}
            mutate={mutate}
          />
        </Suspense>
        <SnackbarWrapper
          snackbarText={snackbarText}
          setSnackbarText={setSnackbarText}
        />
      </>
    );
  }

  const { audience } = data;

  return (
    <>
      <Helmet title="Spiffy | Audience Template" />
      <Suspense fallback={<span />}>
        <RetailAudience audience={audience} mutate={mutate} />
      </Suspense>
      <SnackbarWrapper
        snackbarText={snackbarText}
        setSnackbarText={setSnackbarText}
      />
    </>
  );
};

export default AudienceDetail;

AudienceDetail.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};
