import React, { useState, useContext } from "react";
import { Helmet } from "react-helmet-async";
import { Modal } from "@mui/material";
import { Tag } from "react-feather";
import useRoles from "../../../swr/hooks/Roles/useRoles";
import LoaderWrapper from "../../../components/Loaders/LoaderWrapper";
import SnackbarWrapper from "../../../components/SnackbarWrapper";
import RolesTable from "./RolesTable";
import CreateRole from "./CreateRole";
import PrivateHeader from "../../../components/PrivateHeader";
import { GlobalState } from "../../../store/GlobalState";
import OnLoadError from "../../../components/UI/OnLoadError";
import { AlertTitle, Alert } from "@mui/material";
import CreateButton from "../../../components/UI/CreateButton";

const Roles = () => {
  const { rolesLoading, isError } = useRoles();
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState("");
  const { state } = useContext(GlobalState);
  const { job } = state;
  const { read_only: readOnly } = state?.config?.user;

  const isManager = job === "M";

  if (rolesLoading) {
    return (
      <>
        <PrivateHeader header="Roles" Icon={Tag} />
        <LoaderWrapper text="Roles" />
      </>
    );
  }

  if (isError) {
    return (
      <>
        <PrivateHeader header="Roles" Icon={Tag} />
        <OnLoadError />;
      </>
    );
  }

  return (
    <>
      <Helmet title="Spiffy | Roles" />
      <PrivateHeader header="Roles" Icon={Tag} />
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: 16,
        }}
      >
        <CreateButton
          title="Create Role"
          handleClick={() => setCreateModalOpen(true)}
          disabled={readOnly || isManager}
        />

        <Alert severity="info">
          <AlertTitle style={{ fontWeight: "600" }}>What are roles?</AlertTitle>
          <ul>
            <li>
              {" "}
              Roles are used to group team members with similar job
              functionalities.
            </li>
            <li>
              Modules can be assigned to roles, and only the team members who
              have that role tag will receive the module.
            </li>
            <li>
              In this way, roles can be used to make sure team members are being
              assigned relevant content.
            </li>
          </ul>
        </Alert>

        <RolesTable readOnly={readOnly} isManager={isManager} />
      </div>

      <Modal
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        aria-labelledby="Create Role Modal"
        aria-describedby="Modal to Create Role"
      >
        <div>
          <CreateRole
            setSnackbarText={setSnackbarText}
            setCreateModalOpen={setCreateModalOpen}
          />
        </div>
      </Modal>
      <SnackbarWrapper
        snackbarText={snackbarText}
        setSnackbarText={setSnackbarText}
      />
    </>
  );
};

export default Roles;
