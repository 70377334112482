import React, { useState, useContext } from "react";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import { Modal, Button } from "@mui/material";
import SupervisorLocationTable from "./SupervisorLocationTable";
import AddSupervisorLocation from "./AddSupervisorLocation";
import useManagerDetail from "../../../../swr/hooks/Managers/useManagerDetail";
import PrivateHeader from "../../../../components/PrivateHeader";
import About from "./About";
import LoaderWrapper from "../../../../components/Loaders/LoaderWrapper";
import OnLoadError from "../../../../components/UI/OnLoadError";
import PromoteModal from "./PromoteModal";
import { GlobalState } from "../../../../store/GlobalState";
import { PlusCircle } from "react-feather";
import styled from "styled-components/macro";

const Container = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: stretch;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
  }
`;

const ManagerDetail = ({ match }) => {
  const { id } = match.params;
  const { isLoading, details, mutate, isError } = useManagerDetail(id);
  const [promoteModalInfo, setPromoteModalInfo] = useState(false);
  const { state } = useContext(GlobalState);
  const { config } = state;

  const { user } = config;
  const { read_only: readOnly } = user;

  if (isError) {
    return <OnLoadError />;
  }

  if (isLoading) {
    return (
      <>
        <Helmet title="Spiffy | Supervisor Detail" />
        <PrivateHeader header="Supervisor" secondary={true} />
        <LoaderWrapper text="Supervisor Details" />
      </>
    );
  }

  const { locations, manager } = details;
  const managerName = `${manager.firstName} ${manager.lastName}`;

  return (
    <>
      <Helmet title="Spiffy | Supervisor Detail" />
      <PrivateHeader header="Supervisor" secondary={managerName}>
        <Button
          disabled={readOnly}
          color="primary"
          variant="contained"
          startIcon={<PlusCircle height={14} width={14} />}
          onClick={() =>
            setPromoteModalInfo({
              name: managerName,
              id: manager.id,
              numCompanies: details.companies_where_user_is_manager,
            })
          }
        >
          Promote
        </Button>
      </PrivateHeader>
      <Container>
        <AddSupervisorLocation
          managerId={id}
          mutate={mutate}
          currentlyManaging={locations}
          readOnly={readOnly}
        />

        <About
          manager={manager}
          locations={locations}
          mutate={mutate}
          readOnly={readOnly}
        />
      </Container>
      <div style={{ height: 16, minHeight: 16 }} />
      <SupervisorLocationTable
        locations={locations}
        managerId={id}
        managerName={managerName}
        mutate={mutate}
        readOnly={readOnly}
      />

      <Modal
        open={Boolean(promoteModalInfo)}
        onClose={() => setPromoteModalInfo(false)}
        aria-labelledby="Promote Supervisor"
        aria-describedby="Give Supervisor Admin Privileges"
      >
        <div>
          <PromoteModal
            setPromoteModalInfo={setPromoteModalInfo}
            promoteModalInfo={promoteModalInfo}
          />
        </div>
      </Modal>
    </>
  );
};

export default ManagerDetail;

ManagerDetail.propTypes = {
  match: PropTypes.object.isRequired,
};
