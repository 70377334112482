import React from "react";
import { Typography, Tooltip, Button } from "@mui/material";
import { FilePlus } from "react-feather";
import { Wrapper } from "./styles";
import useAudienceToolStore from "../../../store/useAudienceToolStore";
import TEST_IMG from "../../../assets/images/table.png";
import styled from "styled-components";

const TableButton = styled(Button)`
  padding: 1px;
  margin-left: 17px;
  margin-top: 4px;
  border-radius: 4px;

  &:hover {
    background: lightgray;
  }
`;

const CurrentOther = ({ shouldTableBeViewable = true }) => {
  const viewCurrentAudienceInModal = useAudienceToolStore(
    (state) => state.viewCurrentAudienceInModal,
  );

  return (
    <Wrapper
      style={{
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        flexDirection: "column",
        borderRadius: 4,
        padding: 6,
        backgroundColor: "white",
        border: "1px solid slategray",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          width: "100%",
        }}
      >
        <FilePlus
          style={{
            height: 14,
            width: 14,
            color: "black",
            marginRight: 6,
            borderRadius: 2,
          }}
        />
        <Typography variant="subtitle2" underline style={{ color: "black" }}>
          Custom Audience Matrix
        </Typography>
      </div>
      <span
        style={{
          fontSize: 11,
          fontWeight: "400",
          color: "darkgrey",
          paddingLeft: 20,
        }}
      >
        This module will be deployed to team members based on the role(s) and
        location(s) combination chosen in the table.
      </span>
      {shouldTableBeViewable && (
        <Tooltip arrow title="View matrix">
          <TableButton onClick={viewCurrentAudienceInModal}>
            <img
              style={{
                height: 50,
                width: 90,

                borderRadius: 4,
              }}
              alt="view-table"
              src={TEST_IMG}
            />
          </TableButton>
        </Tooltip>
      )}
    </Wrapper>
  );
};

export default CurrentOther;
