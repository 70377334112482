import React from "react";
import { Calendar } from "react-feather";
import { StyledItem } from "../../../styles";
import {
  USER_TYPES,
  QUIZSTATES,
  LIBRARY_MODAL_KEYS,
} from "../../../../../storage/constants";

const EditEndDate = ({
  id,
  setModalState,
  userType,
  moduleState,
  isRetailerModuleFromMarketplace,
  handleClose,
}) => {
  if (![QUIZSTATES.LIVE, QUIZSTATES.PENDING].includes(moduleState)) {
    return null;
  }

  if (!isRetailerModuleFromMarketplace) return null;

  if (userType !== USER_TYPES.RETAIL_ADMIN) {
    return null;
  }

  return (
    <StyledItem
      onClick={() => {
        setModalState({ key: LIBRARY_MODAL_KEYS.EDIT_END_DATE, id });
        handleClose();
      }}
    >
      <Calendar />
      <span>Edit End Date</span>
    </StyledItem>
  );
};

export default EditEndDate;
