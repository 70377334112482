import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  border-radius: 4px;
  padding: 6px;
  background-color: white;
  width: 100%;
  border: 1px solid lightgray;
  height: 100%;
`;

export const ScrollWrapper = styled.div`
  display: flex;
  padding-left: 18px;

  align-items: flex-start;
  justify-content: flex-start;
  gap: 2px;
  flex-wrap: wrap;
  margin-top: 8px;
  padding-bottom: 8px;
  min-height: 40px;

  max-height: 140px;
  overflow: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
`;
